import styled from 'styled-components';

import { roundedStyles } from 'common/styles/components/wrappers';

export const MessagesButton = styled.button<{ disabled?: boolean }>`
	display: flex;
	width: 100%;
	height: 100%;
	cursor: pointer;
	text-align: left;
	margin: auto;
	${roundedStyles}
	border: none;
	gap: ${(props) => props.theme.spacing['1']};
	margin-bottom: ${(props) => props.theme.spacing['1']};

	> div {
		width: 100%;
	}

	${(props) =>
		props.disabled &&
		`
		opacity: 0.6;
	`}
`;

export const ErrorsWrapper = styled.div`
	margin: ${(props) => props.theme.spacing['1']} 0;
`;

export const AlertListItem = styled.li`
	margin: 0;
	padding: 0;
`;

export const CategoryWrapper = styled.div<{ hide: boolean }>`
	${(props) =>
		props.hide &&
		`
		display: none;
	`}
`;

export const CategoryHeaderWrapper = styled.div`
	margin-bottom: ${(props) => props.theme.spacing['0_5']};
`;

import styled from 'styled-components';

import { CustomButton } from 'app/styles/components/button/button';

export const FormWrapper = styled.div`
	margin: ${(props) => props.theme.spacing['2']} 0
		${(props) => props.theme.spacing['1']};
	display: flex;
	flex-wrap: wrap;
	align-items: center;
`;

export const TextFieldWrapper = styled.div`
	margin: 0 ${(props) => props.theme.spacing['1']};
	width: 100%;
	margin-bottom: -${(props) => props.theme.spacing['1']};
	margin-left: 0;
	margin-right: 0;
`;

export const ButtonsWrapper = styled.div`
	display: flex;
	@media screen and (max-width: 380px) {
		justify-content: space-evenly;
		flex-wrap: wrap;
	} ;
`;

export const FeedbackButton = styled(CustomButton)<{ last?: boolean }>`
	${(props) =>
		props.last &&
		`
			margin-left: ${props.theme.spacing['1']};
			padding-left:0;
			padding-right:0;
			@media screen and (max-width: 380px) {
				margin-left:0
			}

		`}
`;

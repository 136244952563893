import { Blob } from 'buffer';
import { Response, ResponseError } from 'common/typings/response';
import { LanguagesType } from 'common/lang/lang';

import { CommonApiService } from '../config';

export interface Category {
	id: string;
	name: LanguagesType;
}

export interface Subcategory {
	id: string;
	name: LanguagesType;
}

export interface CreateNewExpertConversationPayload {
	patientName: string;
	patientSsn: string;
	patientEmail: string;
	mobile: string;
	sms: boolean;
	subject: string;
	text: string;
	attachments?: Array<File>;
	[key: string]: any;
}

export interface ReplyConversation {
	content: string | File;
	type: 'file' | 'text';
	[key: string]: any;
}

export type Conversations = Array<{
	id: string;
	isClosed?: boolean;

	// for experts only
	unreadMessages?: number;
	name?: string;
	subject?: string;

	// for patients
	categoryId?: string;
	categoryName?: LanguagesType;
	subcategoryId?: string;
	subcategoryName?: LanguagesType;
	expertName?: string;
	expertSpecializations?: Array<{
		id: string;
		name: LanguagesType;
	}>;
	lastMessageDate?: number;
	hasAttachments?: boolean;
}>;

export interface ConversationFilters {
	subject?: string;
	name?: string;
	ssn?: string;
}

export type ConversationMessages = Array<{
	id: string;
	name: string;
	date: number;
	content: string | Blob;
	isRead: boolean;
	isOwn: boolean;
	type: 'file' | 'text';
}>;

export type CategoriesRequests = Array<{
	id: string;
	awaitingRequests?: number | string;
	categoryId?: string;
	categoryName?: LanguagesType;
	subcategoryId?: string;
	subcategoryName?: LanguagesType;
}>;

export type CategoriesRequestMessages = Array<{
	id: string;
	name: string;
	date: number;
	content: string;
}>;

export interface CreateNewPatientConversationPayload {
	text: string; // initial message
	attachments?: Array<File>; // initial attachments
	categoryId: string;
	subcategoryId: string;
	messageCode?: string;
	[key: string]: any;
}

export interface CategoriesListExpert {
	id: string;
	name: string;
	specializations: Array<{
		id: string;
		name: LanguagesType;
	}>;
}

export interface CategoriesListSpecialization {
	id: string;
	name: LanguagesType;
	experts: Array<{
		id: string;
		name: string;
	}>;
}

export type CategoriesList = Array<{
	id: string;
	name: LanguagesType;
	subcategoriesNumber?: number;
	subcategories?: Array<Subcategory>;
}>;

export type SubcategoriesList = Array<{
	id: string;
	name: LanguagesType;
	subcategoriesNumber?: number;
	isDisabled?: boolean;
	openAccess?: boolean;
	addedExperts?: Array<CategoriesListExpert>;
}>;

export interface CreateEditCategorySubcategory {
	isSubcategory: boolean;
	subcategoryParentCategoryId?: string;
	id?: string;
	name?: LanguagesType;
	openAccess?: boolean;
	isDisabled?: boolean;
	addedExperts?: Array<CategoriesListExpert>;
}

export interface ExpertSearchParams {
	searchQuery?: string;
	specialization?: string;
}

export interface ExpertSearchResponse {
	experts: Array<{
		id: string;
		name: string;
		specializations: Array<{
			id: string;
			name: LanguagesType;
		}>;
	}>;
}

export interface CategoriesFilter {
	includeSubcategories?: boolean;
	isRestricted?: boolean;
	isDisabled?: boolean;
}

class AsyncMessagingService {
	apiService: CommonApiService = new CommonApiService();

	appType: string = 'expert';

	public setApiService(apiService: CommonApiService) {
		this.apiService = apiService;

		if (this.apiService.type === 'patient') {
			this.appType = 'patient';
		} else if (this.apiService.type === 'admin') {
			this.appType = 'admin';
		}
	}

	private convertPayloadToFormData(
		data:
			| CreateNewExpertConversationPayload
			| ReplyConversation
			| CreateNewPatientConversationPayload,
	) {
		const formData = new FormData();
		Object.keys(data).forEach((key) => {
			if (Array.isArray(data[key])) {
				const items = data[key] || [];
				items.forEach((file: File) => {
					formData.append(`${key}[]`, file);
				});
			} else if (data[key]) {
				formData.append(key, data[key]);
			}
		});

		return formData;
	}

	public async sendNewMessageExpert(
		data: CreateNewExpertConversationPayload,
	): Promise<ResponseError & {}> {
		const formData = this.convertPayloadToFormData(data);

		const response: Response<ResponseError> = await this.apiService.post(
			`expert/async-messaging/create-conversation`,
			formData,
			{},
			{
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			},
		);

		return response.data;
	}

	public async replyAsyncMessage(
		data: ReplyConversation,
		conversationId: string,
	): Promise<ResponseError & { messageId: string }> {
		const formData = this.convertPayloadToFormData(data);

		const response: Response<{ messageId: string }> =
			await this.apiService.post(
				`${this.appType}/async-messaging/conversations/${conversationId}/send-message`,
				formData,
				{},
				{
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				},
			);
		return response.data;
	}

	public async getConversations(
		isClosed?: boolean,
		filters?: ConversationFilters,
	): Promise<{ items: Conversations }> {
		const response: Response<ResponseError & { items: Conversations }> =
			await this.apiService.get(
				`${this.appType}/async-messaging/conversations`,
				{
					...(isClosed ? { isClosed: 'true' } : {}),
					...(filters?.name ? { name: filters.name } : {}),
					...(filters?.ssn ? { ssn: filters.ssn } : {}),
					...(filters?.subject ? { subject: filters.subject } : {}),
				},
			);

		return {
			...response.data,
			items: response.data.items.map((item: any) => ({
				...item,
			})),
		};
	}

	public async getConversationMessages(
		conversationId: string,
		subcategoryId?: string,
	): Promise<ResponseError & { items: ConversationMessages }> {
		let path = `${this.appType}/async-messaging/conversations/${conversationId}/messages`;

		if (subcategoryId) {
			path = `${this.appType}/async-messaging/subcategories/${subcategoryId}/messages/${conversationId}`;
		}

		const response: Response<{ items: ConversationMessages }> =
			await this.apiService.get(path);

		const itemsTransformed = response.data.items.map(
			(item: ConversationMessages[0]) => ({
				...item,
				date: new Date(item.date).getTime(),
			}),
		);

		return {
			...response.data,
			items: itemsTransformed,
		};
	}

	public async endConversation(conversationId: string) {
		const response: Response<ResponseError & {}> = await this.apiService.post(
			`expert/async-messaging/conversations/${conversationId}/end`,
		);

		return response.data;
	}

	public async markConversationAsRead(conversationId: string) {
		const response: Response<ResponseError & {}> = await this.apiService.post(
			`${this.appType}/async-messaging/conversations/${conversationId}/mark-as-read`,
		);

		return response.data;
	}

	public async getCategoriesRequests(
		includeSubcategories?: boolean,
	): Promise<{ items: CategoriesRequests }> {
		const response: Response<{ items: CategoriesRequests }> =
			await this.apiService.get(`${this.appType}/async-messaging/categories`, {
				includeSubcategories,
			});

		return response.data;
	}

	public async getCategoriesRequestMessages(
		subcategoryId: string,
	): Promise<{ items: CategoriesRequestMessages }> {
		const response: Response<{ items: CategoriesRequestMessages }> =
			await this.apiService.get(
				`${this.appType}/async-messaging/categories/${subcategoryId}/requests`,
			);

		return response.data;
	}

	public async replyCategoryMessage(
		categoryId: string,
		patientId: string,
	): Promise<ResponseError> {
		const response: Response<ResponseError> = await this.apiService.post(
			`${this.appType}/async-messaging/categories/${categoryId}/reply/${patientId}`,
		);
		return response.data;
	}

	public async getCategoriesList(
		filters: CategoriesFilter,
	): Promise<{ items: CategoriesList }> {
		const response: Response<{ items: CategoriesList }> =
			await this.apiService.get(
				`${this.appType}/async-messaging/categories`,
				filters,
			);

		return response.data;
	}

	public async getSubcategoriesList(
		categoryId: string,
	): Promise<{ items: SubcategoriesList }> {
		let url = `${this.appType}/async-messaging/subcategories/${categoryId}`;
		if (this.appType === 'admin') {
			url = `admin/async-messaging/categories/${categoryId}/subcategories`;
		}

		const response: Response<{ items: SubcategoriesList }> =
			await this.apiService.get(url);

		return response.data;
	}

	public async sendNewMessagePatient(
		data: CreateNewPatientConversationPayload,
	): Promise<ResponseError> {
		const formData = this.convertPayloadToFormData(data);

		const response: Response<ResponseError> = await this.apiService.post(
			`${this.appType}/async-messaging/request-conversation`,
			formData,
			{},
			{
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			},
		);

		return response.data;
	}

	public async saveCategory({
		id,
		name,
		openAccess,
	}: CreateEditCategorySubcategory): Promise<
		ResponseError & { categoryId?: string }
	> {
		const urlPart = id ? `${id}/update` : 'create';

		const response: Response<
			ResponseError & { categoryId?: string; subcategoryId?: string }
		> = await this.apiService.post(
			`${this.appType}/async-messaging/categories/${urlPart}`,
			{
				name,
				openAccess,
			},
		);
		return response.data;
	}

	public async saveSubcategory({
		subcategoryParentCategoryId,
		id,
		name,
		openAccess,
		isDisabled,
		addedExperts,
	}: CreateEditCategorySubcategory): Promise<
		ResponseError & { subcategoryId?: string }
	> {
		const urlPart = id
			? `${id}/update`
			: `${subcategoryParentCategoryId}/create`;

		const response: Response<
			ResponseError & { categoryId?: string; subcategoryId?: string }
		> = await this.apiService.post(
			`${this.appType}/async-messaging/subcategories/${urlPart}`,
			{
				name,
				openAccess,
				isDisabled,
				addedExperts: addedExperts?.map(
					(expert: CategoriesListExpert) => expert.id,
				),
			},
		);
		return response.data;
	}

	public async searchForExperts({
		searchQuery,
		specialization,
	}: ExpertSearchParams): Promise<ExpertSearchResponse> {
		const response: Response<ExpertSearchResponse> = await this.apiService.get(
			`${this.appType}/async-messaging/experts/list`,
			{
				searchQuery,
				specialization,
			},
		);

		return response.data;
	}

	public async validateMessageCode(
		messageCode: string,
	): Promise<{ category: Category; subcategory: Subcategory } & ResponseError> {
		const response: Response<any /* MessagingCommonMutationResponse */> =
			await this.apiService.post(
				`${this.appType}/async-messaging/message-code/validate/${messageCode}`,
			);

		return response.data;
	}

	public async markMessageCodeAsUsed(
		messageCode: string,
	): Promise<{} & ResponseError> {
		return this.apiService.post(
			`${this.appType}/async-messaging/message-code/use/${messageCode}`,
		);
	}
}

export default new AsyncMessagingService();

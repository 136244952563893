export const se = {
	'pageTitles.authentication': 'Autentisering',
	'pageTitles.chat': 'Chat',
	'pageTitles.contact': 'Kontaktinformation',
	'pageTitles.feedback': 'Feedback',
	'pageTitles.terms': 'Villkor',
	'pageTitles.serviceInfo': 'Instruktioner',
	'pageTitles.serviceSelection': 'Distansmottagning',
	'pageTitles.bookedAppointments': 'Bokningar',
	'pageTitles.appointmentHistory': 'Tidigare bokningar',
	'pageTitles.appointmentHistoryDetails': 'Detaljer för tidigare bokningar',
	'pageTitles.bookAppointment': 'Ny tidsbokning',
	'pageTitles.changeMyLocation': 'Byt plats',
	'pageTitles.landing': 'Landningssida',
	'appointmentHistory.title': 'Tidigare bokningar',
	'appointmentHistory.explanation1': 'Vi sparar chattsamtalen i tjänsten i ',
	'appointmentHistory.explanation2': '90 dagar',
	'appointmentHistory.explanation3':
		'. Du kan också se och ladda ner de dokument som du har fått i samband med ett chattsamtal. Vi rekommenderar att du laddar ner dessa för eget bruk om du behöver dem även i fortsättningen.',
	'appointmentHistory.subtitle': 'Samtal',
	'appointmentHistory.list.conversed': 'Diskussioner som förts',
	'appointmentHistory.list.document': 'Dokument',
	'appointmentHistoryDetails.error': 'Fel vid laddning av data',
	'common.errors': 'Fel',
	'common.you': 'Du',
	'common.start': 'Starta mötet',
	'common.minutes': 'minuter',
	'common.skipContent': 'Hoppa över innehåll',
	'common.skip': 'Hoppa över',
	'common.send': 'Skicka',
	'common.saved': 'Sparad',
	'common.writeMessage': 'Skriv ett meddelande',
	'common.welcome': 'Välkommen',
	'common.hello': 'Hej',
	'common.back': 'Tillbaka',
	'common.continue': 'Fortsätt',
	'common.close': 'Stäng',
	'common.backToContact': 'Tillbaka till kontaktinformation',
	'common.backToServiceSelection': 'Tillbaka till startsidan',
	'common.backToAppointmentHistory': 'Tillbaka till tidigare bokningar',
	'common.backToHomepage': 'Tillbaka',
	'common.backToMessages': 'Tillbaka till meddelanden',
	'common.next': 'Nästa',
	'common.save': 'Spara',
	'common.ok': 'OK',
	'common.cancel': 'Avboka',
	'common.logOut': 'Logga ut',
	'common.logOutInfo':
		'Är det säkert att du vill logga ut? Du måste autentisera dig igen för att logga in.',
	'common.menuLink': 'Meny',
	'common.savingError': 'Fel vid sparande. Försök igen senare.',
	'common.mainMenu.contact': 'Min kontaktinformation',
	'common.mainMenu.terms': 'Villkor',
	'common.mainMenu.howServiceWorks': 'Hur tjänsten fungerar',
	'common.mainMenu.remoteAppointments': 'Digital mottagning',
	'common.mainMenu.yourAppointments': 'Dina bokningar',
	'common.mainMenu.actOnBehalf': 'Uträtta ärenden åt någon annan',
	'common.mainMenu.accessibilityStatement': 'Tillgänglighetsförklaring',
	'common.mainMenu.changeMyLocation': 'Byt plats',
	'common.mainMenu.history': 'Tidigare bokningar',
	'common.mainMenu.asyncMessages': 'Dina meddelanden',
	'common.mainMenu.bookAppointment': 'Ny tidsbokning',
	'common.mainMenu.section1': 'Hur kan vi hjälpa dig?',
	'common.mainMenu.section2': 'Om tjänsten',
	'common.mainMenu.authenticate': 'Autentisering',
	'common.new': 'Ny',
	'cookies.headline': 'Vi använder kakor på vår webbplats.',
	'cookies.body':
		'Genom att godkänna alla kakor säkerställer du bästa möjliga användarupplevelse. {{link}} och användningen av dem eller ändra dina inställningar för kakor.',
	'cookies.body.link': 'Läs mer om kakor ',
	'cookie.modal.link': 'Ändra inställningar för kakor',
	'cookies.button': 'Godkänn kakor',
	'cookies.modal.headline': 'Inställningar för kakor',
	'cookies.modal.top':
		'Vi vill erbjuda dig bästa möjliga service även på nätet. Därför samlar vi information om användningen av webbplatsen med hjälp av interna och externa verktyg. Vi sparar inte information som direkt kan identifiera en person i uppföljningsverktygen. Den information som skickas används för att utveckla verksamheten och för marknadsföring. Dina inställningar för kakor sparas och tillämpas på alla verktyg som vi använder. Du kan när som helst ändra dina val eller rensa kakorna i din webbläsare.',
	'cookies.modal.required.headline': 'Nödvändiga kakor',
	'cookies.modal.required.text':
		'Med de nödvändiga kakorna säkerställer vi att webbplatsen fungerar tillförlitligt och säkert. Med deras hjälp samlar vi exempelvis in statistisk information om användningen av tjänsterna och säkerställer att bland annat språkinställningarna fungerar korrekt. Användning av nödvändiga kakor kan endast förhindras via webbläsarinställningarna. Vi lämnar inte ut uppgifter utanför Terveystalo.',
	'cookies.modal.functional.headline': 'Kakor för utveckling av verksamheten',
	'cookies.modal.functional.text':
		'Med hjälp av den information som samlas in genom kakorna för utveckling av verksamheten undersöker vi bland annat besökarnas aktivitet och beteende i våra olika webb-och mobiltjänster. Utifrån informationen om användarnas beteende skapar vi olika användarklassificeringar och -modeller samt utvecklar en personlig användarupplevelse i olika servicekanaler. Vi lämnar inte ut uppgifter utanför Terveystalo.',
	'cookies.modal.targeting.headline': 'Riktningskakor',
	'cookies.modal.targeting.text':
		'Med hjälp av riktningskakorna får du innehåll som intresserar dig i stället för innehåll och marknadsföringskommunikation som riktar sig till alla. Terveystalo kan tillhandahålla innehåll och marknadsföringskommunikation som intresserar dig i sina webb- och mobiltjänster samt i samarbetspartners tjänster. Vi kan välja ut innehåll som motsvarar dina intressen exempelvis utifrån vilka webbplatser du besökt eller vilket innehåll du har tittat på. Vi lämnar inte ut uppgifter utanför Terveystalo.',
	'cookies.modal.marketing.headline': 'Marknadsföringskakor',
	'cookies.modal.marketing.text':
		'Med hjälp av marknadsföringskakorna kan vi rikta aktuell marknadsföring till dig på webbplatser och i sociala nätverkstjänster som hör till vårt reklamnätverk (Facebook, Google, Instagram, LinkedIn, Twitter och YouTube). Användningen av marknadsföringskakor kan innebära att information lämnas ut utanför Terveystalo för användning i reklamnätverk. Samarbetspartnerna kan använda informationen även för eget bruk, till exempel för att erbjuda dig andra tjänster som intresserar dig.',
	'cookies.modal.submit': 'Godkänn valen',
	'progressSection.item.authentication': 'Autentisering',
	'progressSection.item.serviceSelection': 'Val av tjänst',
	'progressSection.item.remoteAppoitment': 'Digitalt besök',
	'authentication.identificationHeadline':
		'Hej! Vänligen autentisera för att fortsätta till tjänsten.',
	'authentication.identificationBody':
		'Tjänsten hanterar privat information som patientjournaler och för att visa korrekt information kräver tjänsten stark autentisering. Du kan göra detta med dina bankuppgifter eller via autentisering i mobilen.',
	'authentication.identificationButton': 'Till identifiering',
	'authentication.identificationLockText': 'Anslutningen är krypterad',
	'authentication.actOnBehalfTitle': 'Uträttar du ärenden åt någon annan?',
	'authentication.actOnBehalfContent':
		'För att kunna uträtta ärenden åt någon annan måste du först legitimera dig och ansöka om auktorisation. När du har loggat in kan du välja att uträtta ärenden åt någon annan bakom menyn.',
	'contact.headline': 'Din kontaktinformation',
	'contact.mainText': 'Säkerställ att vår information om dig är uppdaterad.',
	'contact.firstName': 'Förnamn',
	'contact.lastName': 'Efternamn',
	'contact.street': 'Gatuadress',
	'contact.code': 'Postnummer',
	'contact.city': 'Ort',
	'contact.mobile': 'Mobilnummer (för bokningspåminnelser)',
	'contact.nationality': 'Nationalitet',
	'contact.country': 'Land',
	'contact.email': 'E-postadress',
	'contact.error': 'Vänligen fyll i',
	'terms.headline': 'Villkor',
	'terms.subtitle': 'Vänligen bekanta dig med våra villkor.',
	'terms.acceptButton': 'Godkänn och fortsätt',
	'welcome.headline': 'Så här fungerar tjänsten',
	'welcome.button': 'Fortsätt till tjänsten',
	'welcome.dontShowMessage': 'Visa inte detta igen',
	'serviceSelection.headline': 'Digitala mottagningar',
	'serviceSelection.chatCode.inputField': 'Ange en chattkod',
	'serviceSelection.chatCode.submit': 'Starta mötet',
	'serviceSelection.chatCode.error.101': 'Användaren är inte autentiserad.',
	'serviceSelection.chatCode.error.201': 'Chattkoden är inte giltig.',
	'serviceSelection.chatCode.error.202':
		'Chattkoden kan inte öppnas just nu. Var vänlig kontrollera datum och tid för ditt möte.',
	'serviceSelection.chatCode.error.203': 'Chattkoden har gått ut.',
	'serviceSelection.chatCode.error.204': 'Chattkoden har redan använts.',
	'serviceSelection.chatCode.error.500': 'Fel vid behandlingen av chattkoden.',
	'serviceSelection.chatCode.error.501': 'Fel vid behandlingen av chattkoden.',
	'serviceSelection.availableServices': 'Tillgängliga tjänster',
	'serviceSelection.chatCode.headline':
		'Tjänster för dig som använder chattkod',
	'serviceSelection.chatCode.lead':
		'Använd en chattkod för att komma direkt till den expert du behöver.',
	'serviceSelection.chatCode.infoLink': 'Var kan jag få en chattkod?',
	'serviceSelection.chatCode.infoButton': 'Vad är chattkoder?',
	'serviceSelection.chatCode.services': 'Tjänster som kräver en chattkod',
	'serviceSelection.chatCode.infoModal.headline': 'Vad är en chattkod?',
	'serviceSelection.chatCode.infoModal.body':
		'En chattkod ger dig direkt åtkomst till den tjänst du blivit tilldelad av vårdpersonalen i din kommun. Du kan ha fått en chattkod via någon annan tjänst som din kommun tillhandahåller, till exempel när du har träffat eller talat med en sjuksköterska. Genom att ange en chattkod kan vi direkt ge dig åtkomst till den tjänst du behöver!',
	'serviceSelection.chatCode.infoModal.body.second':
		'Du kan hitta mer information om huruvida din kommun tillhandahåller chattkoder via kommunen.',
	'serviceSelection.chatCode.infoModal.submit': 'Okej',
	'serviceSelection.yourAppointments.future.title': 'Framtida bokningar',
	'serviceSelection.yourAppointments.past.title': 'Tidigare bokningar',
	'serviceSelection.yourAppointments': 'Dina bokningar',
	'serviceSelection.yourAppointments.text':
		'Du kan ansluta till mötet 5 minuter innan det startar',
	'serviceSelection.yourAppointments.cancel':
		'Du kan avboka det här mötet fram till 24 timmar innan det börjar',
	'serviceSelection.yourAppointmentsModal.text':
		'Ditt möte med {{name}} börjar om 5 minuter. Det kan ta en stund innan mötet börjar.',
	'serviceSelection.yourAppointmentsModal.submit': 'Starta mötet',
	'serviceSelection.bookings.newAppointment.button': 'Ny tidsbokning',
	'serviceSelection.noUpcomingAppointments': 'Du har inte några bokningar',
	'serviceSelection.groupVideo': 'Gruppmottagning',
	'serviceSelection.groupVideo.host': 'Arrangören',
	'serviceSelection.groupVideo.multiple': 'flera deltagare',
	'serviceSelection.book.oneToOne': 'Bokning med {{name}}',
	'serviceSelection.book.group':
		'Bokning med mera deltagaren (arrangör: {{name}})',
	'appointmentModal.headline': 'Välkommen till ditt digitala möte!',
	'appointmentModal.body.one': 'Vi kopplar dig nu till mötet.',
	'appointmentModal.body.two':
		'Om du vill påskynda ditt ärende, kan du svara på symptomförfrågan. Läkaren eller sjukskötaren som tar emot dig får en sammanfattning av förfrågan.',
	'appointmentModal.button': 'Starta mötet',
	'appointmentModal.questionnaire.button': 'Fyll i symptomförfrågan',
	'appointmentModal.mandatory.body':
		'Besvara symptomförfrågan innan du ansluter till mötet så att personen du träffar får en sammanfattning inför mötet',
	'appointmentModal.mandatory.button': 'Besvara frågorna och gå till mötet',
	'appointmentModal.cancellable.title': 'Ditt kommande möte',
	'appointmentModal.cancellable.toast': 'Möte avbokat',
	'appointmentModal.cancellable.button.cancel': 'Avboka möte',
	'appointmentModal.cancellable.lead.top':
		'u kan ansluta till mötet 5 minuter innan det startar.',
	'appointmentsModal.cancellable.lead.middle':
		'Om du behöver kan du avboka detta möte fram till 24 timmar innan det börjar. Om ditt möte börjar inom 24 timmar måste du kontakta tjänsteleverantören för att avboka mötet.',
	'appointmentsModal.cancellable.lead.bottom':
		'Om du behöver ändra din mötestid måste du först avboka din befintliga tid och sedan boka en ny.',
	'appointmentsModal.cancel.groupchat':
		'Om du behöver avbryta gruppmottagningen, bör du kontakta tjänsteleverantören direkt.',
	'chat.headline': 'Digitalt besök',
	'chat.body':
		'Du kan chatta med experten och skicka foton eller filer med hjälp av gem-ikonen i fönstrets nedre högra hörn.',
	'chat.button.help': 'Hjälp',
	'feedback.title': 'Tack! Vänligen ge oss feedback.',
	'feedback.nps.lead':
		'Hur troligt är det att du skulle rekommendera denna tjänst till andra?',
	'feedback.ces.lead': 'Hur enkelt var det att använda denna tjänst?',
	'feedback.careSatisfaction.lead':
		'Blev ditt ärende löst, eller fick du de instruktioner du behöver?',
	'feedback.form.lead':
		'Vi vill gärna höra vad du tycker så lämna gärna feedback om du vill. All feedback du ger oss kommer att användas för att förbättra tjänsten.',
	'feedback.veryUnlikely': 'Mycket osannolikt',
	'feedback.veryLikely': 'Mycket troligt',
	'feedback.veryHard': 'Mycket svårt',
	'feedback.veryEasy': 'Mycket lätt',
	'feedback.careSatisfaction.bad': 'Inte alls',
	'feedback.careSatisfaction.good': 'Jag fick allt jag behövde',
	'feedback.thankYou.title': 'Mötet avslutad!',
	'feedback.textarea.title': 'Respons',
	'logout.headline': 'Du har blivit utloggad från tjänsten.',
	'logout.text':
		'Tack för att du använder vår tjänst. Du kan nu stänga denna sida.',
	'logout.text.second':
		'Om du behöver använda tjänsten igen kan du logga in igen.',
	'logout.expired':
		'Din session har löpt ut och du har blivit automatiskt utloggad, vänligen logga in igen om du behöver använda tjänsten.',
	'actOnBehalfModal.title': 'Uträtta ärenden åt någon annan',
	'actOnBehalfModal.lead':
		'Om du uträttar ärenden åt någon annan behöver du en Suomi.fi-fullmakt.',
	'actOnBehalfModal.actingAsSomeone': 'Du uträttar ärenden åt {{name}}',
	'actOnBehalfModal.button.actingAsMyself': 'Jag uträttar egna ärenden',
	'actOnBehalfModal.or': 'eller',
	'actOnBehalfModal.button.actingAsSomeone': 'Uträttar ärenden åt någon annan',
	'actOnBehalfModal.button.actingAsSomeoneElse':
		'Sköter ärenden på någon annans vägnar',
	'actOnBehalfModal.willtakeYouMessage': 'Detta tar dej till suomi.fi',
	'selectedOrganizationModal.title':
		'Vilken organisations tjänster vill du använda?',
	'selectedOrganizationModal.title.none':
		'Tjänsterna är inte ännu i bruk i ditt område',
	'selectedOrganizationModal.text':
		' Baserat på dina adressuppgifter verkar det som att du kan använda flera organisationers tjänster. Vilken organisations tjänster vill du använda?',
	'selectedOrganizationModal.text.none':
		'Tyvärr verkar det som att inga tjänster erbjuds i ditt område. För ytterligare information, vänligen kontakta ditt områdes hälsovårdsorganisation.',
	'chatNotAvailableModal.title': 'Ursäkta, vår tjänst är stängd för tillfället',
	'chatNotAvailableModal.body':
		'Vi ber om ursäkt för besväret, du kan försöka komma in i tjänsten senare. Vill du logga ut eller prova en annan tjänst?',
	'chatNotAvailableModal.button': 'Tillbaka till tjänsteval.',
	'bookAppointments.title': 'Ny tidsbokning',
	'bookAppointments.form.date': 'Datum',
	'bookAppointemnts.form.specialization': 'Välj specialist',
	'bookAppointments.button.search': 'Hitta lediga tider',
	'bookAppointments.availableAppointments.title': 'Lediga tider',
	'bookAppointments.button.prev': 'Föregående dag',
	'bookAppointments.button.next': 'Nästa dag',
	'bookAppointments.back': 'Gå tillbaka till att boka en ny tid',
	'bookAppointments.youAreBooking.title': 'Du bokar nu',
	'bookAppointments.preQuestionnaire.title': 'Frågeformulär',
	'bookAppointments.button.confirmBooking': 'Bekräfta bokningen',
	'bookAppointments.error': 'Du måste svara på alla nödvändiga frågor',
	'bookAppointment.confirmed.title': 'Bokningen bekräftad!',
	'bookAppointment.timeInfo.part1': 'Om du inte bekräftar din bokning',
	'bookAppointment.timeInfo.part2': 'inom 15 minuter,',
	'bookAppointment.timeInfo.part3': 'an andra också välja denna tid. Tid kvar:',
	'bookAppointment.failed.title': 'Bokningen misslyckades!',
	'bookAppointment.failed.lead':
		'Något gick fel. Om du inte bekräftade din bokning inom den angivna tidsramen kan någon annan ha bokat tiden. Försök igen!',
	'bookAppointment.failed.button': 'Boka en ny tid',
	'bookAppointment.confirmationModal.title': 'Din bokning är inte bekräftad',
	'bookAppointment.confirmationModal.lead':
		'Du har ännu inte bekräftat din bokning. Om du lämnar den här skärmen avbryts din bokning. Är du säker på att du vill fortsätta?',
	'bookAppointments.timeslotNotAvailable':
		'Du kan inte boka denna tid. Sök igen efter lediga tider.',
	'bookAppointments.noAppointments':
		'Det finns inga tider för det datum du valt',
	'changeMyLocation.title': 'Byt plats',
	'changeMyLocation.lead':
		'Om du inte är i din hemkommun kan du ändå eventuellt besöka tjänsteleverantörens distanstjänster från din nuvarande plats. Välj nedan det område där du vill använda distanstjänster.',
	'changeMyLocation.button': 'Byt plats',
	'changeMyLocation.subText':
		'Genom att bekräfta din plats, överförs du till tjänsterna hos tjänsteleverantören i området i fråga.',
	'changeMyLocation.selectArea': 'Välj område',
	'guestThankYou.content.heading': 'Gruppmottagningen avslutades',
	'guestPage.email': 'E-post',
	'guestPage.pinCode': 'Pinkod',
	'guestPage.appointmentNotFound':
		'Vastaanottoa ei löytynyt tai linkki on virheellinen.',
	'guestPage.enterPinHeader': 'Ange PIN-kod',
	'guestPage.enterPin':
		'Ange din e-postadress och den PIN-kod du fick för att gå vidare till tjänsten.',
	'guestPage.continue': 'Fortsätt till tjänsten',
	'guestPage.malformattedPin': 'PIN-koden är 6 siffror',
	'guestPage.wrongpassword': 'Fel e-post eller PIN-kod',
	'guestPage.malformattedEmail': 'Felformaterad e-postadress',
	'guestPage.unknownLoginError': 'Kan inte logga in!',
	'guestPage.error': 'Kontrollera fälten',
	'landing.activity.title': 'Din aktivitet',
	'landing.howWeCanHelpYou.title': 'Hur kan vi hjälpa dig?',
	'landing.item.bookAppointment': 'Ny tidsbokning',
	'landing.item.bookAppointment.helperText': 'Att boka tid',
	'landing.item.remoteReception': 'Digital mottagning',
	'landing.item.remoteReception.helperText': 'För mottagning',
	'landing.item.chatNow': 'Skriv ett meddelande',
	'landing.item.chatNow.helperText': 'Till meddelanden',
	'guestThankYou.content.thankyou': 'Tack för att du använder vår tjänst!',
	'guestThankYou.content.notify':
		'Notera att varje gruppmöte du ansluter till som gäst använder en separat länk som du får via e-post.',
	'guestThankYou.content.logout': 'Nu kan du logga ut och stänga sidan.',
	'guestThankYou.logout.button': 'Logga ut',
	'questionnaireErrorModal.title':
		'Ett fel uppstod när symptomfrågan skulle laddas.',
	'questionnaireErrorModal.text':
		'Det går inte att ladda symptomförfrågan just nu.',
	'questionnaireErrorModal.text2':
		'Du kan antingen ansluta direkt till mottagningen utan att fylla i symptomförfrågan eller välja en annan tjänst genom att få tillbaka till val av tjänst.',
	'questionnaireErrorModal.button.serviceSelection':
		'Tillbaka till val av tjänst',
	'questionnaireErrorModal.button.chat': 'Anslut till mötet',
	'surveyPage.error': 'Det gick inte att ladda upp enkäten',
	'surveyItem.answerSurvey': 'Svara på enkäten',
	'surveyItem.link': 'För enkäter',
	'surveyPage.thankYou.title': 'Tack för svaren',
	'surveyPage.thankYou.content':
		'Den professionella betjäningen du kommer att synliggöra dina enkätsvar innan eventuell mottagning.',
	'asyncMessaging.messages.title': 'Meddelanden',
	'asyncMessaging.newMessage': 'Skicka ett meddelande',
	'asyncMessaging.yourMessages': 'Ditt meddelande',
	'asyncMessaging.noMessages': 'Chatten är inte tillgänglig',
	'asyncMessaging.sendNewMessage.attachButton': 'Bifoga en fil',
	'asyncMessaging.sendNewMessage.sendButton': 'Skicka',
	'asyncMessaging.reply': 'Svar',
	'asyncMessaging.discardDraft': 'Ta bort utkastet',
	'asyncMessaging.newMessageTitle': 'Skicka ett meddelande',
	'asyncMessaging.selectAnOption': 'Välj',
	'asyncMessaging.sendMessage': 'Skicka ett meddelande',
	'asyncMessaging.sendMessage.description': 'Meddelandeexempel',
	'asyncMessaging.useMessageCode': 'Använd en meddelandekod',
	'asyncMessaging.useMessageCode.description':
		'Exempel använd en meddelandekodsbeskrivning',
	'asyncMessaging.newMessageCreateTitle': 'Nytt meddelande',
	'asyncMessaging.messageCodeTitle': 'Meddelandekod',
	'asyncMessaging.messageCodeSubmit': 'Skicka',
	'asyncMessaging.category': 'Kategori',
	'asyncMessaging.subcategory': 'Sub-kategori',
	'asyncMessaging.message': 'Meddelande',
	'asyncMessaging.sendingError': 'Spara fel. Försök igen senare.',
	'asyncMessaging.codeAdded': 'Kod {{code}} tillagd',
	'asyncMessaging.messageCodes.error.201': 'Meddelandekoden används inte.',
	'asyncMessaging.messageCodes.error.202': 'Meddelandekoden fungerar inte.',
	'asyncMessaging.messageCodes.error.203': 'Meddelandekoden fungerar inte.',
	'asyncMessaging.messageCodes.error.204': 'Meddelandekoden har redan använts.',
	'asyncMessaging.messageCodes.error.500':
		'Fel vid behandlingen av meddelandekoden.',
	'asyncMessaging.messageCodes.error.501':
		'Fel vid behandlingen av meddelandekoden.',
	'asyncMessaging.errors.messageCode': 'Lägg till rätt meddelandekod',
	'asyncMessaging.errors.category': 'älj en kategori från rullgardinsmenyn',
	'asyncMessaging.errors.subcategory': 'Välj subkategori från rullgardinsmenyn',
	'asyncMessaging.errors.messageBoxText': 'Skriv ett meddelande',
	'asyncMessaging.success': 'Meddelande skickades',
};

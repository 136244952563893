export const config = {
	accessibilityStatementUrl:
		'https://www.terveystalo.com/fi/saavutettavuus/julkisen-digiterveyden-digialusta/',
	ninchatCss: (color: string) => {
		const domain =
			process.env.REACT_APP_ENV_TYPE === 'local'
				? process.env.REACT_APP_API_URL
				: `${window.location.origin}/api`;
		const url = `${domain}/style/ninchat.css?color=${color.replace('#', '')}`;

		return url;
	},
	ninchatScript:
		process.env.REACT_APP_ENV_TYPE === 'development' ||
		process.env.REACT_APP_ENV_TYPE === 'local'
			? 'https://ninchat.com/stage/js/embed2.min.js'
			: 'https://ninchat.com/js/embed2.min.js',
	ninchatMessageSound:
		'https://ninchat.s3.amazonaws.com/b/media/notification-1.mp3',
	siteName: 'PRSP',
	paths: {
		base: '/',
		organizationSelect: '/organizationSelect',
		authentication: '/authentication',
		authenticationLogout: '/logout',
		contact: '/contact',
		terms: '/terms',
		welcome: '/welcome',
		landing: '/landing',
		serviceSelection: '/serviceselection',
		bookedAppointments: '/bookedAppointments',
		chat: '/chat',
		feedback: '/feedback',
		logout: '/logoutUser',
		profileContact: '/profile/contact',
		profileTerms: '/profile/terms',
		profileWelcome: '/profile/welcome',
		appointmentHistory: '/appointmenthistory/',
		appointmentDetails: '/appointmenthistory/:id/',
		bookAppointment: '/bookAppointment',
		changeMyLocation: '/changeMyLocation',
		getOrganization: '/contact/organisation/get',
		setOrganization: '/contact/organisation/set',
		authUserInfo: '/authUserInfo',
		getConfig: '/config',
		getCities: '/list/cities',
		guestUser: '/guest/',
		survey: '/survey', // /:responseId
		messages: '/messages',
		conversation: '/messages/conversation',
		conversationNewMessage: '/messages/newMessage',
		conversationNewMessageCode: '/messages/newMessage/code',
		conversationNewMessageCreate: '/messages/newMessage/create',
	},
};

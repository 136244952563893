export enum NINCHATEVENTS {
	AUDIENCE_RESUMED = 'audience_resumed',
	AUDIENCE_STARTED = 'audience_started',
	AUDIENCE_TRANSFERRED = 'audience_transferred',
	CHANNEL = 'channel',
	CLOSE = 'close',
	CLOSE_BUTTON = 'close_button',
	COMMANDLINE_BLURRED = 'commandline_blurred',
	COMMANDLINE_FOCUSED = 'commandline_focused',
	CONTAINER_INITIALIZED = 'container_initialized',
	ERROR = 'error',
	MESSAGE_RECEIVED = 'message_received',
	MESSAGE_SENT = 'message_sent',
	SESSION_CLOSEDb = 'session_closed',
	SSO = 'sso',
	QUEUE_IS_CLOSED = 'queue_is_closed',
	SITE_CONFIG_LOADING_FAILED = 'site_config_loading_failed',
}

import { ErrorCodes } from 'common/typings/error-codes';
import { CommonApiService } from 'common/services/config';

import { config as appConfig } from 'app/config/config';
import WebAuthService from 'app/services/webAuth/webAuth';

class PatientApiService extends CommonApiService {
	public type = 'patient';

	constructor() {
		super();

		this.request.interceptors.response.use(
			(response: any) => {
				if (response?.data?.statusCode === 101) {
					// for below urls not trigger logout action
					if (
						WebAuthService.isAuthenticated() &&
						window.location.pathname !== appConfig.paths.base &&
						window.location.pathname !== appConfig.paths.logout &&
						window.location.pathname !== appConfig.paths.authenticationLogout &&
						window.location.pathname !== appConfig.paths.authentication
					) {
						WebAuthService.logoutWithoutRedirection();
						window.location.href = `${appConfig.paths.authenticationLogout}?unauthorized=true`;
						return Promise.reject(new Error('unauthorized'));
					}
				}
				return response;
			},
			(error: any) => {
				if (error && error.response) {
					const { status } = error.response;
					if (
						status === ErrorCodes.UNAUTHORIZED ||
						status === ErrorCodes.ACCESS_DENIED ||
						status === ErrorCodes.NOT_FOUND
					) {
						// for example logout action here
					}
				}
				return Promise.reject(error);
			},
		);
	}
}

export { PatientApiService };
export default new PatientApiService();

import { LanguagesType } from 'common/lang/lang';
import { Response } from 'common/typings/response';
import { CommonApiService } from '../config';

export type Cities = Array<{
	countryCode: string;
	id: string;
	name: string;
	cityName: LanguagesType;
}>;

export type Countries = Array<{
	code: string;
	name: LanguagesType;
}>;

export type Nationalities = Array<{
	code: string;
	name: LanguagesType;
}>;

class CitiesService {
	apiService: CommonApiService = new CommonApiService();

	public setApiService(apiService: CommonApiService) {
		this.apiService = apiService;
	}

	public async getCities(country = 'fi') {
		const response: Response<Cities> = await this.apiService.get(
			`/list/cities/${country}`,
		);
		return response.data;
	}

	// list only active countries
	public async getCountries() {
		const response: Response<Countries> = await this.apiService.get(
			`/list/countries`,
		);
		return response.data;
	}

	public async getNationalities() {
		const response: Response<Nationalities> = await this.apiService.get(
			`/list/nationalities`,
		);
		return response.data;
	}
}

export default new CitiesService();

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';

import { AdminApplication } from 'adminApplication/adminApplication';
import { ExpertApplication } from 'expertApplication/expertApplication';

import { CustomerApplication } from 'app/customerApplication';

import reportWebVitals from './reportWebVitals';

const queryClient = new QueryClient();

if (process.env.NODE_ENV !== 'production') {
	// eslint-disable-next-line global-require, import/no-extraneous-dependencies
	const axe = require('@axe-core/react');
	axe(React, ReactDOM, 1000);
}

if (process.env.REACT_APP_ENV_TYPE === 'production') {
	// removing console logs for production
	// eslint-disable-next-line no-console
	console.log = () => {};
	// eslint-disable-next-line no-console
	console.error = () => {};
	// eslint-disable-next-line no-console
	console.debug = () => {};
}

ReactDOM.render(
	<React.StrictMode>
		<QueryClientProvider client={queryClient}>
			<Router>
				<Switch>
					<Route
						path={['/expert', '/expert/:path']}
						component={ExpertApplication}
					/>
					<Route
						path={['/admin', '/admin/:path']}
						component={AdminApplication}
					/>
					<Route path="/" component={CustomerApplication} />
				</Switch>
			</Router>
		</QueryClientProvider>
	</React.StrictMode>,
	document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';

import { Icon } from '@terveystalo/design-system-react-components';

import {
	CustomSelectComponent,
	IconWrapper,
	CustomSelectWrapper,
} from './SelectField.styles';

export const CustomSelect = (props: any) => {
	const { className, disabled } = props;
	const theme = useContext(ThemeContext);
	return (
		<CustomSelectWrapper className={className}>
			<CustomSelectComponent {...props} className="" />
			<IconWrapper>
				{!disabled && (
					<Icon name="ArrowsUpNDown" color={theme.colors.primary} />
				)}
			</IconWrapper>
		</CustomSelectWrapper>
	);
};

export const FormikSelectField = (props: any) => {
	const {
		field: { onChange, name, value },
		form: { touched, errors, submitCount },
		onChange: fieldChange,
	} = props;

	return (
		<CustomSelect
			{...props}
			invalid={(touched[name] || submitCount > 0) && errors[name]}
			valid={!errors[name] && value}
			onChange={(pr: any) => {
				onChange(pr);
				if (fieldChange) {
					fieldChange(pr);
				}
			}}
			value={value}
		/>
	);
};

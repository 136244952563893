import styled from 'styled-components';

import { PillButton } from '@terveystalo/design-system-react-components';

import { roundedStyles } from 'common/styles/components/wrappers';

export const ConversationPatientWrapper = styled.div``;

export const ConversationItem = styled.div<{ isOwn?: boolean }>`
	display: flex;

	> div {
		width: 100%;
		max-width: 620px;
	}

	${(props) =>
		!props.isOwn &&
		`
			justify-content: flex-end;

	`}
`;

export const ConversationMessage = styled.div<{ isOwn: boolean }>`
	${roundedStyles};
	margin-bottom: ${(props) => props.theme.spacing['1']};
	background-color: ${(props) => props.theme.colors.zodiacBlack08};
	border-bottom: none;

	${(props) =>
		props.isOwn &&
		`
		background: ${props.theme.colors.primary};
		color: ${props.theme.colors.white};
		border-bottom-left-radius: 0;

	`}

	${(props) =>
		!props.isOwn &&
		`		border-bottom-right-radius: 0;


	`}
`;

export const ConversationMessageTitle = styled.div<{ isOwn: boolean }>`
	color: ${(props) => props.theme.colors.zodiacBlack65};
	margin-bottom: ${(props) => props.theme.spacing['0_25']};
	font-size: ${(props) => props.theme.typography.customFontSizes.small};
	display: flex;
	justify-content: space-between;
	align-items: center;

	${(props) =>
		!props.isOwn &&
		`
		justify-content: flex-end;
	`}
`;

export const NewBadge = styled.div`
	padding: ${(props) => props.theme.spacing['0_25']}
		${(props) => props.theme.spacing['0_5']};
	color: ${(props) => props.theme.colors.matisseBlue};
	font-weight: 700;
	border-radius: ${(props) => props.theme.border.radius.m};
	background-color: ${(props) => props.theme.colors.rainyBlue40};
	margin-left: ${(props) => props.theme.spacing['1']};
`;

export const FileButton = styled(PillButton)`
	margin-right: ${(props) => props.theme.spacing['0_5']};
	font-size: ${(props) => props.theme.typography.customFontSizes.small};

	> span {
		display: flex;
		align-items: center;
		gap: 10px;
	}
`;

import { combineReducers, Reducer } from 'redux';
import configReducer from './configuration/slices/configuration.slice';
import contactReducer from './contact/slices/contact.slice';
import citiesReducer from './cities/slices/cities.slice';
import servicesReducer from './serviceSelection/slices/serviceSelection.slice';

const rootReducer: Reducer = combineReducers({
	config: configReducer,
	contact: contactReducer,
	cities: citiesReducer,
	services: servicesReducer,
});

export default rootReducer;

import PatientApiService from 'app/services/config';
import { Response } from 'common/typings/response';
import { config } from 'app/config/config';
// import { WebAuthClient } from '@terveystalo/web-auth';

export interface UserInitialData {
	date_of_birth: string;
	first_name: string;
	last_name: string;
	full_name: string;
	gender: string;
	ssn: string;
	nationality_code: string;
	country_code: string;
	street?: string;
	code?: string;
	city?: string;
	email?: string;
}

export interface StrippedToken {
	expireTime: number;
	token: string;
	gender: string;
	age: number;
}

class WebAuthService {
	// authClient: WebAuthClient;

	authenticated: boolean;

	constructor() {
		// this.authClient = this.initializeAuthClient();
		this.authenticated = false;
	}

	initializeAuthClient() {
		// return new WebAuthClient({
		// 	domain: process.env.REACT_APP_AUTH_DOMAIN as string,
		// 	client_id: process.env.REACT_APP_AUTH_CLIENT_ID as string,
		// 	redirect_uri: window.location.origin,
		// 	audience: process.env.REACT_APP_AUTH_AUDIENCE,
		// 	logoutReturnTo: window.location.origin,
		// 	timeoutReturnTo: window.location.origin,
		// 	scope: 'openid',
		// 	connection: 'TeliaLogin',
		// });
	}

	async loginWithCredentials() {
		// this.authClient.loginWithRedirect();

		window.location.href = `${process.env.REACT_APP_API_URL}/login`;
	}

	async getAuthState() {
		try {
			// eslint-disable-next-line no-console
			console.log('before checking auth state');
			const response: Response<{}> = await PatientApiService.get('/checkAuth');
			// eslint-disable-next-line no-console
			console.log('checkAuth', response.data);
			if (response.data.status === 'error') {
				this.authenticated = false;
			} else {
				this.authenticated = true;
			}
			// eslint-disable-next-line no-empty
		} catch (error) {
			this.authenticated = false;
		}

		// return this.authClient.getExpectedAuthState();
	}

	isAuthenticated() {
		return this.authenticated;
		// return this.authClient.getExpectedAuthState().type === 'LoggedIn';
	}

	// getTokenSilently() {
	// 	return this.authClient.getTokenSilently();
	// }

	// getUser() {
	// 	return this.authClient.getUser();
	// }

	async getInitialUserData() {
		try {
			const response: Response<UserInitialData> = await PatientApiService.get(
				config.paths.authUserInfo,
			);

			// eslint-disable-next-line no-console
			console.log(`authState${JSON.stringify(response.data)}`);
			if (response.data.status === 'error') {
				return {};
			}
			return response.data;
		} catch (error) {
			return {};
		}
	}

	// handleRedirectCallback() {
	// 	return this.authClient.handleRedirectCallback();
	// }

	async logout() {
		await PatientApiService.get('/logout');
		this.authenticated = false;
		window.location.href = config.paths.authenticationLogout;

		// this.authClient.logout({
		// 	returnTo: window.location.origin,
		// });
	}

	async getHTAConnectorAuthToken() {
		try {
			const response: Response<StrippedToken> = await PatientApiService.get(
				'/stripped_token',
			);
			if (response.data.status !== 'error') {
				return {
					accessToken: response.data.token,
					age: response.data.age,
					gender: response.data.gender.toLowerCase(),
				};
			}
			// eslint-disable-next-line no-empty
		} catch (error) {}
		return {};
	}

	async logoutWithoutRedirection() {
		await PatientApiService.get('/logout');
		this.authenticated = false;
	}
}

export default new WebAuthService();

import { createGlobalStyle } from 'styled-components';
import { setLightness } from 'polished';

import '@terveystalo/design-system-cdn/dist/index/fonts.css';
import { ThemeType } from '../themes/themes';

export const DesignSystemOverrides = createGlobalStyle<{
	theme: ThemeType;
}>`
	${(props) => {
		const primaryColor = props.theme.colors.primary;
		const primaryColorLighter = setLightness(0.5, props.theme.colors.primary);
		return `
			:root {
				--interaction-color-default-content: ${primaryColor};
				--interaction-color-highlight-content: ${primaryColorLighter};
				--forms-color-default-border: ${primaryColor};
				--forms-color-selected-border: ${primaryColorLighter};
				--forms-color-selected-background: ${primaryColorLighter};
				--cta-color-primary-positive-default-background: ${primaryColor};
				--cta-color-primary-positive-default-border: ${primaryColor};
				--cta-color-primary-positive-highlight-background: ${primaryColorLighter};
				--cta-color-primary-positive-highlight-border: ${primaryColorLighter};
				--cta-color-primary-negative-default-content: ${primaryColor};
				--cta-color-primary-negative-highlight-content: ${primaryColorLighter};
				--cta-color-secondary-positive-default-content: ${primaryColor};
				--cta-color-secondary-positive-default-border: ${primaryColor};
				--cta-color-secondary-positive-highlight-content: ${primaryColorLighter};
				--cta-color-secondary-positive-highlight-border: ${primaryColorLighter};
				--cta-color-secondary-negative-highlight-background: ${primaryColorLighter};
				--cta-color-link-default-content: ${primaryColor};
				--cta-color-link-highlight-content: ${primaryColorLighter};
				--navigation-color-default-background: ${primaryColor};
				--navigation-color-highlight-content-on-dark: ${primaryColor};
				--navigation-color-highlight-content-on-light: ${primaryColorLighter};
				--feedback-color-info-content: ${primaryColor};
				--heading-color-content: ${primaryColor};
				--list-color-default-marker-content: ${primaryColor};
				--cta-color-secondary-positive-highlight-background:  ${setLightness(
					0.97,
					primaryColor,
				)}
			}
		`;
	}}
`;

import {
	format,
	compareAsc,
	addMinutes,
	subMinutes,
	addMonths,
	addDays,
	subDays,
	startOfDay,
	endOfDay,
	differenceInMinutes,
} from 'date-fns';
import { Language } from 'common/lang/lang';

export const convertDate = (
	timestampOrDate: number | Date,
	type?: string,
	langCode?: Language,
	time: boolean = true,
) => {
	if (!type && !langCode) {
		return '';
	}

	if (type) {
		return format(timestampOrDate, type);
	}

	switch (langCode) {
		case Language.english:
			return format(timestampOrDate, `MMMM d, yyyy${time ? ', HH:mm' : ''}`);
		case Language.swedish:
			return format(timestampOrDate, `yyyy-M-d${time ? ', HH:mm' : ''}`);
		case Language.finnish:
			return format(timestampOrDate, `d.M.yyyy${time ? ', HH:mm' : ''}`);
		default:
			throw new Error(
				'type or language code not defined, or unknown language code',
			);
	}
};

export const selectDateFormat = (langCode?: Language) => {
	switch (langCode) {
		case Language.swedish:
			return `yyyy-M-d`;
		case Language.finnish:
			return `d.M.yyyy`;
		case Language.english:
		default:
			return `MMMM d, yyyy`;
	}
};

export const compareDatesByDay = (timestamp1: number, timestamp2: number) => {
	const date1Temp = new Date(format(timestamp1, 'yyyy/M/dd'));
	const date2Temp = new Date(format(timestamp2, 'yyyy/M/dd'));
	return compareAsc(date1Temp, date2Temp);
};

export const addDaysToDate = (timestamp: number, days: number) =>
	addDays(timestamp, days);

export const substractDaysFromDate = (timestamp: number, days: number) =>
	subDays(timestamp, days);

export const addMinutesToDate = (timestamp: number, minutes: number) =>
	addMinutes(timestamp, minutes);

export const substractMinutesFromDate = (timestamp: number, minutes: number) =>
	subMinutes(timestamp, minutes);

export enum DateTypes {
	today = 0,
	endOfYear = 1,
	sixMonthsFromNow = 2,
	oneYearFromNow = 3,
	noExpirationDate = 4,
}

export const getDateBasedOnType = (type: DateTypes): Date | string => {
	switch (type) {
		case DateTypes.endOfYear:
			return new Date(format(new Date().getTime(), 'yyyy/12/31'));
		case DateTypes.sixMonthsFromNow:
			return addMonths(new Date(), 6);
		case DateTypes.oneYearFromNow:
			return addMonths(new Date(), 12);
		case DateTypes.noExpirationDate:
			return '';
		case DateTypes.today:
		default:
			return new Date();
	}
};

// day '0' return last of the previous month
export const getDaysInAMonth = (month: number, year: number) =>
	new Date(year, month + 1, 0).getDate();

export const getTimezone = () =>
	Intl.DateTimeFormat().resolvedOptions().timeZone;

export const startOfDate = (date: Date) => startOfDay(date).getTime();

export const endOfDate = (date: Date) => endOfDay(date).getTime();

export const differenceInMinutesBetweenDates = (date1: Date, date2: Date) =>
	differenceInMinutes(date1, date2);

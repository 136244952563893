import styled from 'styled-components';

export const WelcomeWrapper = styled.div``;

export const ButtonsWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const CheckboxWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const TextWrapper = styled.div`
	padding: ${(props) => props.theme.spacing['1']} 0;
`;

import { Response } from 'common/typings/response';

import ExpertApiService from 'expertApplication/services/config';

import { Config } from 'common/typings/organisationConfig';

class OrganisationConfigService {
	public async getConfig() {
		const response: Response<Config> = await ExpertApiService.get(
			`/expert/organisation`,
		);
		return {
			...response.data,
		};
	}
}

export default new OrganisationConfigService();

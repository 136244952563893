import React from 'react';
import { Route, Redirect } from 'react-router-dom';

export const PrivateRoute = ({
	children,
	redirectPath,
	checkRule,
	...rest
}: any) => (
	<>
		{checkRule && <Route {...rest} />}
		{!checkRule && (
			<Redirect
				to={{
					pathname: redirectPath,
				}}
			/>
		)}
	</>
);

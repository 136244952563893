import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from 'styled-components';
import i18next from 'i18next';
import { useSelector } from 'react-redux';

import { Subtitle, Text } from '@terveystalo/design-system-react-components';

import { CustomCheckbox } from 'common/components/forms/checkbox/Checkbox.styles';

import { CustomButton } from 'app/styles/components/button/button';
import LocalStorageService from 'common/services/localStorage';
import { AppContext } from 'app/context/appContext';
import { selectConfig } from 'app/store/configuration/slices/configuration.slice';
import { CustomDialogHeadline } from 'app/styles/components/dialog/dialog';

import {
	CookieWrapper,
	CookieInner,
	TextWrapper,
	ButtonWrapper,
	CookieSettingsButton,
	CookieModal,
	CookieSubmitButton,
	CookieModalButtonsWrapper,
} from './cookie.styles';

export const Cookie = () => {
	const { t } = useTranslation();
	const theme = useContext(ThemeContext);
	const { toggleCookies, cookiesOpen } = useContext(AppContext);
	const config = useSelector(selectConfig);

	const [modalOpen, setModalOpen] = useState(false);
	const [checkedValues, setCheckedValues] = useState({
		required: true,
	});

	const lang: string = i18next.language;

	useEffect(() => {
		const cookieAccepted = !!LocalStorageService.get('cookies');
		if (!cookieAccepted) {
			toggleCookies(true);
		}
	}, []);

	const acceptCookies = () => {
		LocalStorageService.set('cookies', JSON.stringify(checkedValues));
		toggleCookies(false);
	};

	const toggleCookieModal = () => {
		setModalOpen(!modalOpen);
	};

	const handleCheckboxChange =
		(name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
			const { checked } = event.target;

			const newValues: any = {
				...checkedValues,
			};
			newValues[name] = checked;

			setCheckedValues(newValues);
		};

	const cookiePolicyLink = config.data.cookiePolicyLink[lang].url;

	return (
		<>
			{cookiesOpen && (
				<CookieWrapper>
					<CookieInner>
						<TextWrapper>
							<Subtitle color={theme.colors.white} size="s">
								{t('cookies.headline')}
							</Subtitle>
							<br />
							<br />
							<div>
								<Text
									size="m"
									color={theme.colors.white}
									dangerouslySetInnerHTML={{
										__html: t('cookies.body', {
											link: `<a class="cookie-policy-link" target='_blanc' href='${cookiePolicyLink}'>${t(
												'cookies.body.link',
											)}</a>`,
											interpolation: { escapeValue: false },
										}),
									}}
								/>
							</div>
						</TextWrapper>
						<ButtonWrapper>
							<CookieSubmitButton
								data-testid="accept-cookies-btn"
								onClick={acceptCookies}
								variant="secondary"
							>
								{t('cookies.button')}
							</CookieSubmitButton>
							<div>
								<CookieSettingsButton onClick={() => setModalOpen(true)}>
									{t('cookie.modal.link')}
								</CookieSettingsButton>
							</div>
						</ButtonWrapper>
					</CookieInner>
					<CookieModal
						className="cookieModal"
						closeButtonLabel={t('common.close')}
						open={modalOpen}
						onClose={toggleCookieModal}
						title={
							<CustomDialogHeadline>
								{t('cookies.modal.headline')}
							</CustomDialogHeadline>
						}
					>
						<div>
							<Text size="l">{t('cookies.modal.top')}</Text>
							<br />
							<br />
							<br />
							<CustomCheckbox
								name="required"
								label={t('cookies.modal.required.headline')}
								checked
								disabled
								grayBackground
							/>
							<Text size="m">{t('cookies.modal.required.text')}</Text>
							<br />
							<br />
							<br />
							<CustomCheckbox
								name="functional"
								label={t('cookies.modal.functional.headline')}
								grayBackground
								onChange={handleCheckboxChange('functional')}
							/>
							<Text size="m">{t('cookies.modal.functional.text')}</Text>
							<br />
							<br />
							<br />
							<CustomCheckbox
								name="targeting"
								label={t('cookies.modal.targeting.headline')}
								grayBackground
								onChange={handleCheckboxChange('targeting')}
							/>
							<Text size="m">{t('cookies.modal.targeting.text')}</Text>
							<br />
							<br />
							<br />
							<CustomCheckbox
								name="marketing"
								label={t('cookies.modal.marketing.headline')}
								grayBackground
								onChange={handleCheckboxChange('marketing')}
							/>
							<Text size="m">{t('cookies.modal.marketing.text')}</Text>
							<br />
							<br />
							<br />
						</div>
						<CookieModalButtonsWrapper>
							<CustomButton onClick={acceptCookies}>
								{t('cookies.modal.submit')}
							</CustomButton>
						</CookieModalButtonsWrapper>
					</CookieModal>
				</CookieWrapper>
			)}
		</>
	);
};

import { Response } from 'common/typings/response';
import { AppointmentType } from 'common/typings/bookings';
import { LanguagesType } from 'common/lang/lang';

import PatientApiService from 'app/services/config';
import { ChatCodeService } from 'app/store/serviceSelection/slices/serviceSelection.slice';

export interface ServiceItemData {
	serviceName: LanguagesType;
	serviceDescription: LanguagesType;
	serviceImage: string;
	chatQueueId: string;
	uuid: string;
	mandatorySelfAssessment: boolean;
	optionalSelfAssessment: boolean;
}

export interface Survey {
	id: number;
	sid: string;
	created_at: string;
	responded_at?: string;
	response_id: string;
	survey_name?: string; // json
}

export interface AppointmentItemData {
	uuid: string;
	name: string;
	startTime: number;
	duration: number;
	queueId: string;
	audienceId?: string;
	type: AppointmentType;
	surveys?: Survey[];
}

export type Services = {
	appointments: Array<AppointmentItemData>;
	openServices: Array<ServiceItemData>;
	chatCodeServices: Array<ServiceItemData>;
};

export interface SecureData {
	serviceUUID: string;
	secureMetadata: string;
}

export interface LockResponse {
	appointmentLockTime: number;
	appointmentLockUpTo: number;
}

class ServiceSelectionService {
	private readonly PREFIX: string = 'prsp';

	public async getServices() {
		const response: Response<Services> = await PatientApiService.get(
			'/services',
		);
		return response.data;
	}

	public async getChatCodeService(chatCode: string, markAsUsed: boolean) {
		const response: Response<ChatCodeService> = await PatientApiService.post(
			`/services/chatCode/${chatCode}${markAsUsed ? '/use' : ''}`,
		);
		return response.data;
	}

	public async getSecureMetadata(uuid: string, questionnaireMetadata: string) {
		const response: Response<SecureData> = await PatientApiService.post(
			`/services/metadata/${uuid}`,
			{ questionnaireMetadata },
		);
		return response.data;
	}

	public async cancelAppointment(id: string) {
		const response: Response<any> = await PatientApiService.post(
			`/services/cancel/${id}`,
		);
		return response.data;
	}

	public async lockAppointment(id: string, lock: boolean) {
		if (lock) {
			const response: Response<LockResponse> = await PatientApiService.post(
				`/services/lock/${id}`,
			);
			return response.data;
		}

		const response: Response<LockResponse> = await PatientApiService.post(
			`/services/unlock/${id}`,
		);
		return response.data;
	}
}

export default new ServiceSelectionService();

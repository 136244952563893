import { config } from 'app/config/config';
import PatientApiService from 'app/services/config';
import { Response } from 'common/typings/response';

export interface Contact {
	firstName: string;
	lastName: string;
	street: string;
	code: string;
	city: string;
	mobile: string;
	email: string;
	termsAccepted: boolean;
	country: string;
	nationality: string;
}

export interface OrganizationsInfo {
	organisations: Array<{
		id: string;
		name: string;
	}>;
	selectedOrganisation: string;
}

class ContactService {
	public async getContactInfo() {
		const response: Response<Contact> = await PatientApiService.get(
			config.paths.contact,
		);
		return response.data;
	}

	public async setContactInfo(data: Partial<Contact>) {
		const response: Response<Contact> = await PatientApiService.post(
			config.paths.contact,
			data,
		);
		return response.data;
	}

	public async getOrganizations() {
		const response: Response<OrganizationsInfo> = await PatientApiService.get(
			config.paths.getOrganization,
		);
		return response.data;
	}

	public async selectOrganization(organizationId: string) {
		const response: Response<Contact> = await PatientApiService.post(
			config.paths.setOrganization,
			{
				selectedOrganisation: organizationId,
			},
		);
		return response.data;
	}
}

export default new ContactService();

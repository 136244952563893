import styled from 'styled-components';

export const FeedbackWrapper = styled.div``;

export const FeedbackVotingWrapper = styled.div`
	background-color: none;
	margin: ${(props) => props.theme.spacing['2']} 0;
	display: inline;
	padding-left: 2rem;
	padding-right: 2rem;
	align-items: center;
	justify-content: center;
`;

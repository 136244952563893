import styled from 'styled-components';

import { TextButton } from '@terveystalo/design-system-react-components';

export const ChatWrapper = styled.div``;

export const HelpButton = styled(TextButton)`
	border: none;
	background: none;
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const HeaderWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

import {
	createSlice,
	createSelector,
	createAsyncThunk,
} from '@reduxjs/toolkit';

import { ResponseError } from 'common/typings/response';

import IconsService, { IconsData } from 'adminApplication/services/icons';
import { RootState } from 'adminApplication/store';

export interface UserState {
	data: IconsData | null;
	isLoading: boolean;
	isSaving: boolean;
	error: any;
	savingError: any;
}

const initialState: UserState = {
	data: null,
	isLoading: false,
	isSaving: false,
	error: null,
	savingError: null,
};

export const getIconsAsync: any = createAsyncThunk(
	'icons/getIcons',
	async (_obj, thunkAPI) => {
		try {
			const iconsData: IconsData & ResponseError =
				await IconsService.getIcons();

			if (iconsData.status === 'error') {
				return thunkAPI.rejectWithValue(iconsData.statusCode);
			}
			return iconsData;
		} catch (err) {
			return thunkAPI.rejectWithValue(err);
		}
	},
);

export const iconsSlice = createSlice({
	name: 'icons',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(getIconsAsync.pending, (state) => {
			state.isLoading = true;
		});

		builder.addCase(getIconsAsync.rejected, (state, action) => {
			state.error = action.payload;
			state.data = null;
			state.isLoading = false;
		});

		builder.addCase(getIconsAsync.fulfilled, (state, action) => {
			state.data = action.payload;
			state.isLoading = false;
		});
	},
});

export default iconsSlice.reducer;

export const selectIcons = createSelector(
	(state: RootState) => state.icons,
	(icons) => icons,
);

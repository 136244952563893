export const fi = {
	'pageTitles.authentication': 'Tunnistautuminen',
	'pageTitles.chat': 'Chat',
	'pageTitles.contact': 'Yhteystiedot',
	'pageTitles.feedback': 'Palaute',
	'pageTitles.terms': 'Palvelun käyttöehdot',
	'pageTitles.serviceInfo': 'Palvelun ohjeet',
	'pageTitles.serviceSelection': 'Etäasiointi',
	'pageTitles.bookedAppointments': 'Vastaanotot',
	'pageTitles.appointmentHistory': 'Vastaanottohistoria',
	'pageTitles.appointmentHistoryDetails':
		'Vastaanottohistorian tarkemmat tiedot',
	'pageTitles.bookAppointment': 'Varaa uusi aika',
	'pageTitles.changeMyLocation': 'Vaihda sijaintia',
	'pageTitles.landing': 'Etusivu',
	'appointmentHistory.title': 'Vastaanottohistoria',
	'appointmentHistory.explanation1': 'Säilytämme keskustelujasi täällä ',
	'appointmentHistory.explanation2': '90 päivän ajan',
	'appointmentHistory.explanation3':
		'. Pääset myös käsiksi saamiisi asiakirjoihin. Suosittelemme tallentamaan ne omalle laitteellesi myöhempää käyttöä varten.',
	'appointmentHistory.subtitle': 'Vastaanotot viimeisen 90 päivän aikana',
	'appointmentHistory.list.conversed': 'Keskustelussa',
	'appointmentHistory.list.document': 'Asiakirjat',
	'appointmentHistoryDetails.error': 'Ladattaessa tietoja oli ongelmia',
	'common.errors': 'Virhe',
	'common.you': 'Sinä',
	'common.start': 'Aloita',
	'common.minutes': 'Minuuttia',
	'common.skipToContent': 'Ohita sisältö',
	'common.skip': 'Ohita',
	'common.send': 'Lähetä',
	'common.saved': 'Tallennettu',
	'common.writeMessage': 'Kirjoita viesti',
	'common.welcome': 'Tervetuloa',
	'common.hello': 'Hei',
	'common.back': 'Edellinen',
	'common.continue': 'Jatka',
	'common.close': 'Sulje',
	'common.backToContact': 'Takaisin yhteystietoihin',
	'common.backToServiceSelection': 'Takaisin etäasiointiin',
	'common.backToAppointmentHistory': 'Takaisin keskusteluhistoriaan',
	'common.backToHomepage': 'Takaisin etusivulle',
	'common.backToMessages': 'Takaisin viesteihin',
	'common.next': 'Seuraava',
	'common.save': 'Tallenna',
	'common.ok': 'OK',
	'common.cancel': 'Peruuta',
	'common.logOut': 'Kirjaudu ulos',
	'common.logOutInfo':
		'Haluatko varmasti kirjautua ulos? Sinun täytyy tunnistautua uudelleen kirjautuaksesi takaisin sisään.',
	'common.menuLink': 'Valikko',
	'common.savingError': 'Tallennusvirhe. Yritä uudelleen myöhemmin.',
	'common.mainMenu.contact': 'Yhteystietoni',
	'common.mainMenu.terms': 'Ehdot',
	'common.mainMenu.howServiceWorks': 'Miten palvelu toimii',
	'common.mainMenu.remoteAppointments': 'Etäasiointi',
	'common.mainMenu.yourAppointments': 'Tulevat vastaanottoni',
	'common.mainMenu.actOnBehalf': 'Asioi toisen puolesta',
	'common.mainMenu.accessibilityStatement': 'Saavutettavuusseloste',
	'common.mainMenu.changeMyLocation': 'Vaihda sijaintia',
	'common.mainMenu.asyncMessages': 'Omat viestit',
	'common.mainMenu.history': 'Vastaanottohistoria',
	'common.mainMenu.bookAppointment': 'Varaa aika',
	'common.mainMenu.section1': 'Kuinka voimme auttaa sinua?',
	'common.mainMenu.section2': 'Tietoa palvelusta',
	'common.mainMenu.authenticate': 'Tunnistaudu',
	'common.new': 'Uusi',
	'cookies.headline': 'Käytämme verkkosivustollamme evästeitä.',
	'cookies.body':
		'Hyväksymällä kaikki evästeet varmistat parhaan mahdollisen käyttökokemuksen. {{link}} sekä niiden käytöstä tai muuta evästeasetuksiasi.',
	'cookies.body.link': 'Lue lisää evästeistä',
	'cookie.modal.link': 'Muuta evästeasetuksia',
	'cookies.button': 'Hyväksy evästeet',
	'cookies.modal.headline': 'Evästeasetukset',
	'cookies.modal.top':
		'Haluamme tarjota sinulle parasta mahdollista palvelua myös verkossa. Tämän vuoksi keräämme tietoa sivuston käytöstä sisäisillä ja ulkoisilla työkaluilla. Emme tallenna henkilöä suorasti yksilöivää tietoa seurantatyökaluihin. Lähetettyjä tietoja käytetään palvelun kehittämiseen sekä markkinointitarkoituksiin. Tallennamme valitsemasi evästeasetukset, ja kaikki käyttämämme työkalut kunnioittavat valintojasi. Voit muuttaa valintojasi tai tyhjentää selaimesi evästeet koska tahansa.',
	'cookies.modal.required.headline': 'Välttämättömät evästeet',
	'cookies.modal.required.text':
		'Välttämättömien evästeiden avulla varmistamme verkkosivuston luotettavan ja turvallisen toiminnan. Keräämme niiden avulla esimerkiksi tilastotietoa palvelun käytöstä sekä varmistamme, että muun muassa palvelun kieliasetukset toimivat oikein. Välttämättömien evästeiden käytön voit estää ainoastaan selaimesi asetuksissa. Emme luovuta tietoja Terveystalon ulkopuolelle.',
	'cookies.modal.functional.headline': 'Toiminnan kehittämisevästeet',
	'cookies.modal.functional.text':
		'Tutkimme toiminnan kehittämisevästeiden avulla kerätyllä tiedolla muun muassa kävijöiden aktiivisuutta ja käyttäytymistä eri verkko- ja mobiilipalveluissamme. Käyttäytymistiedon perusteella luomme erilaisia käyttäjäluokituksia ja -mallinnuksia sekä kehitämme yksilöllistä käyttäjäkokemusta eri palvelukanavissa. Emme luovuta tietoja Terveystalon ulkopuolelle.',
	'cookies.modal.targeting.headline': 'Kohdentamisevästeet',
	'cookies.modal.targeting.text':
		'Kohdentamisevästeiden avulla saat sinua kiinnostavaa sisältöä kaikille suunnatun sisällön ja markkinointiviestinnän sijasta. Terveystalo voi tarjota sinua kiinnostavaa sisältöä ja markkinointiviestintää omissa verkko- ja mobiilipalveluissaan sekä yhteistyökumppaneidemme palveluissa. Voimme tarjota mielenkiinnon kohteitasi vastaavaa sisältöä esimerkiksi sen perusteella, millä sivustoilla olet käynyt tai mitä sisältöjä olet katsonut. Emme luovuta tietoja Terveystalon ulkopuolelle.',
	'cookies.modal.marketing.headline': 'Markkinointievästeet',
	'cookies.modal.marketing.text':
		'Markkinointievästeiden avulla voimme kohdentaa sinulle markkinointiviestintää mainosverkostoomme kuuluvien sosiaalisen median alustojen kautta (Facebook, Google, Instagram, LinkedIn, Twitter ja YouTube). Markkinointievästeiden käyttö voi siten tarkoittaa tietojen luovutusta Terveystalon ulkopuolelle kolmannen osapuolen mainosverkoston omaan käyttöön. Mainosverkostoomme kuuluvat sosiaalisen median alustat voivat hyödyntää tietoja myös omassa käytössään.',
	'cookies.modal.submit': 'Hyväksy valinnat',
	'progressSection.item.authentication': 'Tunnistautuminen',
	'progressSection.item.serviceSelection': 'Palvelun valitseminen',
	'progressSection.item.remoteAppoitment': 'Etävastaanotto',
	'authentication.identificationHeadline':
		'Hei! Sinun on tunnistauduttava jatkaaksesi palvelun käyttöä.',
	'authentication.identificationBody':
		'Palvelu käsittelee yksityistietoja, kuten potilasasiakirjoja, ja oikeiden tietojen näyttäminen edellyttää vahvaa tunnistautumista. Voit tunnistautua pankkitunnuksillasi tai mobiilivarmenteella.',
	'authentication.identificationButton': 'Tunnistautumiseen',
	'authentication.identificationLockText': 'Yhteys on salattu',
	'authentication.actOnBehalfTitle': 'Asioitko toisen puolesta?',
	'authentication.actOnBehalfContent':
		'Jotta voit asioida toisen puolesta, sinun täytyy ensin tunnistautua ja hakea tälle oikeutus. Kun olet kirjautunut sisään, voit valita toisen puolesta asioinnin valikon takaa.',
	'contact.headline': 'Yhteystietosi',
	'contact.mainText': 'Varmista, että tietosi ovat ajan tasalla.',
	'contact.firstName': 'Etunimi',
	'contact.lastName': 'Sukunimi',
	'contact.street': 'Katuosoite',
	'contact.code': 'Postinumero',
	'contact.city': 'Kaupunki',
	'contact.mobile': 'Matkapuhelinnumero (vastaanottomuistutuksia varten)',
	'contact.nationality': 'Kansalaisuus',
	'contact.country': 'Maa',
	'contact.email': 'Sähköpostiosoite',
	'contact.error': 'Ole hyvä ja täytä',
	'terms.headline': 'Ehdot',
	'terms.subtitle': 'Pyydämme sinua lukemaan ehtomme.',
	'terms.acceptButton': 'Hyväksy ja jatka',
	'welcome.headline': 'Näin palvelumme toimii',
	'welcome.button': 'Jatka palveluun',
	'welcome.dontShowMessage': 'Älä näytä tätä uudestaan',
	'serviceSelection.headline': 'Etäasiointi',
	'serviceSelection.chatCode.inputField': 'Syötä chat-koodi',
	'serviceSelection.chatCode.submit': 'Aloita vastaanottokäynti',
	'serviceSelection.chatCode.error.101': 'Käyttäjä ei ole tunnistautunut.',
	'serviceSelection.chatCode.error.201': 'Virheellinen chat-koodi.',
	'serviceSelection.chatCode.error.202':
		'Chat-koodia ei pystytä avaamaan tällä hetkellä. Ole hyvä ja tarkista vastaanottokäyntisi päivämäärä ja kellonaika.',
	'serviceSelection.chatCode.error.203': 'Chat-koodi on vanhentunut.',
	'serviceSelection.chatCode.error.204': 'Chat-koodi on jo käytetty.',
	'serviceSelection.chatCode.error.500': 'Virhe chat-koodin käsittelyssä.',
	'serviceSelection.chatCode.error.501': 'Virhe chat-koodin käsittelyssä.',
	'serviceSelection.availableServices': 'Avoimet palvelut',
	'serviceSelection.chatCode.headline': 'Chat-koodilla saatavat palvelut',
	'serviceSelection.chatCode.lead':
		'Ota yhteyttä tarvitsemaasi asiantuntijaan chat-koodin avulla.',
	'serviceSelection.chatCode.infoLink': 'Mistä saan chat-koodin?',
	'serviceSelection.chatCode.infoButton': 'Mitä ovat chat-koodit?',
	'serviceSelection.chatCode.services':
		'Palvelut, joita varten tarvitset chat-koodin',
	'serviceSelection.chatCode.infoModal.headline': 'Mikä on chat-koodi?',
	'serviceSelection.chatCode.infoModal.body':
		'Chat-koodin avulla saat suoran yhteyden palveluun, johon kuntasi terveydenhuollon ammattilainen on sinut ohjannut. Olet voinut saada chat-koodin jonkin toisen kuntasi tarjoaman palvelun kautta, esim. hoitajan vastaanotolla. Chat-koodilla saat suoran yhteyden tarvitsemaasi palveluun!',
	'serviceSelection.chatCode.infoModal.body.second':
		'Kuntasi voi antaa sinulle lisää tietoa siitä, jakaako kuntasi chat-koodeja.',
	'serviceSelection.chatCode.infoModal.submit': 'Selvä',
	'serviceSelection.yourAppointments.future.title': 'Tulevat vastaanotot',
	'serviceSelection.yourAppointments.past.title': 'Menneet vastaanotot',
	'serviceSelection.yourAppointments': 'Sinun varauksesi',
	'serviceSelection.yourAppointments.text':
		'Voit siirtyä vastaanotolle 5 minuuttia ennen vastaanoton alkua.',
	'serviceSelection.yourAppointments.cancel':
		'Voit perua varauksen 24 tuntia ennen varauksen alkua.',
	'serviceSelection.yourAppointmentsModal.text':
		'Sinulle varattu etävastaanotto henkilön {{name}} kanssa alkaa pian. Saatat joutua odottamaan vastaanoton alkua hetken.',
	'serviceSelection.yourAppointmentsModal.submit': 'Mene vastaanotolle',
	'serviceSelection.bookings.newAppointment.button': 'Varaa aika',
	'serviceSelection.noUpcomingAppointments':
		'Sinulla ei ole tulevia ajanvarauksia',
	'serviceSelection.groupVideo': 'Ryhmävastaanotto',
	'serviceSelection.groupVideo.host': 'Järjestäjä',
	'serviceSelection.groupVideo.multiple': 'useita osallistujia',
	'serviceSelection.book.oneToOne': 'Varattu aika henkilön {{name}} kanssa',
	'serviceSelection.book.group':
		'Varattu ryhmävastaanottoaika (järjestäjä: {{name}})',
	'appointmentModal.headline': 'Tervetuloa etävastaanotolle!',
	'appointmentModal.body.one': 'Yhdistämme sinut nyt etävastaanotolle.',
	'appointmentModal.body.two':
		'Jos haluat nopeuttaa asiointiasi, voit vastata oirekyselyyn, jonka yhteenvedon vastaanottava ammattilainen saa.',
	'appointmentModal.button': 'Aloita vastaanottokäynti',
	'appointmentModal.questionnaire.button': 'Täytä oirekysely',
	'appointmentModal.mandatory.body':
		'Vastaathan oirekyselyyn ennen etävastaanottoa. Vastaanottava ammattilainen saa yhteenvedon oirekyselystä.',
	'appointmentModal.mandatory.button': 'Täytä oirekysely ja mene vastaanotolle',
	'appointmentModal.cancellable.title': 'Tulevat vastaanottosi',
	'appointmentModal.cancellable.toast': 'Vastaanottoaika peruttu',
	'appointmentModal.cancellable.button.cancel': 'Peruuta aika',
	'appointmentModal.cancellable.lead.top':
		'Voit liittyä etävastaanotolle 5 minuuttia ennen vastaanoton alkua.',
	'appointmentsModal.cancellable.lead.middle':
		'Jos sinun täytyy perua aika, voit tehdä sen 24 tuntia ennen varauksen alkua. Jos sinun täytyy perua varaus vähemmän kuin 24 h varauksesta, sinun täytyy olla yhteydessä suoraan palveluntarjoajaan.',
	'appointmentsModal.cancellable.lead.bottom':
		'Jos haluat muokata varaustasi, sinun täytyy ensin perua nykyinen ajanvaraus ja tämän jälkeen varata uusi aika.',
	'appointmentsModal.cancel.groupchat':
		'Jos sinun täytyy perua ryhmävastaanotto, sinun täytyy olla yhteydessä suoraan palveluntarjoajaan.',

	'chat.headline': 'Etävastaanotto',
	'chat.body':
		'Voit keskustella asiantuntijan kanssa ja lähettää hänelle kuvia tai tiedostoja painamalla paperiliittimen muotoista kuvaketta ikkunan vasemmassa alakulmassa.',
	'chat.button.help': 'Apua',
	'feedback.title': 'Kiitos! Arvostaisimme myös palautettasi.',
	'feedback.nps.lead': 'Kuinka todennäköisesti suosittelisit palvelua muille?',
	'feedback.ces.lead': 'Kuinka helppoa palvelua oli käyttää?',
	'feedback.careSatisfaction.lead':
		'Saitko asiasi ratkaistua tai tarvittavan jatko-ohjeistuksen?',
	'feedback.form.lead':
		'Halutessasi voit myös antaa meille vapaamuotoista palautetta. Annetun palautteen perusteella voimme parantaa etävastaanottopalvelua.',
	'feedback.veryUnlikely': 'Hyvin epätodennäköisesti',
	'feedback.veryLikely': 'Hyvin todennäköisesti',
	'feedback.veryHard': 'Erittäin vaikeaa',
	'feedback.veryEasy': 'Erittäin helppoa',
	'feedback.careSatisfaction.bad': 'En lainkaan',
	'feedback.careSatisfaction.good': 'Sain kaiken tarvitsemani',
	'feedback.thankYou.title': 'Etävastaanotto päättynyt!',
	'feedback.textarea.title': 'Palautetta',
	'logout.headline': 'Sinut on kirjattu ulos palvelusta.',
	'logout.text': 'Kiitos kun käytit palveluamme. Voit nyt sulkea tämän sivun.',
	'logout.text.second':
		'Jos sinun täytyy käyttää palvelua uudestaan, voit kirjautua uudelleen sisään.',
	'logout.expired':
		'Istuntosi on vanhentunut ja sinut on kirjattu ulos automaattisesti. Kirjauduthan uudelleen sisään jos käytät vielä palvelua, kiitos.',
	'actOnBehalfModal.title': 'Asioi toisen puolesta',
	'actOnBehalfModal.lead':
		'Jos sinulla on oikeus asioida toisen puolesta, voit kirjautua suomi.fi -palvelun kautta ja hakea toisen puolesta asioitavan oikeutuksen.',
	'actOnBehalfModal.actingAsSomeone': 'Asioit henkilön {{name}} puolesta',
	'actOnBehalfModal.button.actingAsMyself': 'Asioin itsenäni',
	'actOnBehalfModal.or': 'tai',
	'actOnBehalfModal.button.actingAsSomeone': 'Asioi toisen puolesta',
	'actOnBehalfModal.button.actingAsSomeoneElse': 'Asioi toisen puolesta',
	'actOnBehalfModal.willtakeYouMessage': 'Tämä vie sinut suomi.fi:hin',
	'selectedOrganizationModal.title': 'Kenen toimijan palveluja haluat käyttää?',
	'selectedOrganizationModal.title.none':
		'Palveluja ei vielä ole käytössä alueesi sote-palvelujen tarjoajilla',
	'selectedOrganizationModal.text':
		' Vaikuttaa siltä, että osoitetietojesi perusteella olet oikeutettu käyttämään useamman palveluntarjoajan palveluja. Valitse minkä palveluntarjoajan palveluja haluat käyttää.',
	'selectedOrganizationModal.text.none':
		'Valitettavasti vaikuttaa siltä, että alueellasi ei tarjota tätä kautta etäasioinnin palveluja. Lisätietoja halutessasi ole yhteydessä alueesi palveluntarjoajaan.',
	'selectedOrganizationModal.changeButton': 'Vaihda osoite',
	'chatNotAvailableModal.title':
		'Pahoittelut, palvelu on suljettu tällä hetkellä.',
	'chatNotAvailableModal.body':
		'Pahoittelut häiriöstä, voit yrittää asiointia uudestaan myöhemmin. Voit myös kokeilla toista palvelua tai kirjautua ulos?',
	'chatNotAvailableModal.button': 'Takaisin palvelun valintaan',
	'bookAppointments.title': 'Varaa uusi aika',
	'bookAppointments.form.date': 'Päivämäärä',
	'bookAppointemnts.form.specialization': 'Erikoisala/ammattilainen',
	'bookAppointments.button.search': 'Etsi aikoja',
	'bookAppointments.availableAppointments.title': 'Vapaat ajat',
	'bookAppointments.button.prev': 'Edellinen päivä',
	'bookAppointments.button.next': 'Seuraava päivä',
	'bookAppointments.back': 'Palaa takaisin varaamaan uusia aikoja',
	'bookAppointments.youAreBooking.title': 'Varaat nyt aikaa',
	'bookAppointments.preQuestionnaire.title': 'Esikysely',
	'bookAppointments.button.confirmBooking': 'Vahvista varaus',
	'bookAppointments.error':
		'Sinun täytyy vastata kaikkiin tarvittaviin kysymyksiin',
	'bookAppointment.confirmed.title': 'Varaus vahvistettu!',
	'bookAppointment.timeInfo.part1': 'Jos et vahvista varaustasi',
	'bookAppointment.timeInfo.part2': '15 minuutin kuluessa,',
	'bookAppointment.timeInfo.part3':
		'muut voivat valita saman ajan. Aikaa jäljellä:',
	'bookAppointment.failed.title': 'Varaus epäonnistui!',
	'bookAppointment.failed.lead':
		'Jokin meni pieleen. Jos et vahvistanut aikaasi annetussa ajassa, toinen henkilö on voinut varata ajan. Ole hyvä ja yritä varata uusi aika.',
	'bookAppointment.failed.button': 'Varaa uusi aika',
	'bookAppointment.confirmationModal.title':
		'Ajanvaraustasi ei ole vahvistettu',
	'bookAppointment.confirmationModal.lead':
		'Et ole vielä vahvistanut ajanvaraustasi. Jos poistut nyt, menetät ajanvarauksesi. Oletko varma että haluat jatkaa ja poistua?',
	'bookAppointments.timeslotNotAvailable':
		'Et voi varata tätä aikaa. Etsi uudelleen avoimia aikoja.',
	'bookAppointments.noAppointments':
		'Valitulle päivämäärälle ei ole vapaita aikoja',
	'changeMyLocation.title': 'Vaihda sijaintia',
	'changeMyLocation.lead':
		'Jos et ole kotikunnassasi, voit silti mahdollisesti asioida nykyisen sijaintisi palveluntarjoajan etäasioinnin palveluissa. Valitse alta alue jonka etäasioinnin palveluja haluat käyttää.',
	'changeMyLocation.button': 'Vaihda sijainti',
	'changeMyLocation.subText':
		'Vahvistamalla sijainnin, sinut siirretään kyseisen alueen palveluntarjoajan palveluihin.',
	'changeMyLocation.selectArea': 'Valitse alue',
	'guestThankYou.content.heading': 'Ryhmävastaanotto päättyi',
	'guestPage.email': 'Sähköposti',
	'guestPage.pinCode': 'PIN-koodi',
	'guestPage.appointmentNotFound':
		'Vastaanottoa ei löytynyt tai linkki on virheellinen.',
	'guestPage.enterPinHeader': 'Syötä PIN-koodi',
	'guestPage.enterPin':
		'Syötä sähköpostisi ja saamasi pin-koodi jatkaaksesi palveluun.',
	'guestPage.continue': 'Jatka palveluun',
	'guestPage.malformattedPin': 'Pin-koodi on kuusi numeroa',
	'guestPage.wrongpassword': 'Väärä PIN-koodi tai sähköposti',
	'guestPage.malformattedEmail': 'Virheellinen sähköpostiosoite',
	'guestPage.unknownLoginError': 'Kirjautuminen ei onnistu!',
	'guestPage.error': 'Tarkista kentät',
	'landing.activity.title': 'Sinun toimintosi',
	'landing.howWeCanHelpYou.title': 'Kuinka voimme auttaa sinua?',
	'landing.item.bookAppointment': 'Varaa aika',
	'landing.item.bookAppointment.helperText': 'Ajanvaraukseen',
	'landing.item.remoteReception': 'Etäasiointi',
	'landing.item.remoteReception.helperText': 'Etävastaanotolle',
	'landing.item.chatNow': 'Kirjoita viesti',
	'landing.item.chatNow.helperText': 'Viesteihin',
	'guestThankYou.content.thankyou': 'Kiitos kun käytit palveluamme!',
	'guestThankYou.content.notify':
		'Huomaathan, että jokaiseen ryhmävastaanottoon johon liityt vieraana, on erillinen linkki, joka toimitetaan erikseen sähköpostilla.',
	'guestThankYou.content.logout': 'Voit nyt kirjautua ulos ja sulkea sivun.',
	'guestThankYou.logout.button': 'Kirjaudu ulos',
	'questionnaireErrorModal.title':
		'Pahoittelut, virhe oirekyselyn latauksessa.',
	'questionnaireErrorModal.text': 'Oirekyselyä ei voi juuri nyt ladata.',
	'questionnaireErrorModal.text2':
		'Voit joko liittyä suoraan vastaanotolle täyttämättä oirekyselyä tai valita toisen palvelun menemällä takaisin palvelun valintaan.',
	'questionnaireErrorModal.button.serviceSelection':
		'Takaisin palvelun valintaan',
	'questionnaireErrorModal.button.chat': 'Liity etävastaanotolle',
	'surveyPage.error': 'Kyselyn laataaminen ei onnistunut',
	'surveyItem.answerSurvey': 'Vastaa kyselyyn',
	'surveyItem.link': 'Kyselyyn',
	'surveyPage.thankYou.title': 'Kiitos vastauksista ',
	'surveyPage.thankYou.content':
		'Sinua palveleva ammattilainen saa kyselyvastauksesi näkyviin ennen mahdollista vastaanottoa.',
	'asyncMessaging.messages.title': 'Viestit',
	'asyncMessaging.newMessage': 'Lähetä viesti',
	'asyncMessaging.yourMessages': 'Sinun viestisi',
	'asyncMessaging.noMessages': 'Keskustelua ei ole saatavilla',
	'asyncMessaging.sendNewMessage.attachButton': 'Liitä liitetiedosto',
	'asyncMessaging.sendNewMessage.sendButton': 'Lähetä',
	'asyncMessaging.reply': 'Vastaa',
	'asyncMessaging.discardDraft': 'Poista luonnos',
	'asyncMessaging.newMessageTitle': 'Lähetä viesti',
	'asyncMessaging.selectAnOption': 'Valitse',
	'asyncMessaging.sendMessage': 'Lähetä viesti',
	'asyncMessaging.sendMessage.description': 'Viesti esimerkki',
	'asyncMessaging.useMessageCode': 'Käytä viestikoodia',
	'asyncMessaging.useMessageCode.description': 'Esimerkki käydä viestikoodia',
	'asyncMessaging.newMessageCreateTitle': 'Uusi viesti',
	'asyncMessaging.messageCodeTitle': 'Viestikoodi',
	'asyncMessaging.messageCodeSubmit': 'Lähetä',
	'asyncMessaging.category': 'Kategoria',
	'asyncMessaging.subcategory': 'Ala-kategoria',
	'asyncMessaging.message': 'Viesti',
	'asyncMessaging.sendingError':
		'Virhe tallennuksessa. Yritä myöhemmin uudestaan.',
	'asyncMessaging.codeAdded': 'Koodi {{code}} lisätty',
	'asyncMessaging.messageCodes.error.201': 'Viestikoodi ei käytössä',
	'asyncMessaging.messageCodes.error.202': 'Viestikoodi ei ole toiminnassa.',
	'asyncMessaging.messageCodes.error.203': 'Viestikoodi on vanhentunut.',
	'asyncMessaging.messageCodes.error.204': 'Viestikoodi on jo käytetty.',
	'asyncMessaging.messageCodes.error.500': 'Virhe viestikoodin käsittelyssä.',
	'asyncMessaging.messageCodes.error.501': 'Virhe viestikoodin käsittelyssä.',
	'asyncMessaging.errors.messageCode': 'Lisää oikea viestikoodi',
	'asyncMessaging.errors.category': 'Valitse kategoria alasvetovalikosta',
	'asyncMessaging.errors.subcategory': 'Valitse alakategoria alasvetovalikosta',
	'asyncMessaging.errors.messageBoxText': 'Kirjoita viesti',
	'asyncMessaging.success': 'Viesti on lähetetty',
};

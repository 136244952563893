import styled, { css } from 'styled-components';

export const CONTENT_WIDTH = 1094;

export const ContentInner = styled.div`
	width: 100%;
	max-width: ${CONTENT_WIDTH}px;
	margin: 0 auto;
	position: relative;
	padding: ${(props) => props.theme.spacing['1']} 0;
`;

export const ContentSection = styled.div`
	padding: ${(props) => props.theme.spacing['1']}
		${(props) => props.theme.spacing['2']}
		${(props) => props.theme.spacing['1']};
	border: 1px solid ${(props) => props.theme.colors.zodiacBlack22};
	background-color: ${(props) => props.theme.colors.white};
	margin-bottom: ${(props) => props.theme.spacing['2']};
	${(props) => props.theme.media.mediaQueries.desktop} {
		padding: ${(props) => props.theme.spacing['1']}
			${(props) => props.theme.spacing['4']}
			${(props) => props.theme.spacing['4']};
	}
`;

export const ContentSectionInner = styled.div`
	margin: 0 auto;
	padding: ${(props) => props.theme.spacing['1']} 0;
`;

export const roundedStyles = css`
	background-color: ${(props) => props.theme.colors.white};
	border-radius: ${(props) => props.theme.border.radius.m};
	border-bottom: 1px solid ${(props) => props.theme.colors.zodiacBlack15};
	padding: ${(props) => props.theme.spacing['1_5']};
`;

export const RoundedWrapper = styled.div`
	${roundedStyles}
`;

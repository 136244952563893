export const fi = {
	'pageTitles.allBookings': 'Tarkastele kaikkia vastaanottoja',
	'pageTitles.appointment.open.edit': 'Muokkaa varattavia vastaanottoaikoja',
	'pageTitles.appointment.open.new': 'Avaa varattavia vastaanottoaikoja',
	'pageTitles.appointment.edit': 'Muokkaa vastaanottoaikaa',
	'pageTitles.appointment.new': 'Varaa vastaanottoaika',
	'pageTitles.appointmentHistory': 'Kontaktihistoria',
	'pageTitles.login': 'Sisäänkirjautuminen',
	'pageTitles.schedule': 'Kalenteri',
	'pageTitles.surveys': 'Lomakkeet',
	'pageTitles.messagesInbox': 'Saapuneet',
	'pageTitles.messagesSendNew': 'Lähetä uusi viesti',
	'pageTitles.messagesOpenConversations': 'Avoimet keskustelut',
	'pageTitles.messagesEndedConversations': 'Arkistoidut keskustelut',

	'common.writeMessage': 'Kirjoita viesti',
	'common.skip': 'Ohita',
	'common.send': 'Lähetä',
	'common.reopen': 'Avaa uudelleen',
	'common.delete': 'Poista',
	'common.minutes': 'Minuutteja',
	'common.save': 'Tallenna',
	'common.edit': 'Muokkaa',
	'common.cancel': 'Peruuta',
	'common.login': 'Sisäänkirjautuminen',
	'common.menu': 'Valikko',
	'common.username': 'Käyttäjänimi',
	'common.password': 'Salasana',
	'common.logOut': 'Uloskirjautuminen',
	'common.hello': 'Päivää',
	'common.language.finnish': 'Suomi',
	'common.language.english': 'Englanti',
	'common.language.swedish': 'Ruotsi',
	'common.backToSchedule': 'Palaa kalenteriin',
	'common.downloadPDF': 'Lataa PDF',
	'common.backToAllBookings': 'Palaa  ajanvarauksiin',
	'common.email': 'Sähköposti',
	'common.savingError': 'Tallennus epäonnistui',
	'common.errors': 'Virhe',
	'common.errors.105': 'Henkilötunnus ei ole oikeassa muodossa.',
	'common.errors.601': 'Pääkäyttäjää ei ole olemassa',
	'common.errors.602': 'Sinulla ei ole oikeuksia salasanan uusimiseen',
	'common.errors.801': 'Salasanan uusimislinkki on vanhentunut',
	'common.errors.802':
		'Salasana ei ole tarpeeksi vahva, sen tulee pitää sisällään sekä pieniä ja isoja kirjaimia sekä numeroita',
	'common.back': 'Takaisin',
	'common.you': 'Sinä',
	'common.forward': 'Välitä viesti eteenpäin',
	'login.headline': 'Kirjaudu sisään jatkaaksesi',
	'login.lead':
		'Tervetuloa ammattilaisen etätyöalustalle. Täällä voit hallinnoida ja luoda etävastaanottoja tehokkaasti ja helposti.',
	'login.info': 'Täytä kirjautumistietosi ja jatka sisäänkirjautumiseen',
	'login.question.one': 'Mikä on Ammattilaisen etätyöalusta?',
	'login.question.two': 'Miten saan tunnukset?',
	'login.question.three':
		'Miten organisaationi pääsee käyttämään ammattilaisen etätyöalustaa?',
	'login.button': 'Kirjaudu sisään käyttäjänimellä',
	'login.loginFailed': 'Kirjautuminen epäonnistui',
	'login.fromRegistration.title':
		'Olet luonut salasanan onnistuneesti. Jatka sisäänkirjautumiseen',
	'login.changePassword': 'Salasana unohtunut?',
	'login.logout.expired':
		'Istuntosi on vanhentunut ja sinut on automaattisesti uloskirjattu. Kirjauduthan uudestaan sisään jos haluat käyttää ammattilaisen etätyöalustaa.',
	'login.method.vrkCard': 'Kirjaudu VRK kortilla',
	'login.method.adCredentials': 'Kirjaudu organisaatiosi AD-tunnuksella',
	'mainMenu.options.desktop': 'Etätyöalusta',
	'mainMenu.options.schedule': 'Kalenteri',
	'mainMenu.options.surveys': 'Lomakkeet',
	'mainMenu.options.messages': 'Viestit',
	'mainMenu.options.termsOfService': 'Käyttöehdot',
	'mainMenu.options.privacyPolicy': 'Tietosuojaseloste',
	'mainMenu.section1': 'Miten voimme auttaa sinua?',
	'mainMenu.section2': 'Mikä on Ammattilaisen etäalusta?',
	'profileMenu.options.myInformation': 'Minun tietoni',
	'profileMenu.options.workReports': 'Työraportit',
	'profileMenu.options.toAdminPanel': 'Hallintapaneeliin',
	'header.title': 'Ammattilaisen etätyöalusta',
	'header.links.desktop': 'Etätyöalusta',
	'header.links.schedule': 'Kalenteri',
	'header.links.surveys': 'Lomakkeet',
	'header.links.messages': 'Viestit',
	'header.links.ninchat': 'Avaa Ninchat',
	'schedule.title': 'Tänään',
	'schedule.section.today.title': 'Vastaanotot',
	'schedule.section.patient.title': 'Potilas',
	'schedule.section.actions.title': 'Toiminnot',
	'schedule.cancel.title': 'Peruuta vastaanotto?',
	'schedule.cancel.body':
		'Haluatko varmasti peruuttaa vastaanoton? Tämä lähettää tiedon osallistujille.',
	'schedule.cancel.buttons.cancel': 'Peru ajanvaraus',
	'schedule.cancel.buttons.close': 'Sulje ilman perumista',
	'schedule.delete.title': 'Poistetaanko vastaanotto?',
	'schedule.delete.body': 'Oletko varma, että haluat poistaa vastaanoton?',
	'schedule.delete.buttons.submit': 'Poista vastaanotto',
	'schedule.delete.buttons.close': 'Sulje ilman poistamista',
	'schedule.buttons.now': 'Nyt',
	'schedule.buttons.sendAMessage': 'Lähetä viesti',
	'schedule.buttons.patientsAppointmentHistory': 'Potilaan ajanvaraushistoria',
	'schedule.buttons.surveysResults': 'Lomakevastaukset',
	'schedule.patientForm.ssn': 'Henkilötunnus',
	'schedule.patientForm.city': 'Kaupunki',
	'schedule.patientForm.age': 'Ikä',
	'schedule.patientForm.mobile': 'Puhelinnumero',
	'schedule.patientForm.company': 'Yritys',
	'schedule.patientForm.email': 'Sähköposti',
	'schedule.patientForm.additionalInfo': 'Lisätiedot',
	'schedule.buttons.today': 'Tänään',
	'schedule.buttons.openAvailableTimes': 'Avaa vastaanottoaikoja',
	'schedule.buttons.registerNewAppointment': 'Varaa vastaanottoaika',
	'schedule.buttons.browseAllBookings': 'Tarkastele kaikkia vastaanottoja',
	'schedule.buttons.preQuestionnaire': 'Esikyselyn vastaus',
	'schedule.open.title': 'Avaa vastaanottoaika',
	'CreateAppointmentModal.title': 'Varaa vastaanottoaika',
	'CreateAppointmentModal.field.ssn': 'Henkilötunnus',
	'CreateAppointmentModal.field.nationality': 'Kansalaisuus',
	'CreateAppointmentModal.field.expert': 'Ammattilainen',
	'CreateAppointmentModal.field.startingDate': 'Päivämäärä',
	'CreateAppointmentModal.field.startingTime': 'Alkamisaika',
	'CreateAppointmentModal.field.preferredEndingTime': 'Haluttu loppuaika',
	'CreateAppointmentModal.field.otherDuration': 'Muu',
	'CreateAppointmentModal.field.language': 'Potilaan kieli',
	'CreateAppointmentModal.field.email': 'Sähköposti',
	'CreateAppointmentModal.field.firstName': 'Etunimi',
	'CreateAppointmentModal.field.lastName': 'Sukunimi',
	'CreateAppointmentModal.field.name': 'Nimi',
	'CreateAppointmentModal.duration.subtitle': 'Kesto',
	'CreateAppointmentModal.duration.oneTimeslot.subtitle':
		'Yhden vastaanottoajan kesto',
	'CreateAppointmentModal.generateTimeslots.text':
		'{{number}} vastaanottoaikoja luodaan. Sinun tulee vahvistaa vastaanottoajat jälkikäteen avataksesi ne.',
	'CreateAppointmentModal.generateTimeslots.button': 'Luo vastaanottoaikoja',
	'CreateAppointmentModal.timeslots.reservable': 'Varattavissa',
	'CreateAppointmentModal.timeslots.break': 'Tauko',
	'CreateAppointmentModal.timeslots.table.startingTime': 'Alkamisaika',
	'CreateAppointmentModal.timeslots.table.endTime': 'Loppumisaika',
	'CreateAppointmentModal.timeslots.table.type': 'Tyyppi',
	'CreateAppointmentModal.error.ssn': 'Täytä henkilötunnus oikein',
	'CreateAppointmentModal.error.expert': 'Valitse ammattilainen listalta',
	'CreateAppointmentModal.error.startingDate': 'Valitse oikea alkamisajankohta',
	'CreateAppointmentModal.error.startingTime': 'Valitse oikea alkamisaika',
	'CreateAppointmentModal.error.startingDate.past':
		'Mennyttä aikaa ei voi valita',
	'CreateAppointmentModal.error.nationality': 'Valitse kansalaisuus',
	'CreateAppointmentModal.error.timeslotsListEmpty':
		'Vastaanottoaika tulee luoda ensin',
	'CreateAppointmentModal.error.availableDate': 'Päivämäärä ei voi olla tyhjä',
	'CreateAppointmentModal.error.availableAppointment':
		'Vastaanotto ei voi olla tyhjä',
	'CreateAppointmentModal.error.email': 'Aseta oikea sähköpostiosoite',
	'CreateAppointmentModal.reminderCheckbox':
		'Lähetä ajanvarausvahvistus sähköpostitse potilaalle',
	'createRemoteAppointment.emailReminder.lead':
		'Kirjautumisen jälkeen kaikki osapuolet saavat vahvistusviestin vastaanotosta sähköpostitse',
	'CreateAppointmentModal.button.create': 'Vahvista vastaanotto',
	'CreateAppointmentModal.button.update': 'Muokkaa vastaanottoa',
	'CreateAppointmentModal.button.createOpenAppointment':
		'Avaa vastaanottoaikoja',
	'CreateAppointmentModal.openAppointment.lead':
		'Avattuasi vastaanottoajan, potilas näkee sen ja voi varata sen kirjautumalla alustalle',
	'CreateAppointmentModal.subtitle.open.new':
		'Valitse päivä ja miten haluat työskennellä',
	'CreateAppointmentModal.subtitle.open.edit': 'Muokkaa vastaanottoaikoja',
	'CreateAppointmentModal.subtitle.new.createNewTimeslotAndBookIt':
		'Luo vastaanottoaikoja ja varaa',
	'CreateAppointmentModal.subtitle.new.bookAvailableTimeslot':
		'Varaa vastaanottoaika',
	'CreateAppointmentModal.subtitle.edit': 'Muokkaa vastaanottoa',
	'CreateAppointmentModal.field.date': 'Päivämäärä',
	'CreateAppointmentModal.field.appointment': 'Vastaanotto',
	'CreateAppointmentModal.button.nextAvailable':
		'Seuraava vapaa vastaanottoaika',
	'CreateAppointmentModal.bookTimeslot.noMore':
		'Ei enää vapaita vastaanottoaikoja',
	'CreateAppointmentModal.bookTimeslot.timeslotSectionTile': 'Vastaanottoaika',
	'CreateAppointmentModal.bookTimeslot.patientSectionTile': 'Potilas',
	'CreateAppointmentModal.toast.1': 'Uusi vastaanotto luotu',
	'CreateAppointmentModal.toast.2': 'Vastaanotto varattu',
	'CreateAppointmentModal.toast.3': 'Vastaanottoa muokattu',
	'CreateAppointmentModal.toast.4': 'Vapaa vastaanottoaika luotu',
	'CreateAppointmentModal.toast.5': 'Vapaata vastaanottoaikaa muokattu',
	'services.preQuestionnaire.title': 'Itsevarauksen esitietolomake',
	'appointment.title.open.new': 'Avaa varattavia vastaanottoaikoja',
	'appointment.title.open.edit': 'Muokkaa varattavia vastaanottoaikoja',
	'appointment.title.new': 'Varaa vastaanottoaika',
	'appointment.title.edit': 'Muokkaa vastaanottoaikaa',
	'appointment.checkbox.bookAvailableTimeslot': 'Varaa vapaa vastaanottoaika',
	'appointment.checkbox.createNewTimeslotAndBookIt':
		'Luo uusi vastaanottoaika ja varaa',
	'changePassword.headline': 'Ammattilaisen salasanan vaihto',
	'changePassword.successText':
		'An email has been succesfully sent to the address that was provided',
	'changePassword.subtext':
		'Linkki salasanan vaihtoon on lähetetty sähköpostiisi. Se vanhenee 30 minuutin kuluttua lähetyksestä.',
	'changePassword.submit': 'Vaihda salasan',
	'changePassword.backToLogin': 'Palaa sisäänkirjautumiseen',
	'changePassword.error.incorrectFormat': 'Väärä sähköpostiosoite',
	'changePassword.error.general': 'Virhe pyynnössä',
	'changePassword.error.badEmail': 'Sähköpostia ei löytynyt',
	'registration.title': 'Luo salasana',
	'registration.errors.samePassword':
		'Salasanan ja vahvistetun salasanan tulee olla samat',
	'registration.errors.validation':
		'Salasanassa tulee olla vähintään 12 kirjainta, mukaanlukien numerot, isot sekä pienet kirjaimet',
	'registration.errors.general':
		'Salasanaa ei voitu vaihtaa, ole yhteydessä ylläpitoon',
	'registration.fields.password': 'Salasana',
	'registration.fields.passwordConfirm': 'Vahvista salasana',
	'appointmentHistory.title': 'Kontaktihistoria',
	'appointmentHistory.lead':
		'Täällä näet ajat jolloin olet ollut potilaisiin yhteydessä viimeksi Ammattilaisen etäalustalla',
	'allBookings.title': 'Selaa kaikkia vastaanottoja',
	'allBookings.subtitle': 'Voit rajata hakua päivämäärällä',
	'allBookings.filter.startDate': 'Alkupäivä',
	'allBookings.filter.endDate': 'Loppupäivä',
	'allBookings.filter.matchStartDate': 'Valitse alkupäivämäärä',
	'allBookings.table.patient.title': 'Potilas',
	'allBookings.table.status.title': 'Tila',
	'allBookings.table.dateTime.title': 'Päivämäärä, aika',
	'allBookings.table.actions.title': 'Toiminnot',
	'allBookings.table.status.selfBooked': 'Varattu',
	'allBookings.table.status.booked': 'Varattu',
	'allBookings.table.status.cancelled': 'Peruutettu',
	'allBookings.table.status.open': 'Avattu',
	'surveyPage.error': 'Kyselyn laataaminen ei onnistunut',
	'common.backToHomepage': 'Takaisin etusivulle',
	'surveyItem.answerSurvey': 'Vastaa kyselyyn',
	'surveyItem.nameMissing': 'Kyselyn nimi puuttuu',
	'surveyItem.selectSurvey': 'Valitse lisättävä kysely',
	'surveyItem.addSurvey': 'Lisää kysely',
	'survey.Description':
		'Kun rekisteröit vastaanoton, voit valita ennalta lähetettäviä kyselyjä potilaalle lähetettäväksi. Kyselyt voivat sisältää esimerkiksi standaroituja lääketieteellisiä kyselyjä, voit myös luoda omia kyselyjä.',
	'survey.Title': 'Kyselyt',
	'surveyList.surveyResults': 'Kyselyn tulokset',
	'surveyList.answeredSurveys': 'Vastatut kyselyt',
	'surveyList.open': 'Avoimet kyselyt',
	'surveyList.showAnswers': 'Näytä vastaukset',
	'organizationSelectionModal.button.login': 'Kirjaudu sisään',
	'organizationSelectionModal.headline': 'Valitse organisaatio johon kirjaudut',
	'questionnaireModal.title': 'Esikyselyn lomake',
	'questionnaireModal.lead':
		'Alta voit löytää potilaan vastaukset esikyselyyn.',
	'feedback.title': 'Tervetuloa takaisin! Kerrothan mielipiteesi palvelustamme',
	'feedback.lead': 'Kuinka helppoa palvelua oli käyttää?',
	'feedback.veryHard': 'Erittäin vaikeaa',
	'feedback.veryEasy': 'Erittäin helppoa',
	'feedback.form.lead':
		'Halutessasi voit myös antaa meille vapaamuotoista palautetta.',
	'survey.navigation.answered': 'Vastatut kyselyt',
	'survey.navigation.open': 'Avoimet kyselyt',
	'survey.navigation.forwarded': 'Sinulle välitetyt',
	'surveyManagement.forwarded': 'Välitetyt kyselyt',
	'surveyManagement.templates': 'Kyselypohjat',
	'surveyManagement.sortBy': 'Järjestä:',
	'surveyManagement.appointmentDate': 'Tapaamisen päivämäärä',
	'surveyManagement.patientName': 'Potilaan nimi',
	'surveyManagement.templates.description':
		'Tässä näet kaikki organisaatiollasi käytössä olevat kyselyt. Jos haluat käyttöön jonkin uuden kyselyn, ole yhteydessä organisaatiosi hallintakäyttäjään.',
	'surveyList.activatedOn': 'Aktivoitu',
	'surveyList.answered': 'vastattu',
	'surveyList.forwardedBy': 'Välittänyt',
	'surveyList.forwardedAt': '',
	'surveyList.sendReminder': 'Lähetä muistutus',
	'surveyList.forward': 'Välitä',
	'surveyManagement.forwardModal.title': 'Välitä kyselyvastaukset',
	'surveyManagement.forwardModal.selectRecipients': 'Valitse vastaanottaja',
	'surveyManagement.forwardModal.button': 'Välitä vastaukset',
	'surveyManagement.toast.surveyForwarded': 'Kysely välitetty',
	'surveyManagement.remindersSent': 'muistutuksia lähetetty',
	'surveyManagement.toast.reminderSent': 'Muistutus lähetetty',
	'surveyManagement.modal.info': 'Lähetä uusi muistutus kyselystä',
	'surveyManagement.modal.title': 'Lähetä muistutus',
	'asyncMessaging.navigation.inbox': 'Saapuneet',
	'asyncMessaging.navigation.sendNewMessage': 'Lähetä uusi viesti',
	'asyncMessaging.navigation.openConversations': 'Avoimet keskustelut',
	'asyncMessaging.navigation.endedConversations': 'Arkistoidut keskustelut',
	'asyncMessaging.sendNewMessage.title': 'Lähetä uusi viesti',
	'asyncMessaging.sendNewMessage.subtitle': 'Vastaanottaja',
	'asyncMessaging.sendNewMessage.name': 'Vastaanottajan nimi',
	'asyncMessaging.sendNewMessage.ssn': 'Henkilötunnus',
	'asyncMessaging.sendNewMessage.email': 'Vastaanottajan sähköposti',
	'asyncMessaging.sendNewMessage.mobile': 'Vastaanottajan puhelinnumero',
	'asyncMessaging.sendNewMessage.sms': 'Lähetä tekstiviesti muistutus',
	'asyncMessaging.sendNewMessage.subjectTitle': 'Aihe',
	'asyncMessaging.sendNewMessage.messageTitle': 'Viesti',
	'asyncMessaging.sendNewMessage.errorsTitle': 'Virhe:',
	'asyncMessaging.sendNewMessage.ssnError': 'Syötä oikea henkilötunnus',
	'asyncMessaging.sendNewMessage.nameError': 'Syötä oikea nimi',
	'asyncMessaging.sendNewMessage.emailError': 'Syötä oikea sähköposti',
	'asyncMessaging.sendNewMessage.mobileError': 'Syötä oikea puhelinnumero',
	'asyncMessaging.sendNewMessage.subjectError': 'Valitse aihe',
	'asyncMessaging.sendNewMessage.messageBoxTextError': 'Valitse viesti',
	'asyncMessaging.sendNewMessage.sendButton': 'Lähetä',
	'asyncMessaging.sendNewMessage.attachButton': 'Liitä liitetiedosto',
	'asyncMessaging.success': 'Viesti on lähetetty',
	'asyncMessaging.patients.title': 'Asukkaat',
	'asyncMessaging.patients.openConversations.title': 'Avoimet keskustelut',
	'asyncMessaging.patients.endedConversations.title': 'Arkistoidut keskustelut',
	'asyncMessaging.patients.conversation.title': 'Keskustelu',
	'asyncMessaging.patients.actions.title': 'Toiminnot',
	'asyncMessaging.patients.actions.endConversation': 'Päätä keskustelu',
	'asyncMessaging.patients.actions.bookAnAppointment': 'Varaa vastaanottoaika',
	'asyncMessaging.patients.actions.sendSurvey': 'Lähetä kysely',
	'asyncMessaging.patients.reply': 'Vastaa',
	'asyncMessaging.patients.forward': 'Välitä',
	'asyncMessaging.patients.endConversationConfirmation':
		'Oletko varma, että haluat päättää keskustelun?',
	'asyncMessaging.patients.categories.title': 'Kategoriat',
	'asyncMessaging.inbox.title': 'Saapuneet',
	'asyncMessaging.inbox.groups.title': 'Kategoriat',
	'asyncMessaging.filters.title': 'Filter',
	'asyncMessaging.filters.subject': 'Aihe',
	'asyncMessaging.filters.name': 'Vastaanottajan nimi',
	'asyncMessaging.filters.ssn': 'Vastaanottajan henkilötunnus',
	'asyncMessaging.sendingError':
		'Virhe tallennuksessa. Yritä myöhemmin uudestaan.',
};

import styled from 'styled-components';

import {
	Button,
	TextButton,
} from '@terveystalo/design-system-react-components';
import { ThemeType } from 'app/styles/themes/themes';

interface ICustomButton {
	theme: ThemeType;
	fullWidth?: boolean;
	noTopMargin?: boolean;
}

export const CustomButton = styled(Button)<ICustomButton>`
	margin: ${(props) => props.theme.spacing['0_5']} 0;

	${(props: ICustomButton) =>
		props.fullWidth &&
		`
			width: 100%;
		
	`}

	${(props: ICustomButton) =>
		props.noTopMargin &&
		`
			margin-top: 0;
		
	`}
`;

export const CustomTextButton = styled(TextButton)`
	background: none;
	border: none;
`;

import React, { useEffect, useState, useContext } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Alert, Icon } from '@terveystalo/design-system-react-components';

import { config } from 'app/config/config';
import AnalyticsService from 'common/services/analytics/analytics';
import { AppointmentItemData } from 'app/services/serviceSelection';
import { AppContext } from 'app/context/appContext';
import GuestUserService, {
	CompanyCodeResponse,
} from 'app/services/guestUser/index';
import { getConfigAsync } from 'app/store/configuration/slices/configuration.slice';
import { GuestLogin } from 'app/components/guestLogin/guestLogin';
import { ServiceItemAppointment } from 'app/components/serviceItem/serviceItemAppointment';
import { AppointmentModal } from 'app/components/appointmentModal/appointmentModal';

import { SpinnerWrapper } from './guestUser.styles';

export const GuestUser = () => {
	const dispatch = useDispatch();
	const { setGuestDetails, isGuestAuthenticated, guestDetails } =
		useContext(AppContext);
	const [appointmentDialogOpen, setAppointmentDialogOpen] = useState(false);
	const [selectedChatDetails, setSelectedChatDetails] = useState<any>(null);
	const { t } = useTranslation();
	const { uuid } = useParams<{ uuid: string }>();
	const history = useHistory();

	const companyQuery = useQuery(
		'companyId',
		() => GuestUserService.getCompanyIdForAppointment(uuid),
		{
			enabled: false,
			onSuccess: (response: CompanyCodeResponse) => {
				dispatch(getConfigAsync({ companyId: response.organization_code }));
			},
		},
	);

	const appointmentQuery = useQuery(
		'appointmentData',
		() => GuestUserService.getAppointments(uuid),
		{
			enabled: false,
		},
	);

	useEffect(() => {
		companyQuery.refetch();
	}, [uuid]);

	useEffect(() => {
		checkToken();
	}, []);

	useEffect(() => {
		if (companyQuery.data && isGuestAuthenticated()) {
			appointmentQuery.refetch();
		}
	}, [companyQuery.data, guestDetails]);

	const openScheduledAppointmentDialog =
		(item: AppointmentItemData) => async () => {
			setSelectedChatDetails({
				serviceName: item.name,
				queueId: item.queueId,
				chatCodeService: false,
				uuid: item.uuid,
				isCancellable: false,
				type: item.type,
				audienceId: item.audienceId,
			});
			setAppointmentDialogOpen(true);
		};

	const openNinChat = async () => {
		setAppointmentDialogOpen(false);
		let secureMetadata = {};
		if (guestDetails?.name) {
			secureMetadata = { 'First Name': guestDetails?.name };
		}

		AnalyticsService.sendCustomEvent(
			'remoteAppointmentStartAppointment',
			'remote appointment',
			'start appointment',
			'guest user chat',
			{ serviceType: 'group chat' },
		);

		history.push(config.paths.chat, {
			ninChatConfig: { ...selectedChatDetails, secureMetadata },
		});
	};

	const closeAppointment = () => {
		AnalyticsService.sendCustomEvent(
			'remoteAppointmentStartAppointment',
			'remote appointment',
			'cancel appointment',
			'guest user chat',
			{ serviceType: 'group chat' },
		);
	};

	const checkToken = async () => {
		const tokenResponse = await GuestUserService.checkAuth(uuid);

		if ('token' in tokenResponse) {
			setGuestDetails({ name: tokenResponse.name, email: tokenResponse.email });
		}
	};

	return (
		<>
			{(companyQuery.isLoading || appointmentQuery.isLoading) && (
				<SpinnerWrapper>
					<Icon name="Spinner" width={24} height={24} color="matisseBlue" />
				</SpinnerWrapper>
			)}

			{companyQuery.error && (
				<>
					<br />
					<br />
					<Alert icon="AlertOctagon" variant="error">
						{t('guestPage.appointmentNotFound')}
					</Alert>
				</>
			)}

			{companyQuery.data && !isGuestAuthenticated() && <GuestLogin />}
			{isGuestAuthenticated() && appointmentQuery.data && companyQuery.data && (
				<ServiceItemAppointment
					onClick={openScheduledAppointmentDialog}
					item={appointmentQuery.data}
				/>
			)}
			{appointmentDialogOpen && (
				<AppointmentModal
					isOpen={appointmentDialogOpen}
					toggleModal={setAppointmentDialogOpen}
					successAction={openNinChat}
					closeAction={closeAppointment}
					cancelAction={() => {}}
					scheduledAppointmentsActive={false}
					selectedChatDetails={selectedChatDetails}
				/>
			)}
		</>
	);
};

import PatientApiService from 'app/services/config';
import { Response } from 'common/typings/response';

export interface AppointmentHistoryItemDocument {
	id: number;
	filename: string;
}

export interface AppointmentHistoryItemType {
	id: string;
	created_at: string;
	expert_name: string;
	files?: AppointmentHistoryItemDocument[];
}

export interface AppointmentHistoryMessage {
	message_time: number;
	message_user: string;
	message_text: string;
}

export interface AppointmentHistoryChat {
	messages: AppointmentHistoryMessage[];
}

export interface AppointmentHistoryFileLink {
	file_url: string;
	thumbnail_url: string;
}

export type Message = {
	message_time: number;
	message_type: string;
	message_username: string;
	message_user_id: string;
	message_text: string;
};

export type File = {
	id: string;
	filename: string;
};

export interface AppointmentChat {
	messages: Message[];
	files: File[];
	members: {
		[key: string]: {
			[key: string]: string;
		};
	};
}

class AppointmentHistoryService {
	public async getAppointmentHistory() {
		const response: Response<AppointmentHistoryItemType[]> =
			await PatientApiService.get('/ninchat/my-transcripts');
		return response.data;
	}

	public async getAppointmentHistoryFile(fileId: string) {
		const response: Response<AppointmentHistoryFileLink> =
			await PatientApiService.get('/ninchat/file', {
				id: fileId,
			});
		return response.data;
	}

	public async getAppointmentHistoryChat(chatId: string) {
		const response: Response<AppointmentChat> = await PatientApiService.get(
			'/ninchat/transcript',
			{
				id: chatId,
			},
		);
		return response.data;
	}
}

export default new AppointmentHistoryService();

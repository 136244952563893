import styled from 'styled-components';

export const QuestionnaireWrapper = styled.div``;

export const QuestionnaireContainer = styled.div`
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	padding-top: 25px;
	min-height: 500px;
`;

export const QuestionnaireFrame = styled.iframe`
	position: absolute;
	top: 0;
	left: 0;
	border: 0;
	width: 100%;
	height: 100%;
`;

import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from 'styled-components';
import i18next from 'i18next';

import {
	Icon,
	Subtitle,
	Text,
} from '@terveystalo/design-system-react-components';

import { AppContext, UserDetails } from 'app/context/appContext';
import { ContactForm } from 'app/components/contactForm/contactForm';
import AnalyticsService from 'common/services/analytics/analytics';

import { ContentSection } from '../home.styles';

import { FormWrapper } from './contact.styles';

export const prepareInitialValues = (userDetails: UserDetails) => ({
	firstName: userDetails.firstName,
	lastName: userDetails.lastName,
	street: userDetails.street,
	code: userDetails.code,
	city: userDetails.city,
	mobile: userDetails.mobile,
	email: userDetails.email,
	country: userDetails.country,
	nationality: userDetails.nationality,
});

export const ContactSubPage = () => {
	const { t } = useTranslation();
	const { userDetails } = useContext(AppContext);
	const theme = useContext(ThemeContext);

	const [initialValues, setInitialValues] = useState<any>(false);

	useEffect(() => {
		if (userDetails) {
			const values = prepareInitialValues(userDetails);
			setInitialValues(values);
		}
	}, [userDetails]);

	const lang = i18next.language;

	useEffect(() => {
		document.title = t('pageTitles.contact');
	}, [lang]);

	useEffect(() => {
		document.title = t('pageTitles.contact');
		AnalyticsService.sendPageView(t('pageTitles.contact'));
	}, []);

	const loadingInProgress = !initialValues;

	return (
		<div>
			<Subtitle color={theme.colors.zodiacBlack} size="m">
				{t('contact.headline')}
			</Subtitle>
			<ContentSection>
				<Text size="m">{t('contact.mainText')}</Text>
				<FormWrapper>
					{loadingInProgress && (
						<Icon name="Spinner" color={theme.colors.primary} />
					)}
					{!loadingInProgress && <ContactForm initialValues={initialValues} />}
				</FormWrapper>
			</ContentSection>
		</div>
	);
};

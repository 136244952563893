import styled from 'styled-components';
import { ContentSection } from '../home.styles';

export const SubtitleWrapper = styled.div`
	margin: ${(props) => props.theme.spacing['1']}
		${(props) => props.theme.spacing['1']}
		${(props) => props.theme.spacing['0_5']} 0;
`;

export const DateSubtitleWrapper = styled.div`
	margin: ${(props) => props.theme.spacing['1']}
		${(props) => props.theme.spacing['1']}
		${(props) => props.theme.spacing['1']} 0;
`;

export const CustomTextWrapper = styled.div`
	margin: auto ${(props) => props.theme.spacing['1']} auto 0;
`;

export const AppoinmentHistoryDetailsWrapper = styled.div``;

export const SpinnerWrapper = styled.div`
	text-align: center;
`;

export const ContentSectionWrapper = styled(ContentSection)`
	display: flex;
	align-items: center;
	padding: ${(props) => props.theme.spacing['0_75']} 0
		${(props) => props.theme.spacing['0_75']} 0;
	margin-bottom: ${(props) => props.theme.spacing['0_75']};
	cursor: pointer;
`;

export const DocumentIconWrapper = styled.div`
	height: 25px;
	margin: 0 ${(props) => props.theme.spacing['1']} 0
		${(props) => props.theme.spacing['1']};
`;

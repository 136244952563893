export enum ErrorCodes {
	UNAUTHORIZED = 401,
	ACCESS_DENIED = 403,
	NOT_FOUND = 404,
	SERVER_ERROR = 500,
}

export enum BackEndErrorCodes {
	ERR_NO_AUTH = 101, // User not authenticated
	ERR_WRONG_SSN = 105, // Used when SSN can't be turned into GUID

	ERR_CHAT_CODE_NV = 201, // Chat code not valid
	ERR_CHAT_CODE_NV_Y = 202, // Chat code not valid YET
	ERR_CHAT_CODE_EXP = 203, // Chat code expired
	ERR_CHAT_CODE_USED = 204, // Chat code already used
	ERR_ADMIN_APPOINTMENT_TIME_OVERLAPPING = 422,
	ERR_ADMIN_APPOINTMENT_TIME_IN_PAST = 423,
	ERR_OTHER = 500, // Other error
	ERR_NO_MATCHING_AREA = 501,
	ERR_ADMIN_NOT_FOUND = 601, // admin doesent exists
	ERR_ADMIN_NO_ACCESS = 602, // admin has no rights
	ERR_ADMIN_NOT_ACTIVE = 607,
	ERR_PWD_TOKEN_WRONG_OR_EXPIRED = 801, // token expired or wrong
	ERR_PWD_TOO_WEAK = 802, // password to weak

	// -----NINCHAT API REALTED ERRORS ----- //
	ERR_NINCHAT_USERCREATION = 1001, // unable to create user
	ERR_NINCHAT_ADDTOREALM = 1002, // unable to add user to realm
	ERR_NINCHAT_EMAIL_ALREADY_EXISTS = 1003, // email allready exists on ninchat
	ERR_NINCHAT_ADD_TO_AUDIENCE = 1004, // unable to add user to audience
	ERR_NINCHAT_AUDIENCECREATION = 1005, // unable to create audience
	ERR_NINCHAT_EXPERT_MISSING_NINCHAT_ID = 1006, // ninchat expert is missing ninchat user_id
	ERR_NINCHAT_QUECREATION = 1007, // unable to create queue

	ERR_EXPERT_DUPLICATE_EMAIL = 1100, // expert with duplicate email
}

import styled from 'styled-components';

export const TextAreaWrapper = styled.div`
	&& {
	 		textarea {
	 			background-color: ${(props) => props.theme.colors.zodiacBlack04};
	
	 			&:hover:not(.disabled) {
	 				border-color: ${(props) => props.theme.colors.primary};
	 			}
	
	 			&:active:not(.disabled),
	 			&:focus:not(.disabled) {
	 				box-shadow: 0 0 0 0.125rem ${(props) => props.theme.colors.primary};
	 			}
			}
`;

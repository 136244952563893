import styled from 'styled-components';
import { TextField } from '@terveystalo/design-system-react-components';

export const CustomTextField = styled(TextField).attrs({
	grayBackground: true,
})<{
	errorStyle?: 'error' | 'warning';
	label: string;
	type?: string;
}>`
	&& {
		[id*='-valid-msg'] {
			visibility: hidden;
		}

		input {
			${(props) =>
				props.errorStyle === 'error' &&
				`
					background-color: ${props.theme.colors.coralRed15};
					border-color: ${props.theme.colors.coralRed150};
					color: ${props.theme.colors.coralRed150};
					font-weight: bold;
				}
			`};

			${(props) =>
				props.errorStyle === 'warning' &&
				`
					background-color: ${props.theme.colors.cadmiumYellow15};
					font-weight: bold;
				}
			`};

			${(props) =>
				props.type === 'number' &&
				`
					padding-right: ${props.theme.spacing['1']};
					
					+ img {
						display: none;
					}
				}
			`};
		}
	}
`;

import styled from 'styled-components';

export const FieldsWrapper = styled.div`
	display: flex;
	width: 100%;
	flex-direction: column;

	${(props) => props.theme.media.mediaQueries.desktop} {
		flex-direction: row;
	}

	> div {
		width: 100%;
	}
`;

export const PostalCodeField = styled.div`
	&& {
		margin-right: ${(props) => props.theme.spacing['1_5']};

		${(props) => props.theme.media.mediaQueries.desktop} {
			max-width: 265px;
		}
	}
`;

export const ButtonsWrapper = styled.div`
	display: flex;
	justify-content: flex-end;
`;

export const AlertListItem = styled.li`
	margin: 0;
	padding: 0;
`;

export const SuccessWrapper = styled.div`
	background-color: ${(props) => props.theme.colors.viridianGreen15};
	padding: ${(props) => props.theme.spacing['1']};
	display: flex;
	color: ${(props) => props.theme.colors.okayGreen};

	> span {
		margin-left: ${(props) => props.theme.spacing['0_5']};
	}
`;

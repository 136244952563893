import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ThemeContext } from 'styled-components';
import { useLocation, useRouteMatch } from 'react-router-dom';
import i18next from 'i18next';
import { Text, Icon, Title } from '@terveystalo/design-system-react-components';

import { Toggle } from 'common/components/toggle/Toggle';

import { CustomButton } from 'app/styles/components/button/button';

import WebAuthService from 'app/services/webAuth/webAuth';

import { AppContext } from 'app/context/appContext';
import { config } from 'app/config/config';
import AnalyticsService from 'common/services/analytics/analytics';

import {
	ButtonInfoWrapper,
	ButtonWrapper,
	ButtonInnerWrapper,
	ActionOnBehalfWrapper,
	OnBehalfButtonContent,
	OnBehalfButtonText,
	OnBehalfContent,
} from './authentication.styles';
import { ContentSection } from '../home.styles';

enum LogoutState {
	default = 'default',
	unauthorized = 'unathorized',
}

export const AuthenticationSubPage = () => {
	const { t } = useTranslation();
	const lang = i18next.language;

	const location: any = useLocation();

	const [logoutState, setLogoutState] = useState(LogoutState.default);

	const theme = useContext(ThemeContext);
	const { cookiesOpen } = useContext(AppContext);

	const isLogout = !!useRouteMatch(config.paths.authenticationLogout);

	const handleAuthentication = () => {
		WebAuthService.loginWithCredentials();
	};

	useEffect(() => {
		document.title = t('pageTitles.authentication');
	}, [lang]);

	useEffect(() => {
		document.title = t('pageTitles.authentication');
		AnalyticsService.sendPageView(t('pageTitles.authentication'));

		const params = Object.fromEntries(
			new URLSearchParams(location.search).entries(),
		);

		if (params.unauthorized) {
			setLogoutState(LogoutState.unauthorized);
		}

		// eslint-disable-next-line
	}, []);

	return (
		<div>
			<Title
				data-testid="authentication-headline"
				color={theme.colors.primary}
				as="h1"
				size="l"
			>
				{isLogout
					? t('logout.headline')
					: t('authentication.identificationHeadline')}
			</Title>
			<ContentSection>
				{isLogout && (
					<div>
						{logoutState === LogoutState.default && (
							<div>
								<Text size="m">{t('logout.text')}</Text>
								<br />
								<Text size="m">{t('logout.text.second')}</Text>
							</div>
						)}
						{logoutState === LogoutState.unauthorized && (
							<Text size="m">{t('logout.expired')}</Text>
						)}
					</div>
				)}
				{!isLogout && (
					<div>
						<Text size="m">{t('authentication.identificationBody')}</Text>
					</div>
				)}
				<ActionOnBehalfWrapper>
					<Toggle
						trigger={(isCollapsed: boolean) => (
							<OnBehalfButtonContent>
								<Icon name="AgeGroup" color={theme.colors.primary} />
								<OnBehalfButtonText color={theme.colors.primary} size="m">
									{t('authentication.actOnBehalfTitle')}
								</OnBehalfButtonText>
								{isCollapsed ? (
									<Icon name="ChevronDownSmall" color={theme.colors.primary} />
								) : (
									<Icon name="ChevronUpSmall" color={theme.colors.primary} />
								)}
							</OnBehalfButtonContent>
						)}
						content={
							<OnBehalfContent>
								<Text color={theme.colors.primary} size="m">
									{t('authentication.actOnBehalfContent')}
								</Text>
							</OnBehalfContent>
						}
					/>
				</ActionOnBehalfWrapper>
				<ButtonWrapper>
					<ButtonInnerWrapper>
						<CustomButton
							data-testid="authentication-button"
							disabled={cookiesOpen}
							onClick={handleAuthentication}
						>
							{t('authentication.identificationButton')}
						</CustomButton>
						<ButtonInfoWrapper>
							<Icon name="Lock" color={theme.colors.primary} />
							&nbsp;&nbsp;
							<Text size="m">{t('authentication.identificationLockText')}</Text>
						</ButtonInfoWrapper>
					</ButtonInnerWrapper>
				</ButtonWrapper>
			</ContentSection>
		</div>
	);
};

import { Response } from 'common/typings/response';

import AdminApiService from 'adminApplication/services/config';

export type IconsData = Array<{
	name: string;
	url: string;
}>;

class IconsService {
	public async getIcons(): Promise<any> {
		const response: Response<IconsData> = await AdminApiService.get(
			`/admin/icon/list`,
		);
		return response.data;
	}
}

export default new IconsService();

import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from 'styled-components';
import { Text, Icon } from '@terveystalo/design-system-react-components';
import i18next from 'i18next';

import { convertDate } from 'common/helpers/dates';
import { Language } from 'common/lang/lang';

import { config } from 'app/config/config';
import {
	AppointmentHistoryButton,
	AppointmentHistoryItemWrapper,
	CustomConversedText,
	CustomDateText,
	HistoryIconWrapper,
	RightArrowIconWrapper,
} from './appointmentHistoryItem.styles';

export interface AppointmentHistoryItemDocument {
	id: number;
	filename: string;
}
export interface AppointmentHistoryItemType {
	id: string;
	created_at: string;
	expert_name: string;
	files?: AppointmentHistoryItemDocument[];
}

export interface AppointmentHistoryItemProps {
	appointmentItem: AppointmentHistoryItemType;
}

export const AppointmentHistoryItem = ({
	appointmentItem,
}: AppointmentHistoryItemProps) => {
	const { t } = useTranslation();
	const theme = useContext(ThemeContext);
	const history = useHistory();
	const [date, setDate] = useState('');
	const isAppointmentFiles = !!appointmentItem.files?.length;

	const currentLanguage = i18next.language as Language;

	useEffect(() => {
		if (!appointmentItem.id) return;
		const dateObject = new Date(appointmentItem.created_at);
		const dateString = convertDate(
			dateObject.getTime(),
			undefined,
			currentLanguage,
		);
		setDate(dateString);
	}, [appointmentItem, currentLanguage]);

	const handleClick = () => {
		history.push(config.paths.appointmentHistory + appointmentItem.id);
	};

	const renderDocumentStrings = () => {
		let documentStrings = '';
		if (appointmentItem.files) {
			documentStrings = appointmentItem.files.join(', ');
		}
		if (documentStrings.length > 45) {
			documentStrings = documentStrings.slice(0, 45);
			documentStrings += ' ...';
		}
		return documentStrings;
	};

	return (
		<AppointmentHistoryItemWrapper>
			<AppointmentHistoryButton
				onClick={handleClick}
				data-testid="history-item-button"
			>
				<HistoryIconWrapper>
					<Icon name="History" color={theme.colors.primary} />
				</HistoryIconWrapper>
				<div>
					<CustomDateText size="m" color={theme.colors.primary}>
						{date}
					</CustomDateText>
					<CustomConversedText color={theme.colors.zodiacBlack65} size="m">
						{t('appointmentHistory.list.conversed')}:{' '}
						{appointmentItem.expert_name}
					</CustomConversedText>
					{isAppointmentFiles && (
						<Text size="m">
							{t('appointmentHistory.list.document')}:&nbsp;
							{renderDocumentStrings()}
						</Text>
					)}
				</div>
				<RightArrowIconWrapper>
					<Icon name="ArrowRight" color={theme.colors.primary} />
				</RightArrowIconWrapper>
			</AppointmentHistoryButton>
		</AppointmentHistoryItemWrapper>
	);
};

import React, { useContext, useEffect, useState } from 'react';
import { Formik, Field, Form, FormikValues, FormikErrors } from 'formik';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import i18next from 'i18next';
import { ThemeContext } from 'styled-components';
import { useQuery } from 'react-query';

import { Alert, Icon } from '@terveystalo/design-system-react-components';

import { showErrors, validateEmail } from 'common/helpers/validation';
import { FormikTextField } from 'common/components/forms/textField/FormikTextField';
import { FormikSelectField } from 'common/components/forms/select/FormikSelectField';
import CitiesService, { Nationalities, Cities } from 'common/services/cities/';

import { CustomButton } from 'app/styles/components/button/button';
import { Contact } from 'app/services/contact';
import { config } from 'app/config/config';
import { AppContext } from 'app/context/appContext';
import { selectCities } from 'app/store/cities/slices/cities.slice';
import { setContactAsync } from 'app/store/contact/slices/contact.slice';
import AnalyticsService from 'common/services/analytics/analytics';
import {
	getConfigAsync,
	selectConfig,
} from 'app/store/configuration/slices/configuration.slice';
import PatientApiService from 'app/services/config';

import {
	FieldsWrapper,
	PostalCodeField,
	ButtonsWrapper,
	AlertListItem,
	SuccessWrapper,
} from './contactForm.styles';

export const validate = (values: FormikValues) => {
	const errors: FormikErrors<FormikValues> = {};

	if (!values.firstName) {
		errors.firstName = 'contact.firstName';
	}

	if (!values.lastName) {
		errors.lastName = 'contact.lastName';
	}

	if (!values.street) {
		errors.street = 'contact.street';
	}

	if (!values.code || values.code.length !== 5 || Number.isNaN(values.code)) {
		errors.code = 'contact.code';
	}

	if (!values.city) {
		errors.city = 'contact.city';
	}

	const mobileCheck = /^[0-9|+][0-9]+$/;
	if (!values.mobile || !mobileCheck.test(values.mobile)) {
		errors.mobile = 'contact.mobile';
	}

	if (!validateEmail(values.email)) {
		errors.email = 'contact.email';
	}

	return errors;
};

interface IContactFormProps {
	initialValues: Contact;
}

const defaultCities = ['', 'Helsinki', 'Turku', 'Espoo'];

CitiesService.setApiService(PatientApiService);

export const ContactForm = ({ initialValues }: IContactFormProps) => {
	const { t } = useTranslation();
	const [savingInProgress, setSavingInProgress] = useState(false);
	const history = useHistory();
	const [savingError, setSavingError] = useState<any>(false);
	const [savingSucess, setSavingSucess] = useState(false);
	const { userDetails, setUserDetails } = useContext(AppContext);
	const location = useLocation<any>();
	const dispatch = useDispatch();
	const citiesData = useSelector(selectCities);
	const [cities, setCities] = useState(defaultCities);
	const [edited, setEdited] = useState(false);
	const theme = useContext(ThemeContext);
	const configData = useSelector(selectConfig);

	const lang = i18next.language;

	const standalone: boolean = location?.state?.standalone;

	const disableContactFields = !!configData.data?.actOnBehalf?.ssn;

	const { data: nationalities } = useQuery(
		'nationalities',
		async () => CitiesService.getNationalities(),
		{
			placeholderData: [] as any,
			staleTime: Infinity,
			select: (nationalitiesData) => [
				{ label: '', value: '' },
				...nationalitiesData.map((nationality: Nationalities[0]) => ({
					label: nationality.name[lang],
					value: nationality.code,
				})),
			],
		},
	);

	useEffect(() => {
		if (citiesData.data && i18next.language) {
			setCities([
				{
					label: '',
					value: '',
				},
				...citiesData.data.map((city: Cities[0]) => ({
					label: city.cityName[i18next.language] || city.name,
					value: city.id,
				})),
			]);
		}
	}, [citiesData, i18next.language]);

	const saveContactInfo = async (values: FormikValues) => {
		if (!standalone) {
			AnalyticsService.sendCustomEvent(
				'onBoardingStep2',
				'on boarding',
				'send on boarding form',
				'',
			);
		}
		try {
			setSavingError(false);
			setSavingSucess(false);
			setSavingInProgress(true);

			const response = await dispatch(setContactAsync(values));
			if (response.type === 'config/setConfig/rejected') {
				setSavingError(t('common.savingError'));
				setSavingInProgress(false);
			} else {
				setSavingSucess(true);
				setUserDetails({ ...userDetails, ...values });
				await dispatch(getConfigAsync({}));
				setSavingInProgress(false);

				if (!standalone) {
					history.push(config.paths.terms);

					AnalyticsService.sendCustomEvent(
						'onBoardingStep2Auto',
						'on boarding',
						'on boarding completed',
						'',
					);
					AnalyticsService.sendCustomDimension(values.city);
				}
			}
		} catch (error) {
			setSavingError(t('common.savingError'));
			setSavingInProgress(false);
		}
	};

	const handleValidate = (values: any) => {
		if (!standalone && !edited) {
			setEdited(true);
			AnalyticsService.sendCustomEvent(
				'onBoardingStep1',
				'on boarding',
				'start on boarding form',
				'',
			);
		}
		return validate(values);
	};

	return (
		<Formik
			validate={handleValidate}
			validateOnBlur
			initialValues={initialValues}
			onSubmit={async (values) => {
				saveContactInfo(values);
			}}
		>
			{({ errors, submitCount }) => (
				<Form>
					<Field
						label={t('contact.firstName')}
						id="firstName"
						name="firstName"
						aria-describedby="firstName-error"
						required
						component={FormikTextField}
						autocomplete="given-name"
						disabled
					/>
					<Field
						label={t('contact.lastName')}
						id="lastName"
						name="lastName"
						aria-describedby="lastName-error"
						required
						component={FormikTextField}
						autocomplete="family-name"
						disabled
					/>
					<Field
						label={t('contact.nationality')}
						id="nationality"
						name="nationality"
						aria-describedby="nationality-error"
						options={nationalities}
						component={FormikSelectField}
					/>

					<Field
						label={t('contact.street')}
						id="street"
						name="street"
						aria-describedby="street-error"
						required
						component={FormikTextField}
						autocomplete="address-line1"
					/>
					<FieldsWrapper>
						<PostalCodeField>
							<Field
								label={t('contact.code')}
								id="code"
								name="code"
								aria-describedby="code-error"
								required
								component={FormikTextField}
								autocomplete="postal-code"
							/>
						</PostalCodeField>
						<div>
							<Field
								label={t('contact.city')}
								id="city"
								name="city"
								aria-describedby="city-error"
								required
								options={cities}
								component={FormikSelectField}
							/>
						</div>
					</FieldsWrapper>
					<Field
						label={t('contact.mobile')}
						id="mobile"
						name="mobile"
						aria-describedby="mobile-error"
						required
						component={FormikTextField}
						autocomplete="tel"
						disabled={disableContactFields}
					/>
					<Field
						label={t('contact.email')}
						id="email"
						name="email"
						aria-describedby="email-error"
						required
						component={FormikTextField}
						autocomplete="email"
						disabled={disableContactFields}
					/>
					{showErrors(submitCount, errors).length > 0 && (
						<Alert
							data-testid="contactform-fields-errors"
							icon="AlertOctagon"
							variant="error"
							role="alert"
						>
							{t('contact.error')}:
							<ul>
								{showErrors(submitCount, errors, true).map(
									([key, error]: any) => (
										<AlertListItem id={`${key}-error`}>
											{t(error)}
										</AlertListItem>
									),
								)}
							</ul>
						</Alert>
					)}
					{savingError && (
						<Alert aria-live="polite" icon="AlertOctagon" variant="error">
							{savingError}
						</Alert>
					)}
					{savingSucess && showErrors(submitCount, errors).length === 0 && (
						<>
							<SuccessWrapper aria-live="polite">
								<Icon name="Check" color={theme.colors.okayGreen} />
								<span>{t('common.saved')}</span>
							</SuccessWrapper>
						</>
					)}
					<br />
					<ButtonsWrapper>
						<CustomButton
							{...(savingInProgress ? { icon: 'Spinner' } : {})}
							type="submit"
							data-testid="contactform-submit-button"
						>
							{standalone ? t('common.save') : t('common.next')}
						</CustomButton>
					</ButtonsWrapper>
				</Form>
			)}
		</Formik>
	);
};

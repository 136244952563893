import React, { useContext, useEffect, useRef, useState } from 'react';
import { ThemeContext } from 'styled-components';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Text, Icon } from '@terveystalo/design-system-react-components';

import { AppContext } from 'app/context/appContext';
import { config } from 'app/config/config';
import { SelectedOrganizationContext } from 'app/context/selectedOrganizationContext';
import { selectConfig } from 'app/store/configuration/slices/configuration.slice';

import {
	MainMenuWrapper,
	TopSectionWrapper,
	MenuItemWrapper,
	MenuButton,
	MenuButtonClose,
	MainMenuIconWrapper,
	MenuText,
	MainMenuOverlay,
	BottomLinksSection,
	BottomLink,
	ExternalIconWrapper,
} from './mainMenu.styles';

export const MainMenu = () => {
	const { setMainMenuOpen, mainMenuOpen, userDetails } = useContext(AppContext);
	const { t } = useTranslation();
	const menuButtonRef = useRef<any>(null);
	const menuWrapperRef = useRef<any>(null);
	const [menuTransitionEnd, setMenuTransitionEnd] = useState(true);
	const theme = useContext(ThemeContext);
	const history = useHistory();
	const { selectedOrganization } = useContext(SelectedOrganizationContext);
	const termsAccepted = userDetails?.termsAccepted;
	const configData = useSelector(selectConfig);

	const isServiceSelectionRoute = !!useRouteMatch(
		config.paths.serviceSelection,
	);
	const isProfileWelcomeRoute = !!useRouteMatch(config.paths.profileWelcome);
	const isProfileTermsRoute = !!useRouteMatch(config.paths.profileTerms);
	const isBookAppointmentRoute = !!useRouteMatch(config.paths.bookAppointment);

	const handleCloseClick = (ev: React.MouseEvent) => {
		ev.preventDefault();
		setMainMenuOpen(false);
	};

	const redirectTo = (path: string) => (ev: React.MouseEvent) => {
		ev.preventDefault();

		if (termsAccepted && selectedOrganization) {
			history.push(path, {
				standalone: true,
			});
		}

		setMainMenuOpen(false);
	};

	const handleFocusTrap = () => {
		const handler = (ev: KeyboardEvent) => {
			const elements = menuWrapperRef.current.querySelectorAll(
				'a:not([disabled]), button:not([disabled])',
			);
			const isTabPressed = ev.key === 'Tab';
			const isEscPressed = ev.key === 'Escape';

			if (isEscPressed) {
				setMainMenuOpen(false);
				return;
			}

			if (!isTabPressed) {
				return;
			}

			if (ev.shiftKey) {
				/* shift + tab */ if (document.activeElement === elements[0]) {
					elements[elements.length - 1].focus();
					ev.preventDefault();
				}
			} else if (document.activeElement === elements[elements.length - 1]) {
				elements[0].focus();
				ev.preventDefault();
			}
		};

		menuWrapperRef.current.addEventListener('keydown', handler);
	};

	useEffect(() => {
		const listener = (ev: Event) => {
			if (mainMenuOpen && !menuWrapperRef.current.contains(ev.target)) {
				ev.stopPropagation();
				ev.preventDefault();
				setMainMenuOpen(false);
			}
		};

		if (mainMenuOpen) {
			document.addEventListener('click', listener);
		}
		return () => {
			document.removeEventListener('click', listener);
		};
		// eslint-disable-next-line
	}, [mainMenuOpen]);

	useEffect(() => {
		if (mainMenuOpen) {
			setTimeout(() => menuButtonRef.current.focus(), 100);
		} else {
			setMenuTransitionEnd(false);
		}
	}, [mainMenuOpen]);

	useEffect(() => {
		menuWrapperRef.current.addEventListener('transitionend', () => {
			setMenuTransitionEnd(true);
		});

		handleFocusTrap();
		// eslint-disable-next-line
	}, []);

	return (
		<>
			<MainMenuWrapper
				data-testid="main-menu"
				aria-label="Main menu"
				aria-hidden={mainMenuOpen ? 'false' : 'true'}
				visible={mainMenuOpen}
				transitionEnd={menuTransitionEnd && !mainMenuOpen}
				ref={menuWrapperRef}
			>
				<TopSectionWrapper>
					<MenuButtonClose
						data-testid="main-menu-close-button"
						ref={menuButtonRef}
						onClick={handleCloseClick}
						aria-expanded={mainMenuOpen}
					>
						<Icon name="Close" color={theme.colors.primary} />
						<span className="sr-only">{t('common.close')}</span>
					</MenuButtonClose>
				</TopSectionWrapper>
				{userDetails && (
					<>
						<MenuItemWrapper>
							<Text size="m" weight="bold" color={theme.colors.primary}>
								{t('common.mainMenu.section1')}
							</Text>
						</MenuItemWrapper>
						{configData?.data?.remoteReceptionEnabled && (
							<MenuItemWrapper
								active={isServiceSelectionRoute}
								aria-current={isServiceSelectionRoute ? 'page' : false}
							>
								<MenuButton
									href="#"
									onClick={redirectTo(config.paths.serviceSelection)}
									disabled={!termsAccepted || !selectedOrganization}
									data-testid="main-menu-serviceSelection-button"
								>
									<MainMenuIconWrapper>
										<Icon name="Chat" color={theme.colors.primary} />
									</MainMenuIconWrapper>
									<MenuText
										size="m"
										weight="bold"
										active={isServiceSelectionRoute}
									>
										{t('common.mainMenu.remoteAppointments')}
									</MenuText>
								</MenuButton>
							</MenuItemWrapper>
						)}
						{configData?.data?.bookingsEnabled && (
							<MenuItemWrapper
								active={isBookAppointmentRoute}
								aria-current={isBookAppointmentRoute ? 'page' : false}
							>
								<MenuButton
									href="#"
									onClick={redirectTo(config.paths.bookAppointment)}
									disabled={!termsAccepted || !selectedOrganization}
									data-testid="main-menu-bookAppointment-button"
								>
									<MainMenuIconWrapper>
										<Icon name="BookAppointment" color={theme.colors.primary} />
									</MainMenuIconWrapper>
									<MenuText
										size="m"
										weight="bold"
										active={isBookAppointmentRoute}
									>
										{t('common.mainMenu.bookAppointment')}
									</MenuText>
								</MenuButton>
							</MenuItemWrapper>
						)}

						<MenuItemWrapper>
							<Text size="m" weight="bold" color={theme.colors.primary}>
								{t('common.mainMenu.section2')}
							</Text>
						</MenuItemWrapper>

						<MenuItemWrapper
							active={isProfileWelcomeRoute}
							aria-current={isProfileWelcomeRoute ? 'page' : false}
						>
							<MenuButton
								href="#"
								onClick={redirectTo(config.paths.profileWelcome)}
								disabled={!termsAccepted || !selectedOrganization}
							>
								<MainMenuIconWrapper>
									<Icon name="Home" color={theme.colors.primary} />
								</MainMenuIconWrapper>
								<MenuText size="m" weight="bold" active={isProfileWelcomeRoute}>
									{t('common.mainMenu.howServiceWorks')}
								</MenuText>
							</MenuButton>
						</MenuItemWrapper>

						{configData?.data?.termsAndConditionsEnabled && (
							<MenuItemWrapper
								aria-current={isProfileTermsRoute ? 'page' : false}
								active={isProfileTermsRoute}
							>
								<MenuButton
									href="#"
									data-testid="main-menu-profile-terms"
									onClick={redirectTo(config.paths.profileTerms)}
									disabled={!termsAccepted || !selectedOrganization}
								>
									<MainMenuIconWrapper>
										<Icon name="Document" color={theme.colors.primary} />
									</MainMenuIconWrapper>
									<MenuText size="m" active={isProfileTermsRoute} weight="bold">
										{t('common.mainMenu.terms')}
									</MenuText>
								</MenuButton>
							</MenuItemWrapper>
						)}
					</>
				)}
				{userDetails && (
					<BottomLinksSection>
						<BottomLink
							href={config.accessibilityStatementUrl}
							target="_blank"
							rel="noreferrer"
						>
							<Text size="m" color={theme.colors.primary}>
								{t('common.mainMenu.accessibilityStatement')}
							</Text>
							<ExternalIconWrapper>
								<Icon
									name="ExternalLink"
									width={24}
									height={24}
									color={theme.colors.primary}
								/>
							</ExternalIconWrapper>
						</BottomLink>
					</BottomLinksSection>
				)}
			</MainMenuWrapper>
			{mainMenuOpen && <MainMenuOverlay />}
		</>
	);
};

import styled, { css } from 'styled-components';

export const rteStyles = css`
	font-size: ${(props) => props.theme.typography.fontSize.body.m};
	font-family: ${(props) => props.theme.typography.fontFamily.body};
	line-height: 1.5rem;

	h2 {
		font-size: ${(props) => props.theme.typography.fontSize.subtitle.m};
		line-height: 1.8125rem;
		font-weight: 700;
		letter-spacing: normal;
		margin: 0.5rem 0;
		padding: 0;
	}

	h4 {
		font-size: ${(props) => props.theme.typography.fontSize.subtitle.s};
		line-height: 1.5625rem;
		font-weight: 700;
		letter-spacing: normal;
		margin: 0.5rem 0;
		padding: 0;
	}

	p {
		margin: 0;
		padding: 0;
	}
`;

export const RteContentBase = styled.div`
	${rteStyles}
`;

import styled from 'styled-components';

import { PillButton } from '@terveystalo/design-system-react-components';

import { roundedStyles } from 'common/styles/components/wrappers';

export const ContentWrapper = styled.div`
	${roundedStyles};
	margin-top: ${(props) => props.theme.spacing['1']};
`;

export const BottomArea = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	gap: ${(props) => props.theme.spacing['1']};
`;

export const UploadWrapper = styled.div`
	display: flex;
	align-items: center;
	flex-wrap: wrap;
`;

export const SendWrapper = styled.div`
	flex-shrink: 0;
`;

export const IconButton = styled.button`
	background: none;
	border: none;
	padding: 0;
	line-height: 1em;
	cursor: pointer;
`;

export const FileButton = styled(PillButton)`
	margin-right: ${(props) => props.theme.spacing['0_5']};
	margin-bottom: ${(props) => props.theme.spacing['0_5']};
	margin-top: ${(props) => props.theme.spacing['0_5']};

	> span {
		display: flex;
		align-items: center;
	}
`;

export const SimpleMessageBoxWrapper = styled.div``;

export const SimpleAttachmentsArea = styled.button`
	border: 2px dashed ${(props) => props.theme.colors.zodiacBlack15};
	background: none;
	padding: ${(props) => props.theme.spacing['1']};
	display: flex;
	align-items: center;
	gap: 20px;
	margin-bottom: ${(props) => props.theme.spacing['1']};
	cursor: pointer;
	color: ${(props) => props.theme.colors.zodiacBlack65};
`;

export const SimpleSendWrapper = styled.div`
	display: flex;
	justify-content: flex-end;
	margin-top: ${(props) => props.theme.spacing['1']};
	> button {
		width: 100%;
		max-width: 300px;
	}
`;

export const SimpleTextareaWrapper = styled.div``;

export const CharactersLimitWrapper = styled.div`
	margin-top: -${(props) => props.theme.spacing['1']};
	margin-bottom: ${(props) => props.theme.spacing['1']};
	text-align: right;
`;

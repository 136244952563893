import React from 'react';
import { useTranslation } from 'react-i18next';

import { Text } from '@terveystalo/design-system-react-components';

import { CustomDialog } from 'app/styles/components/dialog/dialog';
import { CustomButton } from 'app/styles/components/button/button';

interface ChatInfoModalProps {
	isOpen: boolean;
	toggleModal(open: boolean): void;
}

export const ChatInfoModal = ({ isOpen, toggleModal }: ChatInfoModalProps) => {
	const { t } = useTranslation();

	const toggleChatInfoModal = () => {
		toggleModal(false);
	};

	const handleCloseAppointment = () => {
		toggleModal(false);
	};

	return (
		<CustomDialog
			className="chatInfoModal"
			open={isOpen}
			onClose={toggleChatInfoModal}
			noHeader
			title=""
			description={
				<div>
					<Text size="m">{t('chat.body')}</Text>
				</div>
			}
		>
			<CustomButton variant="secondary" onClick={handleCloseAppointment}>
				{t('common.close')}
			</CustomButton>
		</CustomDialog>
	);
};

import { Response } from 'common/typings/response';
import { CommonApiService } from '../config';

export interface FeedbackData {
	score: number;
	comment?: string;
	tags: Array<{ name: string; value: string }>;
}

export enum ScoreTypes {
	open = 'open', // open
	nps = 'nps', // numbers
	ces = 'ces', // faces
	care = 'care', // care satisfaction faces
	expertCes = 'expertCes', // expertApp faces
}

interface FeedbackResponse {}

class FeedbackService {
	apiService: CommonApiService = new CommonApiService();

	public setApiService(apiService: CommonApiService) {
		this.apiService = apiService;
	}

	public async sendFeedback(
		data: FeedbackData,
		scoreType?: ScoreTypes,
		expertUuid?: string,
	) {
		const response: Response<FeedbackResponse> = await this.apiService.post(
			'/feedback',
			{
				data,
				scoreType,
				expertUuid,
			},
		);

		return response.data;
	}
}

export default new FeedbackService();

import {
	createSlice,
	createSelector,
	createAsyncThunk,
} from '@reduxjs/toolkit';

import { RootState } from 'app/store';
import ServiceSelectionService, {
	ServiceItemData,
	Services,
} from 'app/services/serviceSelection';
import { ResponseError } from 'common/typings/response';

export interface ServicesState {
	data: Services | null;
	chatCodeData: Services['chatCodeServices'][0] | null;
	isLoading: boolean;
	isLoadingChatCode: boolean;
	error: any;
	chatCodeError: any;
}
export type ChatCodeService = Partial<ServiceItemData> & ResponseError;

const initialState: ServicesState = {
	data: null,
	chatCodeData: null,
	isLoading: false,
	isLoadingChatCode: false,
	error: null,
	chatCodeError: null,
};

export const getServicesAsync: any = createAsyncThunk(
	'services/getServices',
	async (_obj, thunkAPI) => {
		try {
			const servicesArray: Services & ResponseError =
				await ServiceSelectionService.getServices();
			return servicesArray;
		} catch (err: any) {
			return thunkAPI.rejectWithValue(err);
		}
	},
);

export const getChatCodeServiceAsync: any = createAsyncThunk<
	ChatCodeService,
	string
>('services/getChatCodeService', async (chatCode, thunkAPI) => {
	try {
		const response: ChatCodeService =
			await ServiceSelectionService.getChatCodeService(chatCode, false);
		return response;
	} catch (error: any) {
		return thunkAPI.rejectWithValue(error);
	}
});

export const servicesSlice = createSlice({
	name: 'services',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(getServicesAsync.pending, (state) => {
			state.isLoading = true;
		});

		builder.addCase(getServicesAsync.rejected, (state, action) => {
			state.error = action.payload;
			state.isLoading = false;
		});

		builder.addCase(getServicesAsync.fulfilled, (state, action) => {
			if (action.payload.status === 'error') {
				state.error = action.payload.statusCode;
			} else {
				state.data = action.payload;
			}
			state.isLoading = false;
		});

		builder.addCase(getChatCodeServiceAsync.pending, (state) => {
			state.isLoadingChatCode = true;
		});

		builder.addCase(getChatCodeServiceAsync.fulfilled, (state, action) => {
			if (action.payload.status === 'error') {
				state.chatCodeData = null;
				state.chatCodeError = action.payload.statusCode;
			} else {
				state.chatCodeError = null;
				state.chatCodeData = action.payload;
			}
			state.isLoadingChatCode = false;
		});

		builder.addCase(getChatCodeServiceAsync.rejected, (state, action) => {
			state.chatCodeError = action.payload;
			state.isLoadingChatCode = false;
		});
	},
});

export default servicesSlice.reducer;

export const selectServices = createSelector(
	(state: RootState) => state.services,
	(services) => services,
);

import React, { useContext } from 'react';

import i18next from 'i18next';
import { config } from 'app/config/config';

import { ThemeContext } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { Text, Icon } from '@terveystalo/design-system-react-components';

import {
	SurveyButton,
	SurveyIconWrapper,
	SurveyItemInfo,
	TitleWrapper,
	SurveyActionWrapper,
	SurveyActionText,
} from './surveyItem.style';

interface SurveyItemProps {
	responseId: string;
	nameJson?: string;
}

interface SurveyName {
	fi: string;
	en: string;
	sv: string;
}

enum SurveyLang {
	fi = 'fi',
	en = 'en',
	se = 'sv',
}

export const SurveyItem = ({ responseId, nameJson }: SurveyItemProps) => {
	const theme = useContext(ThemeContext);
	const { t } = useTranslation();
	const history = useHistory();

	const handleOnClick = () => {
		history.push(`${config.paths.survey}/${responseId}`);
	};

	const parseSurveyName = (): String => {
		try {
			if (!nameJson) return '';
			const nameObject: SurveyName = JSON.parse(nameJson);
			const lang: SurveyLang =
				i18next.language === 'se'
					? SurveyLang.se
					: (i18next.language as SurveyLang);
			return `${t('surveyItem.answerSurvey')}: ${nameObject[lang]}`;
		} catch (error) {
			return t('surveyItem.answerSurvey');
		}
	};

	return (
		<SurveyButton onClick={handleOnClick}>
			<SurveyIconWrapper>
				<Icon name="Survey" color={theme.colors.primary} />
			</SurveyIconWrapper>
			<SurveyItemInfo>
				<TitleWrapper>
					<Text color={theme.colors.primary} size="m">
						{parseSurveyName()}
					</Text>
				</TitleWrapper>
			</SurveyItemInfo>
			<SurveyActionWrapper>
				<SurveyActionText size="m" color={theme.colors.primary}>
					{t('surveyItem.link')}
				</SurveyActionText>
				<Icon name="ChevronRight" color={theme.colors.primary} />
			</SurveyActionWrapper>
		</SurveyButton>
	);
};

import { Response, ResponseError } from 'common/typings/response';
import { LanguagesType } from 'common/lang/lang';

import AdminApiService from 'adminApplication/services/config';

export interface OrganizationData {
	organizationId: string;
	organizatioName: string;
	urlString: string;
	paymentNumber?: string;
	contactPerson: string;
	contactPersonEmail: string;
	country: string;
	logo: {
		name: string;
		url: string;
	};
	primaryColor: string;
	organizationInfo?: string;
	secondaryColor?: string;
	background?: {
		name: string;
		url: string;
	};
	endOfService: LanguagesType;
	redirectionLinksHeadline: LanguagesType;
	redirectionLinks: Array<{
		fi: {
			name?: string;
			url: string;
		};
		en: {
			name?: string;
			url: string;
		};
		se: {
			name?: string;
			url: string;
		};
	}>;
	redirectionLinksHeadlineSecond: LanguagesType;
	redirectionLinksSecond: Array<{
		fi: {
			name?: string;
			url: string;
		};
		en: {
			name?: string;
			url: string;
		};
		se: {
			name?: string;
			url: string;
		};
	}>;
	redirectionPrimaryButtons: Array<string>;
	redirectionSelectedButtons: Array<string>;
	redirectionButtons: Array<{
		id: string;
		details: {
			fi: {
				name?: string;
				url: string;
			};
			en: {
				name?: string;
				url: string;
			};
			se: {
				name?: string;
				url: string;
			};
			[key: string]: any;
		};
	}>;
	questionnaire: Array<{
		id: string;
		isMandatory: boolean;
		content: LanguagesType;
	}>;
	bookingConfirmedText: LanguagesType;
	bookingConfirmedLink: {
		fi: { url: string; name: string };
		se: { url: string; name: string };
		en: { url: string; name: string };
	};
	serviceInfo: LanguagesType;
	chatCodesInfo: LanguagesType;
	privacyStatementUrl: {
		fi: {
			name: string;
			url: string;
		};
		en: {
			name: string;
			url: string;
		};
		se: {
			name: string;
			url: string;
		};
	};
	services: Array<{
		id: string;
		name: LanguagesType;
		description: LanguagesType;
		chatCode?: {
			isRequired?: boolean;
			codesUsed?: number;
			codesTotal?: number;
		};
		chatQueueId: string;
		mandatorySelfAssessment: boolean;
		optionalSelfAssessment: boolean;
		icon?: string;
		// imageId?: number;
	}>;
	areas: Array<{
		municipalityId: string | null;
		postalCode: string | null;
	}>;
	additionalServicesInformation: string;
	ninchatSiteKey: string;
	ninchatMasterKey: string;
	ninchatSecret: string;
	bookingsEnabled: boolean;
	actingEnabled: boolean;
	logoutEnabled: boolean;
	contactEnabled: boolean;
	yourBookingsEnabled: boolean;
	changeLocationEnabled: boolean;
	htaConnectorEnabled: boolean;
	appointmentHistoryEnabled: boolean;
	asyncMessagingEnabled: boolean;
	remoteReceptionEnabled: boolean;
	finnishEnabled: boolean;
	swedishEnabled: boolean;
	englishEnabled: boolean;
	dontShowOnListingEnabled: boolean;
	organisationType: string;
	patientDataSavingDuration: number;
	adminDataSavingDuration: number;
	expertDataSavingDuration: number;
	termsAndConditionsEnabled: boolean;
	surveys: Array<{
		id: string;
		name?: LanguagesType;
	}>;
	asyncMessagingFrontPage: LanguagesType;
	asyncMessagingNewMessageBanner: LanguagesType;
	[key: string]: any;
}

type OrganizationDataToServer = Omit<
	OrganizationData,
	'endOfService' | 'serviceInfo' | 'redirectionLinks' | 'services' | 'logo'
> & {
	endOfService: string;
	serviceInfo: string;
	redirectionLinks: string;
	services: string;
	logo: File;
	background?: File;
};
export interface OrganizationDataListItem {
	organizationName: string;
	organizationId: string;
}

export type OrganizationsDataList = Array<OrganizationDataListItem>;

export interface EmailTemplateDto {
	templates: EmailTemplateData[];
}

export interface EmailTemplateData {
	metadata?: EmailTemplateMetadata;
	documentName: string;
	isDefault?: boolean;
	lang: string;
	content: EmailTemplate;
}

export interface EmailTemplateLanguages {
	[key: string]: EmailTemplateData;
}

export interface EmailTemplate {
	subject: string;
	from: { name: string; email: string };
	body: string;
}

export interface IEmailTag {
	tag: string;
	description: string;
}
export interface EmailTemplateMetadata {
	tags?: IEmailTag[];
	templateName?: string;
	templateDescription?: string;
}

class OrganizationService {
	public prepareOrganizationDataToServer(
		values: Partial<OrganizationData>,
	): Partial<OrganizationDataToServer> {
		const newValues: Partial<OrganizationDataToServer> = {};

		if (values.organizationName !== undefined) {
			newValues.organizationName = values.organizationName;
		}
		if (values.paymentNumber !== undefined) {
			newValues.paymentNumber = values.paymentNumber;
		}
		if (values.contactPerson !== undefined) {
			newValues.contactPerson = values.contactPerson;
		}
		if (values.contactPersonEmail !== undefined) {
			newValues.contactPersonEmail = values.contactPersonEmail;
		}

		if (values.country !== undefined) {
			newValues.country = values.country;
		}

		if (values.organizationInfo !== undefined) {
			newValues.organizationInfo = values.organizationInfo;
		}

		if (values.primaryColor !== undefined) {
			newValues.primaryColor = values.primaryColor;
		}
		// send only if file was uploaded by user and has File type
		if (values.logo instanceof File) {
			newValues.logo = values.logo;
		}

		// send only if file was uploaded by user and has File type
		if (values.logoEn instanceof File) {
			newValues.logoEn = values.logoEn;
		}

		// send only if file was uploaded by user and has File type
		if (values.logoSe instanceof File) {
			newValues.logoSe = values.logoSe;
		}

		if (values.secondaryColor !== undefined) {
			newValues.secondaryColor = values.secondaryColor;
		}

		// send only if file was uploaded by user and has File type or its null
		if (values.background instanceof File || values.background === null) {
			newValues.background = values.background;
		}

		if (values.endOfService !== undefined) {
			newValues.endOfService = JSON.stringify(values.endOfService);
		}

		if (values.serviceInfo !== undefined) {
			newValues.serviceInfo = JSON.stringify(values.serviceInfo);
		}

		if (values.chatCodesInfo !== undefined) {
			newValues.chatCodesInfo = JSON.stringify(values.chatCodesInfo);
		}

		if (values.asyncMessagingFrontPage !== undefined) {
			newValues.asyncMessagingFrontPage = JSON.stringify(
				values.asyncMessagingFrontPage,
			);
		}

		if (values.asyncMessagingNewMessageBanner !== undefined) {
			newValues.asyncMessagingNewMessageBanner = JSON.stringify(
				values.asyncMessagingNewMessageBanner,
			);
		}

		// always send as an array, event empty array
		if (values.questionnaire !== undefined) {
			newValues.questionnaire = JSON.stringify(values.questionnaire);
		}

		// always send as an array, event empty array
		if (values.redirectionLinks !== undefined) {
			newValues.redirectionLinks = JSON.stringify(values.redirectionLinks);
		}

		if (values.redirectionLinksHeadline !== undefined) {
			newValues.redirectionLinksHeadline = JSON.stringify(
				values.redirectionLinksHeadline,
			);
		}

		// always send as an array, event empty array
		if (values.bookingConfirmedText !== undefined) {
			newValues.bookingConfirmedText = JSON.stringify(
				values.bookingConfirmedText,
			);
		}

		if (values.bookingConfirmedLink !== undefined) {
			newValues.bookingConfirmedLink = JSON.stringify(
				values.bookingConfirmedLink,
			);
		}

		// always send as an array, event empty array
		if (values.redirectionLinksSecond !== undefined) {
			newValues.redirectionLinksSecond = JSON.stringify(
				values.redirectionLinksSecond,
			);
		}

		if (values.redirectionLinksHeadlineSecond !== undefined) {
			newValues.redirectionLinksHeadlineSecond = JSON.stringify(
				values.redirectionLinksHeadlineSecond,
			);
		}

		if (values.redirectionPrimaryButtons !== undefined) {
			newValues.redirectionPrimaryButtons = JSON.stringify(
				values.redirectionPrimaryButtons,
			);
		}

		if (values.redirectionSelectedButtons !== undefined) {
			newValues.redirectionSelectedButtons = JSON.stringify(
				values.redirectionSelectedButtons,
			);
		}

		if (values.redirectionButtons !== undefined) {
			newValues.redirectionButtons = JSON.stringify(values.redirectionButtons);
		}

		if (values.privacyStatementUrlFi !== undefined) {
			newValues.privacyStatementUrl = JSON.stringify({
				fi: {
					url: values.privacyStatementUrlFi,
					name: values.privacyStatementUrlTextFi,
				},
				en: {
					url: values.privacyStatementUrlEn,
					name: values.privacyStatementUrlTextEn,
				},
				se: {
					url: values.privacyStatementUrlSe,
					name: values.privacyStatementUrlTextSe,
				},
			});
		}

		if (values.additionalServicesInformation !== undefined) {
			newValues.additionalServicesInformation =
				values.additionalServicesInformation;
		}

		if (values.bookingsEnabled !== undefined) {
			newValues.bookingsEnabled = values.bookingsEnabled;
		}

		if (values.actingEnabled !== undefined) {
			newValues.actingEnabled = values.actingEnabled;
		}

		if (values.logoutEnabled !== undefined) {
			newValues.logoutEnabled = values.logoutEnabled;
		}

		if (values.contactEnabled !== undefined) {
			newValues.contactEnabled = values.contactEnabled;
		}

		if (values.yourBookingsEnabled !== undefined) {
			newValues.yourBookingsEnabled = values.yourBookingsEnabled;
		}

		if (values.changeLocationEnabled !== undefined) {
			newValues.changeLocationEnabled = values.changeLocationEnabled;
		}

		if (values.htaConnectorEnabled !== undefined) {
			newValues.htaConnectorEnabled = values.htaConnectorEnabled;
		}

		if (values.appointmentHistoryEnabled !== undefined) {
			newValues.appointmentHistoryEnabled = values.appointmentHistoryEnabled;
		}

		if (values.asyncMessagingEnabled !== undefined) {
			newValues.asyncMessagingEnabled = values.asyncMessagingEnabled;
		}

		if (values.remoteReceptionEnabled !== undefined) {
			newValues.remoteReceptionEnabled = values.remoteReceptionEnabled;
		}

		if (values.termsAndConditionsEnabled !== undefined) {
			newValues.termsAndConditionsEnabled = values.termsAndConditionsEnabled;
		}

		if (values.finnishEnabled !== undefined) {
			newValues.finnishEnabled = values.finnishEnabled;
		}

		if (values.swedishEnabled !== undefined) {
			newValues.swedishEnabled = values.swedishEnabled;
		}

		if (values.englishEnabled !== undefined) {
			newValues.englishEnabled = values.englishEnabled;
		}

		// always send as an array, event empty array, removing chatCode details
		if (values.services !== undefined) {
			const servicesToSend = values.services.map((service) => ({
				...service,
				chatCodeDetails: undefined,
			}));
			newValues.services = JSON.stringify(servicesToSend);
		}

		if (values.areas !== undefined) {
			newValues.areas = JSON.stringify(values.areas);
		}

		if (values.ninchatSiteKey !== undefined) {
			newValues.ninchatSiteKey = values.ninchatSiteKey;
		}

		if (values.ninchatMasterKey !== undefined) {
			newValues.ninchatMasterKey = values.ninchatMasterKey;
		}

		if (values.ninchatSecret !== undefined) {
			newValues.ninchatSecret = values.ninchatSecret;
		}

		if (values.organisationType !== undefined) {
			newValues.organisationType = values.organisationType;
		}

		if (typeof values.expertDataSavingDuration === 'number') {
			newValues.expertDataSavingDuration = Math.round(
				values.expertDataSavingDuration * 30.437,
			);
		}

		if (typeof values.adminDataSavingDuration === 'number') {
			newValues.adminDataSavingDuration = Math.round(
				values.adminDataSavingDuration * 30.437,
			);
		}

		if (typeof values.patientDataSavingDuration === 'number') {
			newValues.patientDataSavingDuration = values.patientDataSavingDuration;
		}

		if (values.dontShowOnListingEnabled !== undefined) {
			newValues.dontShowOnListingEnabled = values.dontShowOnListingEnabled;
		}

		if (values.attachedSurveys !== undefined) {
			newValues.surveys = JSON.stringify(
				values.attachedSurveys.map((attached: any) => ({
					id: attached.value,
				})),
			);
		}

		return newValues;
	}

	public async getOrganization(
		organizationId: string,
	): Promise<OrganizationData & ResponseError> {
		const response: Response<OrganizationData> = await AdminApiService.get(
			`/admin/organisation/get/${organizationId}`,
		);

		return {
			...response.data,
		};
	}

	public async getOrganizations(): Promise<
		OrganizationsDataList & ResponseError
	> {
		const response: Response<OrganizationsDataList> = await AdminApiService.get(
			`/admin/organisation/list`,
		);
		return response.data;
	}

	public async createOrganization(values: Partial<OrganizationData>) {
		const newValues = this.prepareOrganizationDataToServer(values);
		const formData = new FormData();

		Object.keys(newValues).forEach((key) => {
			formData.append(key, newValues[key]);
		});

		const response: Response<{ message: string }> = await AdminApiService.post(
			`/admin/organisation/create`,
			formData,
			{},
			{
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			},
		);
		return response.data;
	}

	public async updateOrganization(values: Partial<OrganizationData>) {
		if (process.env.REACT_APP_USE_MOCK) {
			const mockAPI = () =>
				new Promise((resolve) =>
					setTimeout(() => {
						resolve(true);
					}, 1000),
				);
			const responseData: any = await mockAPI();
			return responseData;
		}
		const newValues = this.prepareOrganizationDataToServer(values);

		const formData = new FormData();

		Object.keys(newValues).forEach((key) => {
			formData.append(key, newValues[key]);
		});

		const response: Response<{ message: string }> = await AdminApiService.post(
			`/admin/organisation/update`,
			formData,
			{},
			{
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			},
		);

		return response.data;
	}

	public async getEmailTemplates(): Promise<EmailTemplateDto & ResponseError> {
		const response: Response<EmailTemplateDto> = await AdminApiService.get(
			`/admin/organisation/email-templates`,
		);

		return {
			...response.data,
		};
	}

	public async saveEmailTemplates(
		template: EmailTemplateDto,
	): Promise<EmailTemplateDto | ResponseError> {
		const response: Response<EmailTemplateDto> = await AdminApiService.post(
			`/admin/organisation/email-templates`,
			template,
		);

		return {
			...response.data,
		};
	}

	public async deleteEmailTemplate(
		templateName: string,
		languageCode: string,
	): Promise<EmailTemplateDto | ResponseError> {
		const response: Response<EmailTemplateDto> = await AdminApiService.delete(
			`/admin/organisation/email-templates/${templateName}`,
			{ language: languageCode },
		);
		return {
			...response.data,
		};
	}
}

export default new OrganizationService();

import { Response } from 'common/typings/response';
import { getTimezone } from 'common/helpers/dates';

import PatientApiService from 'app/services/config';

export interface ClientParams {
	lang?: string;
	timezone?: string;
}

class UserService {
	public async setClientParams(params: ClientParams) {
		const response: Response<{ message: string }> =
			await PatientApiService.post(`/client_params`, {
				...params,
				timezone: getTimezone(),
			});

		return response.data;
	}
}

export default new UserService();

import {
	createSlice,
	createSelector,
	createAsyncThunk,
} from '@reduxjs/toolkit';

import { RootState } from 'app/store';
import ConfigService from 'app/services/config/index';
import { Config } from 'common/typings/organisationConfig';
import { ResponseError } from 'common/typings/response';

export interface ConfigState {
	data: Config | null;
	isLoading: boolean;
	loadingError: any;
}

const initialState: ConfigState = {
	data: null,
	isLoading: false,
	loadingError: null,
};

export interface ConfigParameters {
	companyId?: string;
}

export const getConfigAsync: any = createAsyncThunk<any, ConfigParameters>(
	'config/getConfig',
	async (props, thunkAPI) => {
		try {
			const configData: Config & ResponseError = await ConfigService.getConfig(
				props,
			);
			return configData;
		} catch (error) {
			thunkAPI.dispatch(getConfigFailure());
			return thunkAPI.rejectWithValue(error);
		}
	},
);

export const configSlice = createSlice({
	name: 'config',
	initialState,
	reducers: {
		getConfigFailure: () => {
			// Redux Toolkit allows us to write "mutating" logic in reducers. It
			// doesn't actually mutate the state because it uses the Immer library,
			// which detects changes to a "draft state" and produces a brand new
			// immutable state based off those changes
		},
	},
	extraReducers: (builder) => {
		builder.addCase(getConfigAsync.pending, (state) => {
			state.isLoading = true;
		});

		builder.addCase(getConfigAsync.rejected, (state, action) => {
			state.loadingError = action.payload;
			state.isLoading = false;
		});

		builder.addCase(getConfigAsync.fulfilled, (state, action) => {
			if (action.payload.status === 'error') {
				state.loadingError = action.payload.statusCode;
			} else {
				state.data = { ...action.payload };
			}
			state.isLoading = false;
		});
	},
});

// Action creators are generated for each case reducer function
export const { getConfigFailure } = configSlice.actions;

export default configSlice.reducer;

export const selectConfig = createSelector(
	(state: RootState) => state.config,
	(config) => config,
);

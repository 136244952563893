import styled from 'styled-components';

import { Text } from '@terveystalo/design-system-react-components';

export const BackContainer = styled.div`
	padding: ${(props) => props.theme.spacing['1']} 0;
`;

export const BackButton = styled.a`
	align-items: center;
	text-decoration: none;
	display: flex;
`;

export const BackButtonText = styled(Text)`
	margin-left: ${(props) => props.theme.spacing['0_25']};
`;

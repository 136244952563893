export const languages = {
	en: 'en',
	fi: 'fi',
	se: 'se',
};

export enum Language {
	finnish = 'fi',
	swedish = 'se',
	english = 'en',
}

export interface LanguagesType {
	en: string;
	fi: string;
	se: string;
	[key: string]: string;
}

import React, { useContext, useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from 'styled-components';
import { Formik, Form, Field, FormikErrors } from 'formik';
import { useMutation, useQuery } from 'react-query';
import i18next from 'i18next';
import { useSelector } from 'react-redux';
import { Prompt, useHistory } from 'react-router-dom';

import {
	Alert,
	Icon,
	Lead,
	Subtitle,
	Text,
	Title,
} from '@terveystalo/design-system-react-components';

import {
	addDaysToDate,
	selectDateFormat,
	substractDaysFromDate,
} from 'common/helpers/dates';
import { FormikDatePicker } from 'common/components/forms/date/FormikDatePicker';
import SpecializationsService, {
	Specializations,
} from 'common/services/specializations/specializations';
import { FormikAutocompleteComponent } from 'common/components/forms/autocomplete/FormikAutocomplete';
import { ResponseError } from 'common/typings/response';
import { FormikTextField } from 'common/components/forms/textField/FormikTextField';
import { showErrors } from 'common/helpers/validation';
import {
	BackButton,
	BackButtonText,
	BackContainer,
} from 'common/styles/components/back/back';
import { Language } from 'common/lang/lang';

import AnalyticsService from 'common/services/analytics/analytics';
import { config } from 'app/config/config';
import ServiceSelectionService from 'app/services/serviceSelection';
import OpenAppointmentsService, {
	OpenAppointmentBook,
	OpenAppointmentItemData,
} from 'app/services/openAppointments/openAppointments';
import { selectConfig } from 'app/store/configuration/slices/configuration.slice';
import { Config } from 'common/typings/organisationConfig';

import { CustomButton } from 'app/styles/components/button/button';
import { BookingConfirmationModal } from 'app/components/bookingConfirmationModal/bookingConfirmationModal';
import PatientApiService from 'app/services/config';

import { ContentSection, HomeHeader } from '../home.styles';

import {
	BookAppointmentWrapper,
	SpecializationWrapper,
	ButtonsWrapper,
	LoaderWrapper,
	OpenAppointmentItemDecorated,
	OpenAppointmentItemSimple,
	PrevNextButtonsWrapper,
	PrevButton,
	NextButton,
	CounterWrapper,
	TimeWrapper,
	SearchResultsSection,
} from './bookAppointment.styles';

interface FormValues {
	date: Date | null;
	specialization: string | null;
	timestamp: number | null;
}

const initialValues: FormValues = {
	date: null,
	specialization: null,
	timestamp: null,
};

enum BookingStates {
	search = 1,
	confirmation = 2,
	success = 3,
	failed = 4,
}

interface QuestionnaireValues {
	[key: string]: string;
}

SpecializationsService.setApiService(PatientApiService);

export const BookAppointmentPage = () => {
	const { t } = useTranslation();
	const theme = useContext(ThemeContext);
	const lang = i18next.language;
	const history = useHistory();

	const [openAppointmentsFetched, setOpenAppointmentsFetched] = useState(false);
	const configData = useSelector(selectConfig);

	const [appointmentLockTime, setAppointmentLockTime] = useState(0);
	const [lockingAppointmentInProgress, setLockingAppointmentInProgress] =
		useState<null | string>(null);

	const [bookingConfirmationModalOpen, setBookingConfirmationModalOpen] =
		useState(false);
	const [leaveUrl, setLeaveUrl] = useState('');
	const [goToLastUrlWithoutConfirmation, setGoToLastUrlWithoutConfirmation] =
		useState(false);

	const [timeLeft, setTimeLeft] = useState<number>(0);
	const interval = useRef<any>(null);

	const [bookingState, setBookingsState] = useState(BookingStates.search);
	const [selectedOpenAppointment, setSelectedOpenAppointment] =
		useState<OpenAppointmentItemData | null>(null);
	const [currentFormValues, setCurrentFormValues] = useState<FormValues>({
		...initialValues,
	});

	const {
		data: specializations,
		refetch: fetchSpecializations,
		isFetching: loadingSpecializations,
	} = useQuery(
		'specializations',
		async () => SpecializationsService.getSpecializations(true),
		{
			enabled: false,
			initialData: [] as any,
			select: (specializationsData: Specializations & ResponseError) => {
				if (specializationsData.status !== 'error') {
					return specializationsData.map(
						(specialization: Specializations[0]) => ({
							label: specialization.name[lang as any],
							value: specialization.id,
							availableAt: specialization.availableAt,
						}),
					);
				}

				return [];
			},
		},
	);

	const {
		data: openAppointments,
		refetch: fetchOpenAppointments,
		isFetching: loadingOpenAppointments,
	} = useQuery(
		['openAppointments', currentFormValues],
		async () => {
			const timeNow = new Date();
			let startTime = currentFormValues?.date || timeNow;

			// don't search for past appointments
			if (startTime.getTime() < timeNow.getTime()) {
				startTime = timeNow;
			}
			// in future days search appointments from 00:00:00 ->
			else {
				startTime.setHours(0, 0, 0, 0);
			}

			return OpenAppointmentsService.getOpenAppointments({
				date: startTime.getTime(),
				specialization: currentFormValues.specialization || '',
			});
		},
		{
			onSuccess: () => {
				setOpenAppointmentsFetched(true);
			},
		},
	);

	const { isLoading: savingQuestionnaire, mutate: saveQuestionnaire } =
		useMutation(
			(values: OpenAppointmentBook) =>
				OpenAppointmentsService.bookOpenAppointment(values),
			{
				onError: () => {
					setBookingsState(BookingStates.failed);
				},
				onSuccess: (responseData) => {
					if (responseData.status === 'error') {
						setBookingsState(BookingStates.failed);
					} else {
						setBookingsState(BookingStates.success);
					}
				},
			},
		);

	const { /* isLoading: lockingAppointment , */ mutate: lockAppointment } =
		useMutation(
			({
				openAppointment,
				lock,
			}: {
				openAppointment: OpenAppointmentItemData;
				lock: boolean;
			}) => {
				setLockingAppointmentInProgress(openAppointment.uuid);
				return ServiceSelectionService.lockAppointment(
					openAppointment.uuid,
					lock,
				);
			},
			{
				onError: (responseData, { lock }) => {
					setLockingAppointmentInProgress(null);

					if (lock) {
						showTimeslotNotAvailable();
					}
				},
				onSuccess: (responseData, { openAppointment, lock }) => {
					setLockingAppointmentInProgress(null);

					// eslint-disable-next-line no-empty
					if (responseData.status === 'error') {
						if (lock) {
							showTimeslotNotAvailable();
						}
					} else if (lock) {
						setAppointmentLockTime(responseData.appointmentLockTime);
						setBookingsState(BookingStates.confirmation);
						setSelectedOpenAppointment(openAppointment);
					}
				},
			},
		);

	useEffect(() => {
		document.title = t('pageTitles.bookAppointment');
	}, [lang]);

	useEffect(() => {
		document.title = t('pageTitles.bookAppointment');
		AnalyticsService.sendPageView(t('pageTitles.bookAppointment'));

		fetchSpecializations();
	}, []);

	useEffect(() => {
		setTimeLeft(appointmentLockTime * 60 * 1000);
		if (appointmentLockTime && bookingState === BookingStates.confirmation) {
			interval.current = setInterval(() => {
				setTimeLeft((time) => Math.max(time - 1000, 0));
			}, 1000);
		}
		return () => {
			clearInterval(interval.current);
			setTimeLeft(appointmentLockTime * 60 * 1000);
		};
	}, [appointmentLockTime, bookingState]);

	useEffect(() => {
		if (currentFormValues.date && currentFormValues.specialization) {
			fetchOpenAppointments();
		}
		// eslint-disable-next-line
	}, [currentFormValues]);

	useEffect(() => {
		if (goToLastUrlWithoutConfirmation) {
			history.push(leaveUrl);
		}
		// eslint-disable-next-line
	}, [goToLastUrlWithoutConfirmation]);

	useEffect(() => {
		if (specializations && specializations.length > 0) {
			const urlSearchParams = new URLSearchParams(window.location.search);
			const params = Object.fromEntries(urlSearchParams.entries());

			if (specializations.length === 1) {
				setCurrentFormValues({
					...currentFormValues,
					specialization: specializations[0].value,
					...(specializations[0].availableAt && {
						date: new Date(specializations[0].availableAt),
					}),
					timestamp: new Date().getTime(),
				});
			} else if (params.specialization) {
				const specExists = specializations.find(
					(spec: { label: string; value: string }) =>
						spec.value === params.specialization,
				);

				if (specExists) {
					setCurrentFormValues({
						...currentFormValues,
						specialization: params.specialization,
						...(specExists.availableAt && {
							date: new Date(specExists.availableAt),
						}),
						timestamp: new Date().getTime(),
					});
				}
			}
		}
	}, [specializations]);

	const validate = (values: QuestionnaireValues) => {
		const errors: FormikErrors<QuestionnaireValues> = {};

		if (selectedOpenAppointment?.preQuestionnaire) {
			const mandatoryIds = configData.data.questionnaire
				.filter((q: Config['questionnaire'][0]) => q.isMandatory)
				.map((q: Config['questionnaire'][0]) => q.id);

			mandatoryIds.forEach((id: string) => {
				if (!values[id]) {
					errors[id] = 'bookAppointments.error';
				}
			});
		}

		return errors;
	};

	const handleSubmit = (values: FormValues) => {
		let expertSpecialisation;
		specializations?.map((item) => {
			if (item.value === values.specialization) {
				expertSpecialisation = item.label;
			}
			return item.label;
		});

		AnalyticsService.sendCustomEvent(
			'SearchAppointmentTime',
			'remote appointment',
			'search appointment time',
			'',
			{
				searchDate: new Date().toISOString(),
				appointmentDate: !values.date
					? new Date(values.date ? values.date : '').toISOString()
					: '',
				specialization: expertSpecialisation,
			},
		);
		setCurrentFormValues({ ...values, timestamp: new Date().getTime() });
	};

	const handleClickOpenAppointment =
		(openAppointment: OpenAppointmentItemData) => () => {
			lockAppointment({ openAppointment, lock: true });
		};

	const handleSearchDayBefore = () => {
		const newDate = substractDaysFromDate(
			currentFormValues.date?.getTime() as number,
			1,
		);
		setCurrentFormValues({ ...currentFormValues, date: newDate });
	};

	const handleSearchNextDay = () => {
		const newDate = addDaysToDate(
			currentFormValues.date?.getTime() as number,
			1,
		);

		setCurrentFormValues({ ...currentFormValues, date: newDate });
	};

	const handleGoBackWithModalConfirmation = (ev: React.MouseEvent) => {
		ev.preventDefault();
		setBookingConfirmationModalOpen(true);
		setLeaveUrl('GO_BACK');
	};

	const handleGoBackDirectly = (ev: React.MouseEvent) => {
		ev.preventDefault();
		setBookingsState(BookingStates.search);
	};

	const handleGoBackWithSearchClear = (ev: React.MouseEvent) => {
		ev.preventDefault();
		setBookingsState(BookingStates.search);
		setCurrentFormValues(initialValues);
	};

	const handleGoToLandingPage = (ev: React.MouseEvent) => {
		ev.preventDefault();
		history.push(config.paths.landing);
	};

	const handleLeaveConfirmation = (location: any /* action: any */) => {
		if (goToLastUrlWithoutConfirmation) {
			return true;
		}

		setBookingConfirmationModalOpen(true);
		setLeaveUrl(location.pathname);
		return false;
	};

	const handleConfirmBooking = (values: QuestionnaireValues) => {
		const dataForSending: OpenAppointmentBook = {
			id: selectedOpenAppointment?.uuid as string,
			isOpen: false,
			questionnaireAnswers: [],
		};

		Object.entries(configData.data?.questionnaire).forEach(
			([, /* _key */ question]: [string, any]) => {
				if (values[question.id]) {
					dataForSending.questionnaireAnswers?.push({
						id: question.id,
						question: question.content,
						answer: values[question.id],
					});
				}
			},
		);
		AnalyticsService.sendCustomEvent(
			'remoteAppointmentBooking',
			'remote appointment',
			'book an appointment',
			'',
		);
		saveQuestionnaire(dataForSending);
	};

	const toggleBookingConfirmationModal = () => {
		setBookingConfirmationModalOpen(!bookingConfirmationModalOpen);
	};

	const handleBookingConfirmationModalSuccess = () => {
		if (selectedOpenAppointment) {
			lockAppointment({
				openAppointment: selectedOpenAppointment,
				lock: false,
			});
		}
		if (leaveUrl === 'GO_BACK') {
			setBookingsState(BookingStates.search);
		} else {
			setGoToLastUrlWithoutConfirmation(true);
		}
	};

	const formatTime = (milisecondsLeft: number) => {
		const seconds = milisecondsLeft / 1000;
		let minutesLeft: any = Math.floor(seconds / 60);
		let secondsLeft: any = seconds - minutesLeft * 60;

		if (minutesLeft < 10) {
			minutesLeft = `0${minutesLeft}`;
		}

		if (secondsLeft < 10) {
			secondsLeft = `0${secondsLeft}`;
		}

		return `${minutesLeft}:${secondsLeft}`;
	};

	const showTimeslotNotAvailable = () => {
		alert(t('bookAppointments.timeslotNotAvailable'));
	};

	return (
		<BookAppointmentWrapper>
			{bookingState === BookingStates.search && (
				<div>
					<BackContainer>
						<BackButton
							aria-label="Back button"
							href=""
							onClick={handleGoToLandingPage}
						>
							<Icon name="ArrowLeft" color={theme.colors.primary} />
							<BackButtonText size="m" color={theme.colors.primary}>
								{t('common.backToHomepage')}
							</BackButtonText>
						</BackButton>
					</BackContainer>
					<Subtitle size="m" color={theme.colors.zodiacBlack} as="h3">
						{t('bookAppointments.title')}
					</Subtitle>
					<ContentSection smallBottom>
						<Formik
							initialValues={currentFormValues}
							onSubmit={handleSubmit}
							enableReinitialize
						>
							{({ values }: { values: FormValues }) => (
								<Form>
									<Field
										label={t('bookAppointments.form.date')}
										id="startingDate"
										name="date"
										required
										icon="Calendar"
										locale={lang}
										dateFormat={selectDateFormat(lang as Language)}
										minDate={new Date()}
										component={FormikDatePicker}
									/>
									<SpecializationWrapper>
										<Field
											loading={loadingSpecializations}
											label={t('bookAppointemnts.form.specialization')}
											id="specialization"
											name="specialization"
											items={specializations}
											component={FormikAutocompleteComponent}
										/>
									</SpecializationWrapper>
									<ButtonsWrapper>
										<CustomButton
											disabled={!values.date || !values.specialization}
											type="submit"
											data-testid="bookAppointment-searchTimes-button"
										>
											{t('bookAppointments.button.search')}
										</CustomButton>
									</ButtonsWrapper>
								</Form>
							)}
						</Formik>
					</ContentSection>
					<br />
					<SearchResultsSection isFetched={openAppointmentsFetched}>
						{openAppointments !== null && (
							<Subtitle color={theme.colors.primary} size="s">
								{t('bookAppointments.availableAppointments.title')}
							</Subtitle>
						)}
						<br />
						{loadingOpenAppointments && (
							<LoaderWrapper aria-hidden="true">
								<Icon name="Spinner" color={theme.colors.primary} />
							</LoaderWrapper>
						)}
						{openAppointments?.map(
							(openAppointment: OpenAppointmentItemData) => (
								<OpenAppointmentItemDecorated
									isLoading={
										lockingAppointmentInProgress === openAppointment.uuid
									}
									isClickable
									handleClick={handleClickOpenAppointment(openAppointment)}
									item={openAppointment}
								/>
							),
						)}
						{openAppointments?.length === 0 && (
							<div>
								<br />
								<Text size="m">{t('bookAppointments.noAppointments')}</Text>
								<br />
								<br />
							</div>
						)}
						{openAppointmentsFetched && (
							<PrevNextButtonsWrapper>
								<PrevButton onClick={handleSearchDayBefore}>
									<Icon name="ChevronLeft" color={theme.colors.primary} />
									{t('bookAppointments.button.prev')}
								</PrevButton>
								<NextButton onClick={handleSearchNextDay}>
									{t('bookAppointments.button.next')}
									<Icon name="ChevronRight" color={theme.colors.primary} />
								</NextButton>
							</PrevNextButtonsWrapper>
						)}
					</SearchResultsSection>
				</div>
			)}
			{bookingState === BookingStates.confirmation && (
				<div>
					<Prompt when message={handleLeaveConfirmation} />
					<BookingConfirmationModal
						toggleModal={toggleBookingConfirmationModal}
						isOpen={bookingConfirmationModalOpen}
						success={handleBookingConfirmationModalSuccess}
					/>
					<BackContainer>
						<BackButton
							aria-label="Back button"
							href=""
							onClick={handleGoBackWithModalConfirmation}
						>
							<Icon name="ArrowLeft" color={theme.colors.primary} />
							<BackButtonText size="m" color={theme.colors.primary}>
								{t('bookAppointments.back')}
							</BackButtonText>
						</BackButton>
					</BackContainer>
					<Subtitle color={theme.colors.primary} size="s">
						{t('bookAppointments.youAreBooking.title')}:
					</Subtitle>
					{selectedOpenAppointment && (
						<OpenAppointmentItemDecorated item={selectedOpenAppointment} />
					)}
					<CounterWrapper>
						{t('bookAppointment.timeInfo.part1')}{' '}
						<strong>{t('bookAppointment.timeInfo.part2')}</strong>{' '}
						{t('bookAppointment.timeInfo.part3')}
						<TimeWrapper>
							<Subtitle size="mplus" color={theme.colors.primary}>
								{formatTime(timeLeft)}
							</Subtitle>
						</TimeWrapper>
					</CounterWrapper>
					<div>
						{selectedOpenAppointment?.preQuestionnaire &&
							configData.data?.questionnaire.length > 0 && (
								<div>
									<Subtitle color={theme.colors.primary} size="s">
										{t('bookAppointments.preQuestionnaire.title')}
									</Subtitle>
									<br />
									<br />
								</div>
							)}
						<div>
							<Formik
								validate={validate}
								initialValues={{}}
								validateOnChange
								validateOnBlur
								onSubmit={handleConfirmBooking}
							>
								{({ errors, submitCount }) => (
									<Form>
										{selectedOpenAppointment?.preQuestionnaire &&
											configData.data?.questionnaire.map(
												(questionnaire: Config['questionnaire'][0]) => (
													<div>
														<Field
															label={questionnaire.content[lang]}
															id={questionnaire.id}
															name={questionnaire.id}
															required={questionnaire.isMandatory}
															component={FormikTextField}
														/>
													</div>
												),
											)}
										{showErrors(submitCount, errors).length > 0 && (
											<Alert icon="AlertOctagon" variant="error">
												{showErrors(submitCount, errors).map((error: any) => (
													<div>{t(error)}</div>
												))}
											</Alert>
										)}
										<div>
											{/* {savingError && (
													<Alert icon="AlertOctagon" variant="error">
														{savingError}
													</Alert>
												)} */}
										</div>
										<br />
										<ButtonsWrapper>
											<CustomButton
												type="submit"
												disabled={savingQuestionnaire}
												{...(savingQuestionnaire ? { icon: 'Spinner' } : {})}
											>
												{t('bookAppointments.button.confirmBooking')}
											</CustomButton>
										</ButtonsWrapper>
									</Form>
								)}
							</Formik>
						</div>
					</div>
				</div>
			)}
			{bookingState === BookingStates.success && (
				<div>
					<BackContainer>
						<BackButton
							aria-label="Back button"
							href="#"
							onClick={handleGoBackWithSearchClear}
						>
							<Icon name="ArrowLeft" color={theme.colors.primary} />
							<BackButtonText size="m" color={theme.colors.primary}>
								{t('bookAppointments.back')}
							</BackButtonText>
						</BackButton>
					</BackContainer>
					<ContentSection>
						<Title size="l" color={theme.colors.primary} as="h3">
							{t('bookAppointment.confirmed.title')}
						</Title>
						{!!configData.data?.bookingConfirmedText[lang] && (
							<>
								<Lead size="s">
									{configData.data?.bookingConfirmedText[lang]}
								</Lead>
								<br />
							</>
						)}
						{selectedOpenAppointment && (
							<OpenAppointmentItemSimple item={selectedOpenAppointment} />
						)}
						<ButtonsWrapper>
							{!!configData.data?.bookingConfirmedLink[lang].text && (
								<CustomButton
									role="link"
									onClick={() =>
										window.open(configData.data?.bookingConfirmedLink[lang].url)
									}
								>
									{configData.data?.bookingConfirmedLink[lang].text}
								</CustomButton>
							)}
						</ButtonsWrapper>
					</ContentSection>
				</div>
			)}
			{bookingState === BookingStates.failed && (
				<div>
					<HomeHeader>
						<BackContainer>
							<BackButton
								aria-label="Back button"
								href="#"
								onClick={handleGoBackDirectly}
							>
								<Icon name="ArrowLeft" color={theme.colors.primary} />
								<BackButtonText size="m" color={theme.colors.primary}>
									{t('bookAppointments.back')}
								</BackButtonText>
							</BackButton>
						</BackContainer>
					</HomeHeader>
					<ContentSection>
						<Title size="l" color={theme.colors.primary} as="h3">
							{t('bookAppointment.failed.title')}
						</Title>
						<Lead size="s">{t('bookAppointment.failed.lead')}</Lead>
						<br />
						<br />
						<ButtonsWrapper>
							<CustomButton role="link" onClick={handleGoBackDirectly}>
								{t('bookAppointment.failed.button')}
							</CustomButton>
						</ButtonsWrapper>
					</ContentSection>
				</div>
			)}
		</BookAppointmentWrapper>
	);
};

import {
	createSlice,
	createSelector,
	createAsyncThunk,
} from '@reduxjs/toolkit';

import { ResponseError } from 'common/typings/response';

import { RootState } from 'expertApplication/store';
import ExpertUserService, {
	ExpertUserData,
} from 'expertApplication/services/expertUser';

export interface UserState {
	data: ExpertUserData | null;
	isLoading: boolean;
	isSaving: boolean;
	error: any;
	savingError: any;
}

const initialState: UserState = {
	data: null,
	isLoading: false,
	isSaving: false,
	error: null,
	savingError: null,
};

export const getExpertUserAsync = createAsyncThunk(
	'user/getUser',
	async (_obj, thunkAPI) => {
		try {
			const userData: ExpertUserData & ResponseError =
				await ExpertUserService.getExpertUserData();

			if (userData.status === 'error') {
				return thunkAPI.rejectWithValue(userData.statusCode);
			}
			return userData;
		} catch (err) {
			return thunkAPI.rejectWithValue(err);
		}
	},
);

export const expertUserSlice = createSlice({
	name: 'expertUser',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(getExpertUserAsync.pending, (state) => {
			state.isLoading = true;
		});

		builder.addCase(getExpertUserAsync.rejected, (state, action) => {
			state.error = action.payload;
			state.data = null;
			state.isLoading = false;
		});

		builder.addCase(getExpertUserAsync.fulfilled, (state, action) => {
			state.data = action.payload;
			state.isLoading = false;
		});
	},
});

export default expertUserSlice.reducer;

export const selectExpertUser = createSelector(
	(state: RootState) => state.expertUser,
	(user) => user,
);

export const en = {
	'pageTitles.authentication': 'Authentication',
	'pageTitles.chat': 'Chat',
	'pageTitles.contact': 'Contact',
	'pageTitles.feedback': 'Feedback',
	'pageTitles.terms': 'Terms & Conditions',
	'pageTitles.serviceInfo': 'Service Info',
	'pageTitles.serviceSelection': 'Remote reception',
	'pageTitles.bookedAppointments': 'Booked Appointments',
	'pageTitles.appointmentHistory': 'Appointment history',
	'pageTitles.appointmentHistoryDetails': 'Appointment history details',
	'pageTitles.bookAppointment': 'Book new appointment',
	'pageTitles.changeMyLocation': 'Change my location',
	'pageTitles.landing': 'Landing',
	'appointmentHistory.title': 'Appointment history',
	'appointmentHistory.explanation1': 'We store your chats here ',
	'appointmentHistory.explanation2': 'for 90 days',
	'appointmentHistory.explanation3':
		'. You can also access any documents you might have received. We recommend storing them on your own devices for future access.',
	'appointmentHistory.subtitle': 'Appointments in the past 90 days',
	'appointmentHistory.list.conversed': 'Conversed with',
	'appointmentHistory.list.document': 'Documents',
	'appointmentHistoryDetails.error': 'There was an error loading the data',
	'common.errors': 'Errors',
	'common.you': 'You',
	'common.start': 'Start',
	'common.minutes': 'minutes',
	'common.skipToContent': 'Skip to content',
	'common.skip': 'Skip',
	'common.send': 'Send',
	'common.saved': 'Saved',
	'common.writeMessage': 'Write a message',
	'common.welcome': 'Welcome',
	'common.hello': 'Hello',
	'common.back': 'Back',
	'common.continue': 'Continue',
	'common.close': 'Close',
	'common.backToContact': 'Back to Contact information',
	'common.backToServiceSelection': 'Back to Remote reception',
	'common.backToAppointmentHistory': 'Back to Appointment history',
	'common.backToHomepage': 'Back to Home page',
	'common.backToMessages': 'Back to messages',
	'common.next': 'Next',
	'common.save': 'Save',
	'common.ok': 'OK',
	'common.cancel': 'Cancel',
	'common.logOut': 'Log out',
	'common.logOutInfo':
		'Are you sure you want to log out? You will need to authenticate again in order to log back in.',
	'common.menuLink': 'Menu',
	'common.savingError': 'Saving error. Try again later.',
	'common.mainMenu.contact': 'My contact information',
	'common.mainMenu.terms': 'Terms & conditions',
	'common.mainMenu.howServiceWorks': 'How does the service work',
	'common.mainMenu.remoteAppointments': 'Remote reception',
	'common.mainMenu.yourAppointments': 'My bookings',
	'common.mainMenu.actOnBehalf': 'Act on behalf of someone',
	'common.mainMenu.accessibilityStatement': 'Accessibility statement',
	'common.mainMenu.changeMyLocation': 'Change my location',
	'common.mainMenu.asyncMessages': 'Your messages',
	'common.mainMenu.history': 'Appointment history',
	'common.mainMenu.bookAppointment': 'Book an appointment',
	'common.mainMenu.section1': 'How can we help you?',
	'common.mainMenu.section2': 'About the service',
	'common.mainMenu.authenticate': 'Authenticate',
	'common.new': 'New',
	'cookies.headline': 'Our website uses cookies.',
	'cookies.body':
		'Approving all cookies ensures an optimal user experience. {{link}} and their use or change your cookie settings.',
	'cookies.body.link': 'Read more about cookies',
	'cookie.modal.link': 'Change cookie settings',
	'cookies.button': 'Accept cookies',
	'cookies.modal.headline': 'Cookie settings',
	'cookies.modal.top':
		'We would like to offer the best possible service also online. To this end, we use internal and external tools to collect information about the use of our website. We don’t store any information about your identity to the tracking tools. The sent information is used for developing the service and for marketing purposes. We will save the cookie settings you make, and all of the tools respect the options you have selected. You can change the settings or clear browser cookies at any time.',
	'cookies.modal.required.headline': 'Required cookies',
	'cookies.modal.required.text':
		'Required cookies ensure that the website is reliable and secure. They are used to collect statistical data about the use of the service and to ensure the correct functioning of the service’s language settings, etc. You can disable the use of required cookies only in browser settings. We do not disclose any information outside Terveystalo.',
	'cookies.modal.functional.headline': 'Cookies for functional development',
	'cookies.modal.functional.text':
		'Cookies for functional development are used to collect information to analyze user activity and behavior in various online and mobile services. The behavior data is used to create different user categories and models and to develop personal user experiences in different service channels. We do not disclose any information outside Terveystalo.',
	'cookies.modal.targeting.headline': 'Targeting cookies',
	'cookies.modal.targeting.text':
		'These cookies are used to personalize the content on the basis on your interests instead of sending the same marketing communications to everyone. They enable Terveystalo to provide content you find interesting in its online and mobile services, as well as the services provided by our partners. The content is personalized on the basis of the websites you have visited or the content you have watched. We do not disclose any information outside Terveystalo.',
	'cookies.modal.marketing.headline': 'Marketing cookies',
	'cookies.modal.marketing.text':
		'Marketing cookies are used to target marketing to you in various websites and social network services within our advertising network (Facebook, Google, Instagram, LinkedIn, Twitter and YouTube). The use of marketing cookies may entail disclosing information to a third party advertising network outside Terveystalo. Social media marketing platforms can also use this data for their own use, by offering other services of interest to you, for example.',
	'cookies.modal.submit': 'Confirm choices',
	'progressSection.item.authentication': 'Authentication',
	'progressSection.item.serviceSelection': 'Service selection',
	'progressSection.item.remoteAppoitment': 'Remote appointment',
	'authentication.identificationHeadline':
		'Hello! Please authenticate to continue to the service.',
	'authentication.identificationBody':
		'The service handles private information, such as patient records and in order to show the correct information, the service requires strong authentication. You can do so using your bank credentials or mobile authentication.',
	'authentication.identificationButton': 'To identification',
	'authentication.identificationLockText': 'Connection is encrypted',
	'authentication.actOnBehalfTitle': "Acting on someone's behalf?",
	'authentication.actOnBehalfContent':
		"In order to act on someone's behalf, you need to first authorize as yourself. After you have logged in yourself, you can select ‘act on someone’s behalf’ from the main menu.",
	'contact.headline': 'Your contact information',
	'contact.mainText':
		'Please make sure that our information about you is up to date.',
	'contact.firstName': 'First name',
	'contact.lastName': 'Last name',
	'contact.street': 'Street address',
	'contact.code': 'Postal code/ZIP code',
	'contact.city': 'City',
	'contact.mobile': 'Mobile number (For appointment reminders)',
	'contact.nationality': 'Nationality',
	'contact.country': 'Country',
	'contact.email': 'Email address',
	'contact.error': 'Please fill in',
	'terms.headline': 'Terms and conditions',
	'terms.subtitle':
		'Please familiarize yourself with our terms and conditions.',
	'terms.acceptButton': 'Accept and continue',
	'welcome.headline': 'This is how the service works',
	'welcome.button': 'Continue to service',
	'welcome.dontShowMessage': 'Don’t show this again',
	'serviceSelection.headline': 'Remote reception',
	'serviceSelection.chatCode.inputField': 'Enter a chat code',
	'serviceSelection.chatCode.submit': 'Start appointment',
	'serviceSelection.chatCode.error.101': 'User not authenticated.',
	'serviceSelection.chatCode.error.201': 'Chat code not valid.',
	'serviceSelection.chatCode.error.202':
		'Chat code cannot be opened at this time. Please check the date and time of your appointment.',
	'serviceSelection.chatCode.error.203': 'Chat code has expired.',
	'serviceSelection.chatCode.error.204': 'Chat code has already been used.',
	'serviceSelection.chatCode.error.500': 'Error processing chat code.',
	'serviceSelection.chatCode.error.501': 'Error processing chat code.',
	'serviceSelection.availableServices': 'Open services',
	'serviceSelection.chatCode.headline': 'Services for those using a chat code',
	'serviceSelection.chatCode.lead':
		'Use a chat code to connect straight to the expert you need.',
	'serviceSelection.chatCode.infoLink': 'Where can I get a chat code?',
	'serviceSelection.chatCode.infoButton': 'What are chat codes?',
	'serviceSelection.chatCode.services': 'Services that require a chat code',
	'serviceSelection.chatCode.infoModal.headline': 'What is a chat code?',
	'serviceSelection.chatCode.infoModal.body':
		"A chat code gives you direct access to the service you have been assigned to by your municipality's healthcare professional. You might have received a chat code through some other service your municipality provides for example when you have seen or talked with a nurse. By entering a chat code, we can directly give access to the service you need!",
	'serviceSelection.chatCode.infoModal.body.second':
		'You can find more information about whether your municipality provides chat codes from the municipality.',
	'serviceSelection.chatCode.infoModal.submit': 'Okay',
	'serviceSelection.yourAppointments.future.title': 'Future appointments',
	'serviceSelection.yourAppointments.past.title': 'Past appointments',
	'serviceSelection.yourAppointments': 'Your booked appointments',
	'serviceSelection.yourAppointments.text':
		'You can enter this appoinment 5 minutes before it starts.',
	'serviceSelection.yourAppointments.cancel':
		'You can cancel this appointment up to 24 hours before it starts.',
	'serviceSelection.yourAppointmentsModal.text':
		'Your remote appointment with {{name}} starting in 5 minutes. You may have to wait for them to connect.',
	'serviceSelection.yourAppointmentsModal.submit': 'Enter appointment',
	'serviceSelection.bookings.newAppointment.button': 'Book an appointment',
	'serviceSelection.noUpcomingAppointments':
		'You have no upcoming appointments',
	'appointmentModal.headline': 'Welcome to your remote appointment!',
	'serviceSelection.groupVideo': 'Group appointment',
	'serviceSelection.groupVideo.host': 'Organizer',
	'serviceSelection.groupVideo.multiple': 'multiple participants',
	'serviceSelection.book.oneToOne': 'Booked appointment with {{name}}',
	'serviceSelection.book.group':
		'Booked appointment with multiple participants (organizer: {{name}})',
	'appointmentModal.body.one':
		'We will now connect you to the remote appointment.',
	'appointmentModal.body.two':
		'If you wish to speed up your process, you can choose to fill a self-assessment survey for the expert.',
	'appointmentModal.button': 'Start appointment',
	'appointmentModal.questionnaire.button': 'Fill out survey',
	'appointmentModal.mandatory.body':
		'Before we connect you to the expert, please answer to a few self-assessment questions. The expert will receive a summary of this assessment.',
	'appointmentModal.mandatory.button': 'Fill out survey and go the reception',
	'appointmentModal.cancellable.title': 'Your upcoming remote appointment',
	'appointmentModal.cancellable.toast': 'Upcoming appointment cancelled',
	'appointmentModal.cancellable.button.cancel': 'Cancel appointment',
	'appointmentModal.cancellable.lead.top':
		'You can join this appointment 5 minutes before it starts.',
	'appointmentsModal.cancellable.lead.middle':
		'If you need to, you can cancel this appointment until 24 hours before the appointment. If you appointment is starting within 24 hours, you have to contact the service provider to cancel the appointment.',
	'appointmentsModal.cancellable.lead.bottom':
		'If you need to change your appointment time, you have to first cancel your existing appointment and then book a new one.',
	'appointmentsModal.cancel.groupchat':
		'If you need to cancel group appointment, you must contact service provider.',

	'chat.headline': 'Remote appointment',
	'chat.body':
		'You can discuss with the expert and send photos or files to them using the paperclip icon in the lower left corner of the window.',
	'chat.button.help': 'Help',
	'feedback.title': 'Thank you! Please give us feedback.',
	'feedback.nps.lead':
		'How likely are you to recommend this service to others?',
	'feedback.ces.lead': 'How easy was it to use this service?',
	'feedback.careSatisfaction.lead':
		'Did you receive the help you needed or necessary further instructions on your matter?',
	'feedback.form.lead':
		'If you wish, you can leave us open feedback. Any and all feedback you give us will be used to improve the remote appointment service.',
	'feedback.veryUnlikely': 'Very unlikely',
	'feedback.veryLikely': 'Very likely',
	'feedback.veryHard': 'Very hard',
	'feedback.veryEasy': 'Very easy',
	'feedback.careSatisfaction.bad': 'No, not at all',
	'feedback.careSatisfaction.good': 'Yes, everything was solved',
	'feedback.thankYou.title': 'Remote reception finished!',
	'feedback.textarea.title': 'Feedback',
	'logout.headline': 'You have been logged out.',
	'logout.text':
		'Thank you for using our service! You can now close this page.',
	'logout.text.second':
		'If you need to use the service again, you can do so by logging back in.',
	'logout.expired':
		'Your session expired and you have been automatically logged out, please log in again if you need to use the service, thank you.',
	'actOnBehalfModal.title': 'Act on behalf of someone',
	'actOnBehalfModal.lead':
		'If you have a guardianship over someone or are in another way authorized to act on someone’s behalf, you can log in and use the service as them through suomi.fi service.',
	'actOnBehalfModal.actingAsSomeone': 'You are acting on behalf of {{name}}',
	'actOnBehalfModal.button.actingAsMyself': 'Act as myself',
	'actOnBehalfModal.or': 'or',
	'actOnBehalfModal.button.actingAsSomeone': 'Act on behalf of someone',
	'actOnBehalfModal.button.actingAsSomeoneElse':
		'Act on behalf of someone else',
	'actOnBehalfModal.willtakeYouMessage': 'This will take you to suomi.fi',
	'selectedOrganizationModal.title': 'Whose services would you like to use?',
	'selectedOrganizationModal.title.none': 'No services available in your area',
	'selectedOrganizationModal.text':
		'It seems that based on your address, you are entitled to the services of multiple municipalities. Which municipality’s services would you like to use?',
	'selectedOrganizationModal.text.none':
		'Unfortunately it seems that your municipality does not provide remote healthcare services through etapalvelut.fi. Please refer to your local healthcare provider for more information.',
	'selectedOrganizationModal.changeButton': 'Change my address',
	'chatNotAvailableModal.title':
		"We're sorry, the service is closed at this time.",
	'chatNotAvailableModal.body':
		'We apologize for the inconvenience, you can try to enter the service later. Would you like to log out or try another service?',
	'chatNotAvailableModal.button': 'To service selection',
	'bookAppointments.title': 'Book a new appointment',
	'bookAppointments.form.date': 'Date',
	'bookAppointemnts.form.specialization': 'Specialization / Expert field',
	'bookAppointments.button.search': 'Search times',
	'bookAppointments.availableAppointments.title': 'Available appointments',
	'bookAppointments.button.prev': 'The day before',
	'bookAppointments.button.next': 'The next day',
	'bookAppointments.back': 'Back to book a new appointment',
	'bookAppointments.youAreBooking.title': 'You are booking',
	'bookAppointments.preQuestionnaire.title': 'Pre-questionnaire',
	'bookAppointments.button.confirmBooking': 'Confirm booking',
	'bookAppointments.error': 'You need to answer all required questions',
	'bookAppointment.confirmed.title': 'Booking confirmed!',
	'bookAppointment.timeInfo.part1': 'If you do not confirm your booking',
	'bookAppointment.timeInfo.part2': 'within 15 minutes,',
	'bookAppointment.timeInfo.part3':
		'others can select this time as well. Time left:',
	'bookAppointment.failed.title': 'Booking failed!',
	'bookAppointment.failed.lead':
		'Something went wrong. If you did not confirm your booking within the given time frame, someone might have selected the time for themselves. Please try again!',
	'bookAppointment.failed.button': 'Book a new appointment',
	'bookAppointment.confirmationModal.title': 'Your booking is not confirmed',
	'bookAppointment.confirmationModal.lead':
		'You have not yet confirmed your booking. Leaving this screen will cancel your booking. Are you sure you want to continue?',
	'bookAppointments.timeslotNotAvailable':
		'You cannot book this appointment. Search again for available timeslots.',
	'bookAppointments.noAppointments':
		"There isn't any appointments available for selected date",
	'changeMyLocation.title': 'Change my location',
	'changeMyLocation.lead':
		'If you are not at your home municipality, you might still be able to access your current locations’ remote services. Select an area below to continue to use their remote services.',
	'changeMyLocation.button': 'Change location',
	'changeMyLocation.subText':
		'Confirming your location will transfer you to the selected area’s services.',
	'changeMyLocation.selectArea': 'Select an area',
	'guestThankYou.content.heading': 'Group appointment ended',
	'guestPage.email': 'Email',
	'guestPage.pinCode': 'PIN-code',
	'guestPage.appointmentNotFound': 'Appointment not found or link malformatted',
	'guestPage.enterPinHeader': 'Enter a pin-code',
	'guestPage.enterPin':
		'please enter your email and the pin-code you have received in you email to continue.',
	'guestPage.continue': 'Continue',
	'guestPage.malformattedPin': 'PIN-code is 6 digit number',
	'guestPage.wrongpassword': 'Wrong email or PIN-code',
	'guestPage.malformattedEmail': 'Malformatted email address',
	'guestPage.unknownLoginError': 'Unable to login!',
	'guestPage.error': 'Please check fields',
	'landing.activity.title': 'Your activity',
	'landing.howWeCanHelpYou.title': 'How can we help you?',
	'landing.item.bookAppointment': 'Book an appointment',
	'landing.item.bookAppointment.helperText': 'To bookings',
	'landing.item.remoteReception': 'Remote reception',
	'landing.item.remoteReception.helperText': 'To services',
	'landing.item.chatNow': 'Chat now',
	'landing.item.chatNow.helperText': 'To messages',
	'guestThankYou.content.thankyou': 'Thank you for using our service!',
	'guestThankYou.content.notify':
		'Please note that for each group appointment you join as a guest uses a separate link, which you should have received via email.',
	'guestThankYou.content.logout': 'You can now log out and close this page.',
	'guestThankYou.logout.button': 'Log out',
	'questionnaireErrorModal.title': 'Sorry! Error loading the survey',
	'questionnaireErrorModal.text':
		'Preliminary survey can’t be displayed right now',
	'questionnaireErrorModal.text2':
		'You can either join the reception without filling the survey, or you can select another service by going back to the service selection.',
	'questionnaireErrorModal.button.serviceSelection': 'Back to selection',
	'questionnaireErrorModal.button.chat': 'Join reception',
	'surveyPage.error': 'Error loading surveys',
	'surveyItem.answerSurvey': 'Answer your survey',
	'surveyItem.nameMissing': 'Kyselyn nimi puuttuu',
	'surveyItem.selectSurvey': 'Valitse lisättävä kysely',
	'surveyItem.addSurvey': 'Lisää kysely',
	'surveyItem.link': 'To survey',
	'surveyPage.thankYou.title': 'Thank you for your answers',
	'surveyPage.thankYou.content':
		'The expert serving you will receive the given answers before a possible reception.',
	'asyncMessaging.messages.title': 'Messages',
	'asyncMessaging.newMessage': 'Message us',
	'asyncMessaging.yourMessages': 'Your messages',
	'asyncMessaging.noMessages': 'No conversations available',
	'asyncMessaging.sendNewMessage.attachButton': 'Attach a file',
	'asyncMessaging.sendNewMessage.sendButton': 'Send',
	'asyncMessaging.reply': 'Reply',
	'asyncMessaging.discardDraft': 'Discard draft',
	'asyncMessaging.newMessageTitle': 'Message us',
	'asyncMessaging.selectAnOption': 'Select an option',
	'asyncMessaging.sendMessage': 'Send a message',
	'asyncMessaging.sendMessage.description':
		'Example send a message description',
	'asyncMessaging.useMessageCode': 'Use a message code',
	'asyncMessaging.useMessageCode.description':
		'Example use a message code description',
	'asyncMessaging.newMessageCreateTitle': 'New message',
	'asyncMessaging.messageCodeTitle': 'Message code',
	'asyncMessaging.messageCodeSubmit': 'Submit',
	'asyncMessaging.category': 'Category',
	'asyncMessaging.subcategory': 'Sub-category',
	'asyncMessaging.message': 'Message',
	'asyncMessaging.sendingError': 'Sending error. Please try again later.',
	'asyncMessaging.codeAdded': 'Code {{code}} added',
	'asyncMessaging.messageCodes.error.201': 'Message code not valid.',
	'asyncMessaging.messageCodes.error.202':
		'Message code cannot be opened at this time.',
	'asyncMessaging.messageCodes.error.203': 'Message code has expired.',
	'asyncMessaging.messageCodes.error.204':
		'Message code has already been used.',
	'serviceSelection.messageCodes.error.500': 'Error processing message code.',
	'serviceSelection.messageCodes.error.501': 'Error processing message code.',
	'asyncMessaging.errors.messageCode': 'Put correct message code',
	'asyncMessaging.errors.category': 'Select category from dropdown',
	'asyncMessaging.errors.subcategory': 'Select subcategory from dropdown',
	'asyncMessaging.errors.messageBoxText': 'Put correct message',
	'asyncMessaging.success': 'Message was sent',
};

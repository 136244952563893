import styled from 'styled-components';
import { setLightness } from 'polished';

import { PillButton } from '@terveystalo/design-system-react-components';

import { CustomTextButton } from 'app/styles/components/button/button';
import { ThemeType } from 'app/styles/themes/themes';

type ILanguageButton = {
	theme: ThemeType;
	myvariant?: 'primary' | 'secondary';
};

export const LanguageWrapper = styled.nav``;

export const LanguageButton = styled(PillButton)<ILanguageButton>`
	margin-left: ${(props) => props.theme.spacing['0_5']};

	${(props: ILanguageButton) =>
		props.myvariant === 'primary' &&
		`
				background-color: ${props.theme.colors.primary};
				color: ${props.theme.colors.white};
				border: none;

				&:before {
					background-color: ${setLightness(0.5, props.theme.colors.primary)};
				}
	
				&:not(:disabled):hover {
					color: ${props.theme.colors.white};
				}
			`}
`;

export const LanguageSimpleButton = styled(CustomTextButton)<{
	active: boolean;
}>`
	display: block;
	margin: ${(props) => props.theme.spacing['1_5']};
	font-size: ${(props) => props.theme.typography.fontSize.body.l};

	span {
		margin: 0;
		${({ active, theme }) =>
			active &&
			`
			border-bottom: 1px solid ${theme.colors.primary};
		`}
	}
`;

import styled from 'styled-components';

export const ToastWrapper = styled.div<{
	active: boolean;
	color?: string;
	background?: string;
}>`
	position: absolute;
	max-width: 944px;
	width: calc(100% - ${(props) => props.theme.spacing['2']});
	left: 50%;
	transform: translate(-50%);
	background-color: ${(props) => props.theme.colors.white};
	transition: all 0.3s;
	border-radius: ${(props) => props.theme.border.radius.m};
	overflow: hidden;
	max-height: 0;
	display: flex;
	align-items: center;
	transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
	padding: 0;
	color: ${(props) => props.theme.colors.okayGreen};
	z-index ${(props) => props.theme.zIndex.toast};

	${(props) =>
		props.color &&
		`
		color: ${props.color};
	`}

	> div {
		background-color: ${(props) => props.theme.colors.viridianGreen15};
		padding: ${(props) => props.theme.spacing['1']};
		display: flex;
		height: 55px;
		width: 100%;
		align-items: center;
		box-shadow: ${(props) => props.theme.boxShadows.boxShadow3};

		${(props) =>
			props.background &&
			`
			background-color: ${props.background};
		`}
	}

	${(props) =>
		props.active &&
		`
    max-height: 100px;
  `}

	top: ${(props) => props.theme.spacing['1']};
	${(props) => props.theme.media.mediaQueries.desktop} {
		width: 100%;
		top: ${(props) => props.theme.spacing['1_5']};
	}

	span {
		color: ${(props) => props.theme.colors.viridianGreen};
	}
`;

export const ToastIcon = styled.div`
	margin-right: ${(props) => props.theme.spacing['1']};
`;

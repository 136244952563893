import { Response } from 'common/typings/response';
import { AppointmentType } from 'common/typings/bookings';
import { LanguagesType } from 'common/lang/lang';

import PatientApiService from 'app/services/config';

export interface OpenAppointmentItemData {
	uuid: string;
	name: string;
	startTime: number;
	duration: number;
	queueId: string;
	preQuestionnaire: boolean;
	type: AppointmentType;
}

export type OpenAppointments = Array<OpenAppointmentItemData>;

export interface OpenAppointmentsParams {
	date: number; // timestamp
	specialization: string;
}

export interface QuestionnaireAnswerItem {
	id: string;
	question: LanguagesType;
	answer: string;
}

export interface OpenAppointmentBook {
	id: string;
	isOpen: boolean;
	questionnaireAnswers?: Array<QuestionnaireAnswerItem>;
}

class OpenAppointmentsService {
	public async getOpenAppointments({
		date,
		specialization,
	}: OpenAppointmentsParams) {
		const response: Response<OpenAppointments> = await PatientApiService.get(
			`/services/open/${date}/${specialization}`,
		);
		return response.data;
	}

	public async bookOpenAppointment(params: OpenAppointmentBook) {
		const response: Response<OpenAppointments> = await PatientApiService.post(
			`/services/book/${params.id}`,
			{
				questionnaireAnswers: params.questionnaireAnswers,
			},
		);
		return response.data;
	}
}

export default new OpenAppointmentsService();

import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { ThemeContext } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
	Title,
	Subtitle,
	Icon,
} from '@terveystalo/design-system-react-components';

import { addDaysToDate } from 'common/helpers/dates';

import AnalyticsService from 'common/services/analytics/analytics';
import { AppContext } from 'app/context/appContext';
import {
	getServicesAsync,
	selectServices,
} from 'app/store/serviceSelection/slices/serviceSelection.slice';
import { AppointmentItemData, Survey } from 'app/services/serviceSelection';
import { ActivityItem } from 'app/components/activityItem/activityItem';
import { selectConfig } from 'app/store/configuration/slices/configuration.slice';
import { config } from 'app/config/config';
import { ServiceItemAppointment } from 'app/components/serviceItem/serviceItemAppointment';
import { AppointmentModal } from 'app/components/appointmentModal/appointmentModal';
import {
	closeAppointmentCommon,
	openNinChatCommon,
} from 'app/components/appointmentModal/appointmentModalHelpers';
import { checkAppoitmentAvailabilityTime } from 'app/helpers/appointmentsTime';
import { SurveyItem } from 'app/components/surveyItem/surveyItem';

import { LandingWrapper } from './landing.styles';

export const LandingPage = () => {
	const { t } = useTranslation();
	const { userDetails } = useContext(AppContext);
	const theme = useContext(ThemeContext);
	const dispatch = useDispatch();
	const configData = useSelector(selectConfig);
	const history = useHistory();
	const [appointmentDialogOpen, setAppointmentDialogOpen] = useState(false);
	const [selectedChatDetails, setSelectedChatDetails] = useState<any>(null);

	const [activities, setActivities] = useState([]);
	const [surveys, setSurveys] = useState<Survey[]>([]);

	const { isLoading: activitiesLoading, data: servicesData } =
		useSelector(selectServices);

	const lang: string = i18next.language;

	useEffect(() => {
		document.title = t('pageTitles.landing');
	}, [lang]);

	useEffect(() => {
		document.title = t('pageTitles.landing');
		AnalyticsService.sendPageView(t('pageTitles.landing'));
		dispatch(getServicesAsync());
	}, []);

	const setValidAppointments = () => {
		if (servicesData?.appointments?.length > 0) {
			let currentActivities = servicesData.appointments
				.filter(
					(appointment: AppointmentItemData) =>
						appointment.startTime <
							addDaysToDate(new Date().getTime(), 3).getTime() &&
						checkAppoitmentAvailabilityTime(
							appointment.startTime,
							appointment.duration,
						),
				)
				.sort(
					(a: AppointmentItemData, b: AppointmentItemData) =>
						Number(a.startTime) - Number(b.startTime),
				);

			if (!currentActivities.length) {
				const allActivitiesSorted = servicesData.appointments
					.filter((appointment: AppointmentItemData) =>
						checkAppoitmentAvailabilityTime(
							appointment.startTime,
							appointment.duration,
						),
					)
					.sort(
						(a: AppointmentItemData, b: AppointmentItemData) =>
							Number(a.startTime) - Number(b.startTime),
					);

				if (allActivitiesSorted.length > 0) {
					currentActivities = [allActivitiesSorted[0]];
				}
			}

			setActivities(currentActivities);
		}
	};

	const setValidSurveys = () => {
		if (servicesData?.appointments?.length > 0) {
			const unansweredSurveys = servicesData.appointments.reduce(
				(surveyArray: Survey[], appointment: AppointmentItemData) => {
					appointment.surveys?.forEach((survey) => {
						if (
							!survey.responded_at &&
							!surveyArray.find(
								(addedSurv) => addedSurv.response_id === survey.response_id,
							)
						) {
							surveyArray.push(survey);
						}
					});
					return surveyArray;
				},
				[],
			);
			setSurveys(unansweredSurveys);
		}
	};

	useEffect(() => {
		let interval: any = null;
		if (servicesData?.appointments?.length >= 0) {
			setValidAppointments();
			setValidSurveys();

			interval = setInterval(() => {
				setValidAppointments();
			}, 5000);
		}
		return () => clearInterval(interval);
		// eslint-disable-next-line
	}, [servicesData?.appointments]);

	const openScheduledAppointmentDialog =
		(item: AppointmentItemData, isCancellable: boolean) => async () => {
			setSelectedChatDetails({
				serviceName: item.name,
				queueId: item.queueId,
				chatCodeService: false,
				uuid: item.uuid,
				isCancellable,
				type: item.type,
				audienceId: item.audienceId,
			});
			setAppointmentDialogOpen(true);
		};

	const showRemoteReception = () => {
		history.push(config.paths.serviceSelection);
	};

	const showMessages = () => {
		history.push(config.paths.messages);
	};

	const showBookAppointment = () => {
		history.push(config.paths.bookAppointment);
	};

	const openNinChat = async () => {
		setAppointmentDialogOpen(false);
		await openNinChatCommon(selectedChatDetails);

		history.push(config.paths.chat, {
			ninChatConfig: { ...selectedChatDetails },
		});
	};

	const closeAppointment = () => {
		closeAppointmentCommon(selectedChatDetails);
	};

	const cancelAppointment = () => {
		dispatch(getServicesAsync());
	};

	return (
		<LandingWrapper>
			<div>
				<Title as="h3" size="l" color={theme.colors.primary}>
					{t('common.welcome')}, {userDetails?.firstName}!
				</Title>
			</div>

			{(activities.length > 0 || surveys.length > 0) && (
				<Subtitle size="m">{t('landing.activity.title')}</Subtitle>
			)}

			{surveys.map((survey) => (
				<SurveyItem
					responseId={survey.response_id}
					nameJson={survey.survey_name}
				/>
			))}

			<div>
				{activitiesLoading && (
					<div>
						<br />
						<Icon name="Spinner" color={theme.colors.primary} />
						<br />
					</div>
				)}
				<br />
				<div>
					{activities.map((activity: AppointmentItemData) => (
						<ServiceItemAppointment
							onClick={openScheduledAppointmentDialog}
							item={activity}
						/>
					))}
				</div>
				<br />
			</div>

			<Subtitle size="m">{t('landing.howWeCanHelpYou.title')}</Subtitle>

			{configData.data.asyncMessagingEnabled && (
				<ActivityItem
					iconName="Chat"
					title={t('landing.item.chatNow')}
					linkText={t('landing.item.chatNow.helperText')}
					onClick={showMessages}
				/>
			)}

			{configData.data.remoteReceptionEnabled && (
				<ActivityItem
					iconName="Chat"
					title={t('landing.item.remoteReception')}
					linkText={t('landing.item.remoteReception.helperText')}
					data-testid="landing-remote-reception-button"
					onClick={showRemoteReception}
				/>
			)}
			{configData.data.bookingsEnabled && (
				<ActivityItem
					iconName="BookAppointment"
					linkText={t('landing.item.bookAppointment.helperText')}
					onClick={showBookAppointment}
					title={t('landing.item.bookAppointment')}
				/>
			)}
			{appointmentDialogOpen && (
				<AppointmentModal
					isOpen={appointmentDialogOpen}
					toggleModal={setAppointmentDialogOpen}
					successAction={openNinChat}
					closeAction={closeAppointment}
					cancelAction={cancelAppointment}
					scheduledAppointmentsActive
					selectedChatDetails={selectedChatDetails}
				/>
			)}
		</LandingWrapper>
	);
};

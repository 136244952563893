import { CommonApiService } from 'common/services/config';
import LocalStorageService from 'common/services/localStorage';

class GuestApiService extends CommonApiService {
	constructor() {
		super();
		this.handleTokens();
	}

	public async handleTokens() {
		this.request.interceptors.request.use((config: any) => {
			config.headers.Authorization = `Bearer ${LocalStorageService.get(
				'guestUserToken',
			)}`;
			return config;
		});
	}
}

export default new GuestApiService();

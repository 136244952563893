import styled from 'styled-components';

import { Text } from '@terveystalo/design-system-react-components';

export const HEADER_HEIGHT = 80;
export const HEADER_HEIGHT_MOBILE = 56;

export const HeaderWrapper = styled.header`
	position: fixed;
	display: flex;
	align-items: center;
	justify-content: space-between;
	top: 0;
	left: 0;
	right: 0;
	height: ${HEADER_HEIGHT_MOBILE}px;
	box-shadow: ${(props) => props.theme.boxShadows.boxShadow1};
	background-color: ${(props) => props.theme.colors.white};
	z-index: ${(props) => props.theme.zIndex.header};
	color: ${(props) => props.theme.colors.customColor};

	${(props) => props.theme.media.mediaQueries.desktop} {
		height: ${HEADER_HEIGHT}px;
	}
`;

export const HomeWrapper = styled.button<{ isAuthenticated: boolean }>`
	cursor: pointer;
	background: none;
	border: none;
	align-items: center;
	text-decoration: none;
	text-align: center;
	padding: ${(props) => props.theme.spacing['0_75']}
		${(props) => props.theme.spacing['1']};
	border-right: 1px solid ${(props) => props.theme.colors.rainyBlue15};
	display: block;
	min-width: ${HEADER_HEIGHT_MOBILE}px;

	${(props) =>
		!props.isAuthenticated &&
		`
		display: none;
	`}

	${(props) => props.theme.media.mediaQueries.desktop} {
		min-width: ${HEADER_HEIGHT}px;
	}
`;

export const HeaderText = styled(Text)`
	display: none;
	text-transform: uppercase;

	${(props) => props.theme.media.mediaQueries.desktop} {
		display: block;
	}
`;

export const InnerSection = styled.div``;

export const RightWrapper = styled.div`
	height: 100%;
`;

export const LeftWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const LogoWrapper = styled.div`
	display: inline-block;
	max-width: 256px;
	width: 100%;
	height: 100%;
	padding: ${(props) => props.theme.spacing['1']}
		${(props) => props.theme.spacing['1_5']};

	img {
		max-height: ${HEADER_HEIGHT_MOBILE - 32}px;

		${(props) => props.theme.media.mediaQueries.desktop} {
			max-height: ${HEADER_HEIGHT - 32}px;
		}
	}
`;

export const LandingWrapper = styled.button<{ isAuthenticated: boolean }>`
	height: 100%;
	cursor: pointer;
	background: none;
	border: none;
	align-items: center;
	text-decoration: none;
	text-align: center;
	padding: ${(props) => props.theme.spacing['0_75']}
		${(props) => props.theme.spacing['1']};
	min-width: ${HEADER_HEIGHT_MOBILE}px;

	${(props) => props.theme.media.mediaQueries.desktop} {
		min-width: ${HEADER_HEIGHT}px;
	}

	${(props) =>
		!props.isAuthenticated &&
		`
		display: none;
	`}
`;

export const ProfileWrapper = styled.button`
	height: 100%;
	cursor: pointer;
	background: none;
	border: none;
	align-items: center;
	text-decoration: none;
	text-align: center;
	padding: ${(props) => props.theme.spacing['0_75']}
		${(props) => props.theme.spacing['1']};
	min-width: ${HEADER_HEIGHT_MOBILE}px;
	border-left: 1px solid ${(props) => props.theme.colors.rainyBlue15};

	${(props) => props.theme.media.mediaQueries.desktop} {
		min-width: ${HEADER_HEIGHT}px;
	}
`;

import styled from 'styled-components';
import AutocompleteBase from 'react-autocomplete';

import { CustomTextField } from 'common/components/forms/textField/TextField.styles';

export const AutocompleteWrapper = styled.div``;

export const AutocompleteInnerWrapper = styled.div`
	position: relative;
`;

export const ItemWrapper = styled.button<{
	isHighlighted: boolean;
	isSelected?: boolean;
	noBorder?: boolean;
}>`
	border: none;
	padding: ${(props) => props.theme.spacing['1']};
	background-color: ${(props) => props.theme.colors.zodiacBlack02};
	border-bottom: 1px solid ${(props) => props.theme.colors.zodiacBlack22};
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	font-size: ${(props) => props.theme.typography.fontSize.body.m};
	cursor: pointer;
	text-align: left;

	&:hover {
		background-color: ${(props) => props.theme.colors.zodiacBlack04};
	}

	${(props) =>
		props.isHighlighted &&
		`
		background-color: ${props.theme.colors.zodiacBlack04};

	`};

	${(props) =>
		props.isSelected &&
		`
		background-color: ${props.theme.colors.zodiacBlack04};
		font-weight: bold;

	`};

	${(props) =>
		props.noBorder &&
		`
		border-bottom: none;

	`};

	&:last-child {
		border-bottom: none;
	}
`;

export const AutocompleInput = styled(CustomTextField)<{
	valid?: boolean;
	invalid?: boolean;
}>`
	${(props) =>
		(props.invalid || props.valid) &&
		`
			input + img {
				display: none;
			}
	`}
	width: 100%;

	input {
		padding-right: ${(props) => props.theme.spacing['3']};
	}
`;

export const AutocompleteInputWrapper = styled.div`
	position: relative;
`;

export const AutocompleteIconWrapper = styled.div`
	position: absolute;
	right: 20px;
	top: 50%;
	transform: translate(0, -50%);
	margin-top: 5px;
	pointer-events: none;
`;

export const Autocomplete = styled(AutocompleteBase)``;

export const ChildContainer = styled.div`
	position: absolute;
	left: 0;
	right: 0;
	height: auto;
	z-index: 1000;
	border-radius: ${(props) => props.theme.border.radius.m};
	top: 65px;
	overflow: hidden;
	border: 1px solid ${(props) => props.theme.colors.zodiacBlack22};
`;

export const MenuWrapper = styled.div``;

export const Menu = styled.div<{ hasItems: boolean }>`
	border: 1px solid ${(props) => props.theme.colors.zodiacBlack22};

	${(props) =>
		!props.hasItems &&
		`
	border: none;
`}
`;

export const BackMenu = styled.div`
	display: flex;
	align-items: center;
	gap: ${(props) => props.theme.spacing['0_5']};
`;

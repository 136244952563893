import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import {
	Field,
	Form,
	Formik,
	FormikErrors,
	FormikTouched,
	FormikValues,
} from 'formik';
import { useTranslation } from 'react-i18next';
// import { ThemeContext } from 'styled-components';

import { Text } from '@terveystalo/design-system-react-components';

import { BackEndErrorCodes } from 'common/typings/error-codes';
import { CustomTextField } from 'common/components/forms/textField/TextField.styles';

import { CustomButton } from 'app/styles/components/button/button';
import { selectServices } from 'app/store/serviceSelection/slices/serviceSelection.slice';

import {
	ChatCodeLabel,
	ChatCodeWrapper,
	ChatCodeError,
} from './chatCode.styles';

export const validate = (/* _values: FormikValues */) => {
	const errors: FormikErrors<FormikValues> = {};

	// if (!values.chatCode) {
	// 	errors.chatCode = 'serviceSelection.chatCode.error';
	// }

	return errors;
};

export const showErrors = (errors: FormikErrors<FormikValues>) =>
	Object.entries(errors).map(([, value]) => value);

interface ChatCodeProps {
	onFormSubmit: (chatCode: string) => void;
}

const ChatCodeTextField = (props: any) => {
	const {
		field: { onBlur, onChange },
		status,
	} = props;

	return (
		<CustomTextField
			{...props}
			state={status}
			onChange={onChange}
			onBlur={onBlur}
		/>
	);
};

export const ChatCode = ({ onFormSubmit }: ChatCodeProps) => {
	const initialValues = {};
	const { t } = useTranslation();
	// const theme = useContext(ThemeContext);
	const { isLoadingChatCode, chatCodeError } = useSelector(selectServices);
	const [submitedChatCodeValue, setSubmitedChatCodeValue] = useState('');

	const handleChatCodeSubmit = (values: FormikValues) => {
		setSubmitedChatCodeValue(values.chatCode);
		onFormSubmit(values.chatCode);
	};

	const errorStyle =
		chatCodeError === BackEndErrorCodes.ERR_CHAT_CODE_NV_Y
			? 'warning'
			: 'error';

	return (
		<ChatCodeWrapper>
			<Formik
				validate={validate}
				validateOnChange
				validateOnBlur
				initialValues={initialValues}
				onSubmit={async (values: FormikValues) => {
					handleChatCodeSubmit(values);
				}}
			>
				{({
					values,
				}: {
					errors: FormikErrors<FormikValues>;
					touched: FormikTouched<FormikValues>;
					values: FormikValues;
				}) => {
					const showError =
						chatCodeError &&
						submitedChatCodeValue === values.chatCode &&
						!isLoadingChatCode;

					return (
						<Form>
							<Field>
								{(props: any) => (
									<ChatCodeTextField
										label={
											<ChatCodeLabel>
												{t('serviceSelection.chatCode.inputField')}
												<Text size="l" color="attentionRed" weight="bold">
													&nbsp;*
												</Text>
												{/* <Icon
													name="AlertCircle"
													width={24}
													height={24}
													color={theme.colors.primary}
												/> */}
											</ChatCodeLabel>
										}
										id="chatCode"
										name="chatCode"
										errorStyle={showError && errorStyle}
										{...props}
									/>
								)}
							</Field>
							{showError && (
								<ChatCodeError errorStyle={errorStyle}>
									{t(`serviceSelection.chatCode.error.${chatCodeError}`)}
								</ChatCodeError>
							)}
							<CustomButton
								{...(isLoadingChatCode ? { icon: 'Spinner' } : {})}
								disabled={!values.chatCode}
								type="submit"
								fullWidth
								noTopMargin
								data-testid="contactform-submit-button"
							>
								{t('serviceSelection.chatCode.submit')}
							</CustomButton>
						</Form>
					);
				}}
			</Formik>
		</ChatCodeWrapper>
	);
};

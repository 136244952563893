import React, { useRef, useState, useEffect } from 'react';

import { ToggleWrapper, ToggleButton, ToggleContent } from './Toggle.styles';

interface ToggleProps {
	trigger: (collapsed: boolean) => React.ReactNode;
	content: React.ReactNode;
	buttonStyles?: any;
	isCollapsed?: boolean;
}

export const Toggle = ({
	trigger,
	content,
	buttonStyles,
	isCollapsed,
}: ToggleProps) => {
	const contentEl: any = useRef();
	const [collapsed, setCollapsed] = useState<boolean>(isCollapsed ?? true);

	useEffect(() => {
		if (!collapsed) {
			contentEl.current.style.height = `${contentEl.current.scrollHeight}px`;
		}
	}, []);

	const toggleContent = () => {
		if (collapsed) {
			contentEl.current.style.height = `${contentEl.current.scrollHeight}px`;
			setCollapsed(false);
		} else {
			contentEl.current.style.height = null;
			setCollapsed(true);
		}
	};

	return (
		<ToggleWrapper>
			<ToggleButton
				className={!collapsed ? 'expanded' : ''}
				onClick={toggleContent}
				style={buttonStyles || {}}
			>
				{trigger(collapsed)}
			</ToggleButton>
			<ToggleContent ref={contentEl}>{content}</ToggleContent>
		</ToggleWrapper>
	);
};

import React, { useContext, useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import i18next from 'i18next';
import { ThemeContext } from 'styled-components';
import { useSelector } from 'react-redux';
import md5 from 'crypto-js/md5';
import debounce from 'lodash/debounce';
import useMatchMedia from 'use-match-media-hook';

import { ResponseError } from 'common/typings/response';
import { AppointmentType } from 'common/typings/bookings';

import GuestUserService, {
	AccessKeyResult,
} from 'app/services/guestUser/index';
import { selectConfig } from 'app/store/configuration/slices/configuration.slice';
import { config } from 'app/config/config';
import WebAuthService from 'app/services/webAuth/webAuth';
import { AppContext } from 'app/context/appContext';
import AnalyticsService from 'common/services/analytics/analytics';
import ServiceSelectionService from 'app/services/serviceSelection';
import NinchatService from 'app/services/ninchat/ninchat';

import { NinChatWrapper } from './ninChat.styles';
import { NINCHATEVENTS } from './typings';
import { HEADER_HEIGHT, HEADER_HEIGHT_MOBILE } from '../header/header.styles';

declare global {
	interface Window {
		Ninchat: any;
		NinchatAsyncInit: any;
	}
}

export const containerId = 'ninchat-container';

export const prepareNinchatConfiguration = ({
	lang,
	queueId,
	secureMetadata,
	primaryColor,
	userDetails,
	configData,
}: any) => ({
	configKey: configData.data.ninchatSiteKey,
	environment: [lang],
	audienceMetadata: {
		language: lang,
		...(secureMetadata && { secure: secureMetadata }),
	},
	config: {
		container: containerId,
		default: {
			userName: `${userDetails?.firstName} ${userDetails?.lastName}`,
			audienceQueues: [queueId],
			audienceAutoQueue: queueId,
			sessionKey: `session-${queueId}-${
				userDetails?.telia?.ssn ? md5(userDetails?.telia?.ssn) : ''
			}-${
				configData.data?.actOnBehalf?.ssn
					? md5(configData.data?.actOnBehalf?.ssn)
					: ''
			}`,
			// sessionKey: `session-${queueId}`,
			css: config.ninchatCss(primaryColor),
		},
	},
});

export const prepareGroupVideoNinchatConfiguration = ({
	lang,
	secureMetadata,
	primaryColor,
	userDetails,
	configData,
	audienceId,
	accesskey,
}: any) => ({
	configKey: configData.data.ninchatSiteKey,
	environment: [lang],
	audienceMetadata: {
		language: lang,
		...(secureMetadata && { secure: secureMetadata }),
	},
	config: {
		container: containerId,
		default: {
			userName: `${userDetails?.firstName} ${userDetails?.lastName}`,
			accessKey: accesskey.access_key,
			channelId: audienceId,
			sessionKey: `session-${audienceId}-${
				userDetails?.telia?.ssn ? md5(userDetails?.telia?.ssn) : ''
			}-${
				configData.data?.actOnBehalf?.ssn
					? md5(configData.data?.actOnBehalf?.ssn)
					: ''
			}`,
			css: config.ninchatCss(primaryColor),
		},
	},
});
export interface NinchatConfig {
	queueId: string;
	serviceName: string;
	secureMetadata: string;
	chatCodeService: boolean;
	chatCode?: string;
	audienceId?: string;
	type: AppointmentType;
	uuid: string;
	questionnaireMetadata?: string;
}

export interface NinchatProps {
	config: NinchatConfig;
	onChatError: () => void;
}

const INTERVAL = 1000 * 60 * 15; // 15 minutes

export const NinChat = ({
	config: ninChatConfig,
	onChatError,
}: NinchatProps) => {
	const history = useHistory();
	const { userDetails, guestDetails, isGuestAuthenticated } =
		useContext(AppContext);
	const theme = useContext(ThemeContext);
	const configData = useSelector(selectConfig);
	const [int, setInt] = useState<any>(null);
	const ninchatError = useRef(false);

	const startStopInterval = () => {
		if (int === null) {
			const tempInt = setInterval(async () => {
				await WebAuthService.getAuthState();
			}, INTERVAL);
			setInt(tempInt);
		} else {
			clearInterval(int);
			setInt(null);
		}
	};

	useEffect(
		() => () => {
			if (int) {
				clearInterval(int);
			}
		},
		[int],
	);

	const handleCloseNinchat = () => {
		if (!ninchatError.current) {
			AnalyticsService.sendCustomEvent(
				'chatScreenNavigation',
				'remote appointment',
				'close appointment',
				'',
			);

			document.getElementById(containerId)?.remove();

			startStopInterval();
			history.push(config.paths.feedback, {
				fromNinchat: true,
				ninChatConfig,
			});
		}
	};

	const handleNinchatError = () => {
		ninchatError.current = true;
		onChatError();
	};

	const handleChatStarted = () => {
		new Audio(config.ninchatMessageSound).play();
		const { chatCode } = ninChatConfig;
		if (chatCode) {
			ServiceSelectionService.getChatCodeService(chatCode, true);
		}
	};

	const handleChannelStarted = (ev: any) => {
		NinchatService.saveNinchatChannelId(ev.channel_id);
	};

	const currentLanguage = () => {
		const lang = i18next.language;
		if (lang === 'se') {
			return 'sv';
		}

		return lang;
	};

	const loadNinchat = async () => {
		const { queueId, type, audienceId, uuid } = ninChatConfig;
		ninchatError.current = false;

		startStopInterval();
		const lang = currentLanguage();

		const realmId = configData.data.ninchatSiteKey?.split('/')[1] as string;
		let accesskey: AccessKeyResult | ResponseError | null = null;

		let secureMetadata = '';
		try {
			secureMetadata = (
				await ServiceSelectionService.getSecureMetadata(
					uuid,
					ninChatConfig.questionnaireMetadata || '',
				)
			).secureMetadata;

			// eslint-disable-next-line no-empty
		} catch (err) {}

		//  ---  handle group-video chat ----
		if (type === AppointmentType.group && audienceId) {
			try {
				accesskey = await GuestUserService.getAccesskey(uuid);

				if (!WebAuthService.isAuthenticated() && !isGuestAuthenticated()) {
					WebAuthService.loginWithCredentials();
				}

				if ('status' in accesskey && accesskey.status === 'error') {
					onChatError();
					return;
				}
			} catch (error) {
				onChatError();
				return;
			}

			const chatUserDetails = isGuestAuthenticated()
				? { firstName: guestDetails?.name, lastName: '' }
				: userDetails;

			window.Ninchat.embedInit(
				prepareGroupVideoNinchatConfiguration({
					lang,
					secureMetadata,
					primaryColor: theme.colors.primary,
					chatUserDetails,
					configData,
					audienceId,
					accesskey,
				}),
			);
		}

		// --- handle normal chat ---

		if (type === AppointmentType.oneOnOne || !type) {
			try {
				const queueStatus: any = await NinchatService.getNinchatQueueInfo(
					realmId,
				);

				if (!WebAuthService.isAuthenticated()) {
					WebAuthService.loginWithCredentials();
				}

				if (
					queueStatus.event === 'realm_queues_found' &&
					queueStatus.realm_queues[queueId]?.queue_attrs
						.closed /* || !queueStatus.realm_queues[queueId] */
				) {
					onChatError();
					return;
				}
				// eslint-disable-next-line no-empty
			} catch (error) {}

			window.Ninchat.embedInit(
				prepareNinchatConfiguration({
					lang,
					queueId,
					secureMetadata,
					primaryColor: theme.colors.primary,
					userDetails,
					configData,
				}),
			);
		}

		window.Ninchat.on(
			'error',
			NINCHATEVENTS.QUEUE_IS_CLOSED,
			handleNinchatError,
		);
		// window.Ninchat.on(
		// 	'error',
		// 	NINCHATEVENTS.SITE_CONFIG_LOADING_FAILED,
		// 	handleNinchatError,
		// );

		window.Ninchat.on('event', NINCHATEVENTS.CLOSE, handleCloseNinchat);
		window.Ninchat.on(
			'event',
			NINCHATEVENTS.AUDIENCE_STARTED,
			handleChatStarted,
		);

		window.Ninchat.on('event', NINCHATEVENTS.CHANNEL, handleChannelStarted);
	};

	const createNinchatScript = () => {
		const date = new Date();

		const script = document.createElement('script');
		script.id = 'ninchat-js';
		script.async = true;
		script.src = `${config.ninchatScript}?_= 
			${date.getMonth() * 100 + date.getDate()}`;

		window.NinchatAsyncInit = loadNinchat;
		document.body.appendChild(script);
	};

	useEffect(() => {
		if (userDetails || guestDetails) {
			if (!window.Ninchat) {
				createNinchatScript();
			} else {
				window.Ninchat = null;
				document.getElementById('ninchat-js')?.remove();
				createNinchatScript();
			}
		}
		// eslint-disable-next-line
	}, [userDetails, guestDetails]);

	const removeEventListeners = () => {
		if (window.Ninchat) {
			window.Ninchat.off(
				'error',
				NINCHATEVENTS.QUEUE_IS_CLOSED,
				handleNinchatError,
			);
			// window.Ninchat.off(
			// 	'error',
			// 	NINCHATEVENTS.SITE_CONFIG_LOADING_FAILED,
			// 	handleNinchatError,
			// );

			window.Ninchat.off('event', NINCHATEVENTS.CLOSE, handleCloseNinchat);
			window.Ninchat.off(
				'event',
				NINCHATEVENTS.AUDIENCE_STARTED,
				handleChatStarted,
			);
			window.Ninchat.off('event', NINCHATEVENTS.CHANNEL, handleChannelStarted);
		}
	};

	useEffect(
		() => () => {
			removeEventListeners();
		},
		// eslint-disable-next-line
		[],
	);

	return (
		<NinChatWrapper>
			<Container />
		</NinChatWrapper>
	);
};

const Container = React.memo(() => {
	const ADDITIONAL_SPACING = 40;
	const MINIMUM_CHAT_HEIGHT = 450;
	const MAXIMUM_CHAT_HEIGHT = 700;

	const theme = useContext(ThemeContext);

	const [desktop] = useMatchMedia([
		theme.media.mediaQueries.desktop.replace('@media', ''),
	]);

	const calculateIframeHeight = () => {
		const vh = window.innerHeight;

		return Math.min(
			MAXIMUM_CHAT_HEIGHT,
			Math.max(
				MINIMUM_CHAT_HEIGHT,
				vh -
					(desktop ? HEADER_HEIGHT : HEADER_HEIGHT_MOBILE) -
					ADDITIONAL_SPACING,
			),
		);
	};

	const [iframeHeight, setIframeHeight] = useState(calculateIframeHeight());

	const resize = () => {
		// when keyboard is visible those values will be different -> only works on ios
		// on android iframe still will be resized based on visible area
		if (document.documentElement.clientHeight === window.innerHeight) {
			setIframeHeight(calculateIframeHeight());
		}
	};

	const debouncedResize = debounce(() => {
		resize();
	}, 1000);

	const handleResize = () => {
		debouncedResize();
	};

	useEffect(() => {
		resize();
		window.visualViewport?.addEventListener('resize', handleResize);

		return () => {
			window.visualViewport?.removeEventListener('resize', handleResize);
		};
	}, []);

	return (
		<div id={containerId} style={{ width: '100%', height: iframeHeight }} />
	);
});

import styled from 'styled-components';

import { Alert, Text } from '@terveystalo/design-system-react-components';
import { ContentSection } from '../home.styles';

export const ContentSectionConversation = styled(ContentSection)`
	max-height: 1200px;
	overflow-y: auto;
`;

export const LoaderWrapper = styled.div`
	display: flex;
	justify-content: center;
`;

export const ErrorsWrapper = styled.div`
	margin: ${(props) => props.theme.spacing['1']} 0;
`;

export const ButtonsWrapper = styled.div`
	display: flex;
	justify-content: flex-end;
	margin-top: ${(props) => props.theme.spacing['1']};
	> button {
		width: 100%;
		max-width: 300px;
	}
`;

export const SubcategoryText = styled(Text)`
	margin-top: -${(props) => props.theme.spacing['1']};
	display: block;
`;

export const NoAnswerAlert = styled(Alert)`
	margin: ${(props) => props.theme.spacing['1']} 0;

	> span {
		display: flex;
		align-item: center;
		gap: ${(props) => props.theme.spacing['1']};

		p:first-child {
			margin-top: 0;
		}
	}
`;

export const Separator = styled.div`
	margin-top: ${(props) => props.theme.spacing['2']};
	margin-bottom: ${(props) => props.theme.spacing['2']};
	border-top: 1px solid ${(props) => props.theme.colors.zodiacBlack08};
`;

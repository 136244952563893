export const fi = {
	'common.remove': 'Poista',
	'common.customizableContent': 'Muokattava sisältö',
	'common.backToBookings': 'Takaisin ajanvarauksiin',
	'common.backToServiceSettings': 'Palaa organisaation asetuksiin',
	'common.backToCustomizableContent': 'Palaa muokattava sisältö',
	'common.cancel': 'Peruuta',
	'common.clear': 'Tyhjennä',
	'common.all': 'Kaikki',
	'common.back': 'Edellinen',
	'common.add': 'Lisää',
	'common.menu': 'Menu',
	'common.done': 'Valmis',
	'common.save': 'Tallenna',
	'common.edit': 'Muokkaa',
	'common.delete': 'Poista',
	'common.logOut': 'Kirjaudu ulos',
	'common.login': 'Kirjaudu sisään',
	'common.hello': 'Hei',
	'common.errors': 'Virhe',
	'common.savingError': 'Virhe tallennuksessa',
	'common.saveChanges': 'Tallenna muutokset',
	'common.notAvailable': 'Ei vielä saatavilla',
	'common.language.finnish': 'Suomi',
	'common.language.english': 'Englanti',
	'common.language.swedish': 'Ruotsi',
	'common.username': 'Käyttäjätunnus',
	'common.password': 'Salasana',
	'common.email': 'Sähköposti',
	'common.errors.105': 'Henkilötunnus ei ole oikeassa muodossa.',
	'common.errors.601': 'Käyttäjää ei löydy',
	'common.errors.602':
		'Sinulla ei ole riittäviä oikeuksia käyttäjien salasanan resetointiin',
	'common.errors.801': 'Linkki salasanan asettamiseen on vanhentunut',
	'common.errors.802':
		'Salasana ei ole tarpeeksi vahva, sen täytyy sisältää pieniä ja isoja kirjaimia sekä erikoismerkkejä',
	'common.errors.422':
		'Valittu aika on jo varattu, katso muut vapaat ajat kyseiselle ammattilaiselle',
	'common.errors.423': 'Valitun ajan täytyy olla tulevaisuudessa',
	'common.errors.1007': 'Jonon luonti ei onnistunut',
	'common.errors.1006': 'Asiantuntijalta puuttuu ninchat id',
	'common.errors.1005': 'ninchat-tapaamisen luonti ei onnistunut',
	'common.errors.1004':
		'Asiantuntijan lisääminen ninchat-tapaamisen ei onnistunut ',
	'common.errors.1001': 'Käyttäjän luonti ninchattiin ei onnistunut',
	'common.errors.1002':
		'Asiantuntijan lisääminen ninchat organisaatioon ei onnistunut',
	'common.errors.1003':
		'Sähköpostiosoite on jo käytössä eikä sitä voitu yhdistää olemassaolevaan käyttäjään',
	'common.errors.1100': 'Sähköpostiosoite on jo käytössä',
	'header.serviceSettings': 'Asetukset',
	'header.chat': 'Chat',
	'header.chatCodes': 'Chat-koodit',
	'header.experts': 'Asiantuntijat',
	'header.bookings': 'Ajanvaraukset',
	'header.userManagement': 'Käyttäjähallinta',
	'header.asyncMessaging': 'Viestit',
	'header.asyncMessaging.messagingCategories': 'Viestikategoriat',
	'header.asyncMessaging.messageCodes': 'Viestikoodit',

	'mainMenu.options.global': 'Global',
	'mainMenu.options.globalGeneralSettings': 'Yleiset koko palvelun asetukset',
	'mainMenu.options.globalUserManagement': 'Globaali käyttäjähallinta',
	'mainMenu.options.organizationUserSettings': 'Käyttäjähallinta',

	'mainMenu.options.serviceSettings': 'Palvelun asetukset',
	'mainMenu.options.chat': 'Chat',
	'mainMenu.options.general': 'Yleiset',
	'mainMenu.options.bookingAppointments': 'Ajanvaraukset',
	'mainMenu.options.experts': 'Asiantuntijat',
	'mainMenu.options.newOrganization': 'Uusi organisaatio',
	'mainMenu.options.announcements': 'Tiedotus',
	'mainMenu.options.termsOfService': 'Käyttöehdot',
	'mainMenu.options.surveys': 'Kyselyt',
	'mainMenu.options.messages': 'Viestit',

	'login.headline': 'Pääkäyttäjien kirjautuminen',
	'login.loginFailed': 'Kirjautuminen epäonnistui.',
	'login.fromRegistration.title':
		'Salasana luotu onnistuneesti. Kirjaudu sisään jatkaaksesi.',
	'login.changePassword': 'Unohditko salasanan?',
	'login.logout.expired':
		'Istuntosi on vanhentunut ja sinut on kirjattu ulos automaattisesti. Kirjauduthan uudelleen sisään jos käytät vielä hallintapaneelia, kiitos.',
	'changePassword.headline': 'Pääkäyttäjän salasanan vaihto',
	'changePassword.successText':
		'Sähköposti on onnistuneesti lähetetty antamaasi osoitteeseen',
	'changePassword.subtext':
		'Sähköposti lähetetään tähän osoitteeseen ja se sisältää linkin, jonka avulla salasana vaihdetaan. Tämä linkki vanhenee 30 minuutin kuluttua lähettämisestä.',
	'changePassword.submit': 'Vaihda salasana',
	'changePassword.backToLogin': 'Takaisin sisäänkirjautumiseen',
	'changePassword.error.incorrectFormat': 'Väärä sähköposti formaatti',
	'changePassword.error.general': 'Tapahtui virhe lähetettäessä pyyntöä',
	'changePassword.error.badEmail': 'Sähköpostia ei löydy',
	'chatCodes.headline': 'Chat-koodit',
	'chatCodes.headline.messageCodes': 'Viestikoodit',
	'chatCodes.description':
		' Luo uusia chat-koodeja näe, miten chat-koodeja käytetään.',
	'chatCodes.listOfCodes': 'Viestikoodilista',
	'chatCodes.button.addNew': 'Luo uusia koodeja',
	'chatCodes.table.codeGroupName': 'Koodiryhmän nimi',
	'chatCodes.table.assignedTo': 'Palvelut, mihin koodit on kiinnitetty',
	'chatCodes.table.codes': 'Koodit',
	'chatCodes.table.availability': 'Voimassaoloaika',
	'chatCodes.table.actions': 'Toiminnot',
	'chatCodes.table.subcategory': 'Ala-kategoria',
	'chatCodes.button.file': 'Vie tiedostoon',
	'chatCodes.button.duplicate': 'Kopioi',
	'chatCodes.deleteConfirmation':
		'Oletko varma, että haluat poistaa chat-koodit?',
	'chatCodesModal.title.add': 'Luo uusia koodeja',
	'chatCodesModal.title.edit': 'Muokkaa koodeja',
	'chatCodesModal.fields.codeGroupName': 'Koodiryhmän nimi',
	'chatCodesModal.fields.assignedService':
		'Palvelut, mihin koodit on kiinnitetty',
	'chatCodesModal.fields.amountOfCodes': 'Koodien kokonaismäärä',
	'chatCodesModal.fields.amountOfUses': 'Käytettyjen koodien määrä',
	'chatCodesModal.fields.startingDate': 'Alkupäivämäärä',
	'chatCodesModal.fields.today': 'Tänään',
	'chatCodesModal.fields.expirationDate': 'Loppupäivämäärä',
	'chatCodesModal.fields.endOfYear': 'Vuoden loppuun',
	'chatCodesModal.fields.6monthsFromNow': 'Seuraavat kuusi kuukautta',
	'chatCodesModal.fields.1yearFromNow': 'Yhdeksi vuodeksi',
	'chatCodesModal.fields.noExpirationDate': 'Ei loppupäivämäärää',
	'chatCodesModal.fields.subcategory': 'Ala-kategoria',
	'chatCodesModal.validation.codeGroupName': 'Syötä koodiryhmän nimi',
	'chatCodesModal.validation.assignedService': 'Kiinntä palvelut chatkoodeihin',
	'chatCodesModal.validation.assignedSubcategory':
		'Alakategoriaan vaaditaan viestikoodi',
	'chatCodesModal.validation.codesTotal':
		'Yhteismäärän täytyy olla suurempi kuin 0',
	'chatCodesModal.validation.startingDate': 'Aseta oikea aloituspäivämäärä',
	'chatCodesModal.validation.expirationDate':
		'Aseta oikea viimeinen voimassaolopäivä',
	'chatCodesModal.validation.expirationDate.past':
		'Et voi valita mennyttä päivämäärää',
	'empty.headline': 'Organisaatiota ei ole valittu',
	'empty.description': 'Valitse ensin organisaatio luodaksesi chat-koodeja.',
	'organizationSettings.serviceSettings.title': 'Palvelun asetukset',
	'organizationSettings.serviceSettings.description':
		'Tarkastele ja muokkaa organisaation asetuksia, jotka näkyvät loppuasiakkaan käyttöliittymässä. Huomioithan, että muokkausten tekeminen saattaa vaatia ylläpitotiimin uuden tuotantoonviennin.',
	'organizationSettings.serviceSettings.organizationInformation.title':
		'Organisaation tiedot',
	'organizationSettings.serviceSettings.interface.title': 'Brändi ja ulkoasu',
	'organizationSettings.serviceSettings.serviceUrl.title': 'Sivun URL osoite',
	'organizationSettings.serviceSettings.privacyStatement.title':
		'Tietosuojaseloste',
	'organizationSettings.serviceSettings.navigation.organization':
		'Organisaatio',
	'organizationSettings.serviceSettings.navigation.siteSettings':
		'Sivun asetukset',
	'organizationSettings.serviceSettings.navigation.services': 'Palvelut',
	'organizationSettings.serviceSettings.navigation.serviceArea': 'Palvelualue',
	'organizationSettings.serviceSettings.navigation.customizableContent':
		'Muokattava sisältö',
	'organizationSettings.serviceInformationPage.title': 'Ohjesivun informaatio',
	'organizationSettings.serviceInformationPage.body':
		'Kaikki loppuasiakkaat näkevät tämän sivun kun tulevat ensi kertaa palveluun. Sama sivu näkyy myös palvelun Menu-valikon takaa. Tämän sivun täytyy sisältää ainakin tiedot siitä, mitä palveluja on saatavilla ja millä kielillä, kuka palveluja tuottaa ja onko palvelun käyttö maksutonta.',
	'organizationSettings.chatCodesInformationPage.title':
		'Tietoa chat-koodeista',
	'organizationSettings.chatCodesInformationPage.body':
		'Lisätietokenttä chat-koodeihin, joka liitetään palvelun info -sivulle kun asiakas navigoi Valikon kautta. Tätä sivua ei suositella muokattavan.',
	'organizationSettings.languageInfo':
		'Varmista kieliversioiden muokkaus tarvittaessa. Voit valita muokattavan kielen yläpuolelta.',
	'organizationSettings.endOfService.title':
		'Palvelun jatko-ohjaus ja lopetussivu',
	'organizationSettings.endOfService.body':
		'Tämä sivu näytetään asiakkaalle etävastaanoton ja palautteen annon jälkeen. Tällä sivulla asiakkaille voidaan informoida, miten mahdollinen hoito jatkuu ja ohjata asiakas esimerkiksi ajanvaraukseen tai muihin palveluihin. Asiakas näkee myös tällä sivulla aina linkit apteekkihakuun ja Omakanta -palveluun.',
	'organizationSettings.thankYou.redirectionLinks.title':
		'Uudelleenohjauksen linkit (osio 1)',
	'organizationSettings.thankYou.redirectionLinksSecond.title':
		'Uudelleenohjauksen linkit (osio 2)',
	'organizationSettings.thankYou.redirectionLinks.button': 'Uusi linkki',
	'organizationSettings.thankYou.headline': 'Kiitos sivu ja uudelleenohjaus',
	'organizationSettings.thankYou.informationText.title':
		'Lisätietokentän teksti',
	'organizationSettings.thankYou.redirectionLinks.textfield.title':
		'1. Osion otsikko',
	'organizationSettings.thankYou.redirectionLinksSecond.textfield.title':
		'2. osion otsikko',
	'organizationSettings.thankYou.redirectionButtons.title':
		'Uudelleenohjauksen painike',
	'organizationSettings.thankYou.redirectionButtons.body':
		'Voit muokata ja luoda uusia uudelleenohjauksen painikkeita kiitos -sivulle. Näiden painikkeiden täytyy olla käyttäjälle ensisijaisia seuraavia toimintoja palvelussa. Älä luo tarpeettomia painikkeita, vaan käytä sen sijaan uudelleenohjauksen linkkejä. Valitse ensisijaiseksi painikkeeksi se toiminto, jonka haluat käyttäjien todennäköisimmin tekevän.',
	'organizationSettings.thankYou.redirectionButtonsTitle':
		'Painikkeet (valitse 0-2)',
	'organizationSettings.thankYou.buttons.logOut': 'Kirjaudu ulos',
	'organizationSettings.thankYou.buttons.backToServiceSelection':
		'Takaisin palvelun valintaan',
	'organizationSettings.thankYou.buttons.custom': 'Muokattava painike',
	'organizationSettings.thankYou.buttons.create': 'Luo uusi muokattava painike',
	'organizationSettings.thankYou.primaryButton.title': 'Ensisijainen painike',
	'organizationSettings.thankYou.newButtonModal.title': 'Muokattava painike',
	'organizationSettings.thankYou.newButtonModal.linkText.headline':
		'Painikkeen teksti',
	'organizationSettings.thankYou.newButtonModal.linkUrl.headline':
		'Painikkeen linkki',
	'organizationSettings.customizableContent.customizableContents.title':
		'Muokattava sisältö',
	'organizationSettings.customizableContent.emailTemplates.title':
		'Sähköpostipohjat',
	'organizationSettings.preQuestionnaire.title': 'Esikysely',
	'changeOrganizationModal.addNewOrganization': 'Lisää uusi organisaatio',
	'changeOrganizationModal.title': 'Vaihda organisaatio',
	'changeOrganizationModal.fields.organization.label': 'Organisaatio',
	'newOrganizationModal.title': 'Uusi organisaatio',
	'newOrganizationModal.create': 'Luo uusi organisaatio',
	'newOrganisationModal.subheader.general.info': 'Yleiset tiedot',

	'organization.fields.organizationInfo':
		'Organisaation tiedot ammattilaiselle',
	'organization.fields.primaryColor':
		'Ensisijainen väri HEX (Käytetään tekstiotsikoissa ja napeissa)',
	'organization.fields.primaryColor.placeholder': 'Esimerkiksi #FF5733',
	'organization.fields.secondaryColor': 'Toissijainen väri HEX',
	'organization.fields.secondaryColor.placeholder': 'Esimerkiksi #FF5733',
	'organization.fields.logo': 'Logo',
	'organization.fields.logo.placeholder': 'Valitse tiedosto',
	'organization.fields.logoInfoText':
		'Logo-kuvan täytyy olla 256 pikseliä leveä ja 128 pikseliä korkea(256x128px)',
	'organization.fields.contactEmail': 'Yhteyshenkilön sähköposti',
	'organization.fields.contactPerson': 'Yhteyshenkilön nimi',
	'organization.fields.paymentNumber': 'Maksajanumero',
	'organization.fields.organizationName': 'Organisaation nimi',
	'organization.fields.clientId': 'Asiakkaan ID(luodaan automaattisesti)',
	'organization.fields.background': 'Tausta',
	'organization.fields.background.placeholder': 'Valitse tiedosto',
	'organization.fields.backgroundInfoText':
		'Taustan tiedoston tulisi olla joko vektoritiedosto (PDF, SVG) tai kuva (PNG) kokoa 1900x1800 pikseliä jotta se toimii oikein. ',
	'organization.fields.privacyStatementTitle':
		'Tietosuojaselosteen otsikko ({{lang}})',
	'organization.fields.privacyStatementUrl':
		'Tietosuojaselosteen URL ({{lang}})',
	'organization.fields.serviceModules.title': 'Palvelumoduulit',
	'organization.fields.serviceModules.checkbox.booked': 'Kotisivun ajanvaraus',
	'organization.fields.serviceModules.checkbox.acting':
		'Toisen puolesta asiointi',
	'organization.fields.serviceModules.checkbox.logout': 'Kirjautuminen ulos',
	'organization.fields.serviceModules.checkbox.contact': 'Yhteystiedot',
	'organization.fields.serviceModules.checkbox.yourBookings':
		'Omat vastaanotot',
	'organization.fields.serviceModules.checkbox.changeLocation':
		'Vaihda sijaintia',
	'organization.fields.serviceModules.checkbox.appointmentHistory':
		'Vastaanottohistoria',
	'organization.fields.serviceModules.checkbox.remoteReception':
		'Kotisivun etävastaanotot',
	'organization.fields.serviceModules.checkbox.termsAndConditions':
		'Käyttöehdot',
	'organization.fields.languageOptions.title': 'Kieliversioiden vaihtoehdot',
	'organization.fields.country': 'Maa',
	'organization.fields.type.internal': 'Terveystalo',
	'organization.fields.type.external': 'Ulkoinen',
	'organization.fields.organizationVisibility.title':
		'Vaihda sijaintiasetuksiani',
	'organization.fields.organizationVisibility.dontShow':
		'Piilota organisaatio listauksesta',
	'organization.fields.serviceURLAddress': 'Sivun URL',
	'organization.fields.serviceURLAddress.note':
		'Organisaatio kohtainen URL luodaan organisaation nimen perusteella',
	'organization.validation.organizationName':
		'Organisaation nimi ei voi olla tyhjä',
	'organization.validation.contactPerson':
		'Yhteyshenkilön nimi ei voi olla tyhjä',
	'organization.validation.contactPersonEmail':
		'Sähköpostiosoite on kirjoitettava oikeassa muodossa',
	'organization.validation.logo': 'Logo ei voi olla tyhjä',
	'organization.validation.hex': 'Aseta oikea HEX arvo',
	'organization.validation.privacyStatementUrl':
		'Täytä tietosuojaselosteen linkki {{language}}',
	'organization.validation.privacyStatementUrlText':
		'Fill privacy statement url text in {{language}}',
	'organization.validation.ninchatMasterKey': 'Aseta oikea ninchat MasterKey',
	'organization.validation.ninchatSiteKey': 'Aseta oikea ninchat SiteKey',
	'organization.validation.ninchatSecret': 'Aseta oikea ninchat Secret',
	'organization.creation.success': 'Organisaatio luotu',
	'newRedirectionLinkModal.title': 'Jatko-ohjauksen linkki',
	'newRedirectionLinkModal.linkText.subtitle': 'Linkin teksti',
	'newRedirectionLinkModal.linkUrl.subtitle': 'Linkin URL',
	'newRedirectionLinkModal.error.linkFinnish': 'Aseta linkki suomeksi',
	'newRedirectionLinkModal.error.linkSwedish': 'Aseta linkki ruotsiksi',
	'newRedirectionLinkModal.error.linkEnglish': 'Aseta linkki englanniksi',
	'serviceInformationPageModal.title': 'Palvelun ohjesivu',
	'endOfServiceModal.title': 'Palvelun jatko-ohjaus ja lopetussivu',
	'endOfServiceModal.subtitle': 'Infoteksti',
	'organizationServices.title': 'Palvelut',
	'services.title': 'Palvelulistaus',
	'services.addButton': 'Uusi palvelu',
	'services.additionalInfo.label':
		'Lisätietoa palvelusta ja organisaatiosta ammattilaisille',
	'services.info.codesUsed': 'Käytetyt koodit',
	'services.info.noCodesAssigned': 'Ei asettetua koodilistaa.',
	'services.icons.doctor': 'Lääkäri',
	'services.icons.nurse': 'Sairaanhoitaja',
	'services.icons.mentalwellbeing': 'Terapeutti',
	'services.icons.childhealth': 'Lastenlääkäri',
	'services.icons.weight': 'Fysioterapeutti',
	'services.preQuestionnaire.title': 'Itseajanvarauksen esikysely',
	'services.preQuestionnaire.lead':
		'Aseta tästä kysymykset, mitä potilaalta kysytään itseajanvarauksen yhteydessä',
	'services.selfBookingConfirmation.title':
		'Muokkaa itseajanvarauksen vahvistussivua',
	'services.selfBookingConfirmation.lead':
		'Aseta sisältö ja uudelleenohjaus itseajanvarauksen vahvistussivulle.',
	'servicesForm.title': 'Palvelu',
	'servicesForm.error.serviceNameFinnish': 'Lisää palvelun nimi suomeksi',
	'servicesForm.error.serviceNameSwedish': 'Lisää palvelun nimi ruotsiksi',
	'servicesForm.error.serviceNameEnglish': 'Lisää palvelun nimi englanniksi',
	'servicesForm.error.icon': 'Valitse palvelun ikoni',
	'servicesForm.error.chatqueueId': 'Lisää oikea Ninchatin jonotunnus',
	'servicesForm.serviceName.subtitle': 'Palvelun nimi',
	'servicesForm.field.icon': 'Ikoni',
	'servicesForm.serviceDescription.subtitle': 'Palvelun kuvaus',
	'servicesForm.field.chatQueueId': 'Chat-jonon tunnus',
	'servicesForm.info.chatQueueId':
		'Kopioi ja liitä haluttu chat jono ID Ninchatistä ylläolevaan kenttään.',
	'servicesForm.editService.title': 'Muokkaa palvelua',
	'servicesForm.addService.title': 'Uusi palvelu',
	'expertsModal.fields.ninchatId': 'Ninchat käyttäjä-id',
	'servicesForm.chatCodes.subtitle': 'Chat asetukset',
	'servicesForm.chatCodes.label': 'Vaatii koodin',
	'servicesForm.selfAssessment.checkbox.label': 'Pakollinen oirekysely',
	'servicesForm.selfAssessmentOptional.checkbox.label':
		'Vapaaehtoinen oirekysely',
	'organization.fields.serviceModules.checkbox.htaConnector': 'Oirekysely',
	'organization.fields.serviceModules.checkbox.asyncMessaging':
		'Kiireetön viestintä',
	'areas.title': 'Palvelualue',
	'areas.addButton': 'Uusi alue tai postinumero',
	'areas.subtitle': 'Sisällytä sekä alueet että postinumerot',
	'areas.label.municipality': 'Kunta/kaupunki',
	'areas.label.postalCode': 'Postinumero',
	'CreateAppointmentModal.field.expert': 'Ammattilainen',
	'CreateAppointmentModal.field.preferredEndingTime': 'Loppuaika',
	'CreateAppointmentModal.duration.oneTimeslot.subtitle':
		'Vastaanottoajan kesto',
	'CreateAppointmentModal.generateTimeslots.text':
		'Vastaanottoajat luodaan. Sinun tulee vahvistaa varatut vastaanotot jälkikäteen avataksesi ne.',
	'CreateAppointmentModal.generateTimeslots.button': 'Avaa vastaanottoaikoja',
	'CreateAppointmentModal.timeslots.reservable': 'Varattavissa',
	'CreateAppointmentModal.timeslots.break': 'Tauko',
	'CreateAppointmentModal.timeslots.table.startingTime': 'Aloitusaika',
	'CreateAppointmentModal.timeslots.table.endTime': 'Loppuaika',
	'CreateAppointmentModal.timeslots.table.type': 'Vastaanottoajan tyyppi',
	'CreateAppointmentModal.error.timeslotsListEmpty':
		'Vastaanottoajat tulee olla luotuna ensin',
	'CreateAppointmentModal.error.availableDate': 'Päivämäärä ei voi olla tyhjä',
	'CreateAppointmentModal.error.availableAppointment':
		'Vastaanotto ei voi olla tyhjä',
	'CreateAppointmentModal.button.createOpenAppointment': 'Avaa vastaanottoajat',
	'CreateAppointmentModal.subtitle.open.new':
		'Valitse päivä ja miten aiot työskennellä',
	'CreateAppointmentModal.subtitle.open.edit': 'Muokkaa vastaanottoaikoja',
	'CreateAppointmentModal.subtitle.new.createNewTimeslotAndBookIt':
		'Luo uusi vastaanottoaika ja varaa',
	'CreateAppointmentModal.subtitle.new.bookAvailableTimeslot':
		'Varaa vapaana oleva vastaanottoaika',
	'CreateAppointmentModal.subtitle.edit': 'Muokkaa vastaanottoa',
	'CreateAppointmentModal.field.date': 'Päivä',
	'CreateAppointmentModal.field.appointment': 'Varaus',
	'CreateAppointmentModal.field.firstName': 'Etunimi',
	'CreateAppointmentModal.field.lastName': 'Sukunimi',
	'CreateAppointmentModal.button.nextAvailable':
		'Seuraava vapaa vastaanottoaika',
	'CreateAppointmentModal.bookTimeslot.noMore':
		'Ei enää vapaita vastaanottoaikoja',
	'CreateAppointmentModal.bookTimeslot.timeslotSectionTile': 'Vapaa aika',
	'CreateAppointmentModal.bookTimeslot.patientSectionTile': 'Asiakas',
	'CreateAppointmentModal.toast.1': 'Uusi varaus luotu',
	'CreateAppointmentModal.toast.2': 'Vastaanotto varattu',
	'CreateAppointmentModal.toast.3': 'Vastaanottoa muokattu',
	'CreateAppointmentModal.toast.4': 'Vastaanottoaika luotu',
	'CreateAppointmentModal.toast.5': 'Vastaanottoaikaa muokattu',
	'CreateAppointmentModal.title': 'Varaa aika',
	'CreateAppointmentModal.preQuestionnaire.title': 'Esikysely',
	'CreateAppointmentModal.error.ssn': 'Lisää oikea henkilötunnus',
	'CreateAppointmentModal.error.expert': 'Valitse ammattilainen listalta',
	'CreateAppointmentModal.error.startingDate': 'Aseta oikea alkamisajankohta',
	'CreateAppointmentModal.error.startingTime': 'Aseta oikea alkamisaika',
	'CreateAppointmentModal.error.nationality': 'Valitse kansallisuus',
	'CreateAppointmentModal.error.startingDate.past':
		'Et voi valita mennyttä päivämäärää',
	'CreateAppointmentModal.error.groupAttendee.name': 'Nimi on pakollinen',
	'CreateAppointmentModal.error.groupAttendee.email':
		'Tarkista sähköpostiosoite',
	'CreateAppointmentModal.error.groupAttendee.noExpert': 'Valitse asiantuntija',
	'CreateAppointmentModal.error.groupAttendee.noNinchatId':
		'Asiantuntijalta puuttuu ninchat id',
	'CreateAppointmentModal.error.groupHost.noNinchatId':
		'Järjestäjältä puuttuu ninchat id',
	'CreateAppointmentModal.error.groupAttendee.duplicateExpert':
		'Asintuntija on jo lisätty',
	'CreateAppointmentModal.error.groupAttendee.duplicatePatient':
		'Potilas on jo lisätty',
	'CreateAppointmentModal.error.groupAttendee.duplicateGuest':
		'Vieras on jo lisätty',
	'CreateAppointmentModal.error.email': 'Aseta oikea sähköpostiosoite',
	'CreateAppointmentModal.header.participants': 'Osallistujat',
	'CreateAppointmentModal.field.ssn': 'Henkilötunnus',
	'CreateAppointmentModal.field.nationality': 'Asiakkaan kansallisuus',
	'CreateAppointmentModal.field.startingDate': 'Päivämäärä',
	'CreateAppointmentModal.field.startingTime': 'Alkamisaika',
	'CreateAppointmentModal.field.otherDuration': 'Muu',
	'CreateAppointmentModal.field.language': 'Asiakkaan kieli',
	'CreateAppointmentModal.field.email': 'Sähköposti',
	'CreateAppointmentModal.duration.subtitle': 'Kesto (minuuteissa)',
	'CreateAppointmentModal.field.1on1': 'Yksilövastaanotto',
	'CreateAppointmentModal.field.group': 'Ryhmävastaanotto',
	'CreateAppointmentModal.field.patient': 'Potilas',
	'CreateAppointmentModal.field.groupattendee.expert': 'Asiantuntija',
	'CreateAppointmentModal.field.guest': 'Vieras',
	'CreateAppointmentModal.label.host': 'Järjestäjä',
	'CreateAppointmentModal.field.name': 'Nimi',
	'CreateAppointmentModal.field.guest.language': 'Osallistujan kieli',
	'CreateAppointmentModal.field.guest.language.finnish': 'Suomi',
	'CreateAppointmentModal.field.guest.language.english': 'Englanti',
	'CreateAppointmentModal.field.guest.language.swedish': 'Ruotsi',
	'CreateAppointmentModal.header.addaparticipant': 'Lisää osallistuja',
	'CreateAppointmentModal.button.addparticipant': 'Lisää osallistuja',
	'CreateAppointmentModal.reminderCheckbox':
		'Lähetä potilaalle varausvahvistus sähköpostitse',
	'createRemoteAppointment.emailReminder.lead':
		'Kun ajanvaraus on vahvistunut, ajanvaraaja ja asiakas saavat vahvistusviestin sähköpostitse.',
	'CreateAppointmentModal.button.create': 'Rekisteröi ajanvaraus',
	'CreateAppointmentModal.button.update': 'Päivitä ajanvaraus',
	'CreateAppointmentModal.openAppointment.title': 'Avaa varattava aika',
	'CreateAppointmentModal.openAppointment.lead':
		'Kun olet avannut varattavan ajan, potilaat voivat nähdä ja varata niitä.',
	'CreateAppointmentModal.groupvideo.checkbox': 'Ryhmävideoaika',
	'CreateAppointmentModal.guest.name': 'Vieraan nimi',
	'CreateAppointmentModal.guest.email': 'Vieraan sähköposti',
	'bookings.title': 'Ajanvaraukset',
	'bookings.subtitle': 'Kaikki ajanvaraukset',
	'bookings.lead.part1':
		'Avaa varattavia aikoja, varaa aikoja sekä hallinnoi olemassa olevia ajanvarauksia.',
	'bookings.lead.part2':
		'Huomiothan että kaikki ajanvaraukset täytyy myös tarpeen vaatiessa tehdä potilastietojärjestelmään erikseen.',
	'bookings.table.patient.title': 'Asiakas (SSN)',
	'bookings.table.expert.title': 'Asiantuntija',
	'bookings.table.dateTime.title': 'Päivämäärä, Kellonaika',
	'bookings.table.actions.title': 'Toiminnot',
	'bookings.table.specialization.title': 'Erikoisala',
	'bookings.table.status.selfBooked': 'Varattu',
	'bookings.table.status.booked': 'Varattu',
	'bookings.table.status.cancelled': 'Peruutettu',
	'bookings.table.status.open': 'Avattu',
	'bookings.table.status.title': 'Status',
	'bookings.button.create': 'Varaa aika',
	'bookings.button.create.groupvideo': 'Varaa ryhmävideo aika',
	'bookings.button.book': 'Varaa',
	'bookings.button.preQuestionnaire': 'Esikyselyn vastaus',
	'bookings.button.openAppointment': 'Avaa varattava aika',
	'bookings.filters.experts.label': 'Näytä vain tietyn ammattilaisen ajat',
	'bookings.filters.specialization.label': 'Näytä vain tietyt erikoisalat',
	'bookings.filters.openTimes.label': 'Näytä avoimet ajat',
	'bookings.filters.bookedTimes.label': 'Näytät varatut ajat',
	'bookings.filters.onlyFuture': 'Näytät tulevat',
	'bookings.filters.startDate': 'Alkupäivä',
	'bookings.filters.endDate': 'Loppupäivä',
	'bookings.filters.matchStartDate': 'Valitse alkupäivämäärä',
	'bookings.table.group.multiple.patients': 'Useita potilaita',
	'bookings.deleteConfirmation':
		'Oletko varma että haluat poistaa ajanvarauksen?',
	'experts.title': 'Asiantuntijat',
	'experts.lead':
		'Luo ja hallitse järjestelmään luotuja asiantuntijoita. Huomiothan, että asiantuntijaa luodessa, hänelle luodaan myös henkilökohtainen chat-jono johon ajanvaraukselliset vastaanotot ohjautuvat. ',
	'experts.button.add': 'Lisää asiantuntija',
	'experts.table.expertName': 'Nimi',
	'experts.table.specializations': 'Erikoisala',
	'experts.table.chatQueue': 'Jonon tunnus',
	'expert.table.ninhatId': 'Ninchat käyttäjä',
	'experts.table.status': 'Tila',
	'experts.table.actions': 'Toiminnot',
	'experts.deleteConfirmation':
		'Oletko varma, että haluat poistaa asiantuntijan?',
	'expertsModal.errors.firstName': 'Etunimi -kohta ei voi olla tyhjä',
	'expertsModal.errors.lastName': 'Sukunimi -kohta ei voi olla tyhjä',
	'expertsModal.errors.specializations':
		'Lisää ainakin yksi erikoisala/titteli',
	'expertsModal.errors.chatQueueId': 'Lisää oikea Ninchatin jonotunnus',
	'expertsModal.errors.email': 'Aseta oikea sähköpostiosoite',
	'expertsModal.title.edit': 'Muokkaa asiantuntijaa',
	'expertsModal.title.create': 'Lisää asiantuntija',
	'expertsModal.fields.firstName': 'Etunimi',
	'expertsModal.fields.lastName': 'Sukunimi',
	'expertsModal.fields.specializations': 'Erikoisala/titteli',
	'expertsModal.fields.generateChatQueue':
		'Lisää käyttäjä automaattisesti Ninchatiin sekä luo henkilökohtainen jono',
	'expertsModal.fields.chatQueueId': 'Chat-jono',
	'expertsModal.fields.title.language': 'Kieli',
	'expertsModal.fields.title.communicationLanguage': 'Kieli',
	'expertsModal.fields.language.fi': 'Suomi',
	'expertsModal.fields.language.en': 'Englanti',
	'expertsModal.fields.language.sv': 'Ruotsi',
	'expertsModal.toast.attachedNinchaUser':
		'Käyttäjälle on jo Ninchat-tili, nyt käyttäjän tili lisättiin molempiin järjestelmiin.',
	'registration.title': 'Luo salasana',
	'registration.errors.samePassword': 'Salasanojen täytyy täsmätä',
	'registration.errors.validation':
		'Salasanassa täytyy olla ainakin 12 merkkiä, sisältäen numeroita sekä isoja ja pieniä kirjaimia',
	'registration.errors.general':
		'Salasanaa ei voi asettaa, ole yhteydessä tukeen tai tilin haltijaan.',
	'registration.fields.password': 'Salasana',
	'registration.fields.passwordConfirm': 'Vahvista salasana',
	'usersModal.actions.title': 'Toiminnot',
	'usersModal.actions.status.title': 'Status',
	'usersModal.actions.actions.title': 'Toiminnot',
	'usersModal.actions.buttons.resetPassword': 'Resetoi salasana',
	'usersModal.actions.buttons.lockUser': 'Lukitse käyttäjä',
	'usersModal.actions.buttons.unlockUser': 'Avaa käyttäjä',
	'usersModal.actions.buttons.logoutUser': 'Kirjaa käyttäjä ulos',
	'usersModal.actions.buttons.actionLog': 'Lataa käyttäjäloki',
	'usersModal.actions.error': 'Virhe, toiminto epäonnistui.',
	'usersModal.actions.success': 'Toiminto onnistui.',
	'usersModal.validation.name': 'Aseta oikea käyttäjän nimi',
	'usersModal.validation.lastName': 'Aseta oikea käyttäjän etunimi',
	'usersModal.validation.firstName': 'Aseta oikea käyttäjän sukunimi',

	'usersModal.validation.email': 'Aseta oikea sähköpostiosoite',
	'usersModal.validation.organization': 'Valitse organisaatio',
	'usersModal.edit.title': 'Muokkaa käyttäjää',
	'usersModal.add.title': 'Lisää käyttäjä',
	'usersModal.fields.name': 'Nimi',
	'usersModal.fields.firstName': 'Etunimi',
	'usersModal.fields.lastName': 'Sukunimi',
	'usersModal.fields.email': 'Käyttäjän sähköposti',
	'usersModal.fields.organization': 'Organisaatio',
	'usersModal.fields.title.access': 'Oikeus',
	'usersModal.fields.presets': 'Ennakkoasetukset',
	'usersModal.fields.editingRights.title': 'Muokkausoikeudet',
	'usersModal.fields.viewingRights.title': 'Katseluoikeudet',
	'usersModal.fields.chatCode': 'Chat-koodit',
	'usersModal.fields.experts': 'Asiantuntijat',
	'usersModal.fields.bookings': 'Ajanvaraukset',
	'usersModal.fields.users': 'Käyttäjät',
	'usersModal.fields.serviceSettings': 'Palvelun asetukset',
	'usersModal.buttons.add': 'Lisää käyttäjä',
	'usersModal.buttons.edit': 'Muokkaa käyttäjää',
	'usersModal.confirmation.lockUser':
		'Oletko varma, että haluat lukita käyttäjän?',
	'usersModal.confirmation.unlockUser':
		'Oletko varma, että haluat avaa käyttäjän?',
	'usersModal.confirmation.logoutUser':
		'Oletko varma, että haluat kirjata ulos tämän käyttäjän?',
	'usersModal.confirmation.resetPassword':
		'Oletko varma, että haluat resetoida tämän käyttäjän salasanan?',
	'users.status.noPasswordSet': 'Salasanaa ei ole asetettu',
	'users.status.notActive': 'Käyttäjä ei ole aktiivinen',
	'users.status.active': 'Käyttäjä on aktiivinen',
	'users.presets.booker': 'Ajanvaraaja',
	'users.presets.organizationAdmin': 'Organisaation pääkäyttäjä',
	'users.presets.operationalAdmin': 'Toimintojen pääkäyttäjä',
	'users.presets.custom': 'Mukautettu',
	'users.toast.createUser':
		'Uusi käyttäjä luotu, käyttäjä saa nyt sähköpostin, jonka kautta hän voi luoda itselleen salasanan. Salasanan luonnin linkki on voimassa 24 tuntia.',
	'users.toast.resetPassword':
		'Toiminto onnistui, käyttäjä on saanut sähköpostin, jonka kautta voi asettaa salasanan, salasanan asetuksen linkki on voimassa 30 minuuttia.',
	'usersTable.confirmation.delete':
		'Oletko varma, että haluat poistaa käyttäjän?',
	'usersTable.global.title': 'Yleinen käyttäjähallinta',
	'usersTable.organization.title': 'Pääkäyttäjät',
	'usersTable.lead':
		'Hallitse käyttäjiä. Täällä voit resetoida salasanoja, kirjata käyttäjiä ulos, nähdä käyttölokit sekä hallinnoida käyttäjien oikeuksia ja luoda uusia käyttäjiä.',
	'usersTable.buttons.add': 'Lisää käyttäjä',
	'usersTable.buttons.actions': 'Toiminnot',
	'usersTable.columns.name': 'Nimi',
	'usersTable.columns.organization': 'Organisaatio',
	'usersTable.columns.role': 'Rooli',
	'usersTable.columns.lastLogin': 'Viimeisin kirjautuminen',
	'usersTable.columns.status': 'Status',
	'usersTable.columns.actions': 'Toiminnot',
	'questionnaireForm.title': 'Esikyselyn lomake',
	'questionnaireForm.lead': 'Alta voit löytää potilaan vastaukset esikyselyyn.',
	'questionnaireForm.form.title': 'Esikyselyn lomake',
	'questionnaireForm.form.setQuestion': 'Aseta kysymys',
	'questionnaireForm.form.mandatory': 'Pakollinen',
	'questionnaireForm.form.addQuestion': 'Lisää kysymys',
	'selfBookingConfirmationModal.title': 'Itseajanvarauksen vahvistussivu',
	'selfBookingConfirmationModal.additionalTest.subtitle':
		'Vahvistussivun ohjeteksti',
	'selfBookingConfirmationModal.redirectionButton.subtitle':
		'Uudelleenohjauksen painikkeen teksti',
	'selfBookingConfirmationModal.redirectionLink.subtitle':
		'Uudelleenohjauksen linkki',

	'datadeletion.fields.header': 'Henkilötietojen säilytysajat',
	'datadeletion.fields.patientData': 'Potilaat',
	'datadeletion.fields.adminData': 'Hallinnointikäyttäjät',
	'datadeletion.fields.expertData': 'Ammattilaiskäyttäjät',
	'datadeletion.specifier.day': 'Pv',
	'datadeletion.specifier.month': 'Kk',
	'userManagement.title': 'Käyttäjähallinta',
	'userManagement.buttons.admins': 'Pääkäyttäjät',
	'userManagement.buttons.experts': 'Ammattilaiset',
	'userManagement.button.back': 'Palaa käyttäjähallintaan',
	'userManagement.button.addEditExpert': 'Lisää/muokkaa ammattilaista',
	'userManagement.button.addEditAdmin': 'Lisää/muokkaa pääkäyttäjää',
	'surveyItem.answerSurvey': 'Vastaa kyselyyn',
	'surveyItem.nameMissing': 'Kyselyn nimi puuttuu',
	'surveyItem.selectSurvey': 'Valitse lisättävä kysely',
	'surveyItem.addSurvey': 'Lisää kysely',
	'survey.Description':
		'Kun rekisteröit vastaanoton, voit valita ennalta lähetettäviä kyselyjä potilaalle lähetettäväksi. Kyselyt voivat sisältää esimerkiksi standaroituja lääketieteellisiä kyselyjä, voit myös luoda omia kyselyjä.',
	'survey.Title': 'Kyselyt',
	'organizationSelectionModal.button.login': 'Kirjaudu sisään',
	'organizationSelectionModal.headline': 'Valitse organisaatio johon kirjaudut',
	'profileMenu.options.myInformation': 'Tietoni',
	'profileMenu.options.changeOrganization': 'Vaihda organisaatiota',
	'newOrganization.title': 'Uusi organisaatio',
	'appointment.title.open.new': 'Avaa varattavia vastaanottoaikoja',
	'appointment.title.open.edit': 'Muokkaa varattavia vastaanottoaikoja',
	'appointment.title.new': 'Varaa vastaanottoaika',
	'appointment.title.edit': 'Muokkaa vastaanottoaikaa',
	'appointment.checkbox.bookAvailableTimeslot': 'Varaa vapaa vastaanottoaika',
	'appointment.checkbox.groupAppointment': 'Luo uusi ryhmävastaanotto',
	'appointment.checkbox.createNewTimeslotAndBookIt':
		'Luo uusi vastaanottoaika ja varaa',
	'schedule.cancel.title': 'Peruuta vastaanotto?',
	'schedule.cancel.body':
		'Haluatko varmasti peruuttaa vastaanoton? Tämä lähettää tiedon osallistujille.',
	'schedule.cancel.buttons.cancel': 'Peru ajanvaraus',
	'schedule.cancel.buttons.close': 'Sulje ilman perumista',

	'emailTemplates.title': 'Sähköpostipohjat',
	'emailTemplates.toast.saved': 'Pohja tallennettu',
	'emailTemplates.toast.resetted': 'Oletusohja palautettu',
	'emailTemplates.confirmModal.headline': 'Palautetaanko oletuspohjat?',
	'emailTemplates.confirmModal.text':
		'Oletko varma, että haluat palauttaa oletuspohjat? Valintaa ei voi perua',
	'emailTemplates.confirmModal.okButton': 'Palauta oletuspohjat',
	'emailTemplates.back': 'Takaisin sähköpostipohjiin',
	'emailTemplates.editor.title': 'Sähköposti pohja',
	'emailTemplates.editor.default': '(OLETUS)',
	'emailTemplates.editor.custom': '(MUOKATTU)',
	'emailTemplates.editor.content': 'Sisältö',
	'emailTemplates.editor.showTagList': 'Näytä merkintöjen lista',
	'emailTemplates.editor.explanation':
		'Käytä tageja kuten expert_nimi tiettyyn tarkoitukseen oleviin viestipohjiin, jotta  oikea sisältä välittyy vastaanottajalle.',
	'emailTemplates.editor.tag': 'Merkintä',
	'emailTemplates.editor.description': 'Kuvaus',
	'emailTemplates.editor.actions': 'Toiminnot',
	'emailTemplates.editor.copyTag': 'Kopioi merkintä',
	'emailTemplates.editor.emailRichTextEditor': 'Sähköposti',
	'emailTemplates.editor.resetButton': 'Palauta oletuspohjiksi',
	'emailTemplates.editor.previewtButton': 'Esikatselu',
	'emailTemplates.editor.saveButton': 'Tallenna pohja',
	'emailTemplates.editor.reset.toast':
		'Sähköpostipohjan palautus oletuksiin epäonnistui',
	'emailTemplates.editor.sender.name': 'Lähettäjän nimi',
	'emailTemplates.editor.sender.address': 'Lähettäjän sähköposti',
	'emailTemplates.editor.email.title': 'Sähköpostin otsikko',

	'leaveChecker.title': 'Tallentamattomat muutokset',
	'leaveChecker.description':
		'Sinulla on tallentamattomia muutoksia, oletko varma että haluat poistua sivulta?',
	'leaveChecker.submit': 'Poistu',

	'asyncMessaging.categories.title': 'Viestikategoriat',
	'asyncMessaging.categories.lead':
		'Luo viestikategorioita ja lisää ammattilaisia niihin',
	'asyncMessaging.categories.create': 'Luo kategoria',
	'asyncMessaging.subcategories': 'alakategoriaa',
	'asyncMessaging.newCategory.title': 'Kategorian nimi',
	'asyncMessaging.button.disableCategory': 'Poista kategoria käytöstä',
	'asyncMessaging.createCategory.form.subtitle.details': 'Tiedot',
	'asyncMessaging.createCategory.form.fields.categoryName': 'Kategorian nimi',
	'asyncMessaging.createCategory.form.fields.open': 'Avaa',
	'asyncMessaging.createCategory.form.fields.open.more':
		'Kategoria ei vaadi viestikoodia',
	'asyncMessaging.createCategory.form.fields.restricted': 'Rajattu',
	'asyncMessaging.createCategory.form.fields.restricted.more':
		'Viestikoodi vaaditaan tähän kategoriaan',
	'asyncMessaging.subcategories.create': 'Lisää alakategoria',
	'asyncMessaging.createCategory.form.validation.categoryName':
		'Lisää oikea nimi {{lang}}',
	'asyncMessaging.sendingError':
		'Virhe tallennuksessa. Yritä myöhemmin uudestaan.',
	'asyncMessaging.success': 'Tallennus onnistui!',
	'asyncMessaging.newSubcategory.title': 'Nimi alakategoriassa',
	'asyncMessaging.button.disableSubcategory': 'Inaktivoi alakategoria',
	'asyncMessaging.disableCategory.confirmation':
		'Oletko varma, että haluat poistaa kategorian käytöstä?',
	'asyncMessaging.disableSubcategory.confirmation':
		'Oletko varma, että haluat poistaa alakategorian käytöstä?',
	'asyncMessaging.customizableContent.title': 'Kiireetön viestintä',
	'asyncMessaging.customizableContent.lead': 'Muokattava sisältö',
	'asyncMessaging.subcategories.title': 'Alakategoriat',
	'asyncMessaging.customizableContent.messageFrontPage.title':
		'Viestien etusivu',
	'asyncMessaging.customizableContent.messageFrontPage.description':
		'Sisältöteksti',
	'asyncMessaging.customizableContent.newMessageBanner.title':
		'Uuden viestin näkymä',
	'asyncMessaging.customizableContent.newMessagebanner.description':
		'Palvelulupaus',
	'asyncMessaging.experts.title': 'Ammattilainen',
	'asyncMessaging.experts.button.addExperts': 'Lisää ammattilaisia',
	'asyncMessaging.newExperts.title': 'Lisää ammattilaisia',
	'asyncMessaging.newExperts.searchBox.label':
		'Hae erikoisalaa tai ammattilaista',
	'asyncMessaging.newExperts.filters.all': 'Kaikki',
	'asyncMessaging.newExperts.filters.specializations': 'Erikoisalat',
	'asyncMessaging.newExperts.filters.experts': 'Ammattilaiset',
	'asyncMessaging.newExperts.searchAutocomplete.experts.label': 'Ammattilaiset',
	'asyncMessaging.newExperts.searchAutocomplete.noExperts':
		'Ei vastaavia ammattilaisia',
	'asyncMessaging.subcategory.search.label':
		'Etsi ammattilaisia tästä alakategoriasta',
	'asyncMessaging.newExperts.specialization.filter.label':
		'Suodata erikoisaloittain',
	'asyncMessaging.newExperts.specialization.filter.all': 'Kaikki erikoisalat',
	'asyncMessaging.newExperts.addFilters':
		'Lisää hakutermi tai suodata tuloksia',
	'asyncMessaging.newExperts.selectAll': 'Valitse kaikki',
	'asyncMessaging.newExperts.confirm': 'Hyväksy valinnat',
	'asyncMessaging.newExperts.selectedExperts': 'Valitut ammattilaiset',
	'asyncMessaging.newExperts.noSelectedExperts':
		'Yhtään ammattilaista ei ole valittu',
	'asyncMessaging.newExperts.add': 'Lisää {{number}} ammattilaista',
};

import { ErrorCodes } from 'common/typings/error-codes';
import { config as appConfig } from 'adminApplication/config/config';
import LoginService from 'common/services/login';
import { CommonApiService } from 'common/services/config';

class AdminApiService extends CommonApiService {
	public type = 'admin';

	constructor() {
		super();

		this.request.interceptors.response.use(
			(response: any) => {
				if (response?.data?.statusCode === 101) {
					// for below urls not trigger logout actions
					if (
						LoginService.isAuthenticated() &&
						window.location.pathname !== appConfig.paths.home &&
						window.location.pathname !== appConfig.paths.login &&
						window.location.pathname !== appConfig.paths.register &&
						window.location.pathname !== appConfig.paths.changePassword
					) {
						LoginService.logOut();
						window.location.href = `${appConfig.paths.login}?unauthorized=true`;
						return Promise.reject(new Error('unauthorized'));
					}
				}
				return response;
			},
			(error: any) => {
				if (error && error.response) {
					const { status } = error.response;
					if (
						status === ErrorCodes.UNAUTHORIZED ||
						status === ErrorCodes.ACCESS_DENIED ||
						status === ErrorCodes.NOT_FOUND
					) {
						// for example logout action here
					}
				}
				return Promise.reject(error);
			},
		);
	}
}

export { AdminApiService };
export default new AdminApiService();

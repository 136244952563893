import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import i18next from 'i18next';
import { ThemeContext } from 'styled-components';

import {
	Alert,
	Icon,
	Subtitle,
	Text,
} from '@terveystalo/design-system-react-components';

import { convertDate } from 'common/helpers/dates';

import AppointmentHistoryService from 'app/services/appointmentHistory/index';
import { Language } from 'common/lang/lang';
import AnalyticsService from 'common/services/analytics/analytics';
import AppointmentHistoryChat from './components/appointmentHistoryChat';

import {
	AppoinmentHistoryDetailsWrapper,
	ContentSectionWrapper,
	CustomTextWrapper,
	DateSubtitleWrapper,
	DocumentIconWrapper,
	SpinnerWrapper,
} from './appoinmentHistoryDetails.styles';

export type AppointmentHistoryItemDocument = {
	id: number;
	filename: string;
};

export interface AppointmentHistoryItemType {
	id: string;
	created_at: string;
	expert_name: string;
	files?: AppointmentHistoryItemDocument[];
}

export type AppointmentHistory = AppointmentHistoryItemType[];

export type Message = {
	message_time: number;
	message_type: string;
	message_username: string;
	message_user_id: string;
	message_text: string;
};

export type File = {
	id: string;
	filename: string;
};

export interface AppointmentChat {
	messages: Message[];
	files: File[];
	members: {
		[key: string]: {
			[key: string]: string;
		};
	};
}

const AppoinmentHistoryDetails = () => {
	const { id } = useParams<{ id: string }>();
	const theme = useContext(ThemeContext);
	const { t } = useTranslation();
	const [date, setDate] = useState('');
	const lang = i18next.language;

	const [error, setError] = useState('');
	const [fileId, setFileId] = useState('');

	const {
		data: appointmentHistory,
		isLoading: isLoadingHistory,
		error: historyLoadingError,
		isError: isHistoryLoadingError,
		remove: removeHistoryFromCache,
		refetch: fetchAppointmentHistory,
	} = useQuery(
		'appointmentHistory',
		() => AppointmentHistoryService.getAppointmentHistory(),
		{ initialData: [] as AppointmentHistory, enabled: false },
	);

	const {
		data: appointmentChat,
		isLoading: isLoadingChat,
		error: chatLoadingError,
		isError: isChatLoadingError,
		remove: removeChatFromCache,
		refetch: fetchAppointmentChat,
	} = useQuery(
		'appointmentChat',
		() => AppointmentHistoryService.getAppointmentHistoryChat(id),
		{ initialData: {} as AppointmentChat, enabled: false },
	);

	const {
		data: fileDownload,
		error: fileLoadingError,
		isError: isFileLoadingError,
		refetch: fetchFile,
	} = useQuery(
		['fileDownload'],
		() => AppointmentHistoryService.getAppointmentHistoryFile(fileId),
		{ enabled: false },
	);

	const isAppointmentChat = !!appointmentChat;
	const isAppointmentHistory = !!appointmentHistory;
	const isError = !!error;
	const hasFileId = !!fileId;
	const isFileDownload = !!fileDownload;

	const currentLanguage = i18next.language as Language;

	useEffect(() => {
		fetchAppointmentChat();
		fetchAppointmentHistory();
		return () => {
			removeHistoryFromCache();
			removeChatFromCache();
		};
	}, []);

	useEffect(() => {
		document.title = t('pageTitles.appointmentHistoryDetails');
	}, [lang]);

	useEffect(() => {
		document.title = t('pageTitles.appointmentHistoryDetails');
		AnalyticsService.sendPageView(t('pageTitles.appointmentHistoryDetails'));
	}, []);

	useEffect(() => {
		if (!isAppointmentHistory) return;
		const currentHistoryAppointment = appointmentHistory.find(
			(app) => app.id === id,
		);
		const isCurrentHistoryAppointment = !!currentHistoryAppointment;
		if (!isCurrentHistoryAppointment) return;
		const dateObject = new Date(currentHistoryAppointment.created_at);
		const dateString = convertDate(
			dateObject.getTime(),
			undefined,
			currentLanguage,
		);
		setDate(dateString);
	}, [appointmentHistory, isAppointmentHistory, id, currentLanguage]);

	useEffect(() => {
		if (isHistoryLoadingError || isChatLoadingError || isFileLoadingError) {
			setError(t('appointmentHistoryDetails.error'));
		}
	}, [chatLoadingError, historyLoadingError, fileLoadingError]);

	useEffect(() => {
		if (!isError) return undefined;
		const ERROR_TIMEOUT = 5000;
		const timer = setTimeout(() => {
			setError('');
		}, ERROR_TIMEOUT);
		return () => {
			clearTimeout(timer);
		};
	}, [error]);

	useEffect(() => {
		if (hasFileId) {
			fetchFile();
			setFileId('');
		}
	}, [fileId, hasFileId, fetchFile]);

	useEffect(() => {
		if (!isFileDownload) return;
		if (fileDownload.file_url) {
			window.open(fileDownload.file_url, '_blank');
		}
	}, [fileDownload, isFileDownload]);

	return (
		<AppoinmentHistoryDetailsWrapper>
			{isError && (
				<Alert icon="AlertOctagon" variant="error">
					{error}
				</Alert>
			)}
			{(isLoadingChat || isLoadingHistory) && (
				<SpinnerWrapper>
					<br />
					<br />
					<Icon name="Spinner" color={theme.colors.primary} />
				</SpinnerWrapper>
			)}
			{!isLoadingChat && !isLoadingHistory && (
				<>
					<DateSubtitleWrapper>
						<Subtitle size="s" color={theme.colors.primary}>
							{date}
						</Subtitle>
					</DateSubtitleWrapper>
					{isAppointmentChat &&
						appointmentChat.files?.map((document) => (
							<ContentSectionWrapper onClick={() => setFileId(document.id)}>
								<DocumentIconWrapper theme={theme}>
									<Icon name="Document" color={theme.colors.primary} />
								</DocumentIconWrapper>
								<div>
									<CustomTextWrapper>
										<Text size="m">{document.filename}</Text>
									</CustomTextWrapper>
								</div>
							</ContentSectionWrapper>
						))}
					<AppointmentHistoryChat />
				</>
			)}
		</AppoinmentHistoryDetailsWrapper>
	);
};

export default AppoinmentHistoryDetails;

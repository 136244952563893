import React, { useContext, useEffect, useState } from 'react';
import { ThemeContext } from 'styled-components';
import { useMutation, useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { useHistory } from 'react-router-dom';

import { Subtitle, Text } from '@terveystalo/design-system-react-components';

import { CustomSelect } from 'common/components/forms/select/FormikSelectField';

import { getConfigAsync } from 'app/store/configuration/slices/configuration.slice';
import { CustomButton } from 'app/styles/components/button/button';
import AnalyticsService from 'common/services/analytics/analytics';
import { config } from 'app/config/config';

import OrganizationService, { OrganizationsInfo } from 'app/services/contact';

import { ContentSection } from '../home.styles';

export const ChangeMyLocationPage = () => {
	const { t } = useTranslation();
	const theme = useContext(ThemeContext);
	const dispatch = useDispatch();
	const [selectedOrganization, setSelectedOrganization] = useState<
		string | null
	>(null);

	const lang = i18next.language;
	const history = useHistory();

	const { mutate: selectOrganization } = useMutation(
		(organizationId: string) =>
			OrganizationService.selectOrganization(organizationId),
		{
			onSuccess: async () => {
				AnalyticsService.sendCustomEvent(
					'ChangeMyLocation',
					'remote appointment',
					'change location',
					'',
				);
				await dispatch(getConfigAsync({}));
			},
		},
	);

	const { data: orgInfoData, refetch: getOrganizationsInfo } = useQuery<any>(
		'organizationsInfo',
		() => OrganizationService.getOrganizations(),
		{
			enabled: false,
		},
	);

	useEffect(() => {
		document.title = t('pageTitles.changeMyLocation');
	}, [lang]);

	useEffect(() => {
		document.title = t('pageTitles.changeMyLocation');
		AnalyticsService.sendPageView(t('pageTitles.changeMyLocation'));

		getOrganizationsInfo();
	}, []);

	const handleChangeArea = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSelectedOrganization(event.target.value);
	};

	const handleSubmitChangeLocation = () => {
		if (selectedOrganization) {
			selectOrganization(selectedOrganization);
			history.push(config.paths.landing);
		}
	};

	return (
		<div>
			<Subtitle color={theme.colors.zodiacBlack} size="m">
				{t('changeMyLocation.title')}
			</Subtitle>
			<ContentSection>
				<Text size="m">{t('changeMyLocation.lead')}</Text>
			</ContentSection>
			<br />
			<ContentSection>
				<div>
					<CustomSelect
						id="selectArea"
						label={t('changeMyLocation.selectArea')}
						onChange={handleChangeArea}
						value={selectedOrganization || orgInfoData.selectedOrganisation}
						options={[
							...(orgInfoData?.organisations?.map(
								(org: OrganizationsInfo['organisations'][0]) => ({
									label: org.name,
									value: org.id,
								}),
							) || []),
						]}
					/>
				</div>
				<CustomButton
					disabled={
						!selectedOrganization ||
						selectedOrganization === orgInfoData.selectedOrganisation
					}
					onClick={handleSubmitChangeLocation}
				>
					{t('changeMyLocation.button')}
				</CustomButton>
				<br />
				<br />
				<Text size="m">{t('changeMyLocation.subText')}</Text>
			</ContentSection>
		</div>
	);
};

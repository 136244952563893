import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from 'styled-components';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import i18next from 'i18next';

import {
	Alert,
	Text,
	Icon,
	Subtitle,
} from '@terveystalo/design-system-react-components';

import { CustomButton } from 'app/styles/components/button/button';

import { setContactAsync } from 'app/store/contact/slices/contact.slice';

import { config } from 'app/config/config';

import { AppContext } from 'app/context/appContext';
import { ButtonsWrapper } from 'app/components/contactForm/contactForm.styles';
import { selectConfig } from 'app/store/configuration/slices/configuration.slice';
import AnalyticsService from 'common/services/analytics/analytics';

import { ContentSection } from '../home.styles';

import { LinkWrapper, TermsTextWrapper, TermsLink } from './terms.styles';

export const TermsSubPage = () => {
	const { t } = useTranslation();
	const theme = useContext(ThemeContext);
	const history = useHistory();
	const dispatch = useDispatch();
	const { setUserDetails, userDetails } = useContext(AppContext);
	const configData = useSelector(selectConfig);

	const [savingError, setSavingError] = useState<any>(false);
	const [savingInProgress, setSavingInProgress] = useState(false);

	const lang: string = i18next.language;

	const location = useLocation<any>();
	const standalone = location?.state?.standalone;

	useEffect(() => {
		document.title = t('pageTitles.terms');
	}, [lang]);

	useEffect(() => {
		document.title = t('pageTitles.terms');
		AnalyticsService.sendPageView(t('pageTitles.terms'));
	}, []);

	const acceptTermsHandler = async () => {
		try {
			AnalyticsService.sendCustomEvent(
				'onBoardingAcceptTerms',
				'on boarding',
				'accept terms and conditions',
				'',
			);
			setSavingError(false);
			setSavingInProgress(true);
			const values = { termsAccepted: true };
			const response = await dispatch(setContactAsync(values));

			if (response.type === 'config/setConfig/rejected') {
				setSavingError(t('common.savingError'));
				setSavingInProgress(false);
			} else {
				setUserDetails({ ...userDetails, ...values });
				history.push(config.paths.welcome);
			}
		} catch (error) {
			setSavingError(t('common.savingError'));
			setSavingInProgress(false);
		}
	};

	const handleCookiePolicyLinkClick = () => {
		if (!standalone) {
			AnalyticsService.sendCustomEvent(
				'onBoardingClickToCookiePolicy',
				'on boarding',
				`click to ${configData.data.cookiePolicyLink[lang].url}`,
				'',
			);
		}
	};

	const handleTermsLinkClick = () => {
		if (!standalone) {
			AnalyticsService.sendCustomEvent(
				'onBoardingClickToTermsPage',
				'on boarding',
				`click to ${configData.data.termsAndConditionsMoreLink[lang].url}`,
				'',
			);
		}
	};

	return (
		<div>
			<Subtitle size="m" color={theme.colors.zodiacBlack} as="h3">
				{t('terms.headline')}
			</Subtitle>
			<ContentSection>
				<Text size="m">{t('terms.subtitle')}</Text>
				<br />
				<br />
				<TermsTextWrapper>
					<Text
						size="m"
						dangerouslySetInnerHTML={{
							__html: configData.data.termsAndConditionsText[lang],
						}}
					/>
				</TermsTextWrapper>
				<LinkWrapper>
					{configData.data?.termsAndConditionsMoreLink[lang]?.text && (
						<TermsLink
							href={configData.data.termsAndConditionsMoreLink[lang].url}
							target="_blank"
							rel="noreferrer"
							onClick={handleTermsLinkClick}
						>
							{configData.data.termsAndConditionsMoreLink[lang].text}
							<Icon name="ExternalLink" color={theme.colors.primary} />
						</TermsLink>
					)}
					<br />
					{configData.data?.cookiePolicyLink[lang]?.text && (
						<TermsLink
							href={configData.data.cookiePolicyLink[lang].url}
							target="_blank"
							rel="noreferrer"
							onClick={handleCookiePolicyLinkClick}
						>
							{configData.data.cookiePolicyLink[lang].text}
							<Icon name="ExternalLink" color={theme.colors.primary} />
						</TermsLink>
					)}
				</LinkWrapper>
				<br />
				<br />
				<br />
				{savingError && !savingInProgress && (
					<Alert icon="AlertOctagon" variant="error">
						{t('common.savingError')}
					</Alert>
				)}
				{!standalone && (
					<ButtonsWrapper>
						<CustomButton
							{...(savingInProgress ? { icon: 'Spinner' } : {})}
							onClick={acceptTermsHandler}
						>
							{t('terms.acceptButton')}
						</CustomButton>
					</ButtonsWrapper>
				)}
			</ContentSection>
		</div>
	);
};

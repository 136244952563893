import React, { useContext, useRef, useEffect } from 'react';
import { ThemeContext } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import i18next from 'i18next';

import { Icon } from '@terveystalo/design-system-react-components';

import { usePrevious } from 'common/helpers/usePrevious';
import { Language } from 'common/lang/lang';

import { AppContext } from 'app/context/appContext';
import WebAuthService from 'app/services/webAuth/webAuth';
import { config } from 'app/config/config';

import { selectConfig } from 'app/store/configuration/slices/configuration.slice';
import {
	HeaderWrapper,
	HomeWrapper,
	HeaderText,
	InnerSection,
	RightWrapper,
	LeftWrapper,
	LogoWrapper,
	LandingWrapper,
	ProfileWrapper,
} from './header.styles';

export const Header = () => {
	const theme = useContext(ThemeContext);
	const { t } = useTranslation();
	const configData = useSelector(selectConfig);
	const { setMainMenuOpen, mainMenuOpen, setProfileMenuOpen, profileMenuOpen } =
		useContext(AppContext);
	const menuRef = useRef<any>(null);
	const profileMenuRef = useRef<any>(null);
	const isAuthenticated = WebAuthService.isAuthenticated();
	const history = useHistory();
	const lang = i18next.language;

	const prevMenuState = usePrevious(mainMenuOpen);
	const prevProfileMenuState = usePrevious(profileMenuOpen);

	const handleMenuOpen = (ev: React.MouseEvent) => {
		ev.preventDefault();
		ev.stopPropagation();
		setMainMenuOpen(true);
	};

	useEffect(() => {
		if (!mainMenuOpen && prevMenuState) {
			menuRef.current.focus();
		}
	}, [mainMenuOpen, prevMenuState]);

	useEffect(() => {
		if (!profileMenuOpen && prevProfileMenuState) {
			profileMenuRef.current.focus();
		}
	}, [profileMenuOpen, prevProfileMenuState]);

	const handleRedirectToLanding = () => {
		history.push(config.paths.landing);
	};

	const showProfileMenu = (ev: React.MouseEvent) => {
		ev.preventDefault();
		ev.stopPropagation();
		setProfileMenuOpen(true);
	};

	const renderLogo = () => {
		let { logoUrl } = configData.data.themeOverrides; // default finnish
		const langTransformed = lang.charAt(0).toUpperCase() + lang.slice(1);

		if (
			(lang === Language.english || lang === Language.swedish) &&
			configData.data.themeOverrides[`logo${langTransformed}Url`]
		) {
			logoUrl = configData.data.themeOverrides[`logo${langTransformed}Url`];
		}

		if (logoUrl) {
			return <img src={logoUrl} alt="Company logo" />;
		}

		return <></>;
	};

	return (
		<HeaderWrapper>
			<LeftWrapper>
				<HomeWrapper
					aria-label="Main menu button"
					data-testid="main-menu-button"
					onClick={handleMenuOpen}
					ref={menuRef}
					aria-expanded={mainMenuOpen}
					isAuthenticated={isAuthenticated}
				>
					<Icon name="AlignJustify" color={theme.colors.primary} />
					<HeaderText color={theme.colors.primary} size="m">
						{t('common.menuLink')}
					</HeaderText>
				</HomeWrapper>
				<LogoWrapper>{renderLogo()}</LogoWrapper>
			</LeftWrapper>
			<InnerSection />
			<RightWrapper>
				<LandingWrapper
					isAuthenticated={isAuthenticated}
					onClick={handleRedirectToLanding}
				>
					<Icon name="Home" color={theme.colors.primary} />
				</LandingWrapper>
				<ProfileWrapper
					data-testid="profile-menu-button"
					ref={profileMenuRef}
					onClick={showProfileMenu}
					aria-expanded={profileMenuOpen}
				>
					<Icon name="Profile" color={theme.colors.primary} />
				</ProfileWrapper>
			</RightWrapper>
		</HeaderWrapper>
	);
};

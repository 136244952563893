import React from 'react';

import { TextField } from '@terveystalo/design-system-react-components';
import { TextAreaWrapper } from './TextArea.styles';

export const CustomTextArea = (props: any) => (
	<TextAreaWrapper>
		<TextField as="textarea" {...props} />
	</TextAreaWrapper>
);

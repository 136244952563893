export const config = {
	paths: {
		login: '/admin/login',
		changePassword: '/admin/changePassword',
		register: '/admin/register',
		home: '/admin',
		homeOrganization: '/admin/organization',
		homeChatCodes: '/admin/chatCodes',
		chatCustomizableContent: '/admin/chat/customizable',
		homeBokingAppointments: '/admin/appointments',
		bookingCustomizableContent: '/admin/appointments/customizable',
		homeExperts: '/admin/experts',
		homeChatCodesInformation: '/admin/chatCodesInformation',
		homeThankYouPage: '/admin/thankYou',
		homeGlobalUsers: '/admin/globalUsers',
		homeOrganizationUsers: '/admin/organizationUsers',
		templateEditor: '/admin/templateEditor',
		newOrganization: '/admin/newOrganization',
		appointment: '/admin/appointment',
		addEditService: '/admin/addEditService',
		preQuestionnaire: '/admin/preQuestionnaire',
		selfBookingConfirmation: '/admin/selfBookingConfirmation',
		asyncMessagingCategories: '/admin/messages/categories',
		asyncMessagingEditCategory: '/admin/messages/category/edit',
		asyncMessagingEditSubcategory: '/admin/messages/subcategory/edit',
		asyncMessagingCustomizableContent: '/admin/messages/customizable',
		asyncMessagingCodes: '/admin/messages/messageCodes',
		asyncMessagingExperts: '/admin/messages/experts',
	},
};

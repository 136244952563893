import { Response, ResponseError } from 'common/typings/response';

import LocalStorageService from 'common/services/localStorage';
import { CommonApiService } from '../config';

export enum AppRole {
	expert = 'expert',
	admin = 'admin',
}

export interface LoginResponse {
	data: Array<{
		role: 'expert' | 'admin';
		role_uuid: string;
		name: string;
	}>;
}

class LoginService {
	authenticated: boolean;

	apiService: CommonApiService = new CommonApiService();

	appRole: AppRole = AppRole.admin;

	constructor() {
		// this.authClient = this.initializeAuthClient();
		this.authenticated = false;
	}

	public setApiService(apiService: CommonApiService, appRole?: AppRole) {
		this.apiService = apiService;

		if (appRole) {
			this.appRole = appRole;
		}
	}

	public async loginUser(
		username: string,
		password: string,
	): Promise<ResponseError & LoginResponse> {
		const response: Response<LoginResponse> = await this.apiService.post(
			`/sso/login`,
			{
				username,
				password,
				app_role: this.appRole,
			},
		);

		// eslint-disable-next-line no-console
		console.log(JSON.stringify(response.data));

		if (response.data.status !== 'error') {
			// to clear defaultOrganization from previous user
			LocalStorageService.remove('defaultOrganization');
			this.authenticated = true;
		} else {
			this.authenticated = false;
		}

		return {
			...response.data,
		};
	}

	public isAuthenticated() {
		return this.authenticated;
	}

	async getAuthState() {
		try {
			const response: Response<{ role: AppRole }> = await this.apiService.get(
				'/sso/user_data',
			);

			if (response.data.status === 'error') {
				this.authenticated = false;
			} else if (response.data.role === this.appRole) {
				this.authenticated = true;
			} else {
				this.authenticated = false;
			}
			// eslint-disable-next-line no-empty
		} catch (error) {
			this.authenticated = false;
		}

		// return this.authClient.getExpectedAuthState();
	}

	public async logOut() {
		await this.apiService.get('/sso/logout');
		this.authenticated = false;
	}

	public async selectOrganization(organizationId: string) {
		const response: Response<{}> = await this.apiService.post(
			`/sso/set_role/${this.appRole};${organizationId}`,
			{},
		);
		return response.data;
	}
}

export default new LoginService();

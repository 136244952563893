import styled from 'styled-components';
import { Text } from '@terveystalo/design-system-react-components';

import { HEADER_HEIGHT, HEADER_HEIGHT_MOBILE } from '../header/header.styles';

export const MainMenuWrapper = styled.nav<{
	visible: boolean;
	transitionEnd: boolean;
}>`
	visibility: visible;
	width: 100%;
	max-width: 375px;
	background-color: ${(props) => props.theme.colors.white};
	position: fixed;
	left: 0;
	top: 0;
	bottom: 0;
	z-index: ${(props) => props.theme.zIndex.mainMenu};
	box-shadow: ${(props) => props.theme.boxShadows.boxShadow2};
	transition: 0.3s all;
	overflow: auto;
	display: flex;
	flex-direction: column;

	${(props) =>
		!props.visible &&
		`	
		left: -375px;

	`}

	${(props) =>
		props.transitionEnd &&
		!props.visible &&
		`
		visibility: hidden;
	`}
`;

export const TopSectionWrapper = styled.div`
	height: ${HEADER_HEIGHT_MOBILE}px;

	${(props) => props.theme.media.mediaQueries.desktop} {
		height: ${HEADER_HEIGHT}px;
	}
	border-bottom: 1px solid ${(props) => props.theme.colors.rgba.zodiacBlack0_11};
	display: flex;
	align-items: center;
	padding: ${(props) => props.theme.spacing['1']}
		${(props) => props.theme.spacing['1']};
`;

export const MenuButton = styled.a<{
	short?: boolean;
	disabled?: boolean;
}>`
	display: flex;
	align-items: center;
	cursor: pointer;
	text-decoration: none;
	width: 100%;

	${(props) =>
		props.disabled &&
		`	
		opacity: 0.7;

	`}
`;

export const MenuButtonClose = styled.button`
	background: none;
	border: none;
	width: auto;
	display: flex;
	align-items: center;
	cursor: pointer;
`;

export const MenuItemWrapper = styled.div<{ active?: boolean }>`
	border-bottom: 1px solid ${(props) => props.theme.colors.rgba.zodiacBlack0_11};
	padding: ${(props) => props.theme.spacing['1']};
	align-items: center;
	display: flex;
	width: 100%;

	${(props) =>
		props.active &&
		`
			padding: ${props.theme.spacing['0_5']} ${props.theme.spacing['0_5']};
			> a {
				width: 100%;
				background-color: ${props.theme.colors.rainyBlue15};
				padding: ${props.theme.spacing['0_5']} ${props.theme.spacing['0_5']};
				border-radius: ${props.theme.border.radius.m};

			}
	`};
`;

export const MainMenuIconWrapper = styled.div`
	margin-right: ${(props) => props.theme.spacing['1']};
	min-width: 24px;
`;

export const MenuText = styled(Text)<{ active?: boolean }>`
	${(props) =>
		props.active &&
		`
			border-bottom: 1px solid ${props.theme.colors.primary};
			color: ${props.theme.colors.primary};
			font-weight: 400;
		`}
`;

export const MainMenuOverlay = styled.div`
	position: fixed;
	background-color: ${(props) => props.theme.colors.zodiacBlack};
	opacity: 0.3;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: ${(props) => props.theme.zIndex.mainMenuOverlay};
`;

export const BottomLinksSection = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	flex: 1;
	padding: ${(props) => props.theme.spacing['1']};
	margin-top: ${(props) => props.theme.spacing['2']};
	margin-bottom: ${(props) => props.theme.spacing['0_5']};
`;

export const BottomLink = styled.a`
	display: flex;
	align-items: center;
	text-decoration-color: ${(props) => props.theme.colors.primary};
`;

export const ExternalIconWrapper = styled.div`
	margin-left: ${(props) => props.theme.spacing['0_5']};
`;

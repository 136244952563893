import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { AppContext } from 'app/context/appContext';

import { Text } from '@terveystalo/design-system-react-components';

import {
	CustomDialog,
	CustomDialogHeadline,
} from 'app/styles/components/dialog/dialog';
import { CustomButton } from 'app/styles/components/button/button';
import WebAuthService from 'app/services/webAuth/webAuth';
import { config } from 'app/config/config';

interface ChatNotAvailableModalProps {
	isOpen: boolean;
	toggleModal(open: boolean): void;
	appointmentUuid?: string;
}

export const ChatNotAvailableModal = ({
	isOpen,
	toggleModal,
	appointmentUuid,
}: ChatNotAvailableModalProps) => {
	const { t } = useTranslation();
	const history = useHistory();
	const { isGuestAuthenticated } = useContext(AppContext);

	const toggleChatNotAvailableModal = () => {
		toggleModal(false);
		handleGoToServiceSelection();
	};

	const handleLogout = () => {
		WebAuthService.logout();
	};

	const handleGoToServiceSelection = () => {
		if (isGuestAuthenticated()) {
			history.push(config.paths.guestUser + appointmentUuid);
		} else {
			history.push(config.paths.serviceSelection);
		}
	};

	return (
		<CustomDialog
			className="appointmentModal"
			open={isOpen}
			onClose={toggleChatNotAvailableModal}
			title={
				<CustomDialogHeadline>
					{t('chatNotAvailableModal.title')}
				</CustomDialogHeadline>
			}
			description={<Text size="m">{t('chatNotAvailableModal.body')}</Text>}
		>
			<CustomButton onClick={handleLogout}>{t('common.logOut')}</CustomButton>
			<CustomButton variant="secondary" onClick={handleGoToServiceSelection}>
				{t('chatNotAvailableModal.button')}
			</CustomButton>
		</CustomDialog>
	);
};

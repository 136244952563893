import {
	createSlice,
	createSelector,
	createAsyncThunk,
} from '@reduxjs/toolkit';

import CitiesService, { Cities } from 'common/services/cities/index';
import { ResponseError } from 'common/typings/response';

import PatientApiService from 'app/services/config';
import { RootState } from 'app/store';

export interface CitiesState {
	data: Cities | null;
	isLoading: boolean;
	error: any;
}

const initialState: CitiesState = {
	data: null,
	isLoading: false,
	error: null,
};

CitiesService.setApiService(PatientApiService);

export const getCitiesAsync: any = createAsyncThunk(
	'cities/getCities',
	async ({ country }: { country: string }, thunkAPI) => {
		try {
			const citiesArray: Cities & ResponseError = await CitiesService.getCities(
				country,
			);
			return citiesArray;
		} catch (err) {
			thunkAPI.dispatch(getCitiesFailure());
			return thunkAPI.rejectWithValue(err);
		}
	},
);

export const citiesSlice = createSlice({
	name: 'cities',
	initialState,
	reducers: {
		getCitiesFailure: () => {
			// Redux Toolkit allows us to write "mutating" logic in reducers. It
			// doesn't actually mutate the state because it uses the Immer library,
			// which detects changes to a "draft state" and produces a brand new
			// immutable state based off those changes
		},
	},
	extraReducers: (builder) => {
		builder.addCase(getCitiesAsync.pending, (state) => {
			state.isLoading = true;
		});

		builder.addCase(getCitiesAsync.rejected, (state, action) => {
			state.error = action.payload;
			state.isLoading = false;
		});

		builder.addCase(getCitiesAsync.fulfilled, (state, action) => {
			if (action.payload.status === 'error') {
				state.error = action.payload.statusCode;
			} else {
				state.data = action.payload;
			}
			state.isLoading = false;
		});
	},
});

// Action creators are generated for each case reducer function
export const { getCitiesFailure } = citiesSlice.actions;

export default citiesSlice.reducer;

export const selectCities = createSelector(
	(state: RootState) => state.cities,
	(cities) => cities,
);

import axios, { AxiosInstance } from 'axios';
import { ErrorCodes } from 'common/typings/error-codes';

axios.defaults.withCredentials = true;

const baseURL = process.env.REACT_APP_API_URL;

class CommonApiService {
	public request: AxiosInstance;

	[key: string]: any;

	constructor() {
		const config = {
			baseURL,
			headers: {
				'Content-Type': 'application/json',
				...this.getAdditionalHeaders(),
			},
		};

		this.request = axios.create(config);

		this.request.interceptors.response.use(
			(response: any) => response,
			(error: any) => {
				if (error && error.response) {
					const { status } = error.response;
					if (
						status === ErrorCodes.UNAUTHORIZED ||
						status === ErrorCodes.ACCESS_DENIED ||
						status === ErrorCodes.NOT_FOUND
					) {
						// for example logout action here
					}
				}
				return Promise.reject(error);
			},
		);
	}

	protected async getAdditionalHeaders() {
		return {};
	}

	public async delete<T>(url: string, params?: any, options?: {}): Promise<T> {
		const headers = await this.getAdditionalHeaders();

		const configParams = { params, headers, ...options };

		return this.request.delete(url, configParams);
	}

	public async get<T>(url: string, params?: any, options?: {}): Promise<T> {
		const headers = await this.getAdditionalHeaders();

		const configParams = { params, headers, ...options };

		return this.request.get(url, configParams);
	}

	public async post<T>(
		url: string,
		data?: any,
		params?: any,
		options?: any,
	): Promise<T> {
		const defaultHeaders = await this.getAdditionalHeaders();

		const configParams = {
			params,
			...options,
			headers: { ...options?.headers, ...defaultHeaders },
		};
		return this.request.post(url, data, configParams);
	}
}

export { CommonApiService };

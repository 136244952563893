export const se = {
	'common.remove': 'Radera',
	'common.customizableContent': 'Anpassningsbart innehåll',
	'common.backToBookings': 'Tillbaka till bokningar',
	'common.backToServiceSettings': 'Återgå till organisationsinställningar',
	'common.backToCustomizableContent': 'Återgå till anpassningsbart innehåll',
	'common.cancel': 'Avbryt',
	'common.clear': 'Klar',
	'common.all': 'Alla',
	'common.back': 'Tillbaka',
	'common.add': 'Lägg till',
	'common.menu': 'Meny',
	'common.done': 'Klart',
	'common.save': 'Spara',
	'common.edit': 'Redigera',
	'common.delete': 'Radera',
	'common.logOut': 'Logga ut',
	'common.login': 'Logga in',
	'common.hello': 'Hej',
	'common.errors': 'Fel',
	'common.savingError': 'Fel vid sparande',
	'common.saveChanges': 'Spara ändringar',
	'common.notAvailable': 'Ej tillgänglig ännu',
	'common.language.finnish': 'Finska',
	'common.language.english': 'Engelska',
	'common.language.swedish': 'Svenska',
	'common.username': 'Användarnamn',
	'common.password': 'Lösenord',
	'common.email': 'E-post',
	'common.errors.105': 'personnummer är inte giltigt.',
	'common.errors.601': 'Användaren hittades inte',
	'common.errors.602':
		'Du har inte tillräckligt med rättigheter för att återställa användarens lösenord',
	'common.errors.801': 'Länken för att ställa in ett lösenord har löpt ut',
	'common.errors.802':
		'Lösenordet är inte tillräckligt starkt, det måste innehålla små och stora bokstäver och siffror',
	'common.errors.422':
		'Den valda tiden är redan bokad, se övriga lediga tider för den specialisten',
	'common.errors.423': 'Tiden måste vara i framtiden',
	'common.errors.1007': 'Det gick inte att skapa kö',
	'common.errors.1006': 'Experten saknar ninchat-id',
	'common.errors.1005': 'Det gick inte att skapa ett ninchatmöte',
	'common.errors.1004':
		'Det gick inte att lägga till expert till ninchat-mötet',

	'common.errors.1001': 'Creating a user to ninchat failed',
	'common.errors.1002':
		'Det gick inte att lägga till en expert till ninchat-organisationen',
	'common.errors.1003':
		'E-postadressen används redan och kan inte bifogas till befintlig användare',
	'common.errors.1100': 'Email adressen används redan',
	'header.serviceSettings': 'Inställningar',
	'header.chat': 'Chatt',
	'header.chatCodes': 'Chattkoder',
	'header.experts': 'Specialister',
	'header.bookings': 'Bokningar',
	'header.userManagement': 'Användarinställningar',
	'header.asyncMessaging': 'Meddelanden',
	'header.asyncMessaging.messagingCategories': 'Meddelandekategorier',
	'header.asyncMessaging.messageCodes': 'Meddelandekoder',

	'mainMenu.options.global': 'Global',
	'mainMenu.options.globalGeneralSettings': 'Globala allmänna inställningar',
	'mainMenu.options.globalUserManagement': 'Globala användarinställningar',
	'mainMenu.options.organizationUserSettings': 'Användarinställningar',

	'mainMenu.options.serviceSettings': 'Tjänsteinställningar',
	'mainMenu.options.chat': 'Chatt',
	'mainMenu.options.general': 'Allmänt',
	'mainMenu.options.bookingAppointments': 'Bokningar',
	'mainMenu.options.experts': 'Specialister',
	'mainMenu.options.newOrganization': 'Ny organisation',
	'mainMenu.options.announcements': 'Information',
	'mainMenu.options.termsOfService': 'Användarvillkor',
	'mainMenu.options.surveys': 'Frågor',
	'mainMenu.options.messages': 'Meddelanden',

	'login.headline': 'Administratörspanelinloggning',
	'login.loginFailed': 'Inloggning misslyckades.',
	'login.fromRegistration.title':
		'Lösenordet har skapats. Logga in för att fortsätta.',
	'login.changePassword': 'Har du glömt lösenordet?',
	'login.logout.expired':
		'Din session har löpt ut och du har blivit automatiskt utloggad, vänligen logga in igen om du behöver använda tjänsten.',
	'changePassword.headline': 'Ändra lösenordet för användarhantering',
	'changePassword.successText':
		'E-postmeddelandet har skickats till den angivna adressen',
	'changePassword.subtext':
		'Ett e-postmeddelande har skickats till den angivna adressen med en länk för att återställa lösenordet. Länken giltig i 30 minuter.',
	'changePassword.submit': 'Byt lösenordet',
	'changePassword.backToLogin': 'Tillbaka till inloggning',
	'changePassword.error.incorrectFormat': 'E-postadressen är ogiltig',
	'changePassword.error.general': 'Ett fel uppstod',
	'changePassword.error.badEmail': 'E-posten hittades inte',
	'chatCodes.headline': 'Chattkoder',
	'chatCodes.headline.messageCodes': 'Meddelandekoder',
	'chatCodes.description':
		'Visa och hantera klientens white label-konfigurationen. Observera att ändringar som görs kan kräva en ny push till utvecklingsmiljön av underhållsteamet för att synas.',
	'chatCodes.listOfCodes': 'Meddelandekodlista',
	'chatCodes.button.addNew': 'Nya koder',
	'chatCodes.table.codeGroupName': 'Kodgruppens namn',
	'chatCodes.table.assignedTo': 'Tilldelad till',
	'chatCodes.table.codes': 'Koder',
	'chatCodes.table.availability': 'Tillgänglighet',
	'chatCodes.table.actions': 'Åtgärder',
	'chatCodes.table.subcategory': 'Underkategori',
	'chatCodes.button.file': 'Fil',
	'chatCodes.button.duplicate': 'Duplicera',
	'chatCodes.deleteConfirmation':
		'Är du säker på att du vill ta bort chattkoden?',
	'chatCodesModal.title.add': 'Nya koder',
	'chatCodesModal.title.edit': 'Redigera koder',
	'chatCodesModal.fields.codeGroupName': 'Kodgruppens namn',
	'chatCodesModal.fields.assignedService': 'Tilldelad tjänst',
	'chatCodesModal.fields.amountOfCodes': 'Totalt antal koder',
	'chatCodesModal.fields.amountOfUses': 'Antal användningar per kod',
	'chatCodesModal.fields.startingDate': 'Startdatum',
	'chatCodesModal.fields.today': 'I dag',
	'chatCodesModal.fields.expirationDate': 'Utgångsdatum',
	'chatCodesModal.fields.endOfYear': 'Årets slut',
	'chatCodesModal.fields.6monthsFromNow': '6 månader från nu',
	'chatCodesModal.fields.1yearFromNow': '1 år från nu',
	'chatCodesModal.fields.noExpirationDate': 'Inget utgångsdatum',
	'chatCodesModal.fields.subcategory': 'Underkategori',
	'chatCodesModal.validation.codeGroupName':
		'kodgruppens namn får inte vara tomt',
	'chatCodesModal.validation.assignedService':
		'tilldela service tjänst till chattkod',
	'chatCodesModal.validation.assignedSubcategory':
		'En meddelandekod krävs för underkategorin',
	'chatCodesModal.validation.codesTotal':
		'total antal koder måste vara större än 0',
	'chatCodesModal.validation.startingDate': 'lägg till korrekt startdatum',
	'chatCodesModal.validation.expirationDate': 'lägg till korrekt utgångsdatum',
	'chatCodesModal.validation.expirationDate.past':
		'du kan inte välja ett datum som redan passerats',
	'empty.headline': 'Organisation har inte valts',
	'empty.description':
		'Välj organisation först för att kunna redigera tjänsteinställningar och chattkoder.',
	'organizationSettings.serviceSettings.title': 'Tjänsteinställningar',
	'organizationSettings.serviceSettings.description':
		'Visa och hantera klientens white label-konfiguration. Observera att ändringar som görs kan kräva en ny push till utvecklingsmiljön av underhållsteamet för att synas.',
	'organizationSettings.serviceSettings.organizationInformation.title':
		'Organisationsinformation',
	'organizationSettings.serviceSettings.interface.title': 'Brand och utseende',
	'organizationSettings.serviceSettings.serviceUrl.title': 'Webbplats-URL',
	'organizationSettings.serviceSettings.privacyStatement.title':
		'Personuppgiftspolicy',
	'organizationSettings.serviceSettings.navigation.organization':
		'Organisation',
	'organizationSettings.serviceSettings.navigation.siteSettings':
		'Sidinställningar',
	'organizationSettings.serviceSettings.navigation.services': 'Tjänster',
	'organizationSettings.serviceSettings.navigation.serviceArea':
		'Serviceområde',
	'organizationSettings.serviceSettings.navigation.customizableContent':
		'Redigerbart innehåll',
	'organizationSettings.serviceInformationPage.title':
		'Tjänsteinformationssida',
	'organizationSettings.serviceInformationPage.body':
		'Alla användare kommer att se den här sidan när de går in i tjänsten för första gången. Den är också tillgänglig från menyn, och bör innehålla minst följande information: Vad tjänsten handlar om, vem som producerar denna tjänst och hur kundens faktureringspolicy ser ut.',
	'organizationSettings.chatCodesInformationPage.title':
		'Information om chattkoder',
	'organizationSettings.chatCodesInformationPage.body':
		'Ytterligare information om chattkoder som bifogas till tjänsteinformationssidan när kunden navigerar via Menyn. Denna sida rekommenderas inte att redigeras.',
	'organizationSettings.languageInfo':
		'Se till att redigera språkversionerna vid behov. Du kan välja vilket språk du vill redigera ovanifrån.',
	'organizationSettings.endOfService.title':
		'Sida för tjänstens slut och omdirigering',
	'organizationSettings.endOfService.body':
		'Den här skärmen visas efter att användaren har slutfört sitt besök och gett feedback. Denna sida ska innehålla information om vad som händer härnäst och ge användaren alternativ för hur hen kan fortsätta på sin vårdväg inom kundens serviceutbud. Dessutom bör användaren ges möjlighet att hitta sina närmaste apotek och gå till OmaKanta.',
	'organizationSettings.thankYou.redirectionLinks.title':
		'Länkar för omdirigering (avsnitt 1)',
	'organizationSettings.thankYou.redirectionLinksSecond.title':
		'Länkar för omdirigering (avsnitt 2)',
	'organizationSettings.thankYou.redirectionLinks.button': 'Ny länk',
	'organizationSettings.thankYou.headline': 'Tack sida och omdirigering',
	'organizationSettings.thankYou.informationText.title':
		'Texten i fältet för ”Ytterligare information”',
	'organizationSettings.thankYou.redirectionLinks.textfield.title':
		'1. Avsnittets rubrik',
	'organizationSettings.thankYou.redirectionLinksSecond.textfield.title':
		'2. Avsnittets rubrik',
	'organizationSettings.thankYou.redirectionButtons.title':
		'Omdirigeringsknapp',
	'organizationSettings.thankYou.redirectionButtons.body':
		'Du kan redigera och skapa nya omdirigeringsknappar på tacksidan. Dessa knappar måste för användaren vara primära i tjänsten för följande funktioner. Skapa inte onödiga knappar, använd omdirigeringslänkar istället. Välj som primär knapp den funktion som du vill att användarna mest sannolikt utför.',
	'organizationSettings.thankYou.redirectionButtonsTitle': 'Knappar (välj 0-2)',
	'organizationSettings.thankYou.buttons.logOut': 'Logga ut',
	'organizationSettings.thankYou.buttons.backToServiceSelection':
		'Tillbaka till val av tjänst',
	'organizationSettings.thankYou.buttons.custom': 'Redigerbar knapp',
	'organizationSettings.thankYou.buttons.create':
		'Skapa en ny redigerbar knapp',
	'organizationSettings.thankYou.primaryButton.title': 'Primär knapp',
	'organizationSettings.thankYou.newButtonModal.title': 'Redigerbar knapp',
	'organizationSettings.thankYou.newButtonModal.linkText.headline':
		'Knappens text',
	'organizationSettings.thankYou.newButtonModal.linkUrl.headline':
		'Knappens länk',
	'organizationSettings.customizableContent.customizableContents.title':
		'Redigerbart innehåll',
	'organizationSettings.customizableContent.emailTemplates.title':
		'E-postmallar',
	'organizationSettings.preQuestionnaire.title': 'Frågeformulär',
	'changeOrganizationModal.addNewOrganization': 'Lägg till ny organisation',
	'changeOrganizationModal.title': 'Ändra organisation',
	'changeOrganizationModal.fields.organization.label': 'Organisation',
	'newOrganizationModal.title': 'Ny organisation',
	'newOrganisationModal.subheader.general.info': 'Allmän information',
	'newOrganizationModal.create': 'Skapa organisation',
	'organization.fields.organizationInfo': 'Organisationsinfo',
	'organization.fields.primaryColor':
		'Primär färg HEX (används för rubriker, knappar)',
	'organization.fields.primaryColor.placeholder': 'Till exempel #FF5733',
	'organization.fields.secondaryColor': 'Sekundär färg HEX',
	'organization.fields.secondaryColor.placeholder': 'Till exempel #FF5733',
	'organization.fields.logo': 'Logotyp',
	'organization.fields.logo.placeholder': 'Välj en fil',
	'organization.fields.logoInfoText':
		'Logofiler måste vara 256 pixlar breda och 128 pixlar höga( 256x128 px)',
	'organization.fields.contactEmail': 'E-post till kontaktperson',
	'organization.fields.contactPerson': 'Kontaktperson',
	'organization.fields.paymentNumber': 'Betalningsnummer',
	'organization.fields.organizationName': 'Organisationsnamn',
	'organization.fields.clientId': 'Klient-ID (genereras automatiskt)',
	'organization.fields.background': 'Bakgrund',
	'organization.fields.background.placeholder': 'Välj en fil',
	'organization.fields.backgroundInfoText':
		'Bakgrundsfiler bör antingen vara vektorfiler (PDF, SVG) eller bilder (PNG) i storleken 1900x1080 pixlar för att fungera korrekt.',
	'organization.fields.privacyStatementTitle':
		'Personuppgiftspolicy title ({{lang}})',
	'organization.fields.privacyStatementUrl':
		'Personuppgiftspolicy URL ({{lang}})',
	'organization.fields.serviceModules.title': 'Tjänstemoduler',
	'organization.fields.serviceModules.checkbox.booked': 'Hemsida bokning',
	'organization.fields.serviceModules.checkbox.acting':
		'Ärenden åt någon annan',
	'organization.fields.serviceModules.checkbox.logout': 'Logga ut',
	'organization.fields.serviceModules.checkbox.contact': 'Kontaktinformation',
	'organization.fields.serviceModules.checkbox.yourBookings': 'Mina bokningar',
	'organization.fields.serviceModules.checkbox.changeLocation': 'Möteshistorik',
	'organization.fields.serviceModules.checkbox.appointmentHistory':
		'Bokningshistorik',
	'organization.fields.serviceModules.checkbox.remoteReception':
		'Hemsida för digital mottagning',
	'organization.fields.serviceModules.checkbox.termsAndConditions': 'Villkor',
	'organization.fields.languageOptions.title': 'Språkalternativ',
	'organization.fields.country': 'Land',
	'organization.fields.type.internal': 'Terveystalo',
	'organization.fields.type.external': 'Extern',
	'organization.fields.organizationVisibility.title':
		'Ändra platsinställningar',
	'organization.fields.organizationVisibility.dontShow': 'Visas inte i listan',
	'organization.fields.serviceURLAddress': 'Webbplats-URL',
	'organization.fields.serviceURLAddress.note':
		'En organisationsspecifik URL skapas baserat på organisationens namn',
	'organization.validation.organizationName':
		'Organisationens namn får inte vara tomt',
	'organization.validation.contactPerson': 'Kontaktperson kan inte vara tom',
	'organization.validation.contactPersonEmail': 'Felaktigt e-postformat',
	'organization.validation.logo': 'Logotyp kan inte vara tom',
	'organization.validation.hex': 'Lägg till korrekt hex-färgvärde',
	'organization.validation.privacyStatementUrl':
		'Fyll i personuppgiftspolicy-url på {{language}}',
	'organization.validation.privacyStatementUrlText':
		'Fyll i personuppgiftspolicy url-text på {{language}}',
	'organization.validation.ninchatMasterKey':
		'Lägg till korrekt ninchat MasterKey',
	'organization.validation.ninchatSiteKey': 'Lägg till korrekt ninchat SiteKey',
	'organization.validation.ninchatSecret': 'Lägg till korrekt ninchat Secret',
	'organization.creation.success': 'Organisation skapad',
	'newRedirectionLinkModal.title': 'Omdirigeringslänk',
	'newRedirectionLinkModal.linkText.subtitle': 'Länktext',
	'newRedirectionLinkModal.linkUrl.subtitle': 'Länk URL',
	'newRedirectionLinkModal.error.linkFinnish': 'Lägg till länktext på finska',
	'newRedirectionLinkModal.error.linkSwedish': 'Lägg till länktext på svenska',
	'newRedirectionLinkModal.error.linkEnglish': 'Lägg till länktext på engelska',
	'serviceInformationPageModal.title': 'Tjänsteinformationssida',
	'endOfServiceModal.title': 'Tjänstens slut och omdirigering',
	'endOfServiceModal.subtitle': 'Informationstext',
	'organizationServices.title': 'Tjänster',
	'services.title': 'Tjänsteförteckning',
	'services.addButton': 'Ny tjänst',
	'services.additionalInfo.label':
		'Ytterligare information om tjänsten till specialister',
	'services.info.codesUsed': 'Använda koder',
	'services.info.noCodesAssigned': 'Ingen kodlista tilldelad.',
	'services.icons.doctor': 'Läkare',
	'services.icons.nurse': 'Sjuksköterska',
	'services.icons.mentalwellbeing': 'Terapeut',
	'services.icons.childhealth': 'Barnläkare',
	'services.icons.weight': 'Fysioterapeut',
	'services.preQuestionnaire.title': 'Frågeformulär före bokning',
	'services.preQuestionnaire.lead':
		'Ange frågorna för frågeformuläret för alla som slutför en bokning i systemet.',
	'services.selfBookingConfirmation.title': 'Bekräftelsesida för självbokning',
	'services.selfBookingConfirmation.lead':
		'Ställ in innehållet och omdirigeringen för bekräftelsesidan efter en lyckad bokning.',
	'servicesForm.title': 'Tjänst',
	'servicesForm.error.serviceNameFinnish': 'Lägg till tjänstens namn på finska',
	'servicesForm.error.serviceNameSwedish':
		'Lägg till tjänstens namn på svenska',
	'servicesForm.error.serviceNameEnglish':
		'Lägg till tjänstens namn på engelska',
	'servicesForm.error.icon': 'Välj tjänsteikon',
	'servicesForm.error.chatqueueId': 'Lägg till korrekt ninchat-kö-ID',
	'servicesForm.serviceName.subtitle': 'Tjänstens namn',
	'servicesForm.field.icon': 'Ikon',
	'servicesForm.serviceDescription.subtitle': 'Tjänstebeskrivning',
	'servicesForm.field.chatQueueId': 'Chattkö-ID',
	'servicesForm.info.chatQueueId':
		'Kopiera och klistra in önskat chattkö-ID från Ninchat i fältet ovan.',
	'servicesForm.editService.title': 'Redigera tjänsten',
	'servicesForm.addService.title': 'Ny tjänst',
	'expertsModal.fields.ninchatId': 'Ninchat användar-id',
	'servicesForm.chatCodes.subtitle': 'Chatt inställningar',
	'servicesForm.chatCodes.label': 'Kräver kod',
	'servicesForm.selfAssessment.checkbox.label': 'Obligatorisk symptomförfrågan',
	'servicesForm.selfAssessmentOptional.checkbox.label':
		'Frivillig symptomförfrågan',
	'organization.fields.serviceModules.checkbox.htaConnector':
		'Symptomförfrågan',
	'organization.fields.serviceModules.checkbox.asyncMessaging': 'Kommunikation',
	'areas.title': 'Serviceområde',
	'areas.addButton': 'Nytt område och/eller postnummer',
	'areas.subtitle': 'Inkludera områden och postnummer',
	'areas.label.municipality': 'Kommun',
	'areas.label.postalCode': 'Postnummer',
	'CreateAppointmentModal.field.expert': 'Specialist',
	'CreateAppointmentModal.field.preferredEndingTime': 'Föredragen sluttid',
	'CreateAppointmentModal.duration.oneTimeslot.subtitle':
		'En tidsluckas varaktighet (minuter)',
	'CreateAppointmentModal.generateTimeslots.text':
		'{{number}} tidsluckor kommer att genereras. Du måste bekräfta tidsluckorna efteråt för att öppna dem.',
	'CreateAppointmentModal.generateTimeslots.button': 'Generera tidsluckor',
	'CreateAppointmentModal.timeslots.reservable': 'Kan reserveras',
	'CreateAppointmentModal.timeslots.break': 'Paus',
	'CreateAppointmentModal.timeslots.table.startingTime': 'Starttid',
	'CreateAppointmentModal.timeslots.table.endTime': 'Sluttid',
	'CreateAppointmentModal.timeslots.table.type': 'Typ',
	'CreateAppointmentModal.error.timeslotsListEmpty':
		'Tidsluckor måste genereras först',
	'CreateAppointmentModal.error.availableDate': 'Datum har inte valts',
	'CreateAppointmentModal.error.availableAppointment': 'Bokning har inte valts',
	'CreateAppointmentModal.button.createOpenAppointment': 'Öppna tidsluckor',
	'CreateAppointmentModal.subtitle.open.new':
		'Välj ett datum och hur mycket du ska arbeta',
	'CreateAppointmentModal.subtitle.open.edit': 'Redigera tidslucka',
	'CreateAppointmentModal.subtitle.new.createNewTimeslotAndBookIt':
		'Skapa en ny tidslucka och boka den',
	'CreateAppointmentModal.subtitle.new.bookAvailableTimeslot':
		'Boka en ledig tidslucka',
	'CreateAppointmentModal.subtitle.edit': 'Redigera en bokning',
	'CreateAppointmentModal.field.date': 'Datum',
	'CreateAppointmentModal.field.appointment': 'Bokning',
	'CreateAppointmentModal.button.nextAvailable': 'Nästa tillgängliga',
	'CreateAppointmentModal.bookTimeslot.noMore': 'Inga fler lediga tidsluckor',
	'CreateAppointmentModal.bookTimeslot.timeslotSectionTile': 'Tidslucka',
	'CreateAppointmentModal.bookTimeslot.patientSectionTile': 'Patient',
	'CreateAppointmentModal.toast.1': 'Ny bokning har skapats',
	'CreateAppointmentModal.toast.2': 'Bokning genomförd',
	'CreateAppointmentModal.toast.3': 'Bokning redigerad',
	'CreateAppointmentModal.toast.4': 'Öppna tidsluckor skapade',
	'CreateAppointmentModal.toast.5': 'Öppen tidslucka redigerad',
	'CreateAppointmentModal.title': 'Boka en tid',
	'CreateAppointmentModal.preQuestionnaire.title': 'Frågeformulär',
	'CreateAppointmentModal.error.ssn': 'Lägg till personnummer',
	'CreateAppointmentModal.error.expert': 'Välj specialist i listan',
	'CreateAppointmentModal.error.startingDate': 'Lägg till korrekt startdatum',
	'CreateAppointmentModal.error.startingTime': 'Lägg till korrekt starttid',
	'CreateAppointmentModal.error.nationality': 'Välj nationalitet',
	'CreateAppointmentModal.error.startingDate.past':
		'Du kan inte välja ett datum som redan passerats',
	'CreateAppointmentModal.error.groupAttendee.name': 'Namn är obligatoriskt',
	'CreateAppointmentModal.error.groupAttendee.email':
		'Ange en giltig e-postadress',
	'CreateAppointmentModal.error.groupAttendee.noExpert': 'Välj en expert',
	'CreateAppointmentModal.error.groupAttendee.noNinchatId':
		'Experten saknar ninchat-id',
	'CreateAppointmentModal.error.groupHost.noNinchatId':
		'Arrangören saknar ninchat-id',
	'CreateAppointmentModal.error.groupAttendee.duplicateExpert':
		'Expert redan tillagd',
	'CreateAppointmentModal.error.groupAttendee.duplicatePatient':
		'Patienten redan tillagd',
	'CreateAppointmentModal.error.groupAttendee.duplicateGuest':
		'Gäst redan tillagd',
	'CreateAppointmentModal.error.email': 'Ange korrekt e-postadress',
	'CreateAppointmentModal.header.participants': 'Deltagarna',
	'CreateAppointmentModal.field.ssn': 'Personnummer',
	'CreateAppointmentModal.field.nationality': 'Patientens nationalitet',
	'CreateAppointmentModal.field.startingDate': 'Datum',
	'CreateAppointmentModal.field.startingTime': 'Starttid',
	'CreateAppointmentModal.field.otherDuration': 'Övrigt',
	'CreateAppointmentModal.field.language': 'Patientens språk',
	'CreateAppointmentModal.field.email': 'E-post',
	'CreateAppointmentModal.duration.subtitle': 'Varaktighet (minuter)',
	'CreateAppointmentModal.field.1on1': 'Individuell mottagning',
	'CreateAppointmentModal.field.group': 'Gruppmottagning',
	'CreateAppointmentModal.field.patient': 'Patient',
	'CreateAppointmentModal.field.groupattendee.expert': 'Specialist',
	'CreateAppointmentModal.field.guest': 'Gäst',
	'CreateAppointmentModal.label.host': 'Arrangören',
	'CreateAppointmentModal.field.name': 'Namn',
	'CreateAppointmentModal.field.guest.language': 'Lägg till deltagar',
	'CreateAppointmentModal.field.guest.language.finnish': 'Finska',
	'CreateAppointmentModal.field.guest.language.english': 'Engelska',
	'CreateAppointmentModal.field.guest.language.swedish': 'Svenska',
	'CreateAppointmentModal.field.firstName': 'Förnamn',
	'CreateAppointmentModal.field.lastName': 'Efternamn',
	'CreateAppointmentModal.header.addaparticipant': 'Lägg till deltagare',
	'CreateAppointmentModal.button.addparticipant': 'Lägg till deltagare',
	'CreateAppointmentModal.reminderCheckbox':
		'Skicka ett bokningsbekräftelsemail till patienten',
	'createRemoteAppointment.emailReminder.lead':
		'När du har registrerat dig får du och alla inblandade parter ett bekräftelsemeddelande om det registrerade besöket via e-post.',
	'CreateAppointmentModal.button.create': 'Registrera tid',
	'CreateAppointmentModal.button.update': 'Uppdatera tid',
	'CreateAppointmentModal.openAppointment.title': 'Öppna en bokningsbar tid',
	'CreateAppointmentModal.groupvideo.checkbox': 'gruppvideo tid',
	'CreateAppointmentModal.openAppointment.lead':
		'När du har öppnat tiden kan patienterna se och reservera den genom systemet.',
	'CreateAppointmentModal.guest.name': 'Gästnamn',
	'CreateAppointmentModal.guest.email': 'Gäst e-postadress',
	'bookings.title': 'Bokningar',
	'bookings.subtitle': 'Alla bokningar',
	'bookings.lead.part1':
		'Öppna bokningsbara tider för bokning, boka nya tider och hantera befintliga bokningar.',
	'bookings.lead.part2':
		'Observera att alla bokningar måste skrivas in i patienthanteringssystemet separat.',
	'bookings.table.patient.title': 'Patient (SSN)',
	'bookings.table.expert.title': 'Specialist',
	'bookings.table.dateTime.title': 'Datum, tid',
	'bookings.table.actions.title': 'Åtgärder',
	'bookings.table.specialization.title': 'Spec.',
	'bookings.table.status.selfBooked': 'Reserverad',
	'bookings.table.status.booked': 'Reserverad',
	'bookings.table.status.cancelled': 'Avbokad',
	'bookings.table.status.open': 'Öppen',
	'bookings.table.status.title': 'Status',
	'bookings.button.create': 'Boka en tid',
	'bookings.button.create.groupvideo': 'Boka en gruppvideo tid',
	'bookings.button.book': 'Boka',
	'bookings.button.preQuestionnaire': 'Frågeformulär',
	'bookings.button.openAppointment': 'Öppna en bokningsbar tid',
	'bookings.filters.experts.label': 'Visa endast specifika specialisttider',
	'bookings.filters.specialization.label':
		'Visa endast specifik specialisering',
	'bookings.filters.openTimes.label': 'Visa öppna tider',
	'bookings.filters.bookedTimes.label': 'Visa bokade tider',
	'bookings.filters.onlyFuture': 'Visa kommande tider',
	'bookings.filters.startDate': 'Startdatum',
	'bookings.filters.endDate': 'Slutdatum',
	'bookings.filters.matchStartDate': 'Matcha med startdatum',
	'bookings.table.group.multiple.patients': 'Flera patienter',
	'bookings.deleteConfirmation': 'Är du säker på att du vill radera bokningen?',
	'experts.title': 'Specialister',
	'experts.lead':
		'Visa och hantera specialister som arbetar för organisationen. Observera att varje specialist har sin egen chattkö.',
	'experts.button.add': 'Lägg till en specialist',
	'experts.table.expertName': 'Namn',
	'experts.table.specializations': 'Specialiseringar',
	'expert.table.ninhatId': 'Ninchat-användare',
	'experts.table.chatQueue': 'Kö-id',
	'experts.table.status': 'Status',
	'experts.table.actions': 'Åtgärder',
	'experts.deleteConfirmation':
		'Är du säker på att du vill ta bort specialist?',
	'expertsModal.errors.firstName': 'Förnamnet kan inte vara tomt',
	'expertsModal.errors.lastName': 'Efternamnet kan inte vara tomt',
	'expertsModal.errors.specializations': 'Lägg till minst en specialisering',
	'expertsModal.errors.chatQueueId': 'Lägg till chattkö-ID',
	'expertsModal.errors.email': 'Ange korrekt e-postadress',
	'expertsModal.title.edit': 'Redigera en specialist',
	'expertsModal.title.create': 'Lägg till en specialist',
	'expertsModal.fields.firstName': 'Förnamn',
	'expertsModal.fields.lastName': 'Efternamn',
	'expertsModal.fields.specializations': 'Specialiseringar/titlar',
	'expertsModal.fields.generateChatQueue':
		'Generera användare automatiskt till Ninchat och lägg till personlig chattkö',
	'expertsModal.fields.chatQueueId': 'Chattkö-ID',
	'expertsModal.fields.title.language': 'Språk',
	'expertsModal.fields.title.communicationLanguage': 'Språk',
	'expertsModal.fields.language.fi': 'Finska',
	'expertsModal.fields.language.en': 'Engelska',
	'expertsModal.fields.language.sv': 'Svenska',
	'expertsModal.toast.attachedNinchaUser':
		'Användaren har redan ett Ninchat-konto, nu har kontot lagts till för att inkluderas i båda systemen.',
	'registration.title': 'Skapa lösenord',
	'registration.errors.samePassword': 'Lösenorden måste stämma överens',
	'registration.errors.validation':
		'Lösenordet måste innehålla minst 12 tecken, inklusive siffror samt stora och små bokstäver',
	'registration.errors.general':
		'Lösenordet kan inte ställas in, kontakta supporten eller kontoinnehavaren.',
	'registration.fields.password': 'Lösenord',
	'registration.fields.passwordConfirm': 'Bekräfta lösenord',
	'usersModal.actions.title': 'Funktioner',
	'usersModal.actions.status.title': 'Status',
	'usersModal.actions.actions.title': 'Funktioner',
	'usersModal.actions.buttons.resetPassword': 'Återställ lösenord',
	'usersModal.actions.buttons.lockUser': 'Lås användare',
	'usersModal.actions.buttons.unlockUser': 'Lås upp användare',
	'usersModal.actions.buttons.logoutUser': 'Logga ut användare',
	'usersModal.actions.buttons.actionLog': 'Ladda ner användarlogg',
	'usersModal.actions.error': 'Fel, åtgärden misslyckades.',
	'usersModal.actions.success': 'Åtgärden lyckades.',
	'usersModal.validation.name': 'Ange korrekt användarnamn',
	'usersModal.validation.lastName': 'Ange korrekt efternamn',
	'usersModal.validation.firstName': 'Ange korrekt förnamn',
	'usersModal.validation.email': 'Ange korrekt e-postadress',
	'usersModal.validation.organization': 'Välj organisation',
	'usersModal.edit.title': 'Redigera användare',
	'usersModal.add.title': 'Lägg till användare',
	'usersModal.fields.name': 'Namn',
	'usersModal.fields.firstName': 'Förnamn',
	'usersModal.fields.lastName': 'Efternamn',
	'usersModal.fields.email': 'Användarens e-postadress',
	'usersModal.fields.organization': 'Organisation',
	'usersModal.fields.title.access': 'Rättighet',
	'usersModal.fields.presets': 'Förinställningar',
	'usersModal.fields.editingRights.title': 'Redigeringsrättigheter',
	'usersModal.fields.viewingRights.title': 'Visningsrättigheter',
	'usersModal.fields.chatCode': 'Chattkoder',
	'usersModal.fields.experts': 'Specialister',
	'usersModal.fields.bookings': 'Tidsbokningar',
	'usersModal.fields.users': 'Användare',
	'usersModal.fields.serviceSettings': 'Tjänstens inställningar',
	'usersModal.buttons.add': 'Lägg till användare',
	'usersModal.buttons.edit': 'Redigera användare',
	'usersModal.confirmation.lockUser':
		'Är du säker på att du vill låsa användaren?',
	'usersModal.confirmation.unlockUser':
		'Är du säker på att du vill låsa upp användaren?',
	'usersModal.confirmation.logoutUser':
		'Är du säker på att du vill logga ut den här användaren?',
	'usersModal.confirmation.resetPassword':
		'Är du säker på att du vill återställa den här användarens lösenord?',
	'users.status.noPasswordSet': 'Inget lösenord har valts',
	'users.status.notActive': 'Användaren är inte aktiv',
	'users.status.active': 'Användaren är aktiv',
	'users.presets.booker': 'Tidsbokare',
	'users.presets.organizationAdmin': 'Organisationsadministratör',
	'users.presets.operationalAdmin': 'Funktionsadministratör',
	'users.presets.custom': 'Redigerad',
	'users.toast.createUser':
		'En ny användare har skapats, användaren får nu ett e-postmeddelande om att skapa ett lösenord åt sig själv. Länken för att skapa lösenord är giltig i 24 timmar.',
	'users.toast.resetPassword':
		'Åtgärden lyckades, användaren har fått ett e-postmeddelande för att återställa lösenordet, återställningslänken är giltig i 30 minuter.',
	'usersTable.confirmation.delete':
		'Är du säker på att du vill radera användaren?',
	'usersTable.global.title': 'Allmän användarhantering',
	'usersTable.organization.title': 'Administratörer',
	'usersTable.lead':
		'Hantera användare. Här kan du återställa lösenord, logga ut användare, visa användningsloggar, hantera användarrättigheter och skapa nya användare.',
	'usersTable.buttons.add': 'Lägg till användare',
	'usersTable.buttons.actions': 'Funktioner',
	'usersTable.columns.name': 'Namn',
	'usersTable.columns.organization': 'Organisation',
	'usersTable.columns.role': 'Roll',
	'usersTable.columns.lastLogin': 'Senaste inloggning',
	'usersTable.columns.status': 'Status',
	'usersTable.columns.actions': 'Funktioner',
	'questionnaireForm.title': 'Frågeformulär',
	'questionnaireForm.lead':
		'Nedan hittar du patientens svar på frågeformuläret.',
	'questionnaireForm.form.title': 'Frågeformulär',
	'questionnaireForm.form.setQuestion': 'Ange fråga',
	'questionnaireForm.form.mandatory': 'Obligatorisk',
	'questionnaireForm.form.addQuestion': 'Lägg till en fråga',
	'selfBookingConfirmationModal.title': 'Bekräftelsesida för självbokning',
	'selfBookingConfirmationModal.additionalTest.subtitle':
		'Ytterligare bekräftelsetext',
	'selfBookingConfirmationModal.redirectionButton.subtitle':
		'Omdirigeringsknapptext',
	'selfBookingConfirmationModal.redirectionLink.subtitle': 'Omdirigeringslänk',
	'datadeletion.fields.header': 'Data storing times',
	'datadeletion.fields.patientData': 'Patienter',
	'datadeletion.fields.adminData': 'Adminanvändare',
	'datadeletion.fields.expertData': 'Experter',
	'datadeletion.specifier.day': 'Dagar',
	'datadeletion.specifier.month': 'Månader',
	'userManagement.title': 'Användarhantering',
	'userManagement.buttons.admins': 'Administratörer',
	'userManagement.buttons.experts': 'Specialister',
	'userManagement.button.back': 'Tillbaka till användarhantering',
	'userManagement.button.addEditExpert': 'Lägg till/redigera en specialist',
	'userManagement.button.addEditAdmin': 'Add / Edit an Admin',

	'surveyItem.nameMissing': 'Enkätnamn saknas',
	'surveyItem.selectSurvey': 'Välj en undersökning att lägga till',
	'surveyItem.addSurvey': 'Lägg till enkät',
	'survey.Description':
		'När du registrerar en praktik kan du välja förskända enkäter att skicka till patienten. Frågor kan innehålla till exempel standardiserade medicinska frågor, du kan också skapa dina egna frågor.',
	'survey.Title': 'Frågor',
	'organizationSelectionModal.button.login': 'Logga in',
	'organizationSelectionModal.headline': 'Välj en organisation',
	'profileMenu.options.myInformation': 'Min information',
	'profileMenu.options.changeOrganization': 'Byt organisation',
	'newOrganization.title': 'Ny organisation',
	'appointment.title.open.new': 'Öppna bokningsbara tider',
	'appointment.title.open.edit': 'Redigera bokningsbar tid',
	'appointment.title.new': 'Boka en tid',
	'appointment.title.edit': 'Redigera en bokning',
	'appointment.checkbox.bookAvailableTimeslot': 'Boka en ledig tidslucka',
	'appointment.checkbox.groupAppointment': 'Boka en gruppvideo tid',
	'appointment.checkbox.createNewTimeslotAndBookIt':
		'Skapa en ny tidslucka och boka den',
	'schedule.cancel.title': 'Avboka tid?',
	'schedule.cancel.body':
		'Är du säker på att du vill avboka tiden? Detta kommer att skicka en avisering till alla deltagare.',
	'schedule.cancel.buttons.cancel': 'Avboka tid',
	'schedule.cancel.buttons.close': 'Stäng utan att avbryta',

	'emailTemplates.title': 'E-postmallar',
	'emailTemplates.toast.saved': 'Mall sparad',
	'emailTemplates.toast.resetted': 'Standardstyrning återställd',
	'emailTemplates.confirmModal.headline': 'Återställs standardmallar?',
	'emailTemplates.confirmModal.text':
		'Är du säker på att du vill återställa standardmallarna? Valet kan inte ångras',
	'emailTemplates.confirmModal.okButton': 'Återställ standardmallarna',
	'emailTemplates.back': 'Tillbaka till e-postmallarna',
	'emailTemplates.editor.title': 'E-postmall',
	'emailTemplates.editor.default': '(STANDARD)',
	'emailTemplates.editor.custom': '(REDIGERAD',
	'emailTemplates.editor.content': 'Innehåll',
	'emailTemplates.editor.showTagList': 'Visa lista över anteckningart',
	'emailTemplates.editor.explanation':
		'Använd taggar som expert_name för meddelandemallar för ett specifikt syfte, så att rätt innehåll förmedlas till mottagaren',
	'emailTemplates.editor.tag': 'Anteckning',
	'emailTemplates.editor.description': 'Beskrivning',
	'emailTemplates.editor.actions': 'Funktioner',
	'emailTemplates.editor.copyTag': 'Kopiera anteckning',
	'emailTemplates.editor.emailRichTextEditor': 'E-post',
	'emailTemplates.editor.resetButton': 'Återställ till standardmallar',
	'emailTemplates.editor.previewtButton': 'Förhandsgranska',
	'emailTemplates.editor.saveButton': 'Spara mall',
	'emailTemplates.editor.reset.toast':
		'Det gick inte att återställa e-postmall',
	'emailTemplates.editor.sender.name': 'Avsändarens namn',
	'emailTemplates.editor.sender.address': 'Avsändarens adress',
	'emailTemplates.editor.email.title': 'E-posttitel',

	'leaveChecker.title': 'Osparade ändringar',
	'leaveChecker.description':
		'Du har osparade ändringar, är du säker på att du vill lämna sidan?',
	'leaveChecker.submit': 'Lämna',

	'asyncMessaging.categories.title': 'Meddelandekategorier',
	'asyncMessaging.categories.lead':
		'Skapa meddelandekategorier och tilldela experter till dem',
	'asyncMessaging.categories.create': 'Skapa en kategori',
	'asyncMessaging.subcategories': 'underkategorier',
	'asyncMessaging.newCategory.title': 'Kategorinamn',
	'asyncMessaging.button.disableCategory': 'Inaktivera kategori',
	'asyncMessaging.form.subtitle.details': 'Details',
	'asyncMessaging.createCategory.form.subtitle.details': 'Detaljer',
	'asyncMessaging.createCategory.form.fields.categoryName': 'Kategorinamn på',
	'asyncMessaging.createCategory.form.fields.open': 'Öppen',
	'asyncMessaging.createCategory.form.fields.open.more':
		'Kategorin kräver ingen meddelandekod',
	'asyncMessaging.createCategory.form.fields.restricted': 'Avgränsad',
	'asyncMessaging.createCategory.form.fields.restricted.more':
		'Meddelandekod krävs för att komma åt den här kategorin',
	'asyncMessaging.subcategories.create': 'Lägg till subkategori',
	'asyncMessaging.createCategory.form.validation.categoryName':
		'Sätt korrekt namn i {{lang}}',
	'asyncMessaging.sendingError': 'Spara fel. Försök igen senare.',
	'asyncMessaging.success': 'Spara framgång!',
	'asyncMessaging.newSubcategory.title': 'Namn på subkategori',
	'asyncMessaging.button.disableSubcategory': 'Inaktivera underkategori',
	'asyncMessaging.disableCategory.confirmation':
		'Är du säker på att du vill inaktivera den här kategorin?',
	'asyncMessaging.disableSubcategory.confirmation':
		'Är du säker på att du vill inaktivera den här subkategorin?',
	'asyncMessaging.customizableContent.title': 'Kommunikation',
	'asyncMessaging.customizableContent.lead': 'Anpassningsbart innehåll',
	'asyncMessaging.subcategories.title': 'Underkategori',
	'asyncMessaging.customizableContent.messageFrontPage.title':
		'Framsidan av Messages',
	'asyncMessaging.customizableContent.messageFrontPage.description':
		'Innehållstext',
	'asyncMessaging.customizableContent.newMessageBanner.title':
		'Nytt meddelandevy',
	'asyncMessaging.customizableContent.newMessagebanner.description':
		'Servicelöfte',
	'asyncMessaging.experts.title': 'Expert',
	'asyncMessaging.experts.button.addExperts': 'Lägg till experts',
	'asyncMessaging.newExperts.title': 'Lägg till experts',
	'asyncMessaging.newExperts.searchBox.label':
		'Sök efter en specialitet eller ett expert',
	'asyncMessaging.newExperts.filters.all': 'Allt',
	'asyncMessaging.newExperts.filters.specializations': 'Specialfält',
	'asyncMessaging.newExperts.filters.experts': 'Experts',
	'asyncMessaging.newExperts.searchAutocomplete.experts.label': 'Experts',
	'asyncMessaging.newExperts.searchAutocomplete.noExperts':
		'Inga matchande experter',
	'asyncMessaging.subcategory.search.label':
		'Sök efter experts i denna underkategori',
	'asyncMessaging.newExperts.specialization.filter.label':
		'Filtrera efter specialitet',
	'asyncMessaging.newExperts.specialization.filter.all': 'Alla specialiteter',
	'asyncMessaging.newExperts.addFilters':
		'Lägg till en sökterm eller filtrera resultaten',
	'asyncMessaging.newExperts.selectAll': 'Välj alla',
	'asyncMessaging.newExperts.confirm': 'Acceptera valen',
	'asyncMessaging.newExperts.selectedExperts': 'Utvalda experts',
	'asyncMessaging.newExperts.noSelectedExperts': 'Inget expert har valts ut',
	'asyncMessaging.newExperts.add': 'Lägg till {{number}} experts',
};

import styled from 'styled-components';

export const FeedbackCircle = styled.button<{ index?: number }>`
	width: 40px;
	height: 40px;
	border: 1px solid ${(props) => props.theme.colors.zodiacBlack};
	display: inline-block;
	border-radius: 100%;
	margin: ${(props) => props.theme.spacing['0_5']};
	background-color: transparent;
	cursor: pointer;
	font-weight: 700;

	${(props) =>
		(props.index === 0 || props.index === 1) &&
		`
      background-color: ${props.theme.colors.coralRed};
  `}

	${(props) =>
		(props.index === 2 || props.index === 3) &&
		`
      background-color: ${props.theme.colors.coralRed40};
  `}

  ${(props) =>
		(props.index === 4 ||
			props.index === 5 ||
			props.index === 6 ||
			props.index === 7) &&
		`
      background-color: ${props.theme.colors.zodiacBlack02};
  `}

  ${(props) =>
		props.index === 8 &&
		`
      background-color: ${props.theme.colors.viridianGreen15};
  `}



	${(props) =>
		(props.index === 9 || props.index === 10) &&
		`
      background-color: ${props.theme.colors.viridianGreen40};
  `}
`;

export const VotingBottomInfoWrapper = styled.div`
	margin-top: ${(props) => props.theme.spacing['0_5']};
	display: flex;
	justify-content: space-between;
`;

export const CircleWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	margin-top: ${(props) => props.theme.spacing['1']};
`;

export const FacesInnerWrapper = styled.div`
	display: inline-flex;
	flex-direction: column;
`;

export const FacesOuterWrapper = styled.div`
	display: flex;
	justify-content: center;
`;

export const LeadTextWrapper = styled.div`
	text-align: center;
	margin-bottom: ${(props) => props.theme.spacing['0_5']};
`;

import styled from 'styled-components';

import { PillButton } from '@terveystalo/design-system-react-components';

import { roundedStyles } from 'common/styles/components/wrappers';

import { OpenAppointmentItem } from 'app/components/serviceItem/openAppointmentItem';
import { addAlpha } from 'app/helpers/colors';

export const BookAppointmentWrapper = styled.div`
	margin-bottom: ${(props) => props.theme.spacing['2']};
`;

export const SpecializationWrapper = styled.div``;

export const ButtonsWrapper = styled.div`
	display: flex;
	justify-content: center;
`;

export const LoaderWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	margin: ${(props) => props.theme.spacing['1']} 0;
`;

export const OpenAppointmentItemDecorated = styled(OpenAppointmentItem)`
	margin: ${(props) => props.theme.spacing['1']} 0;
	${roundedStyles}
`;

export const OpenAppointmentItemSimple = styled(OpenAppointmentItem)`
	margin: ${(props) => props.theme.spacing['1']} 0;
	border: none;
`;

export const PrevNextButtonsWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: ${(props) => props.theme.spacing['1']} 0;
`;

export const PrevButton = styled(PillButton)`
	> span {
		display: flex;
		align-items: center;
	}

	svg {
		margin-right: ${(props) => props.theme.spacing['0_5']};
	}
`;

export const NextButton = styled(PillButton)`
	> span {
		display: flex;
		align-items: center;
	}

	svg {
		margin-left: ${(props) => props.theme.spacing['0_5']};
	}
`;

export const CounterWrapper = styled.div`
	background-color: ${(props) => addAlpha(props.theme.colors.primary, 0.1)};
	color: ${(props) => props.theme.colors.primary};
	padding: ${(props) => props.theme.spacing['1']};
	margin: ${(props) => props.theme.spacing['1']} 0;
	box-shadow: ${(props) => props.theme.boxShadows.boxShadow3};
	border-radius: ${(props) => props.theme.border.radius.m};
`;

export const TimeWrapper = styled.div`
	margin: ${(props) => props.theme.spacing['1']} 0;
	text-align: center;
`;

export const SearchResultsSection = styled.div<{ isFetched: boolean }>`
	// to prevent screen jumping when results are empty
	${(props) =>
		props.isFetched &&
		`
		min-height: 220px;
	`}
`;

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Text } from '@terveystalo/design-system-react-components';

import { CustomSelect } from 'common/components/forms/select/FormikSelectField';

import {
	CustomDialog,
	CustomDialogHeadline,
} from 'app/styles/components/dialog/dialog';
import { CustomButton } from 'app/styles/components/button/button';
import OrganizationService, { OrganizationsInfo } from 'app/services/contact';
import { getConfigAsync } from 'app/store/configuration/slices/configuration.slice';
import WebAuthService from 'app/services/webAuth/webAuth';
import { config } from 'app/config/config';

interface ChatCodeInfoModalProps {
	isOpen: boolean;
	toggleModal(open: boolean): void;
	organizations: OrganizationsInfo['organisations'];
}

export const SelectOrganizationModal = ({
	isOpen,
	// toggleModal,
	organizations,
}: ChatCodeInfoModalProps) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const history = useHistory();
	const [selectedOrganization, setSelectedOrganization] = useState<
		null | string
	>(null);

	const { mutate: selectOrganization } = useMutation(
		(organizationId: string) =>
			OrganizationService.selectOrganization(organizationId),
		{
			onSuccess: async () => {
				await dispatch(getConfigAsync({}));
				history.push(config.paths.terms);
			},
		},
	);

	const toggleSelectOrganizationModal = () => {
		history.push(config.paths.contact);
	};

	const goToContactForm = () => {
		history.push(config.paths.contact);
	};

	const logoutUser = () => {
		WebAuthService.logout();
	};

	const handleChangeArea = (event: React.ChangeEvent<HTMLSelectElement>) => {
		setSelectedOrganization(event.target.value);
	};

	const handleSubmitChangeLocation = () => {
		if (selectedOrganization) {
			selectOrganization(selectedOrganization);
		}
	};

	const noOrganizations = organizations.length === 0;

	return (
		<CustomDialog
			className="selectOrganizationModal"
			open={isOpen}
			onClose={toggleSelectOrganizationModal}
			title={
				<CustomDialogHeadline>
					{noOrganizations && (
						<span>{t('selectedOrganizationModal.title.none')}</span>
					)}
					{!noOrganizations && (
						<span>{t('selectedOrganizationModal.title')}</span>
					)}
				</CustomDialogHeadline>
			}
			description={
				<div>
					<Text size="m">
						{noOrganizations && (
							<span>{t('selectedOrganizationModal.text.none')}</span>
						)}
						{!noOrganizations && (
							<span>{t('selectedOrganizationModal.text')}</span>
						)}
					</Text>
				</div>
			}
		>
			{noOrganizations && (
				<>
					<CustomButton onClick={logoutUser}>{t('common.logOut')}</CustomButton>
					<CustomButton variant="secondary" onClick={goToContactForm}>
						{t('selectedOrganizationModal.changeButton')}
					</CustomButton>
				</>
			)}
			{!noOrganizations && (
				<>
					<div>
						<CustomSelect
							id="selectArea"
							label={t('changeMyLocation.selectArea')}
							onChange={handleChangeArea}
							options={[
								{ label: '', value: '' },
								...organizations.map(
									(org: OrganizationsInfo['organisations'][0]) => ({
										label: org.name,
										value: org.id,
									}),
								),
							]}
						/>
					</div>
					<CustomButton
						disabled={!selectedOrganization}
						onClick={handleSubmitChangeLocation}
						data-testid="selectOrganization-submit-button"
					>
						{t('changeMyLocation.button')}
					</CustomButton>
					<CustomButton variant="secondary" onClick={logoutUser}>
						{t('common.logOut')}
					</CustomButton>
				</>
			)}
		</CustomDialog>
	);
};

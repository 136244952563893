import React, { useState, useRef, useEffect, useContext } from 'react';
import { ThemeContext } from 'styled-components';

import {
	Button,
	Icon,
	Text,
} from '@terveystalo/design-system-react-components';

import { CustomTextArea } from 'common/components/forms/textArea/TextArea';
import { PrimaryPillButton } from 'common/styles/components/button/button';

import {
	ContentWrapper,
	BottomArea,
	UploadWrapper,
	SendWrapper,
	IconButton,
	FileButton,
	SimpleMessageBoxWrapper,
	SimpleTextareaWrapper,
	SimpleSendWrapper,
	SimpleAttachmentsArea,
	CharactersLimitWrapper,
} from './messageBox.styles';

interface MessageBoxProps {
	onSend: ({
		text,
		attachments,
	}: {
		text: string;
		attachments: Array<File>;
	}) => void;
	isSending?: boolean;
	sendingSuccess?: any;
	onChange?: (text: string, attachments: Array<File>) => void;
	onBlur?: () => void;
	initialText?: string;
	labels?: {
		attachButton?: string;
		sendButton?: string;
		attachemnts?: string;
		message?: string;
	};
	disableSend?: boolean;
	invalid?: boolean;
	simple?: boolean;
	charactersLimit?: number;
	hideAttachments?: boolean;
}

const defaultLabels = {
	attachButton: 'Attach a file',
	sendButton: 'Send',
	attachments: 'Attachments',
};

export const MessageBox = ({
	onSend,
	isSending,
	sendingSuccess,
	onChange,
	onBlur,
	labels,
	initialText,
	disableSend,
	invalid,
	simple,
	charactersLimit,
	hideAttachments,
}: MessageBoxProps) => {
	const [attachments, setAttachments] = useState<Array<File>>([]);
	const [text, setText] = useState(initialText || '');
	const fileRef = useRef<any>();
	const theme = useContext(ThemeContext);

	useEffect(() => {
		if (sendingSuccess) {
			setText(initialText || '');

			setAttachments([]);
			if (fileRef?.current?.value) {
				fileRef.current.value = null;
			}

			if (onChange) {
				onChange('', []);
			}
		}
	}, [sendingSuccess]);

	const {
		attachButton,
		sendButton,
		attachments: attachmentsLabel,
	} = {
		...defaultLabels,
		...labels,
	};

	const handleFileChange = (event: any) => {
		const fileList: Array<File> = event.target.files;

		if (fileList.length > 0) {
			setAttachments([...attachments, ...fileList]);
			if (onChange) {
				onChange(text, [...attachments, ...fileList]);
			}
		}
	};

	const handleAddAttachment = () => {
		fileRef?.current?.click();
	};

	const handleSend = () => {
		onSend({ text, attachments });
	};

	const handleChangeText = (ev: React.ChangeEvent<HTMLInputElement>) => {
		if (!charactersLimit || ev.target.value.length <= charactersLimit) {
			if (onChange) {
				onChange(ev.target.value, attachments);
			}
			setText(ev.target.value);
		}
	};

	const handleBlur = () => {
		if (onBlur) {
			onBlur();
		}
	};

	const handleRemoveFile = (index: number) => () => {
		const files = [...attachments];
		files.splice(index, 1);
		setAttachments(files);

		if (onChange) {
			onChange(text, files);
		}

		if (fileRef?.current?.value) {
			fileRef.current.value = null;
		}
	};

	const renderComplex = () => (
		<ContentWrapper>
			<CustomTextArea
				onChange={handleChangeText}
				name="messageBoxText"
				invalid={invalid}
				onBlur={handleBlur}
				value={text}
			/>
			<BottomArea>
				<UploadWrapper>
					<FileButton
						type="button"
						onClick={handleAddAttachment}
						iconLeft="Attachment"
					>
						{attachButton}
					</FileButton>
					{attachments.map((file: File, index: number) => (
						<FileButton>
							{file.name}{' '}
							<IconButton type="button" onClick={handleRemoveFile(index)}>
								<Icon name="CloseSmall" color="attentionRed" />
							</IconButton>
						</FileButton>
					))}
					<input
						ref={fileRef}
						type="file"
						id="upload-file"
						multiple
						hidden
						tabIndex={-1}
						onChange={handleFileChange}
						data-testid="organization-add-logo"
					/>
				</UploadWrapper>
				<SendWrapper>
					<PrimaryPillButton
						onClick={handleSend}
						disabled={disableSend || isSending}
					>
						{isSending && <Icon name="Spinner" color="white" />}
						{sendButton}
						<Icon name="ArrowUpRight" color="white" />
					</PrimaryPillButton>
				</SendWrapper>
			</BottomArea>
		</ContentWrapper>
	);

	const renderSimple = () => (
		<SimpleMessageBoxWrapper>
			<SimpleTextareaWrapper>
				<CustomTextArea
					onChange={handleChangeText}
					name="messageBoxText"
					invalid={invalid}
					onBlur={handleBlur}
					value={text}
				/>
				<CharactersLimitWrapper>
					{text.length}/{charactersLimit}
				</CharactersLimitWrapper>
			</SimpleTextareaWrapper>

			{!hideAttachments && (
				<div>
					<Text size="m" weight="bold">
						{attachmentsLabel}
					</Text>
					<br />

					<div>
						{attachments.map((file: File, index: number) => (
							<FileButton>
								{file.name}{' '}
								<IconButton type="button" onClick={handleRemoveFile(index)}>
									<Icon name="CloseSmall" color="attentionRed" />
								</IconButton>
							</FileButton>
						))}
					</div>
					<br />
					<SimpleAttachmentsArea type="button" onClick={handleAddAttachment}>
						<Icon name="Attachment" color={theme.colors.primary} />
						{attachButton} (max 10mb)(.pdf,.jpg,.jpeg,.png)
					</SimpleAttachmentsArea>
					<input
						ref={fileRef}
						type="file"
						id="upload-file"
						accept=".jpg,.jpeg,.png,.pdf"
						multiple
						hidden
						tabIndex={-1}
						onChange={handleFileChange}
					/>
				</div>
			)}

			<SimpleSendWrapper>
				<Button
					onClick={handleSend}
					disabled={disableSend || isSending}
					{...(isSending ? { icon: 'Spinner' } : {})}
				>
					{sendButton}
				</Button>
			</SimpleSendWrapper>
		</SimpleMessageBoxWrapper>
	);

	return (
		<div>
			<div>{!simple && renderComplex()}</div>
			<div>{simple && renderSimple()}</div>
		</div>
	);
};

import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@terveystalo/design-system-react-components';
import { useHistory } from 'react-router-dom';
import LocalStorageService from 'common/services/localStorage';
import {
	GuestThankYouPageWrapper,
	GuestThankYouContentWrapper,
	GuestLogoutWrapper,
	GuestTextBlock,
	Title,
} from './thankYouGuest.styles';

export const ThankYouGuest = () => {
	const { t } = useTranslation();
	const history = useHistory();
	const handleLogout = () => {
		LocalStorageService.remove('guestUserToken');
		history.push('/');
	};

	return (
		<GuestThankYouPageWrapper>
			<Title>{t('guestThankYou.content.heading')}</Title>
			<GuestThankYouContentWrapper>
				<GuestTextBlock>{t('guestThankYou.content.thankyou')}</GuestTextBlock>
				<GuestTextBlock>{t('guestThankYou.content.notify')}</GuestTextBlock>
				<GuestTextBlock>{t('guestThankYou.content.logout')}</GuestTextBlock>
				<GuestLogoutWrapper>
					<Button onClick={handleLogout}>
						{t('guestThankYou.logout.button')}
					</Button>
				</GuestLogoutWrapper>
			</GuestThankYouContentWrapper>
		</GuestThankYouPageWrapper>
	);
};

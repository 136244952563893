import AnalyticsService from 'common/services/analytics/analytics';

export const openNinChatCommon = async (selectedChatDetails: any) => {
	AnalyticsService.sendCustomEvent(
		'remoteAppointmentStartAppointment',
		'remote appointment',
		'start appointment',
		'patient chat',
		{
			serviceType: selectedChatDetails.chatCodeService
				? 'serviceWithChatCode'
				: 'servicesForYou',
			service: selectedChatDetails.serviceName,
		},
	);
};

export const closeAppointmentCommon = (selectedChatDetails: any) => {
	AnalyticsService.sendCustomEvent(
		'remoteAppointmentCancelAppointment',
		'remote appointment',
		'cancel appointment',
		'patient chat',
		{
			serviceType: selectedChatDetails.chatCodeService
				? 'serviceWithChatCode'
				: 'servicesForYou',
			service: selectedChatDetails.serviceName,
		},
	);
};

import {
	createSlice,
	createSelector,
	createAsyncThunk,
} from '@reduxjs/toolkit';

import { ResponseError } from 'common/typings/response';

import { RootState } from 'adminApplication/store';
import OrganizationService, {
	OrganizationData,
} from 'adminApplication/services/organization';

export interface OrganizationState {
	data: OrganizationData | null;
	isLoading: boolean;
	isSaving: boolean;
	error: any;
	savingError: any;
}

const initialState: OrganizationState = {
	data: null,
	isLoading: false,
	isSaving: false,
	error: null,
	savingError: null,
};

export const getOrganizationAsync: any = createAsyncThunk(
	'organization/getOrganization',
	async (organizationId: string, thunkAPI) => {
		try {
			const organizationData: OrganizationData & ResponseError =
				await OrganizationService.getOrganization(organizationId);

			if (organizationData.status === 'error') {
				return thunkAPI.rejectWithValue(organizationData.statusCode);
			}

			return organizationData;
		} catch (err) {
			return thunkAPI.rejectWithValue(err);
		}
	},
);

export const organizationSlice = createSlice({
	name: 'organization',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(getOrganizationAsync.pending, (state) => {
			state.isLoading = true;
		});

		builder.addCase(getOrganizationAsync.rejected, (state, action) => {
			state.error = action.payload;
			state.data = null;
			state.isLoading = false;
		});

		builder.addCase(getOrganizationAsync.fulfilled, (state, action) => {
			state.data = action.payload;
			state.isLoading = false;
		});
	},
});

export default organizationSlice.reducer;

export const selectOrganization = createSelector(
	(state: RootState) => state.organization,
	(organization) => organization,
);

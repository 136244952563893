import React, { useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { ThemeContext } from 'styled-components';

import { Icon } from '@terveystalo/design-system-react-components';

import { convertDate } from 'common/helpers/dates';
import AppointmentHistoryService from 'app/services/appointmentHistory/index';
import {
	CustomerMessageBubble,
	CustomerMessageBubbleText,
	CustomTimeText,
	ExpertMessageBubble,
	ExpertMessageBubbleText,
	SpinnerWrapper,
} from './appointmentHistoryChat.styles';
import { ContentSection } from '../../home.styles';

export type Message = {
	message_time: number;
	message_type: string;
	message_username: string;
	message_user_id: string;
	message_text: string;
};

export type File = {
	id: string;
	filename: string;
};

export interface AppointmentChat {
	messages: Message[];
	files: File[];
	members: {
		[key: string]: {
			[key: string]: string;
		};
	};
}

const AppointmentHistoryChat = () => {
	const { id } = useParams<{ id: string }>();
	const [participants, setParticipants] = useState({} as any);
	const theme = useContext(ThemeContext);

	const {
		data: appointmentChat,
		isFetching: isFetchingChat,
		refetch: fetchAppointmentChat,
	} = useQuery(
		'appointmentChat',
		() => AppointmentHistoryService.getAppointmentHistoryChat(id),
		{ enabled: false },
	);

	useEffect(() => {
		fetchAppointmentChat();
	}, []);

	const hasAppointmentChat = !!appointmentChat;

	useEffect(() => {
		if (!hasAppointmentChat || !appointmentChat.members) return;
		const participantIds = Object.keys(appointmentChat.members);
		const participantIdsAndRoles = {} as { [key: string]: string };
		participantIds.forEach((participantId) => {
			const value = Object.keys(appointmentChat.members[participantId])[0];
			participantIdsAndRoles[participantId] = value;
		});
		setParticipants(participantIdsAndRoles);
	}, [appointmentChat, hasAppointmentChat]);

	const parseDate = (dateValue: number) => {
		const dateString = `
			${convertDate(dateValue * 1000, 'HH:mm')}
		`;
		return dateString;
	};

	const renderChat = () => {
		if (!hasAppointmentChat || !appointmentChat.messages?.length)
			return undefined;
		return appointmentChat?.messages
			.sort((curr, prev) => prev.message_time - curr.message_time)
			.map((message) => {
				const date = parseDate(message.message_time);
				if (participants[message.message_user_id] === 'agent') {
					return (
						<ExpertMessageBubble>
							<CustomTimeText size="m" weight="light">
								{message.message_username}&nbsp;&nbsp;{date}
							</CustomTimeText>
							<ExpertMessageBubbleText size="m">
								{message.message_text}
							</ExpertMessageBubbleText>
						</ExpertMessageBubble>
					);
				}
				return (
					<CustomerMessageBubble>
						<CustomTimeText size="m" weight="light">
							{message.message_username}&nbsp;&nbsp;&nbsp;{date}
						</CustomTimeText>
						<CustomerMessageBubbleText size="m">
							{message.message_text}
						</CustomerMessageBubbleText>
					</CustomerMessageBubble>
				);
			});
	};

	return (
		<ContentSection>
			{isFetchingChat && (
				<SpinnerWrapper>
					<br />
					<br />
					<Icon name="Spinner" color={theme.colors.primary} />
				</SpinnerWrapper>
			)}
			{!isFetchingChat && renderChat()}
		</ContentSection>
	);
};

export default AppointmentHistoryChat;

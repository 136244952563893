import React, { useContext, useEffect } from 'react';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import {
	Icon,
	Subtitle,
	Text,
} from '@terveystalo/design-system-react-components';
import { ThemeContext } from 'styled-components';
import { useQuery } from 'react-query';

import AppointmentHistoryService from 'app/services/appointmentHistory/index';
import AnalyticsService from 'common/services/analytics/analytics';

import { ContentSection } from '../home.styles';
import {
	AppointmentHistoryWrapper,
	SpinnerWrapper,
	SubtitleWrapper,
} from './appointmentHistory.styles';
import { AppointmentHistoryItem } from './components/appointmentHistoryItem';

export interface AppointmentHistoryItemDocument {
	id: number;
	filename: string;
}

export interface AppointmentHistoryItemType {
	id: string;
	created_at: string;
	expert_name: string;
	files?: AppointmentHistoryItemDocument[];
}

export type AppointmentHistory = AppointmentHistoryItemType[];

export const AppointmentHistoryPage = () => {
	const { t } = useTranslation();
	const theme = useContext(ThemeContext);
	const lang = i18next.language;

	const {
		data: appointmentHistory,
		isFetching,
		refetch: fetchData,
	} = useQuery(
		'appointmentHistory',
		() => AppointmentHistoryService.getAppointmentHistory(),
		{ initialData: [] as AppointmentHistory, enabled: false },
	);
	const hasAppointmentHistory = !!appointmentHistory;

	useEffect(() => {
		document.title = t('pageTitles.appointmentHistory');
	}, [lang]);

	useEffect(() => {
		document.title = t('pageTitles.appointmentHistory');
		AnalyticsService.sendPageView(t('pageTitles.appointmentHistory'));
	}, []);

	useEffect(() => {
		fetchData();
	}, []);

	const renderAppointmentHistory = () => {
		if (!hasAppointmentHistory || !appointmentHistory.length) return undefined;
		const sortedAppointmentHistory = appointmentHistory.sort(
			(curr, prev) =>
				new Date(prev.created_at).getTime() -
				new Date(curr.created_at).getTime(),
		);
		return sortedAppointmentHistory.map(
			(appointmentItem: AppointmentHistoryItemType) => (
				<AppointmentHistoryItem
					appointmentItem={appointmentItem}
					key={appointmentItem.id}
				/>
			),
		);
	};

	return (
		<AppointmentHistoryWrapper>
			<Subtitle size="m" color={theme.colors.zodiacBlack} as="h3">
				{t('appointmentHistory.title')}
			</Subtitle>
			<ContentSection>
				<Text size="m">{t('appointmentHistory.explanation1')}</Text>
				<Text size="m" weight="bold">
					{t('appointmentHistory.explanation2')}
				</Text>
				<Text size="m">{t('appointmentHistory.explanation3')}</Text>
			</ContentSection>
			<SubtitleWrapper>
				<Subtitle size="s" color={theme.colors.primary}>
					{t('appointmentHistory.subtitle')}
				</Subtitle>
			</SubtitleWrapper>
			{isFetching && (
				<SpinnerWrapper>
					<br />
					<br />
					<Icon name="Spinner" color={theme.colors.primary} />
				</SpinnerWrapper>
			)}
			{!isFetching && renderAppointmentHistory()}
		</AppointmentHistoryWrapper>
	);
};

import { Response, ResponseError } from 'common/typings/response';
import { CommonApiService } from '../config';

export interface SurveyAccess {
	url?: string;
	token?: string;
	theme?: any;
}

export interface SurveyCloseReponse {
	status: string;
	statusCode?: string;
	message?: string;
}

export interface SurveyTemplate {
	formTitle: {
		en: string;
		fi: string;
		sv: string;
	};
	id: string;
}

export interface AttachedSurvey {
	name: {
		en: string;
		fi: string;
		sv: string;
	};
	id: string;
}

export interface ExpertSurveys {
	ownSurveys: Survey[];
	forwardedSurveys: ForwardedSurvey[];
}

export interface Survey {
	createdAt: string;
	respondedAt: string;
	/** JSON, including 3 languages */
	surveyName: string;
	patientName: string;
	patientSsn: string;
	responseId: string;
	remindersSent: number;
}

export interface ForwardedSurvey extends Survey {
	forwardedBy: string;
	forwardedAt: string;
}

export type SurveyTemplateResponse = Array<SurveyTemplate>;
class SurveyService {
	apiService: CommonApiService = new CommonApiService();

	appType: string = 'admin';

	public setApiService(apiService: CommonApiService) {
		this.apiService = apiService;

		if (this.apiService.type === 'expert') {
			this.appType = 'expert';
		}
	}

	async getSurveyUrlAndAuthToken(
		responseId: string,
		expert?: boolean,
	): Promise<SurveyAccess> {
		try {
			let response: Response<SurveyAccess>;

			if (expert) {
				response = await this.apiService.get(
					`/survey-expert/token/${responseId}`,
				);
			} else {
				response = await this.apiService.get(`/survey/token/${responseId}`);
			}

			if (response.data?.status !== 'error') {
				return response.data;
			}
			// eslint-disable-next-line no-empty
		} catch (error) {
			return { url: undefined, token: undefined, theme: undefined };
		}
		return { url: undefined, token: undefined, theme: undefined };
	}

	async closeSurvey(responseId: string) {
		try {
			const response: Response<SurveyCloseReponse> = await this.apiService.get(
				`/survey/close/${responseId}`,
			);
			if (response.data.status !== 'error') {
				return response.data;
			}
		} catch (error) {
			return {};
		}
		return {};
	}

	async getOrganisationSurveys() {
		const response: Response<SurveyTemplateResponse | ResponseError> =
			await this.apiService.get(
				`/${
					this.appType === 'admin' ? 'survey-admin' : 'survey-expert'
				}/templates`,
			);

		return response.data;
	}

	/** endpoint allowed only for super-admin */
	async getAllSurveys() {
		const response: Response<SurveyTemplateResponse | ResponseError> =
			await this.apiService.get('/survey-admin/templates-all');

		return response.data;
	}

	async getExpertSurveys(appointmentUuids?: string[]) {
		const params = appointmentUuids
			? { appointmentUuids: [...appointmentUuids] }
			: undefined;

		const response: Response<ExpertSurveys & ResponseError> =
			await this.apiService.get('survey-expert/surveys', {
				...params,
			});
		return response.data;
	}

	async forwardSurvey(responseId: string, expertUuid: string) {
		const response: Response<any & ResponseError> = await this.apiService.post(
			`/${this.appType === 'admin' ? 'survey-admin' : 'survey-expert'}/forward`,
			{ responseId, expertUuid },
		);
		return response.data;
	}

	async sendReminder(responseId: string) {
		const response: Response<any & ResponseError> = await this.apiService.post(
			'survey-expert/reminder',
			{ responseId },
		);
		return response.data;
	}
}

export default new SurveyService();

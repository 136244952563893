import React, {
	useContext,
	useEffect,
	useState,
	useRef,
	useCallback,
} from 'react';
import { useSelector } from 'react-redux';
import i18next from 'i18next';
import { ThemeContext } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import {
	Icon,
	Subtitle,
	Text,
} from '@terveystalo/design-system-react-components';

import { Config } from 'common/typings/organisationConfig';

import { selectConfig } from 'app/store/configuration/slices/configuration.slice';
import WebAuthService from 'app/services/webAuth/webAuth';

import { CustomButton } from 'app/styles/components/button/button';
import { Rte } from 'app/components/rte/rte';
import { config as appConfig } from 'app/config/config';

import {
	ToggableSection,
	FeedbackListItem,
	ListBulletIcon,
	ToggleButton,
	FeedbackLink,
	ButtonWrapper,
	FeedbackList,
	ExternalIconWrapper,
	ChevronWrapper,
	ThankYouWrapper,
} from './thankYou.styles';

export const ThankYou = () => {
	const [myIssuesAttentionOpen, setMyIssuesAttentionOpen] = useState(false);
	const [myIssuesResolvedOpen, setMyIssuesResolvedOpen] = useState(false);
	const [myIssuesAttentionTransitionEnd, setMyIssuesAttentionTransitionEnd] =
		useState(true);

	const [myIssuesResolvedTransitionEnd, setMyIssuesResolvedTransitionEnd] =
		useState(true);

	const [buttons, setButtons] = useState<
		Array<Config['redirectionButtons'][0] & { isPrimary: boolean }>
	>([]);

	const attentionListRef = useRef<any>();
	const resolvedListRef = useRef<any>();

	const config = useSelector(selectConfig);
	const { t } = useTranslation();
	const theme = useContext(ThemeContext);
	const history = useHistory();

	const lang: string = i18next.language;

	const { thankYouLinksAddAttention, thankYouLinksResolved } = config.data;

	useEffect(() => {
		if (config.data) {
			const selectedButtons: any = [];

			config.data.redirectionSelectedButtons.forEach((id: string) => {
				const buttonInfo = config.data.redirectionButtons.find(
					(but: Config['redirectionButtons'][0]) => id === but.id,
				);
				const isPrimary =
					config.data.redirectionPrimaryButtons.indexOf(id) !== -1;

				selectedButtons.push({
					id,
					details: {
						en: {
							text: '',
							url: '',
						},
						fi: {
							text: '',
							url: '',
						},
						se: {
							text: '',
							url: '',
						},
					},
					isPrimary,
					...buttonInfo,
				});
			});

			setButtons(
				// true first
				selectedButtons.sort(
					(a: any, b: any) => Number(b.isPrimary) - Number(a.isPrimary),
				),
			);
		}
	}, [config]);

	const transitionEndHandler = useCallback(() => {
		setMyIssuesAttentionTransitionEnd(true);
	}, []);

	const toggleMyIssuesAttentionSection = (ev: any) => {
		ev.currentTarget.nextSibling.removeEventListener(
			'transitionend',
			transitionEndHandler,
		);
		ev.currentTarget.nextSibling.addEventListener(
			'transitionend',
			transitionEndHandler,
		);

		if (myIssuesAttentionOpen) {
			ev.currentTarget.nextSibling.style.height = 0;
		} else {
			ev.currentTarget.nextSibling.style.height = `${ev.currentTarget.nextSibling.scrollHeight}px`;
		}

		setMyIssuesAttentionTransitionEnd(false);
		setMyIssuesAttentionOpen(!myIssuesAttentionOpen);
	};

	const resolvedTransitionEndHandler = useCallback(() => {
		setMyIssuesResolvedTransitionEnd(true);
	}, []);

	const toggleMyIssuesResolvedSection = (ev: any) => {
		ev.currentTarget.nextSibling.removeEventListener(
			'transitionend',
			resolvedTransitionEndHandler,
		);
		ev.currentTarget.nextSibling.addEventListener(
			'transitionend',
			resolvedTransitionEndHandler,
		);

		setMyIssuesResolvedTransitionEnd(false);

		if (myIssuesResolvedOpen) {
			ev.currentTarget.nextSibling.style.height = 0;
		} else {
			ev.currentTarget.nextSibling.style.height = `${ev.currentTarget.nextSibling.scrollHeight}px`;
		}

		setMyIssuesResolvedOpen(!myIssuesResolvedOpen);
	};

	useEffect(() => {
		if (thankYouLinksAddAttention.length < 4) {
			setMyIssuesAttentionOpen(true);

			const attList: HTMLElement | null = document.querySelector(
				'.my-issues-attention-list',
			);

			if (attList) {
				attList.style.height = `${attList.scrollHeight}px`;
			}
		}

		if (thankYouLinksResolved.length < 4) {
			setMyIssuesResolvedOpen(true);

			const attList: HTMLElement | null = document.querySelector(
				'.my-issues-resolved-list',
			);

			if (attList) {
				attList.style.height = `${attList.scrollHeight}px`;
			}
		}
		// eslint-disable-next-line
	}, []);

	const handleLogout = () => {
		WebAuthService.logout();
	};

	const handleBackToServiceSelection = () => {
		history.push(appConfig.paths.serviceSelection);
	};

	return (
		<ThankYouWrapper>
			{config.data?.thankYouText[lang] && (
				<>
					<Rte content={config.data?.thankYouText[lang]} />
					<br />
				</>
			)}
			<div>
				{thankYouLinksAddAttention.length > 0 && (
					<ToggableSection>
						<ToggleButton
							aria-expanded={myIssuesAttentionOpen}
							type="button"
							onClick={toggleMyIssuesAttentionSection}
						>
							<Subtitle color={theme.colors.primary} size="m">
								{config.data?.thankYouLinksAddAttentionHeadline[lang]}
							</Subtitle>
							<ChevronWrapper open={myIssuesAttentionOpen}>
								<Icon name="ChevronDown" color={theme.colors.primary} />
							</ChevronWrapper>
						</ToggleButton>
						<FeedbackList
							className="my-issues-attention-list"
							open={myIssuesAttentionOpen}
							transitionEnd={myIssuesAttentionTransitionEnd}
							items={thankYouLinksAddAttention.length}
							ref={attentionListRef}
						>
							{thankYouLinksAddAttention.map(
								(item: Config['thankYouLinksAddAttention'][0]) => (
									<FeedbackListItem as="div">
										<FeedbackLink
											href={item[lang].url}
											target="_blank"
											rel="noreferrer"
										>
											<ListBulletIcon
												name="Bullet"
												color={theme.colors.primary}
											/>
											<Text size="m">{item[lang].text}</Text>
											<span className="sr-only">(Open in new window)</span>
											<ExternalIconWrapper>
												<Icon
													name="ExternalLink"
													color={theme.colors.primary}
												/>
											</ExternalIconWrapper>
										</FeedbackLink>
									</FeedbackListItem>
								),
							)}
						</FeedbackList>
					</ToggableSection>
				)}
				{thankYouLinksResolved.length > 0 && (
					<ToggableSection>
						<ToggleButton
							aria-expanded={myIssuesResolvedOpen}
							type="button"
							onClick={toggleMyIssuesResolvedSection}
						>
							<Subtitle color={theme.colors.primary} size="m">
								{config.data?.thankYouLinksResolvedHeadline[lang]}
							</Subtitle>
							<ChevronWrapper open={myIssuesResolvedOpen}>
								<Icon name="ChevronDown" color={theme.colors.primary} />
							</ChevronWrapper>
						</ToggleButton>
						<FeedbackList
							className="my-issues-resolved-list"
							open={myIssuesResolvedOpen}
							items={thankYouLinksResolved.length}
							transitionEnd={myIssuesResolvedTransitionEnd}
							ref={resolvedListRef}
						>
							{thankYouLinksResolved.map(
								(item: Config['thankYouLinksResolved'][0]) => (
									<FeedbackListItem as="div">
										<FeedbackLink
											href={item[lang].url}
											target="_blank"
											rel="noreferrer"
										>
											<ListBulletIcon
												name="Bullet"
												color={theme.colors.primary}
											/>
											<Text size="m">{item[lang].text}</Text>
											<span className="sr-only">(Open in new window)</span>
											<ExternalIconWrapper>
												<Icon
													name="ExternalLink"
													width={24}
													height={24}
													color={theme.colors.primary}
												/>
											</ExternalIconWrapper>
										</FeedbackLink>
									</FeedbackListItem>
								),
							)}
						</FeedbackList>
					</ToggableSection>
				)}
				<ButtonWrapper>
					{buttons.map((button) => {
						if (button.id === 'logOut') {
							return (
								<CustomButton
									onClick={handleLogout}
									variant={button.isPrimary ? 'primary' : 'secondary'}
								>
									{t('common.logOut')}
								</CustomButton>
							);
						}

						if (button.id === 'backToServiceSelection') {
							return (
								<CustomButton
									onClick={handleBackToServiceSelection}
									variant={button.isPrimary ? 'primary' : 'secondary'}
								>
									{t('common.backToServiceSelection')}
								</CustomButton>
							);
						}
						return (
							<CustomButton
								role="link"
								onClick={() => window.open(button.details[lang].url)}
								variant={button.isPrimary ? 'primary' : 'secondary'}
							>
								{button.details[lang].text}
							</CustomButton>
						);
					})}
				</ButtonWrapper>
			</div>
		</ThankYouWrapper>
	);
};

import { combineReducers, Reducer } from 'redux';
import expertUserReducer from './expertUser/expertUser.slice';
import organisationConfigReducer from './organisationConfig/organisationConfig.slice';

const rootReducer: Reducer = combineReducers({
	expertUser: expertUserReducer,
	organisationConfig: organisationConfigReducer,
});

export default rootReducer;

import React, { useContext, useEffect, useState } from 'react';
import { Switch, Route, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ThemeProvider } from 'styled-components';
// import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

// import { Auth0UserProfile } from 'auth0-js';
import { GuestUser } from 'app/pages/home/guestUser/guestUser';
import { ThankYouGuest } from 'app/pages/home/feedback/components/thankYouGuest';
import { Icon } from '@terveystalo/design-system-react-components';

import { PrivateRoute } from 'common/helpers/privateRoute';

import { GlobalStyles } from 'app/styles/global/global';
import { DesignSystemOverrides } from 'app/styles/global/designsystem';

import { defaultTheme, prepareUserTheme } from 'app/styles/themes/themes';
import Home from 'app/pages/home/home';
import {
	getConfigAsync,
	selectConfig,
} from 'app/store/configuration/slices/configuration.slice';
import WebAuthService, { UserInitialData } from 'app/services/webAuth/webAuth';
import { AppContext } from 'app/context/appContext';
import { CustomButton } from 'app/styles/components/button/button';
import {
	getContactAsync,
	selectContact,
} from 'app/store/contact/slices/contact.slice';
import {
	CustomDialog,
	CustomDialogHeadline,
} from 'app/styles/components/dialog/dialog';
import { getCompanyId } from 'app/helpers/url';
import { ContactSubPage } from 'app/pages/home/contact/contact';
import { config } from 'app/config/config';
import { TermsSubPage } from 'app/pages/home/terms/terms';
import { WelcomeSubpage } from 'app/pages/home/welcome/welcome';
import { Survey } from 'common/pages/survey/survey';
import LocalStorageService from 'common/services/localStorage';

import OrganizationService, {
	Contact,
	OrganizationsInfo,
} from 'app/services/contact';
import {
	getCitiesAsync,
	selectCities,
} from 'app/store/cities/slices/cities.slice';
import AnalyticsService from 'common/services/analytics/analytics';
import { SelectedOrganizationContext } from 'app/context/selectedOrganizationContext';
import { SelectOrganizationModal } from 'app/components/selectOrganizationModal/selectOrganizationModal';
import { isMobile } from 'common/helpers/mobile';
import { ChangeMyLocationPage } from 'app/pages/home/changeMyLocation/changeMyLocation';
import PatientApiService from 'app/services/config';

import { Header } from '../header/header';
import { Footer } from '../footer/footer';
import { MainMenu } from '../mainMenu/mainMenu';
import { Toast } from '../toast/toast';
import { Cookie } from '../cookie/cookie';
import { ActOnBehalfModal } from '../actOnBehalfModal/actOnBehalfModal';
import { ProfileMenu } from '../profileMenu/profileMenu';

import {
	Content,
	ContentInner,
	LoaderWrapper,
	ApplicationWrapper,
	ProfileWrapper,
	ActOnBehalfInfo,
	SkipToContentLink,
	ActOnBehalfEmpty,
} from './application.styles';

export const prepareUserData = (
	// auth0UserData: Auth0UserProfile,
	userInitialData: UserInitialData,
	contactData: Partial<Contact>,
) => ({
	// auth0: auth0UserData,
	telia: userInitialData,
	...{
		firstName: contactData?.firstName || userInitialData.first_name || '', // auth0UserData.given_name,
		lastName: contactData?.lastName || userInitialData.last_name || '', // auth0UserData.family_name,
		email: contactData?.email || userInitialData?.email || '', // auth0UserData.email,
		street: contactData?.street || userInitialData?.street || '',
		code: contactData?.code || userInitialData?.code || '',
		city: contactData?.city || userInitialData?.city || '',
		mobile: contactData?.mobile || '',
		termsAccepted: contactData?.termsAccepted || '',
		nationality:
			contactData?.nationality || userInitialData.nationality_code || '',
		country: contactData?.country || userInitialData.country_code || '',
	},
});

const Application = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const { t } = useTranslation();
	const [selectedTheme, setSelectedTheme] = useState(defaultTheme);
	const configData = useSelector(selectConfig);
	const contactData = useSelector(selectContact);
	const citiesData = useSelector(selectCities);
	const { selectedOrganization, setSelectedOrganization } = useContext(
		SelectedOrganizationContext,
	);
	const [organizationSelectionDone, setOrganizationSelectionDone] =
		useState(false);
	// const [auth0UserData, setAuth0UserData] = useState<any>(false);
	const [userInitialData, setUserInitialData] = useState<any>(false);

	const {
		setUserDetails,
		userDetails,
		logoutDialogOpen,
		setLogoutDialogOpen,
		setActOnBehalfDialogOpen,
		actOnBehalfDialogOpen,
		setGuestDetails,
		isGuestAuthenticated,
	} = useContext(AppContext);

	const [selectOrganizationModalOpen, setSelectOrganizationModalOpen] =
		useState(true);

	let isAuthenticated = WebAuthService.isAuthenticated();
	const termsAccepted = userDetails?.termsAccepted;

	const logOut = () => {
		if (isGuestAuthenticated()) {
			setGuestDetails(null);
			LocalStorageService.remove('guestUserToken');
		}
		WebAuthService.logout();
	};

	useEffect(() => {
		if (contactData.data) {
			dispatch(getCitiesAsync({ country: contactData.data.country || 'fi' }));
		}
	}, [contactData.data]);

	const getUserData = async () => {
		try {
			// await WebAuthService.getTokenSilently();
			// const user = await WebAuthService.getUser();
			// setAuth0UserData(user);

			const user = await WebAuthService.getInitialUserData();

			setUserInitialData(user);

			dispatch(getContactAsync());
			// eslint-disable-next-line no-empty
		} catch (error) {
			// setAuth0UserData({});
		}
	};

	const getConfig = () => {
		dispatch(
			getConfigAsync(isAuthenticated ? {} : { companyId: getCompanyId() }),
		);
	};

	const initialize = async () => {
		await WebAuthService.getAuthState();
		isAuthenticated = WebAuthService.isAuthenticated();

		if (
			window.location.search.indexOf(/* '?code=' */ '?redirect=true') !== -1
		) {
			redirectToContactPage();
		} else {
			getConfig();

			if (isAuthenticated) {
				getUserData();
			}

			if (window.location.search.indexOf('?onBehalfRedirect=true') !== -1) {
				redirectToRemoteAppointments();
			}
		}
	};

	useEffect(() => {
		initialize();
	}, []);

	useEffect(() => {
		if (
			/* auth0UserData && */
			userInitialData &&
			(contactData.data || contactData.error) &&
			citiesData.data
		) {
			setUserDetails(
				prepareUserData(/* auth0UserData */ userInitialData, contactData.data),
			);

			if (contactData.data.city) {
				const city = citiesData.data.find(
					(item: any) => item.id === contactData.data.city,
				);

				if (city && (city.cityName?.en || city.name)) {
					AnalyticsService.sendCustomDimension(city.cityName?.en || city.name);
				}
			}
		}
	}, [/* auth0UserData, */ userInitialData, contactData, citiesData]);

	useEffect(() => {
		if (configData.data) {
			const newTheme = prepareUserTheme(configData.data.themeOverrides);
			setSelectedTheme(newTheme);

			setOrganizationSelectionDone(false);
			getOrganizationsInfo();
		}
	}, [configData]);

	const { data: orgInfoData, refetch: getOrganizationsInfo } = useQuery<any>(
		'organizationsInfo',
		() => OrganizationService.getOrganizations(),
		{
			enabled: false,
			onSuccess: (response) => {
				organizationSelectSteps(response);
			},
		},
	);

	const organizationSelectSteps = (organizationsInfo: OrganizationsInfo) => {
		if (organizationsInfo?.organisations) {
			if (
				organizationsInfo.selectedOrganisation &&
				organizationsInfo.organisations.find(
					(org: OrganizationsInfo['organisations'][0]) =>
						org.id === organizationsInfo.selectedOrganisation,
				)
			) {
				setSelectedOrganization(organizationsInfo.selectedOrganisation);
			} else {
				setSelectedOrganization(null);
				history.push(config.paths.organizationSelect);
			}
		}

		setOrganizationSelectionDone(true);
	};

	const redirectToContactPage = async () => {
		try {
			// await WebAuthService.handleRedirectCallback();

			// eslint-disable-next-line no-console
			console.log('redirect to contact page');

			getConfig();
			getUserData();

			window.history.replaceState({}, document.title, window.location.pathname);
		} catch (error) {
			getConfig();
		}
	};

	const redirectToRemoteAppointments = () => {
		history.push(config.paths.landing);
	};

	const loadingInProgress =
		!configData.data ||
		(isAuthenticated && !userDetails) ||
		(isAuthenticated && !organizationSelectionDone);

	return (
		<ApplicationWrapper>
			<ThemeProvider theme={selectedTheme}>
				<GlobalStyles />
				<DesignSystemOverrides />
				{/* if we disable logout route also shoudl not be available  */}
				{configData?.data?.logoutEnabled && (
					<Switch>
						<Route
							exact
							path={config.paths.logout}
							render={() => {
								WebAuthService.logout();
								return <></>;
							}}
						/>
					</Switch>
				)}

				{!loadingInProgress ? (
					<>
						{!isMobile() && (
							<SkipToContentLink href="#main">
								{t('common.skipToContent')}
							</SkipToContentLink>
						)}
						<Cookie />
						<MainMenu />
						<ProfileMenu />
						<Header />

						<Content id="main">
							{isAuthenticated && configData.data?.actOnBehalf?.ssn && (
								<>
									<ActOnBehalfEmpty />
									<ActOnBehalfInfo>
										<div>
											{t('actOnBehalfModal.actingAsSomeone', {
												name: configData.data?.actOnBehalf?.name,
											})}
										</div>
									</ActOnBehalfInfo>
								</>
							)}
							<ContentInner>
								<Toast />
								<Switch>
									{!isAuthenticated && (
										<Route
											path={`${config.paths.guestUser}:uuid`}
											component={GuestUser}
										/>
									)}
									<PrivateRoute
										exact
										path="/thank-you-guest"
										checkRule={isGuestAuthenticated()}
										component={ThankYouGuest}
									/>
									<PrivateRoute
										exact
										path={config.paths.changeMyLocation}
										checkRule={
											isAuthenticated &&
											termsAccepted &&
											selectedOrganization &&
											configData?.data?.changeLocationEnabled
										}
										component={ChangeMyLocationPage}
										redirectPath={config.paths.base}
									/>
									<PrivateRoute
										checkRule={isAuthenticated}
										path={`${config.paths.survey}/:responseId`}
										render={(pr: any) => (
											<Survey
												{...pr}
												returnUrl={config.paths.landing}
												apiService={PatientApiService}
											/>
										)}
										redirectPath={config.paths.base}
									/>
									<Route
										path="/profile"
										render={() => (
											<ProfileWrapper>
												<Switch>
													<PrivateRoute
														exact
														checkRule={
															isAuthenticated &&
															termsAccepted &&
															selectedOrganization &&
															configData?.data?.contactEnabled
														}
														path={config.paths.profileContact}
														redirectPath={config.paths.base}
														render={(pr: any) => <ContactSubPage {...pr} />}
													/>
													<PrivateRoute
														exact
														path={config.paths.profileTerms}
														checkRule={
															isAuthenticated &&
															termsAccepted &&
															selectedOrganization
														}
														render={(pr: any) => <TermsSubPage {...pr} />}
														redirectPath={config.paths.base}
													/>
													<PrivateRoute
														exact
														path={config.paths.profileWelcome}
														checkRule={
															isAuthenticated &&
															termsAccepted &&
															selectedOrganization
														}
														component={WelcomeSubpage}
														redirectPath={config.paths.base}
													/>
												</Switch>
											</ProfileWrapper>
										)}
									/>
									<PrivateRoute
										exact
										checkRule={isAuthenticated && !selectedOrganization}
										path={config.paths.organizationSelect}
										render={() => (
											<>
												<SelectOrganizationModal
													isOpen={selectOrganizationModalOpen}
													toggleModal={() => {
														setSelectOrganizationModalOpen(
															!selectOrganizationModalOpen,
														);
													}}
													organizations={orgInfoData?.organisations || []}
												/>
											</>
										)}
										redirectPath={config.paths.base}
									/>
									<Route path="/" component={Home} />;
								</Switch>
							</ContentInner>
						</Content>
						<Footer />
						<CustomDialog
							open={logoutDialogOpen}
							onClose={() => setLogoutDialogOpen(false)}
							title={
								<CustomDialogHeadline>
									{t('common.logOut')}?
								</CustomDialogHeadline>
							}
							description={t('common.logOutInfo')}
						>
							<CustomButton onClick={logOut}>{t('common.ok')}</CustomButton>
							<CustomButton
								variant="secondary"
								onClick={() => setLogoutDialogOpen(false)}
							>
								{t('common.cancel')}
							</CustomButton>
						</CustomDialog>

						{configData?.data?.actingEnabled && (
							<ActOnBehalfModal
								isOpen={actOnBehalfDialogOpen}
								toggleModal={setActOnBehalfDialogOpen}
							/>
						)}
					</>
				) : (
					<LoaderWrapper aria-hidden="true">
						<Icon name="Spinner" color={selectedTheme.colors.primary} />
					</LoaderWrapper>
				)}
			</ThemeProvider>
		</ApplicationWrapper>
	);
};

export default Application;

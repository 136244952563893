import React, { useEffect, useContext } from 'react';

import { AppContext } from 'app/context/appContext';

import { ToastWrapper, ToastIcon } from './toast.styles';

const TOAST_TIMEOUT = 3000;

export interface ToastOpen {
	text: string | boolean;
	icon?: JSX.Element;
	color?: string;
	background?: string;
}

export const Toast = () => {
	const { toastOpen, toggleToast } = useContext(AppContext);

	useEffect(() => {
		const timer = setTimeout(() => {
			toggleToast({ ...toastOpen, text: false });
		}, TOAST_TIMEOUT);

		return () => clearTimeout(timer);
		// eslint-disable-next-line
	}, [toastOpen]);

	return (
		<ToastWrapper
			active={!!toastOpen.text}
			color={toastOpen.color}
			background={toastOpen.background}
		>
			<div>
				{toastOpen.icon && <ToastIcon>{toastOpen.icon}</ToastIcon>}
				<div>{toastOpen?.text}</div>
			</div>
		</ToastWrapper>
	);
};

import styled from 'styled-components';

export const AppointmentHistoryWrapper = styled.div``;

export const SubtitleWrapper = styled.div`
	margin: ${(props) => props.theme.spacing['1']};
`;

export const SpinnerWrapper = styled.div`
	text-align: center;
	margin: auto;
`;

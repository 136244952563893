import { Response, ResponseError } from 'common/typings/response';
import { getTimezone } from 'common/helpers/dates';

import { CommonApiService } from 'common/services/config';

export interface UserData {
	uuid: string;
	accessRights: {
		serviceSettings: {
			read: boolean;
			write: boolean;
		};
		users: {
			read: boolean;
			write: boolean;
		};
		experts: {
			read: boolean;
			write: boolean;
		};
		bookings: {
			read: boolean;
			write: boolean;
		};
		chatCodes: {
			read: boolean;
			write: boolean;
		};
	};
	username: string;
	organizationId: string | null;
}

export interface ClientParams {
	lang?: string;
	timezone?: string;
}
class UserService {
	apiService: CommonApiService = new CommonApiService();

	appType: string;

	constructor() {
		this.appType = 'admin';
	}

	public setApiService(apiService: CommonApiService) {
		this.apiService = apiService;

		if (this.apiService.type === 'expert') {
			this.appType = 'expert';
		}
	}

	public async getUserData(): Promise<UserData & ResponseError> {
		const response: Response<UserData> = await this.apiService.get(
			`/sso/user_data`,
		);

		return {
			...response.data,
		};
	}

	public async setClientParams(params: ClientParams) {
		const response: Response<{ message: string }> = await this.apiService.post(
			`/sso/client_params`,
			{ ...params, timezone: getTimezone() },
		);

		return response.data;
	}
}

export default new UserService();

import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import i18next from 'i18next';
import { useHistory } from 'react-router-dom';

import { Icon, Subtitle } from '@terveystalo/design-system-react-components';

import { AppointmentItemData } from 'app/services/serviceSelection';
import AnalyticsService from 'common/services/analytics/analytics';

import {
	getServicesAsync,
	selectServices,
} from 'app/store/serviceSelection/slices/serviceSelection.slice';
import { ServiceItemAppointment } from 'app/components/serviceItem/serviceItemAppointment';
import { useValidAppointments } from 'app/hooks/validAppointments';

import {
	closeAppointmentCommon,
	openNinChatCommon,
} from 'app/components/appointmentModal/appointmentModalHelpers';
import { AppointmentModal } from 'app/components/appointmentModal/appointmentModal';
import { config } from 'app/config/config';

import {
	BookedAppointmentsWrapper,
	AvailableServicesWrapper,
	AvailableServicesList,
} from './bookedAppointments.styles';

export const BookedAppointmentsPage = () => {
	const { t } = useTranslation();
	const theme = useContext(ThemeContext);
	const [appointmentDialogOpen, setAppointmentDialogOpen] = useState(false);
	const [selectedChatDetails, setSelectedChatDetails] = useState<any>(null);

	const history = useHistory();
	const dispatch = useDispatch();

	const { isLoading, data: servicesData } = useSelector(selectServices);

	const [futureAppointments] = useValidAppointments(servicesData?.appointments);

	const lang = i18next.language;

	useEffect(() => {
		document.title = t('pageTitles.bookedAppointments');
	}, [lang]);

	useEffect(() => {
		document.title = t('pageTitles.bookedAppointments');
		AnalyticsService.sendPageView(t('pageTitles.bookedAppointments'));
		dispatch(getServicesAsync());
		// eslint-disable-next-line
	}, []);

	const openScheduledAppointmentDialog =
		(item: AppointmentItemData, isCancellable: boolean) => async () => {
			setSelectedChatDetails({
				serviceName: item.name,
				queueId: item.queueId,
				chatCodeService: false,
				uuid: item.uuid,
				mandatorySelfAssessment: false,
				optionalSelfAssessment: false,
				isCancellable,
				type: item.type,
				audienceId: item.audienceId,
			});
			setAppointmentDialogOpen(true);
		};

	const openNinChat = async ({
		showQuestionnaire,
	}: {
		showQuestionnaire: boolean;
	}) => {
		setAppointmentDialogOpen(false);
		await openNinChatCommon(selectedChatDetails);

		history.push(config.paths.chat, {
			ninChatConfig: {
				...selectedChatDetails,
				showQuestionnaire,
			},
		});
	};

	const closeAppointment = () => {
		closeAppointmentCommon(selectedChatDetails);
	};

	const cancelAppointment = () => {
		dispatch(getServicesAsync());
	};

	return (
		<BookedAppointmentsWrapper>
			<AvailableServicesWrapper>
				{isLoading && (
					<div>
						<br />
						<Icon name="Spinner" color={theme.colors.primary} />
					</div>
				)}
				{!isLoading && (
					<div>
						<div>
							<Subtitle color={theme.colors.primary} size="s">
								{t('serviceSelection.yourAppointments.future.title')}
							</Subtitle>
							{!isLoading && futureAppointments.length > 0 && (
								<div>
									{futureAppointments.map(
										(appointment: AppointmentItemData) => (
											<>
												<AvailableServicesList>
													<ServiceItemAppointment
														onClick={openScheduledAppointmentDialog}
														item={appointment}
													/>
												</AvailableServicesList>
											</>
										),
									)}
								</div>
							)}
						</div>
					</div>
				)}
				<div>
					{appointmentDialogOpen && (
						<AppointmentModal
							isOpen={appointmentDialogOpen}
							toggleModal={setAppointmentDialogOpen}
							successAction={openNinChat}
							closeAction={closeAppointment}
							cancelAction={cancelAppointment}
							scheduledAppointmentsActive
							selectedChatDetails={selectedChatDetails}
						/>
					)}
				</div>
			</AvailableServicesWrapper>
		</BookedAppointmentsWrapper>
	);
};

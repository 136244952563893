import styled from 'styled-components';
import { TextButton } from '@terveystalo/design-system-react-components';

export const ActingOnBehalfWrapper = styled.div`
	text-align: center;
	margin-top: ${(props) => props.theme.spacing['1_5']};
`;

export const CancelButton = styled(TextButton)`
	background: none;
	border: none;
	font-weight: 800;
	font-size: ${(props) => props.theme.typography.fontSize.subtitle.mplus};
`;

import React, { useState } from 'react';
import { ToastData } from 'common/components/toast/toast';

interface IAdminAppContextType {
	mainMenuOpen: boolean;
	setMainMenuOpen: (value: boolean) => void;
	profileMenuOpen: boolean;
	setProfileMenuOpen: (value: boolean) => void;
	selectedOrganization: string | null;
	setSelectedOrganization: (value: string) => void;
	changeOrganizationModalOpen: boolean;
	setChangeOrganizationModalOpen: (value: boolean) => void;
	toastData: ToastData;
	setToastData: (value: ToastData) => void;
}

export const AdminAppContext = React.createContext<IAdminAppContextType>({
	mainMenuOpen: false,
	setMainMenuOpen: () => {},
	profileMenuOpen: false,
	setProfileMenuOpen: () => {},
	selectedOrganization: null,
	setSelectedOrganization: () => {},
	changeOrganizationModalOpen: false,
	setChangeOrganizationModalOpen: () => {},
	toastData: {
		isOpen: false,
		text: '',
		color: '',
		icon: <></>,
		background: '',
	},
	setToastData: () => {},
});

const { Provider } = AdminAppContext;

export const AppProvider = ({ children }: any) => {
	const [toastData, setToastData] = useState<ToastData>({
		isOpen: false,
		text: '',
		color: '',
		icon: <></>,
		background: '',
	});

	const [mainMenuOpen, setMainMenuOpen] = useState<boolean>(false);
	const [profileMenuOpen, setProfileMenuOpen] = useState<boolean>(false);

	const [changeOrganizationModalOpen, setChangeOrganizationModalOpen] =
		useState<boolean>(false);

	const [selectedOrganization, setSelectedOrganization] = useState<
		string | null
	>(null);

	return (
		<Provider
			value={{
				mainMenuOpen,
				setMainMenuOpen,
				profileMenuOpen,
				setProfileMenuOpen,
				selectedOrganization,
				setSelectedOrganization,
				changeOrganizationModalOpen,
				setChangeOrganizationModalOpen,
				toastData,
				setToastData,
			}}
		>
			{children}
		</Provider>
	);
};

import styled from 'styled-components';

import { Text } from '@terveystalo/design-system-react-components';

export const DownloadButton = styled.a`
	align-items: center;
	text-decoration: none;
	display: inline;
	float: right;
`;

export const DownloadButtonText = styled(Text)`
	margin-left: ${(props) => props.theme.spacing['0_25']};
`;

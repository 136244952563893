import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';

import i18next from 'i18next';

import { ThemeContext } from 'styled-components';
import { useSelector } from 'react-redux';

import {
	Text,
	Subtitle,
	Icon,
} from '@terveystalo/design-system-react-components';

import {
	BackButton,
	BackButtonText,
	BackContainer,
} from 'common/styles/components/back/back';
import FeedbackService, {
	FeedbackData,
	ScoreTypes,
} from 'common/services/feedback/feedback';
import { CustomLead } from 'common/styles/components/lead/lead';

import { VoteFaces } from 'common/components/feedback/voteFaces';
import {
	FeedbackCircle,
	VotingBottomInfoWrapper,
	CircleWrapper,
	LeadTextWrapper,
} from 'common/components/feedback/voteFaces.styles';
import { FeedbackForm } from 'common/components/feedback/feedbackForm';

import { AppContext } from 'app/context/appContext';
import { config } from 'app/config/config';
import { isMobile } from 'common/helpers/mobile';
import { selectConfig } from 'app/store/configuration/slices/configuration.slice';
import AnalyticsService from 'common/services/analytics/analytics';
import GuestApiService from 'app/services/guestUser/GuestApiService';
import PatientApiService from 'app/services/config';

import { ThankYou } from './components/thankYou';
import { ContentSection } from '../home.styles';

import { FeedbackWrapper, FeedbackVotingWrapper } from './feedback.styles';

enum FeedbackState {
	none = -1,
	voteNumbers = 0,
	voteFaces = 1,
	voteCareSatisfaction = 4,
	form = 2,
	thankYou = 3,
}

export const FeedbackSubPage = () => {
	const { t } = useTranslation();
	const [feedbackState, setFeedbackState] = useState<FeedbackState>(
		FeedbackState.none,
	);
	const [feedbackData, setFeedbackData] = useState<{
		score?: number;
		scoreType?: ScoreTypes;
	}>({});
	const [savingError, setSavingError] = useState<any>(false);
	const [savingInProgress, setSavingInProgress] = useState(false);

	const [textValue, setTextValue] = useState('');
	const { isGuestAuthenticated } = useContext(AppContext);

	const theme = useContext(ThemeContext);
	const location: any = useLocation();
	const configData = useSelector(selectConfig);
	const history = useHistory();

	const lang = i18next.language;

	useEffect(() => {
		document.title = t('pageTitles.feedback');
	}, [lang]);

	useEffect(() => {
		const random = Math.floor(Math.random() * 3); // 0, 1 or 2
		const states = [
			FeedbackState.voteNumbers,
			FeedbackState.voteFaces,
			FeedbackState.voteCareSatisfaction,
		];

		const defaultFeedbackView = states[random];
		setFeedbackState(defaultFeedbackView);

		FeedbackService.setApiService(
			isGuestAuthenticated() ? GuestApiService : PatientApiService,
		);

		document.title = t('pageTitles.feedback');
		AnalyticsService.sendPageView(t('pageTitles.feedback'));
	}, []);

	useEffect(() => {
		if (feedbackState === FeedbackState.thankYou && isGuestAuthenticated()) {
			history.push('/thank-you-guest');
		}
	}, [feedbackState]);

	const handleFeedbackClick = (index: number, scoreType: ScoreTypes) => () => {
		const analyticsNameHead = {
			open: 'open',
			nps: 'nps',
			ces: 'ces',
			care: 'careSatisfaction',
			expertCes: 'expertCes',
		};

		AnalyticsService.sendCustomEvent(
			`${analyticsNameHead[scoreType]}ScoreSend`,
			'feedback',
			`send ${analyticsNameHead[scoreType].toUpperCase()} ${index}`,
			'',
		);

		setFeedbackData({ ...feedbackData, score: index, scoreType });
		setFeedbackState(FeedbackState.form);
	};

	const sendFeedback = (sendText: boolean) => async () => {
		setSavingInProgress(true);
		setSavingError(false);

		const { serviceName } = location.state?.ninChatConfig;

		const data: FeedbackData = {
			score: feedbackData.score || 0,
			tags: [
				{ name: 'environment', value: process.env.REACT_APP_ENV_TYPE || '' },
				{ name: 'device', value: isMobile() ? 'mobile' : 'desktop' },
				{ name: 'organization', value: configData.data.name || '' },
				{ name: 'service name', value: serviceName },
			],
		};
		if (sendText) {
			data.comment = textValue;
		}

		try {
			const responseData = await FeedbackService.sendFeedback(
				data,
				feedbackData.scoreType,
			);

			AnalyticsService.sendCustomEvent(
				'openFeedbackForm',
				'feedback',
				`${sendText ? 'send' : 'skip'} feedback form`,
				'',
			);

			if (responseData.status === 'error') {
				setSavingError(true);
			} else {
				setFeedbackState(FeedbackState.thankYou);
			}
		} catch (error) {
			setSavingError(true);
		}

		setSavingInProgress(false);
	};

	const renderVoteNumbers = () => (
		<div>
			<LeadTextWrapper>
				<CustomLead size="s" weight="light">
					{t('feedback.nps.lead')}
				</CustomLead>
			</LeadTextWrapper>
			<CircleWrapper>
				{new Array(11).fill('').map((item, index) => (
					<FeedbackCircle
						onClick={handleFeedbackClick(index, ScoreTypes.nps)}
						index={index}
					>
						{index}
					</FeedbackCircle>
				))}
			</CircleWrapper>
			<VotingBottomInfoWrapper>
				<Text size="m">{`0 - ${t('feedback.veryUnlikely')}`}</Text>
				<Text size="m">{`10 - ${t('feedback.veryLikely')}`}</Text>
			</VotingBottomInfoWrapper>
		</div>
	);

	const handleGoToRemoteReception = (ev: React.MouseEvent) => {
		ev.preventDefault();
		history.push(config.paths.serviceSelection);
	};

	return (
		<FeedbackWrapper>
			<BackContainer>
				<BackButton
					aria-label="Back button"
					href=""
					onClick={handleGoToRemoteReception}
				>
					<Icon name="ArrowLeft" color={theme.colors.primary} />
					<BackButtonText size="m" color={theme.colors.primary}>
						{t('common.backToServiceSelection')}
					</BackButtonText>
				</BackButton>
			</BackContainer>
			{!isGuestAuthenticated() && (
				<Subtitle size="m" color={theme.colors.zodiacBlack} as="h3">
					{feedbackState === FeedbackState.thankYou
						? t('feedback.thankYou.title')
						: t('feedback.title')}
				</Subtitle>
			)}
			<ContentSection>
				<FeedbackVotingWrapper data-testid="voting-area">
					{feedbackState === FeedbackState.voteNumbers && renderVoteNumbers()}
					{feedbackState === FeedbackState.voteFaces && (
						<VoteFaces
							leadText={t('feedback.ces.lead')}
							type={ScoreTypes.ces}
							handleFeedbackClick={handleFeedbackClick}
						/>
					)}
					{feedbackState === FeedbackState.voteCareSatisfaction && (
						<VoteFaces
							leadText={t('feedback.careSatisfaction.lead')}
							type={ScoreTypes.care}
							handleFeedbackClick={handleFeedbackClick}
						/>
					)}
					{feedbackState === FeedbackState.form && (
						<FeedbackForm
							textValue={textValue}
							setTextValue={setTextValue}
							savingError={savingError}
							savingInProgress={savingInProgress}
							sendFeedback={sendFeedback}
						/>
					)}
					{feedbackState === FeedbackState.thankYou && <ThankYou />}
				</FeedbackVotingWrapper>
			</ContentSection>
			<br />
			<br />
		</FeedbackWrapper>
	);
};

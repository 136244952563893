import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { ThemeContext } from 'styled-components';
import { useSelector } from 'react-redux';

import { Alert, Icon, Text } from '@terveystalo/design-system-react-components';

import { AppointmentType } from 'common/typings/bookings';

import {
	CustomDialog,
	CustomDialogHeadline,
} from 'app/styles/components/dialog/dialog';
import { CustomButton } from 'app/styles/components/button/button';
import { AppContext } from 'app/context/appContext';
import { addAlpha } from 'app/helpers/colors';
import { selectConfig } from 'app/store/configuration/slices/configuration.slice';

import ServiceSelectionService from 'app/services/serviceSelection';

enum ModalStates {
	open = 'open',
	scheduled = 'scheduled',
	scheduledCancellable = 'scheduledCancellable',
	scheduledGroup = 'scheduledGroup',
}
interface AppointmentModalProps {
	isOpen: boolean;
	toggleModal(open: boolean): void;
	successAction({ showQuestionnaire }: { showQuestionnaire: boolean }): void;
	closeAction(): void;
	cancelAction(id: string): void;
	scheduledAppointmentsActive: boolean;
	selectedChatDetails: any;
}

export const AppointmentModal = ({
	isOpen,
	toggleModal,
	successAction,
	cancelAction,
	closeAction,
	scheduledAppointmentsActive,
	selectedChatDetails,
}: AppointmentModalProps) => {
	const { t } = useTranslation();
	const [currentModalState, setCurrentModalState] = useState(ModalStates.open);
	const { toggleToast } = useContext(AppContext);
	const theme = useContext(ThemeContext);
	const [savingError, setSavingError] = useState('');
	const configData = useSelector(selectConfig);

	useEffect(() => {
		if (scheduledAppointmentsActive) {
			if (selectedChatDetails.type === AppointmentType.group) {
				setCurrentModalState(ModalStates.scheduledGroup);
			} else if (selectedChatDetails.isCancellable) {
				setCurrentModalState(ModalStates.scheduledCancellable);
			} else {
				setCurrentModalState(ModalStates.scheduled);
			}
		}
	}, []);

	const { isLoading: cancellingBooking, mutate: cancelBooking } = useMutation(
		(id: string) => {
			setSavingError('');
			return ServiceSelectionService.cancelAppointment(id);
		},
		{
			onError: () => {
				setSavingError(t('common.savingError'));
			},
			onSuccess: (responseData) => {
				if (responseData.status === 'error') {
					setSavingError(t('common.savingError'));
				} else {
					toggleAppointmentModal();
					cancelAction(selectedChatDetails.uuid);
					toggleToast({
						text: t('appointmentModal.cancellable.toast'),
						color: theme.colors.primary,
						background: addAlpha(theme.colors.primary, 0.1),
						icon: <Icon name="Check" color={theme.colors.primary} />,
					});
				}
			},
		},
	);

	const toggleAppointmentModal = () => {
		toggleModal(false);
	};

	const handleCancelAppoitment = () => {
		cancelBooking(selectedChatDetails.uuid);
	};

	const handleCloseAppointment = () => {
		toggleAppointmentModal();
		closeAction();
	};

	const handleStartAppointment = () => {
		successAction({ showQuestionnaire: false });
	};

	const handleFillSurvey = () => {
		successAction({ showQuestionnaire: true });
	};

	const showQuestionnaireButton =
		configData.data.htaConnectorEnabled &&
		(selectedChatDetails.mandatorySelfAssessment ||
			selectedChatDetails.optionalSelfAssessment) &&
		currentModalState === ModalStates.open;

	const showStartAppointmentButton =
		currentModalState === ModalStates.scheduled ||
		!configData.data.htaConnectorEnabled ||
		(currentModalState === ModalStates.open &&
			!selectedChatDetails.mandatorySelfAssessment) ||
		currentModalState === ModalStates.scheduledGroup;

	return (
		<CustomDialog
			className="appointmentModal"
			noHeader={currentModalState === ModalStates.scheduled}
			open={isOpen}
			onClose={toggleAppointmentModal}
			title={
				<div>
					{currentModalState === ModalStates.open && (
						<CustomDialogHeadline>
							{t('appointmentModal.headline')}
						</CustomDialogHeadline>
					)}
					{currentModalState === ModalStates.scheduledCancellable && (
						<CustomDialogHeadline>
							{t('appointmentModal.cancellable.title')}
						</CustomDialogHeadline>
					)}
				</div>
			}
			description={
				<>
					{currentModalState === ModalStates.open && (
						<div>
							{selectedChatDetails.mandatorySelfAssessment ? (
								<div>
									<Text size="m">{t('appointmentModal.mandatory.body')}</Text>
								</div>
							) : (
								<div>
									<Text size="m">{t('appointmentModal.body.one')}</Text>
									<br />
									<br />
									{showQuestionnaireButton && (
										<Text size="m">{t('appointmentModal.body.two')}</Text>
									)}
								</div>
							)}
						</div>
					)}
					{currentModalState === ModalStates.scheduled && (
						<div>
							<Text size="m">
								{t('serviceSelection.yourAppointmentsModal.text', {
									name: selectedChatDetails.serviceName,
								})}
							</Text>
						</div>
					)}
					{currentModalState === ModalStates.scheduledCancellable && (
						<div>
							<Text size="m">
								<div>{t('appointmentModal.cancellable.lead.top')}</div>
								<br />
								<div>{t('appointmentsModal.cancellable.lead.middle')}</div>
								<br />
								<div>{t('appointmentsModal.cancellable.lead.bottom')}</div>
							</Text>
						</div>
					)}

					{currentModalState === ModalStates.scheduledGroup && (
						<div>
							<Text size="m">
								<div>{t('appointmentModal.cancellable.lead.top')}</div>
								<br />
								<div>{t('appointmentsModal.cancel.groupchat')}</div>
								<br />
							</Text>
						</div>
					)}
				</>
			}
		>
			{(currentModalState === ModalStates.open ||
				currentModalState === ModalStates.scheduled ||
				currentModalState === ModalStates.scheduledGroup) && (
				<>
					{showStartAppointmentButton && (
						<CustomButton
							onClick={handleStartAppointment}
							data-testid="chat-start-appointment"
						>
							{scheduledAppointmentsActive
								? t('serviceSelection.yourAppointmentsModal.submit')
								: t('appointmentModal.button')}
						</CustomButton>
					)}

					{showQuestionnaireButton && (
						<CustomButton onClick={handleFillSurvey}>
							{selectedChatDetails.mandatorySelfAssessment
								? t('appointmentModal.mandatory.button')
								: t('appointmentModal.questionnaire.button')}
						</CustomButton>
					)}
					<CustomButton variant="secondary" onClick={handleCloseAppointment}>
						{t('common.close')}
					</CustomButton>
				</>
			)}
			{currentModalState === ModalStates.scheduledCancellable && (
				<>
					{savingError && (
						<Alert aria-live="polite" icon="AlertOctagon" variant="error">
							{savingError}
						</Alert>
					)}

					<CustomButton
						{...(cancellingBooking ? { icon: 'Spinner' } : {})}
						disabled={cancellingBooking}
						variant="secondary"
						onClick={handleCancelAppoitment}
					>
						{t('appointmentModal.cancellable.button.cancel')}
					</CustomButton>
					<CustomButton onClick={handleCloseAppointment}>
						{t('common.close')}
					</CustomButton>
				</>
			)}
		</CustomDialog>
	);
};

import styled from 'styled-components';
import {
	ListItem,
	List,
	Icon,
} from '@terveystalo/design-system-react-components';

export const ToggableSection = styled.div`
	margin: ${(props) => props.theme.spacing['1']} 0
		${(props) => props.theme.spacing['3']};
`;

export const FeedbackList = styled(List)<{
	open: boolean;
	items: number;
	transitionEnd?: boolean;
	ref: any;
}>`
	overflow: hidden;
	transition: height 0.3s ease-out;
	height: 0;

	${(props) =>
		props.open &&
		`
		visibility: visible;
	`}

	${(props) =>
		!props.open &&
		props.transitionEnd &&
		`
		visibility: hidden;

	`}
`;

export const FeedbackListItem = styled(ListItem)`
	width: auto;
	padding: ${(props) => props.theme.spacing['1']} 0;
`;

export const ListBulletIcon = styled(Icon)`
	min-width: 24px;
	margin-right: ${(props) => props.theme.spacing['1']};
`;

export const ToggleButton = styled.button`
	background: none;
	border: none;
	width: 100%;
	text-align: left;
	display: flex;
	justify-content: space-between;
	cursor: pointer;
`;

export const FeedbackLink = styled.a`
	display: flex;
	align-items: center;
	text-decoration: none;
	&:hover {
		span {
			color: ${(props) => props.theme.colors.primary};
		}
	}
`;

export const ExternalIconWrapper = styled.div`
	margin-left: ${(props) => props.theme.spacing['1']};
`;

export const ButtonWrapper = styled.div`
	text-align: center;
	> button {
		width: auto;
		display: block;
		margin: 0 auto ${(props) => props.theme.spacing['1']};
	}
`;

export const ChevronWrapper = styled.div<{ open: boolean }>`
	transition: 0.2s all;
	${(props) =>
		!props.open &&
		`
			transform: rotate(180deg);
		`}
`;

export const ThankYouWrapper = styled.div`
	width: 100%;
`;

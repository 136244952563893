import React from 'react';
import { CustomTextField } from './TextField.styles';

export const FormikTextField = (props: any) => {
	const {
		field: { onBlur, onChange, name, value },
		form: { touched, errors, submitCount },
		onChange: fieldChange,
		noState,
	} = props;

	const valid = !errors[name] && value;
	const invalid = (touched[name] || submitCount > 0) && errors[name];

	return (
		<CustomTextField
			{...props}
			{...(!noState && { invalid })}
			{...(!noState && { valid })}
			onChange={(pr: any) => {
				onChange(pr);
				if (fieldChange) {
					fieldChange(pr);
				}
			}}
			onBlur={onBlur}
			value={value}
		/>
	);
};

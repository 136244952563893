import {
	createSlice,
	createSelector,
	createAsyncThunk,
} from '@reduxjs/toolkit';

import { RootState } from 'expertApplication/store';
import OrganisationConfigService from 'expertApplication/services/organisationConfig';

import { Config } from 'common/typings/organisationConfig';
import { ResponseError } from 'common/typings/response';

export interface OrganisationConfigState {
	data: Config | null;
	isLoading: boolean;
	error: any;
}

const initialState: OrganisationConfigState = {
	data: null,
	isLoading: false,
	error: null,
};

export interface OrganisationConfigParameters {
	companyId: string;
}

export const getOrganisationConfigAsync: any = createAsyncThunk<
	any,
	OrganisationConfigParameters
>('organisationConfig/getConfig', async (props, thunkAPI) => {
	try {
		const configData: Config & ResponseError =
			await OrganisationConfigService.getConfig();

		if (configData.status === 'error') {
			return thunkAPI.rejectWithValue(configData.statusCode);
		}

		return configData;
	} catch (error) {
		return thunkAPI.rejectWithValue(error);
	}
});

export const organisationConfigSlice = createSlice({
	name: 'organisationConfig',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(getOrganisationConfigAsync.pending, (state) => {
			state.isLoading = true;
		});

		builder.addCase(getOrganisationConfigAsync.rejected, (state, action) => {
			state.error = action.payload;
			state.isLoading = false;
		});

		builder.addCase(getOrganisationConfigAsync.fulfilled, (state, action) => {
			state.data = action.payload;
			state.isLoading = false;
		});
	},
});

export default organisationConfigSlice.reducer;

export const selectOrganisationConfig = createSelector(
	(state: RootState) => state.organisationConfig,
	(organisationConfig) => organisationConfig,
);

import styled from 'styled-components';
import { ContentSection } from '../../pages/home/home.styles';

export const GuestLoginWrapper = styled(ContentSection)`
	border-color: transparent;
	border-radius: 10px;
`;

export const LoginFieldsWrapper = styled.div`
	margin-top: 30px;
	max-width: 500px;
`;

export const AlertListItem = styled.li`
	margin: 0;
	padding: 0;
`;

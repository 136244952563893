export const en = {
	'pageTitles.allBookings': 'Browse all appointments',
	'pageTitles.appointment.open.edit': 'Edit bookable timeslot',
	'pageTitles.appointment.open.new': 'Open bookable timeslots',
	'pageTitles.appointment.edit': 'Edit an appointment',
	'pageTitles.appointment.new': 'Book an appointment',
	'pageTitles.appointmentHistory': 'Contact history',
	'pageTitles.login': 'Login',
	'pageTitles.schedule': 'Schedule',
	'pageTitles.surveys': 'Surveys',
	'pageTitles.messagesInbox': 'Inbox',
	'pageTitles.messagesSendNew': 'Send a new message',
	'pageTitles.messagesOpenConversations': 'Open conversations',
	'pageTitles.messagesEndedConversations': 'Ended conversations',

	'common.writeMessage': 'Write a message',
	'common.skip': 'Skip',
	'common.send': 'Send',
	'common.reopen': 'Re-open',
	'common.delete': 'Delete',
	'common.minutes': 'minutes',
	'common.save': 'Save',
	'common.edit': 'Edit',
	'common.cancel': 'Cancel',
	'common.login': 'Login',
	'common.menu': 'Menu',
	'common.username': 'Username',
	'common.password': 'Password',
	'common.logOut': 'Log Out',
	'common.hello': 'Hello',
	'common.language.finnish': 'Finnish',
	'common.language.english': 'English',
	'common.language.swedish': 'Swedish',
	'common.backToSchedule': 'Back to Schedule',
	'common.downloadPDF': 'Download PDF',
	'common.backToAllBookings': 'Back to All Bookings',
	'common.email': 'Email',
	'common.savingError': 'Saving error',
	'common.errors': 'Errors',
	'common.errors.105': 'SSN is not valid',
	'common.errors.601': 'The admin user does not exist',
	'common.errors.602': "You don't have enough rights to reset user password",
	'common.errors.801': 'The link for setting a password is expired',
	'common.errors.802':
		'Password is not strong enough, it needs to include small and big letters and digits',
	'common.back': 'Back',
	'common.you': 'You',
	'common.forward': 'Forward',
	'login.headline': 'Log in to continue',
	'login.lead':
		'Welcome to the Expert’s Remote Desktop Application. Hold remote appointments and help their patients efficiently and conveniently.',
	'login.info':
		'	Please fill in your credentials to the right and log in to continue.',
	'login.question.one': 'What is the Expert’s Remote Desktop Application?',
	'login.question.two': 'How do I get credentials?',
	'login.question.three':
		'How do I get my organization to use the remote desktop application?',
	'login.button': 'Log in with username',
	'login.loginFailed': 'Login failed.',
	'login.fromRegistration.title':
		'You have successfully created password. Login to continue.',
	'login.changePassword': 'Forgot password?',
	'login.logout.expired':
		'Your session expired and you have been automatically logged out, please log in again if you need to use the admin panel, thank you.',
	'login.method.vrkCard': 'Log in using VRK card',
	'login.method.adCredentials':
		"Log in using your organization's AD credentials",
	'mainMenu.options.desktop': 'Desktop',
	'mainMenu.options.schedule': 'Schedule',
	'mainMenu.options.surveys': 'Surveys',
	'mainMenu.options.messages': 'Messages',
	'mainMenu.options.termsOfService': 'Terms of service',
	'mainMenu.options.privacyPolicy': 'Privacy Policy',
	'mainMenu.section1': 'How can we help you?',
	'mainMenu.section2': 'About the service',
	'profileMenu.options.myInformation': 'My information',
	'profileMenu.options.workReports': 'Work reports',
	'profileMenu.options.toAdminPanel': 'To Admin Panel',
	'header.title': "Expert's Remote Desktop Application",
	'header.links.desktop': 'Desktop',
	'header.links.schedule': 'Schedule',
	'header.links.surveys': 'Surveys',
	'header.links.messages': 'Messages',
	'header.links.ninchat': 'Open Ninchat',
	'schedule.title': 'Today',
	'schedule.section.today.title': 'Appointments',
	'schedule.section.patient.title': 'Patient',
	'schedule.section.actions.title': 'Actions',
	'schedule.cancel.title': 'Cancel appointment?',
	'schedule.cancel.body':
		'Are you sure you want to cancel the appointment? This will send a notification to all participants.',
	'schedule.cancel.buttons.cancel': 'Cancel appointment',
	'schedule.cancel.buttons.close': 'Close without cancelling',
	'schedule.delete.title': 'Delete appointment?',
	'schedule.delete.body': 'Are you sure you want to delete the appointment?',
	'schedule.delete.buttons.submit': 'Delete appointment',
	'schedule.delete.buttons.close': 'Close without deleting',
	'schedule.buttons.now': 'Now',
	'schedule.buttons.sendAMessage': 'Send a message',
	'schedule.buttons.patientsAppointmentHistory':
		"Patient's appointment history",
	'schedule.buttons.surveysResults': 'Survey results',
	'schedule.patientForm.ssn': 'Social security number',
	'schedule.patientForm.city': 'City',
	'schedule.patientForm.age': 'Age',
	'schedule.patientForm.mobile': 'Mobile number',
	'schedule.patientForm.company': 'Company (Payment number)',
	'schedule.patientForm.email': 'Email',
	'schedule.patientForm.additionalInfo': 'Additional Info',
	'schedule.buttons.today': 'Today',
	'schedule.buttons.openAvailableTimes': 'Open available times',
	'schedule.buttons.registerNewAppointment': 'Book an appointment',
	'schedule.buttons.browseAllBookings': 'Browse all appointments',
	'schedule.buttons.preQuestionnaire': 'Pre-questionnaire',
	'schedule.open.title': 'Open time (reservable)',
	'CreateAppointmentModal.title': 'Book an appointment',
	'CreateAppointmentModal.field.ssn': 'Social security number',
	'CreateAppointmentModal.field.nationality': 'Patient nationality',
	'CreateAppointmentModal.field.expert': 'Expert',
	'CreateAppointmentModal.field.startingDate': 'Date',
	'CreateAppointmentModal.field.startingTime': 'Starting time',
	'CreateAppointmentModal.field.preferredEndingTime': 'Preferred ending time',
	'CreateAppointmentModal.field.otherDuration': 'Other',
	'CreateAppointmentModal.field.language': 'Patient language',
	'CreateAppointmentModal.field.email': 'Email',
	'CreateAppointmentModal.field.firstName': 'First name',
	'CreateAppointmentModal.field.lastName': 'Last name',
	'CreateAppointmentModal.field.name': 'Name',
	'CreateAppointmentModal.duration.subtitle': 'Duration (minutes)',
	'CreateAppointmentModal.duration.oneTimeslot.subtitle':
		'One timeslot duration(minutes)',
	'CreateAppointmentModal.generateTimeslots.text':
		'{{number}} timeslots will be generated. You will need to confirm the timeslots afterwards to open them.',
	'CreateAppointmentModal.generateTimeslots.button': 'Generate timeslots',
	'CreateAppointmentModal.timeslots.reservable': 'Reservable',
	'CreateAppointmentModal.timeslots.break': 'Break',
	'CreateAppointmentModal.timeslots.table.startingTime': 'Starting time',
	'CreateAppointmentModal.timeslots.table.endTime': 'End time',
	'CreateAppointmentModal.timeslots.table.type': 'Type',
	'CreateAppointmentModal.error.ssn': 'Put proper social security number',
	'CreateAppointmentModal.error.expert': 'Choose expert from the list',
	'CreateAppointmentModal.error.startingDate': 'Put correct starting date',
	'CreateAppointmentModal.error.startingTime': 'Put correct starting time',
	'CreateAppointmentModal.error.startingDate.past': 'Cannot select a past date',
	'CreateAppointmentModal.error.nationality': 'Select nationality',
	'CreateAppointmentModal.error.timeslotsListEmpty':
		'Timeslots need to be generated first',
	'CreateAppointmentModal.error.availableDate': 'Date cannot be empty',
	'CreateAppointmentModal.error.availableAppointment':
		'Appointment cannot be empty',
	'CreateAppointmentModal.error.email': 'Put correct email address',
	'CreateAppointmentModal.reminderCheckbox':
		'Send a booking confirmation email to the patient',
	'createRemoteAppointment.emailReminder.lead':
		'Once registered, you and all parties involved will get a confirmation message of the registered appointment via email.',
	'CreateAppointmentModal.button.create': 'Register appointment',
	'CreateAppointmentModal.button.update': 'Update appointment',
	'CreateAppointmentModal.button.createOpenAppointment': 'Open timeslots',
	'CreateAppointmentModal.openAppointment.lead':
		'Once you have opened the time, patients can see and reserve it through the system.',
	'CreateAppointmentModal.subtitle.open.new':
		'Select a date and how much you are going to work',
	'CreateAppointmentModal.subtitle.open.edit': 'Edit timeslot',
	'CreateAppointmentModal.subtitle.new.createNewTimeslotAndBookIt':
		'Create a new timeslot and book it',
	'CreateAppointmentModal.subtitle.new.bookAvailableTimeslot':
		'Book an available timeslot',
	'CreateAppointmentModal.subtitle.edit': 'Edit an appointment',
	'CreateAppointmentModal.field.date': 'Date',
	'CreateAppointmentModal.field.appointment': 'Appointment',
	'CreateAppointmentModal.button.nextAvailable': 'Next available',
	'CreateAppointmentModal.bookTimeslot.noMore':
		'No more available open timeslots',
	'CreateAppointmentModal.bookTimeslot.timeslotSectionTile': 'Timeslot',
	'CreateAppointmentModal.bookTimeslot.patientSectionTile': 'Patient',
	'CreateAppointmentModal.toast.1': 'New appointment created',
	'CreateAppointmentModal.toast.2': 'Appointment booked',
	'CreateAppointmentModal.toast.3': 'Appointment edited',
	'CreateAppointmentModal.toast.4': 'Open timeslots created',
	'CreateAppointmentModal.toast.5': 'Open timeslot edited',
	'services.preQuestionnaire.title': 'Self-booking pre-surveys form',
	'appointment.title.open.new': 'Open bookable timeslots',
	'appointment.title.open.edit': 'Edit bookable timeslot',
	'appointment.title.new': 'Book an appointment',
	'appointment.title.edit': 'Edit an appointment',
	'appointment.checkbox.bookAvailableTimeslot': 'Book an available timeslot',
	'appointment.checkbox.createNewTimeslotAndBookIt':
		'Create a new timeslot and book it',
	'changePassword.headline': 'Expert Change Password',
	'changePassword.successText':
		'An email has been succesfully sent to the address that was provided',
	'changePassword.subtext':
		'An email will be sent to this address with a link to change your password. This link will expire after 30 minutes it was sent.',
	'changePassword.submit': 'Change Password',
	'changePassword.backToLogin': 'Back To Login',
	'changePassword.error.incorrectFormat': 'Incorrect email format',
	'changePassword.error.general': 'There was an error with the request',
	'changePassword.error.badEmail': 'Email was not found',
	'registration.title': 'Create account password',
	'registration.errors.samePassword':
		'Password and Confirm Password need to be the same',
	'registration.errors.validation':
		'Password need to have at least 12 characters including numbers, small and big letters',
	'registration.errors.general': 'Cannot set password. Contact administrator.',
	'registration.fields.password': 'Password',
	'registration.fields.passwordConfirm': 'Confirm password',
	'appointmentHistory.title': 'Contact history',
	'appointmentHistory.lead':
		'Here you can see the times you have interacted the patient in the last {{days}} days using the Expert’s Desktop app.',
	'surveyPage.error': 'Error loading surveys',
	'allBookings.title': 'Browse all appointments',
	'allBookings.subtitle': 'You can filter yout results by date',
	'allBookings.filter.startDate': 'Start Date',
	'allBookings.filter.endDate': 'End Date',
	'allBookings.filter.matchStartDate': 'Match to start date',
	'allBookings.table.patient.title': 'Patient',
	'allBookings.table.status.title': 'Status',
	'allBookings.table.dateTime.title': 'Date, Time',
	'allBookings.table.actions.title': 'Actions',
	'allBookings.table.status.selfBooked': 'Reserved',
	'allBookings.table.status.booked': 'Reserved',
	'allBookings.table.status.cancelled': 'Cancelled',
	'allBookings.table.status.open': 'Open',
	'common.backToHomepage': 'Back to Home page',
	'surveyItem.nameMissing': 'Survey name missing',
	'surveyItem.selectSurvey': 'Select a survey to add',
	'surveyItem.addSurvey': 'Add survey',
	'survey.Description':
		'When you register this appointment, you can choose to surveys to the patient. Surveys can include standardized medical surveys, or you can create your own from the ‘Survey tool’ tab.',
	'survey.Title': 'Surveys',
	'surveyList.surveyResults': 'Survey results',
	'surveyList.answeredSurveys': 'Answered surveys',
	'surveyList.open': 'Open surveys',
	'surveyList.showAnswers': 'Show answers',
	'organizationSelectionModal.button.login': 'Log in',
	'organizationSelectionModal.headline': 'Select an organization to log in to',
	'questionnaireModal.title': 'Pre-questionnaire form',
	'questionnaireModal.lead':
		'Below you can find the patient’s answers to the pre-questionnaire.',
	'feedback.title': 'Welcome back! Please rate our service to continue',
	'feedback.lead': 'How easy was it to use this service?',
	'feedback.veryHard': 'Very hard',
	'feedback.veryEasy': 'Very easy',
	'feedback.form.lead': 'If you wish, you can leave us open feedback.',

	'survey.navigation.answered': 'Answered surveys',
	'survey.navigation.open': 'Open surveys',
	'survey.navigation.forwarded': 'Forwarded to you',
	'surveyManagement.forwarded': 'Forwarded surveys',
	'surveyManagement.templates': 'Survey templates',
	'surveyManagement.sortBy': 'Sort by:',
	'surveyManagement.appointmentDate': 'Appointment date',
	'surveyManagement.patientName': 'Patient name',
	'surveyManagement.templates.description':
		'Here you can see all surveys available for use in your current organization. If you want to have a survey template added, please contact your service administrator.',
	'surveyList.activatedOn': 'activated on',
	'surveyList.answered': 'answered',
	'surveyList.forwardedBy': 'Forwarded by',
	'surveyList.forwardedAt': 'at',
	'surveyList.sendReminder': 'Send a reminder',
	'surveyList.forward': 'Forward',
	'surveyManagement.forwardModal.title': 'Forward survey answers',
	'surveyManagement.forwardModal.selectRecipients': 'Select recipient',
	'surveyManagement.forwardModal.button': 'Forward answers',
	'surveyManagement.toast.surveyForwarded': 'Survey forwarded',
	'surveyManagement.remindersSent': 'reminders sent',
	'surveyManagement.toast.reminderSent': 'Reminder sent',
	'surveyManagement.modal.info': 'Re-send survey notification email to patient',
	'surveyManagement.modal.title': 'Send reminder',
	'asyncMessaging.navigation.inbox': 'Inbox',
	'asyncMessaging.navigation.sendNewMessage': 'Send a new message',
	'asyncMessaging.navigation.openConversations': 'Open conversations',
	'asyncMessaging.navigation.endedConversations': 'Ended conversations',
	'asyncMessaging.sendNewMessage.title': 'Send a new message',
	'asyncMessaging.sendNewMessage.subtitle': 'Receiving patient',
	'asyncMessaging.sendNewMessage.name': 'Patient Name',
	'asyncMessaging.sendNewMessage.ssn': 'Patient Social Security Number',
	'asyncMessaging.sendNewMessage.email': 'Patient e-mail',
	'asyncMessaging.sendNewMessage.mobile': 'Patient mobile phone number',
	'asyncMessaging.sendNewMessage.sms': 'Send SMS notification',
	'asyncMessaging.sendNewMessage.subjectTitle': 'Subject',
	'asyncMessaging.sendNewMessage.messageTitle': 'Message',
	'asyncMessaging.sendNewMessage.errorsTitle': 'Errors:',
	'asyncMessaging.sendNewMessage.ssnError': 'Put correct ssn number',
	'asyncMessaging.sendNewMessage.nameError': 'Put correct name',
	'asyncMessaging.sendNewMessage.emailError': 'Put correct email address',
	'asyncMessaging.sendNewMessage.mobileError':
		'Put correct mobile phone number',
	'asyncMessaging.sendNewMessage.subjectError': 'Put correct message subject',
	'asyncMessaging.sendNewMessage.messageBoxTextError': 'Put correct message',
	'asyncMessaging.sendNewMessage.sendButton': 'Send',
	'asyncMessaging.sendNewMessage.attachButton': 'Attach a file',
	'asyncMessaging.success': 'Message was sent',
	'asyncMessaging.patients.title': 'Patients',
	'asyncMessaging.patients.openConversations.title': 'Open conversations',
	'asyncMessaging.patients.endedConversations.title': 'Ended conversations',

	'asyncMessaging.patients.conversation.title': 'Conversation',
	'asyncMessaging.patients.actions.title': 'Actions',
	'asyncMessaging.patients.actions.endConversation': 'End conversation',
	'asyncMessaging.patients.actions.bookAnAppointment': 'Book and appointment',
	'asyncMessaging.patients.actions.sendSurvey': 'Send a survey',
	'asyncMessaging.patients.reply': 'Reply',
	'asyncMessaging.patients.forward': 'Forward',
	'asyncMessaging.patients.endConversationConfirmation':
		'Are you sure you want to close this conversation?',
	'asyncMessaging.patients.categories.title': 'Groups',
	'asyncMessaging.inbox.title': 'Inbox',
	'asyncMessaging.inbox.groups.title': 'Groups',
	'asyncMessaging.filters.title': 'Filters',
	'asyncMessaging.filters.subject': 'Subject',
	'asyncMessaging.filters.name': 'Patient name',
	'asyncMessaging.filters.ssn': 'Patient ssn',
	'asyncMessaging.sendingError': 'Sending error. Please try again later.',
};

import PatientApiService from 'app/services/config';
import { Response } from 'common/typings/response';

class ActOnBehalfService {
	public async clearOnBehalf(): Promise<any> {
		const response: Response<any> = await PatientApiService.get(
			`/on-behalf/clear`,
		);
		return response.data;
	}

	public connectToSumomiFi() {
		window.location.href = `${process.env.REACT_APP_API_URL}/on-behalf/set`;
	}
}

export default new ActOnBehalfService();

import axios from 'axios';
import { Response } from 'common/typings/response';
import PatientApiService from 'app/services/config';

const ninchatAPIUrl = 'https://api.ninchat.com/v2/call';

const AxiosNinchatInstance = axios.create({
	baseURL: ninchatAPIUrl,
	withCredentials: false,
});

class NinchatService {
	public async getNinchatQueueInfo(realmId: string) {
		const response: Response<any> = await AxiosNinchatInstance.get('', {
			params: {
				data: {
					action: 'describe_realm_queues',
					realm_id: realmId,
				},
			},
		});

		return response.data;
	}

	public async saveNinchatChannelId(channelId: string) {
		const response: Response<any> = await PatientApiService.post(
			'/ninchat/save-audience',
			{},
			{
				channel_id: channelId,
			},
		);
		return response.data;
	}
}

export default new NinchatService();

import styled from 'styled-components';

import { Text } from '@terveystalo/design-system-react-components';

import { roundedStyles } from 'common/styles/components/wrappers';

export const ActivityButton = styled.button`
	background: none;
	border: none;
	${roundedStyles};
	text-align: left;
	display: flex;
	margin: ${(props) => props.theme.spacing['1']} 0;
	padding: ${(props) => props.theme.spacing['1_5']}
		${(props) => props.theme.spacing['1_5']};
	width: 100%;
	cursor: pointer;
	align-items: flex-start;
`;

export const ActivityIconWrapper = styled.div`
	margin-right: ${(props) => props.theme.spacing['1_5']};
	width: 30px;
`;

export const ActivityItemInfo = styled.div`
	width: 100%;
`;

export const TitleWrapper = styled.div<{ nomargin: boolean }>`
	margin-bottom: ${(props) => props.theme.spacing['0_5']};

	${(props) =>
		props.nomargin &&
		`
    margin: 0;
  `}
`;

export const ActivityItemAction = styled.div`
	display: flex;
	align-items: center;
	white-space: nowrap;
`;

export const ActionText = styled(Text)`
	display: none;
	${(props) => props.theme.media.mediaQueries.desktop} {
		display: block;
	}
`;

import {
	createSlice,
	createSelector,
	createAsyncThunk,
} from '@reduxjs/toolkit';

import { RootState } from 'app/store';
import ContactService, { Contact } from 'app/services/contact/index';
import { ResponseError } from 'common/typings/response';

export interface ContactState {
	data: Partial<Contact> | null;
	isLoading: boolean;
	isSaving: boolean;
	error: any;
	savingError: any;
}

const initialState: ContactState = {
	data: null,
	isLoading: false,
	isSaving: false,
	error: null,
	savingError: null,
};

export const getContactAsync: any = createAsyncThunk(
	'contact/getContact',
	async (_obj, thunkAPI) => {
		try {
			const contactData: Contact & ResponseError =
				await ContactService.getContactInfo();

			// eslint-disable-next-line no-console
			console.log(JSON.stringify(contactData));

			if (contactData.status === 'error') {
				return thunkAPI.rejectWithValue(contactData.statusCode);
			}
			return contactData;
		} catch (err) {
			return thunkAPI.rejectWithValue(err);
		}
	},
);

export const setContactAsync: any = createAsyncThunk<any, Partial<Contact>>(
	'config/setConfig',
	async (contactData, thunkAPI) => {
		try {
			const responseData: Contact & ResponseError =
				await ContactService.setContactInfo(contactData);

			if (responseData.status === 'error') {
				return thunkAPI.rejectWithValue(responseData.statusCode);
			}
			return responseData;
		} catch (error) {
			return thunkAPI.rejectWithValue(error);
		}
	},
);

export const contactSlice = createSlice({
	name: 'contact',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(getContactAsync.pending, (state) => {
			state.isLoading = true;
		});

		builder.addCase(getContactAsync.rejected, (state, action) => {
			state.error = action.payload;
			state.data = {};
			state.isLoading = false;
		});

		builder.addCase(getContactAsync.fulfilled, (state, action) => {
			state.data = action.payload;
			state.isLoading = false;
		});

		builder.addCase(setContactAsync.pending, (state) => {
			state.isSaving = true;
		});

		builder.addCase(setContactAsync.fulfilled, (state, action) => {
			state.data = { ...state.data, ...action.payload };
			state.isSaving = false;
		});

		builder.addCase(setContactAsync.rejected, (state, action) => {
			state.savingError = action.payload;
			state.isSaving = false;
		});
	},
});

export default contactSlice.reducer;

export const selectContact = createSelector(
	(state: RootState) => state.contact,
	(contact) => contact,
);

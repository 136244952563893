import styled from 'styled-components';

import { Link } from '@terveystalo/design-system-react-components';

export const ButtonsWrapper = styled.div`
	display: flex;
	justify-content: flex-end;
`;

export const LinkWrapper = styled.div`
	margin-top: ${(props) => props.theme.spacing['2']};
	text-align: right;
`;

export const TermsTextWrapper = styled.div`
	max-height: 600px;
	overflow: auto;
	padding-right: ${(props) => props.theme.spacing['1']};

	a {
		color: ${(props) => props.theme.colors.primary};
	}
`;

export const TermsLink = styled(Link)<{ target: string }>`
	&& {
		vertical-align: middle;
		display: inline-block;

		img {
			margin-left: ${(props) => props.theme.spacing['0_5']};
			line-height: 1.5em;
			width: 15px !important;
			height: 15px !important;
			display: inline-block;
			vertical-align: middle;
		}
	}
`;

import { Response } from 'common/typings/response';

import PatientApiService from 'app/services/config';
import { ConfigParameters } from 'app/store/configuration/slices/configuration.slice';
import { Config } from 'common/typings/organisationConfig';

class ConfigService {
	public async getConfig({ companyId }: ConfigParameters) {
		if (companyId) {
			const response: Response<Config> = await PatientApiService.get(
				`/config/${companyId}`,
			);
			return {
				...response.data,
			};
		}

		const response: Response<Config> = await PatientApiService.get(`/config`);
		return {
			...response.data,
		};
	}
}

export default new ConfigService();

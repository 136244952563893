import styled from 'styled-components';

export const GuestThankYouPageWrapper = styled.div`
	margin: ${(props) => props.theme.spacing['1']}
		${(props) => props.theme.spacing['2']}
		${(props) => props.theme.spacing['1']}
		${(props) => props.theme.spacing['2']};
`;

export const GuestThankYouContentWrapper = styled.div`
	box-sizing: border-box;
	background-color: ${(props) => props.theme.colors.white};
	box-shadow: ${(props) => props.theme.boxShadows.boxShadow3};
	margin: ${(props) => props.theme.spacing['1']} 0;

	padding: ${(props) => props.theme.spacing['1']}
		${(props) => props.theme.spacing['1']}
		${(props) => props.theme.spacing['1']};

	${(props) => props.theme.media.mediaQueries.desktop} {
		padding: ${(props) => props.theme.spacing['1']}
			${(props) => props.theme.spacing['4']};
	}
`;

export const GuestTextBlock = styled.div`
	padding: ${(props) => props.theme.spacing['1']}
		${(props) => props.theme.spacing['1']}
		${(props) => props.theme.spacing['1']};
	font-weight: 400;
	font-size: 16px;
`;

export const Title = styled.h1`
	font-weight: 700;
	font-size: 21px;
`;

export const GuestLogoutWrapper = styled.div`
	display: flex;
	flex-direction: row;
	margin: 30px 0 10px 0;
	justify-content: center;
`;

import React, { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { ThemeContext } from 'styled-components';
import {
	Button,
	Icon,
	Text,
} from '@terveystalo/design-system-react-components';

import { convertDate, substractMinutesFromDate } from 'common/helpers/dates';
import { AppointmentType } from 'common/typings/bookings';
import { Language } from 'common/lang/lang';

import { AppointmentItemData } from 'app/services/serviceSelection';

import {
	ServiceItemWrapper,
	ServiceItemContent,
	ServiceItemArea,
	ServiceItemIconWrapper,
	ServiceItemInfo,
	CancellableWrapper,
	StartWrapper,
} from './serviceItemAppointment.styles';

interface ServiceItemAppointmentProps {
	simple?: boolean; // for displaying simpler version of item
	item: AppointmentItemData;
	onClick?: (item: AppointmentItemData, isCancellable: boolean) => any;
}

const JOIN_TIME_BEFORE = 5;
const CANCEL_TIME_BEFORE = 24 * 60;

export const ServiceItemAppointment = ({
	item,
	onClick,
	simple,
}: ServiceItemAppointmentProps) => {
	const [disabled, setDisabled] = useState(true);
	const [isCancellable, setIsCancellable] = useState(false);
	const { t } = useTranslation();
	const theme = useContext(ThemeContext);

	// user can join chat 5 minutes before meeting start
	// user can cancel appointment more than 24 before it starts
	const checkJoinDates = () => {
		if (
			substractMinutesFromDate(item.startTime, JOIN_TIME_BEFORE).getTime() <=
				new Date().getTime() ||
			(substractMinutesFromDate(item.startTime, CANCEL_TIME_BEFORE).getTime() >=
				new Date().getTime() &&
				item.type === AppointmentType.oneOnOne)
		) {
			setDisabled(false);
		} else {
			setDisabled(true);
		}

		if (
			substractMinutesFromDate(item.startTime, CANCEL_TIME_BEFORE).getTime() >=
			new Date().getTime()
		) {
			setIsCancellable(true);
		} else {
			setIsCancellable(false);
		}
	};
	const currentLanguage = i18next.language as Language;

	useEffect(() => {
		checkJoinDates();
		const interval = setInterval(() => {
			checkJoinDates();
		}, 5000);
		return () => clearInterval(interval);
		// eslint-disable-next-line
	}, [item]);

	const content = () => (
		<>
			<ServiceItemIconWrapper>
				{item.type === AppointmentType.oneOnOne && (
					<Icon name="Time" color={theme.colors.primary} alt="" />
				)}
				{item.type === AppointmentType.group && (
					<Icon name="VideoCamera" color={theme.colors.primary} alt="" />
				)}
			</ServiceItemIconWrapper>
			<ServiceItemInfo>
				{item.type === AppointmentType.group && (
					<div>
						<Text size="m" color={theme.colors.primary}>
							{item.startTime
								? convertDate(item.startTime, undefined, currentLanguage)
								: ''}
						</Text>
						<div>
							<Text
								size="m"
								color="zodiacBlack65"
								dangerouslySetInnerHTML={{
									__html: t('serviceSelection.book.group', {
										name: `<strong>${item.name}</strong>`,
										interpolation: { escapeValue: false },
									}),
								}}
							/>
						</div>
						<br />
						<br />
						<div>
							<StartWrapper>
								<Button
									data-testid="service-groupAppointment-button-start"
									onClick={onClick && onClick(item, false)}
									disabled={disabled}
									variant="primary"
								>
									{t('common.start')}
								</Button>
							</StartWrapper>
						</div>
					</div>
				)}
				{item.type === AppointmentType.oneOnOne && (
					<div>
						<div>
							<Text size="m" color={theme.colors.primary}>
								{item.startTime
									? convertDate(item.startTime, undefined, currentLanguage)
									: ''}
							</Text>
						</div>
						<div>
							<Text
								size="m"
								color="zodiacBlack65"
								dangerouslySetInnerHTML={{
									__html: t('serviceSelection.book.oneToOne', {
										name: `<strong>${item.name}</strong>`,
										interpolation: { escapeValue: false },
									}),
								}}
							/>
						</div>
						{!simple && (
							<div>
								<Text size="m" color="zodiacBlack65">
									{t('serviceSelection.yourAppointments.text')}
								</Text>
								<br />
								<br />
								<div>
									<StartWrapper>
										<Button
											data-testid="service-bookedAppointment-button-start"
											onClick={onClick && onClick(item, isCancellable)}
											disabled={disabled || isCancellable}
											variant="primary"
										>
											{t('common.start')}
										</Button>
									</StartWrapper>
									{isCancellable && (
										<CancellableWrapper>
											<Button
												data-testid="service-bookedAppointment-button-cancel"
												onClick={onClick && onClick(item, isCancellable)}
												variant="secondary"
											>
												{t('common.cancel')}
											</Button>
										</CancellableWrapper>
									)}
								</div>
							</div>
						)}
					</div>
				)}
			</ServiceItemInfo>
		</>
	);

	return (
		<ServiceItemWrapper>
			<ServiceItemContent>
				<ServiceItemArea data-testid="service-bookedAppointment-button">
					{content()}
				</ServiceItemArea>
			</ServiceItemContent>
		</ServiceItemWrapper>
	);
};

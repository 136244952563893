import React, { forwardRef, useEffect, useState } from 'react';

import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { fi, sv, enGB } from 'date-fns/esm/locale';

import { CustomTextField } from '../textField/TextField.styles';
import { DatePickerWrapper, DatePickerInputWrapper } from './DatePicker.styles';

registerLocale('fi', fi);
registerLocale('se', sv);
registerLocale('en', enGB);

const ExampleCustomInput = forwardRef(
	({ onClick, passedProps, value }: any) => (
		<DatePickerInputWrapper>
			<CustomTextField
				{...passedProps}
				autocomplete="off"
				onClick={onClick}
				value={value}
			/>
		</DatePickerInputWrapper>
	),
);

export const DatePickerField = (props: any) => {
	const {
		field: { name, value },
		form: { setFieldValue },
		onChange,
		...rest
	} = props;

	const [date, setDate] = useState('');

	useEffect(() => {
		setDate(value);
	}, [value]);

	return (
		<DatePickerWrapper>
			<DatePicker
				{...rest}
				selected={date}
				onChange={(currentDate: any) => {
					setDate(currentDate);
					setFieldValue(name, currentDate);
					if (onChange) {
						onChange(currentDate);
					}
				}}
				customInput={
					<ExampleCustomInput passedProps={{ ...rest, readonly: 'readonly' }} />
				}
			/>
		</DatePickerWrapper>
	);
};

import React, { useContext, useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { ThemeContext } from 'styled-components';
import {
	Field,
	Form,
	Formik,
	FormikErrors,
	FormikHelpers,
	FormikValues,
} from 'formik';
import i18next from 'i18next';

import {
	Icon,
	Subtitle,
	Title,
	Text,
	Button,
	Alert,
} from '@terveystalo/design-system-react-components';

import {
	BackButton,
	BackButtonText,
	BackContainer,
} from 'common/styles/components/back/back';
import { MessageBox } from 'common/components/asyncMessaging/components/messageBox/messageBox';
import { FormikAutocompleteComponent } from 'common/components/forms/autocomplete/FormikAutocomplete';
import {
	useCategoriesList,
	useSendNewMessagePatient,
	useSubcategoriesList,
	useValidateMessageCode,
} from 'common/hooks/asyncMessaging';
import {
	CategoriesList,
	CreateNewPatientConversationPayload,
} from 'common/services/asyncMessaging/asyncMessaging';
import { FormikTextField } from 'common/components/forms/textField/FormikTextField';
import { showErrors } from 'common/helpers/validation';
import { LanguagesType } from 'common/lang/lang';

import { config } from 'app/config/config';
import PatientApiService from 'app/services/config';
import { AppContext } from 'app/context/appContext';

import {
	AlertListItem,
	ErrorsWrapper,
	MessagesButton,
	CategoryWrapper,
	CategoryHeaderWrapper,
} from './conversationNewMessage.styles';

import { ContentSection } from '../home.styles';

enum NewMessagesState {
	optionselection = 1,
	messageCode = 2,
	sendMessage = 3,
}

export const ConversationNewMessagePage = () => {
	const { t } = useTranslation();
	const history = useHistory();
	const theme = useContext(ThemeContext);
	const location = useLocation<{
		codeValue?: string;
		category?: {
			id: string;
			name: LanguagesType;
		};
		subcategory?: {
			id: string;
			name: LanguagesType;
		};
	}>();
	const lang = i18next.language;
	const formikRef = useRef<any>(null);

	const { toggleToast } = useContext(AppContext);

	const [messageState, setMessageState] = useState(
		NewMessagesState.optionselection,
	);

	const [initialValues, setInitialValues] = useState({
		category: location.state?.category?.id || '',
		subcategory: location.state?.subcategory?.id || '',
		messageBoxText: '',
	});

	const [codeValue, setCodeValue] = useState(location.state?.codeValue || '');

	const [messageBoxText, setMessageBoxText] = useState('');
	const [attachments, setAttachments] = useState<Array<File>>([]);

	const [selectedCategory, setSelectedCategory] = useState<string>('');

	const [categoriesList, categoriesListLoading, getCategoriesList] =
		useCategoriesList(PatientApiService);

	const [subcategoriesList, subcategoriesListLoading, getSubcategoriesList] =
		useSubcategoriesList(PatientApiService, selectedCategory);

	const [
		sendingNewMessageError,
		sendingNewMessageSuccess,
		sendNewMessage,
		sendingNewMessage,
		resetSendNewMessage,
	] = useSendNewMessagePatient(PatientApiService);

	const [
		validateMessageCodeError,
		validateMessageCodeSuccess,
		validateMessageCodeRequest,
		validatingMessageCodeInProgress,
		resetValidation,
	] = useValidateMessageCode(PatientApiService, codeValue);

	useEffect(() => {
		if (sendingNewMessageSuccess) {
			formikRef.current?.resetForm(initialValues);
			setSelectedCategory('');
		}
	}, [sendingNewMessageSuccess]);

	useEffect(() => {
		getCategoriesList();
	}, []);

	useEffect(() => {
		if (selectedCategory) {
			getSubcategoriesList();
		}
	}, [selectedCategory]);

	useEffect(() => {
		if (location.pathname === config.paths.conversationNewMessage) {
			setMessageState(NewMessagesState.optionselection);
		} else if (
			location.pathname === config.paths.conversationNewMessageCreate
		) {
			setMessageState(NewMessagesState.sendMessage);
		} else if (location.pathname === config.paths.conversationNewMessageCode) {
			setMessageState(NewMessagesState.messageCode);
		}
	}, [location]);

	useEffect(() => {
		if (validateMessageCodeSuccess) {
			setInitialValues({
				category: validateMessageCodeSuccess.category?.id || '',
				subcategory: validateMessageCodeSuccess.subcategory?.id || '',
				messageBoxText: '',
			});

			setSelectedCategory(''); // clear selected category
			history.push(config.paths.conversationNewMessageCreate, {
				codeValue,
				category: validateMessageCodeSuccess.category,
				subcategory: validateMessageCodeSuccess.subcategory,
			});

			toggleToast({
				text: t('asyncMessaging.codeAdded', { code: codeValue }),
				color: theme.colors.viridianGreen150,
				background: theme.colors.viridianGreen15,
				icon: <Icon name="Check" color={theme.colors.viridianGreen150} />,
			});
		}
	}, [validateMessageCodeSuccess]);

	const handleGoToLandingPage = (ev: React.MouseEvent) => {
		ev.preventDefault();
		history.goBack();
	};

	const clearState = () => {
		setCodeValue(''); // clear code when using menu navigation
		setInitialValues({ category: '', subcategory: '', messageBoxText: '' }); //
		setSelectedCategory(''); // clear selected category
		resetSendNewMessage();
	};

	const handleGoToMessageCreation = () => {
		clearState();
		history.push(config.paths.conversationNewMessageCreate);
	};

	const handleGoToMessageCode = () => {
		resetValidation();
		history.push(config.paths.conversationNewMessageCode);
	};

	const handleSubmitMessageCode = () => {
		validateMessageCodeRequest();
	};

	const handleNewMessageFormSubmit = (
		values: FormikValues,
		{ resetForm }: FormikHelpers<typeof initialValues>,
	) => {
		formikRef.current = { resetForm };

		const toSend: CreateNewPatientConversationPayload = {
			text: messageBoxText,
			attachments,
			categoryId: values.category,
			subcategoryId: values.subcategory,
			messageCode: codeValue,
		};
		sendNewMessage(toSend);
	};

	const validateMessageCode = (values: FormikValues) => {
		const errors: FormikErrors<FormikValues> = {};

		if (!values.messageCode) {
			errors.messageCode = 'asyncMessaging.errors.messageCode';
		}

		return errors;
	};

	const validateCreateForm = (values: FormikValues) => {
		const errors: FormikErrors<FormikValues> = {};

		if (!values.category) {
			errors.category = 'asyncMessaging.errors.category';
		}

		if (!values.subcategory) {
			errors.subcategory = 'asyncMessaging.errors.subcategory';
		}

		if (!values.messageBoxText) {
			errors.messageBoxText = 'asyncMessaging.errors.messageBoxText';
		}

		return errors;
	};

	const handleChangeCategory = (categoryId: string) => {
		setSelectedCategory(categoryId);
	};

	return (
		<div>
			<BackContainer>
				<BackButton
					aria-label="Back button"
					href=""
					onClick={handleGoToLandingPage}
				>
					<Icon name="ArrowLeft" color={theme.colors.primary} />
					<BackButtonText size="m" color={theme.colors.primary}>
						{t('common.back')}
					</BackButtonText>
				</BackButton>
			</BackContainer>
			{messageState === NewMessagesState.optionselection && (
				<div>
					<Title as="h1" size="l">
						{t('asyncMessaging.newMessageTitle')}
					</Title>
					<Subtitle size="m">{t('asyncMessaging.selectAnOption')}</Subtitle>
					<br />
					<br />
					<MessagesButton onClick={handleGoToMessageCreation}>
						<Icon name="Mail" color={theme.colors.primary} />
						<div>
							<Text size="m" color={theme.colors.primary}>
								{t('asyncMessaging.sendMessage')}
							</Text>
							<div>
								<Text size="m" color={theme.colors.zodiacBlack65}>
									{/* {t('asyncMessaging.sendMessage.description')} */}
								</Text>
							</div>
						</div>
						<Icon name="ChevronRight" color={theme.colors.primary} />
					</MessagesButton>
					<MessagesButton onClick={handleGoToMessageCode}>
						<Icon name="CheckCircle" color={theme.colors.primary} />
						<div>
							<Text size="m" color={theme.colors.primary}>
								{t('asyncMessaging.useMessageCode')}
							</Text>
							<div>
								<Text size="m" color={theme.colors.zodiacBlack65}>
									{/* {t('asyncMessaging.useMessageCode.description')} */}
								</Text>
							</div>
						</div>
						<Icon name="ChevronRight" color={theme.colors.primary} />
					</MessagesButton>
				</div>
			)}
			{messageState === NewMessagesState.messageCode && (
				<div>
					<Title as="h1" size="l">
						{t('asyncMessaging.newMessageTitle')}
					</Title>
					<Formik
						initialValues={{ messageCode: '' }}
						onSubmit={handleSubmitMessageCode}
						enableReinitialize
						validateOnBlur
						validateOnChange
						validate={validateMessageCode}
					>
						{() => (
							<Form>
								<Field
									label={t('asyncMessaging.messageCodeTitle')}
									required
									id="messageCode"
									name="messageCode"
									noState
									onChange={(ev: React.ChangeEvent<HTMLInputElement>) =>
										setCodeValue(ev.target.value)
									}
									component={FormikTextField}
								/>
								<div>
									<Button
										variant="primary"
										{...(validatingMessageCodeInProgress
											? { icon: 'Spinner' }
											: {})}
										disabled={!codeValue || validatingMessageCodeInProgress}
									>
										{t('asyncMessaging.messageCodeSubmit')}
									</Button>
									{validateMessageCodeError && (
										<ErrorsWrapper>
											<Alert icon="AlertOctagon" variant="error">
												{t(validateMessageCodeError)}
											</Alert>
										</ErrorsWrapper>
									)}
								</div>
							</Form>
						)}
					</Formik>
				</div>
			)}
			{messageState === NewMessagesState.sendMessage && (
				<div>
					<ContentSection>
						<Title as="h1" size="l">
							{t('asyncMessaging.newMessageCreateTitle')}
						</Title>
						<Formik
							initialValues={initialValues}
							onSubmit={handleNewMessageFormSubmit}
							enableReinitialize
							validate={validateCreateForm}
						>
							{({
								dirty,
								submitCount,
								errors: formikErrors,
								touched,
								setFieldValue,
							}) => (
								<Form>
									{codeValue && (
										<div>
											<CategoryHeaderWrapper>
												<Text size="l" weight="bold">
													{t('asyncMessaging.category')}
												</Text>
											</CategoryHeaderWrapper>
											<div>
												<Text size="m">
													{location.state?.category?.name[lang]}
												</Text>
											</div>
											<br />
										</div>
									)}

									<CategoryWrapper hide={!!codeValue}>
										<Field
											disabled={categoriesList && categoriesList.length <= 0}
											loading={categoriesListLoading}
											label={t('asyncMessaging.category')}
											id="category"
											name="category"
											required
											onChangeValue={handleChangeCategory}
											items={categoriesList?.map((item: CategoriesList[0]) => ({
												label: item.name[lang],
												value: item.id,
											}))}
											component={FormikAutocompleteComponent}
										/>
									</CategoryWrapper>

									{codeValue && (
										<div>
											<CategoryHeaderWrapper>
												<Text size="l" weight="bold">
													{t('asyncMessaging.subcategory')}
												</Text>
											</CategoryHeaderWrapper>
											<div>
												<Text size="m">
													{location.state?.subcategory?.name[lang]}
												</Text>
											</div>
											<br />
										</div>
									)}
									<CategoryWrapper hide={!!codeValue}>
										<Field
											type="hidden"
											disabled={!selectedCategory}
											loading={subcategoriesListLoading}
											label={t('asyncMessaging.subcategory')}
											id="subcategory"
											name="subcategory"
											required
											items={
												selectedCategory
													? subcategoriesList?.map(
															(item: CategoriesList[0]) => ({
																label: item.name[lang],
																value: item.id,
															}),
													  )
													: []
											}
											component={FormikAutocompleteComponent}
										/>
									</CategoryWrapper>
									<Subtitle size="s">
										{t('asyncMessaging.message')}{' '}
										<Text size="l" color="attentionRed">
											*
										</Text>
									</Subtitle>
									<MessageBox
										hideAttachments
										charactersLimit={2000}
										simple
										isSending={sendingNewMessage}
										sendingSuccess={sendingNewMessageSuccess}
										invalid={
											!messageBoxText &&
											(submitCount > 0 || touched.messageBoxText)
										}
										labels={{
											attachButton: t(
												'asyncMessaging.sendNewMessage.attachButton',
											),
											sendButton: t('asyncMessaging.sendNewMessage.sendButton'),
										}}
										disableSend={!dirty}
										onChange={(text: string, attach: Array<File>) => {
											setFieldValue('messageBoxText', text);
											setMessageBoxText(text);
											setAttachments([...attach]);
										}}
										onSend={() => {}}
									/>
									{showErrors(submitCount, formikErrors).length > 0 && (
										<ErrorsWrapper>
											<Alert
												icon="AlertOctagon"
												variant="error"
												data-testid="patient-add-popup-errors"
											>
												{t('common.errors')}:
												<ul>
													{showErrors(submitCount, formikErrors).map(
														(error: any) => (
															<AlertListItem>{t(error)}</AlertListItem>
														),
													)}
												</ul>
											</Alert>
										</ErrorsWrapper>
									)}
									{sendingNewMessageError && (
										<ErrorsWrapper>
											<Alert icon="AlertOctagon" variant="error">
												{t(sendingNewMessageError)}
											</Alert>
										</ErrorsWrapper>
									)}
									{sendingNewMessageSuccess && (
										<ErrorsWrapper>
											<Alert icon="Check" variant="success">
												{t(sendingNewMessageSuccess)}
											</Alert>
										</ErrorsWrapper>
									)}
								</Form>
							)}
						</Formik>
					</ContentSection>
				</div>
			)}
		</div>
	);
};

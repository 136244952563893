import styled from 'styled-components';
import { Text } from '@terveystalo/design-system-react-components';
import { roundedStyles } from 'common/styles/components/wrappers';

import { ContentSection } from '../../home.styles';

export const AppointmentHistoryItemWrapper = styled(ContentSection)`
	padding: 0;
	margin: ${(props) => props.theme.spacing['1']} 0;
`;

export const AppointmentHistoryButton = styled.button`
	display: flex;
	width: 100%;
	height: 100%;
	cursor: pointer;
	text-align: left;
	margin: auto;
	${roundedStyles}
	border: none;
`;

export const HistoryIconWrapper = styled.div`
	padding: 0 ${(props) => props.theme.spacing['1']};
`;

export const RightArrowIconWrapper = styled.div`
	padding: ${(props) => props.theme.spacing['0_5']}
		${(props) => props.theme.spacing['1']};
	margin: 0 0 auto auto;
`;

export const CustomDateText = styled(Text)`
	display: block;
	margin-bottom: ${(props) => props.theme.spacing['0_5']};
`;

export const CustomConversedText = styled(Text)`
	display: block;
	padding: 0;
	margin-bottom: ${(props) => props.theme.spacing['0_25']};
`;

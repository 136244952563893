import { Response } from 'common/typings/response';
import LocalStorageService from 'common/services/localStorage';
import GuestApiService from './GuestApiService';

export interface CompanyCodeResponse {
	organization_code: string;
}

export interface GuestTokenResponse {
	token: string;
	email: string;
	name?: string;
}

export interface AccessKeyResult {
	event: string;
	access_key?: string;
	channel_id?: string;
}

class GuestUserService {
	public async getCompanyIdForAppointment(uuid: string) {
		const response: Response<CompanyCodeResponse> = await GuestApiService.get(
			`/guest/company-for-appointment/${uuid}`,
		);
		if (response.data?.status === 'error') {
			throw new Error(response.data.statusCode?.toString());
		}

		return response.data;
	}

	public async checkAuth(appointmentUuid: string) {
		const response: Response<GuestTokenResponse> = await GuestApiService.get(
			`/guest/checkAuth/${appointmentUuid}`,
		);
		if ('token' in response.data) {
			LocalStorageService.set('guestUserToken', response.data.token);
		} else {
			LocalStorageService.remove('guestUserToken');
		}
		return response.data;
	}

	public async login(
		appointmentUuid: string,
		email?: string,
		password?: string | number,
	) {
		const response: Response<GuestTokenResponse> = await GuestApiService.post(
			`/guest/login/${appointmentUuid}`,
			{ email, password },
		);

		if ('token' in response.data) {
			LocalStorageService.set('guestUserToken', response.data.token);
		} else {
			LocalStorageService.remove('guestUserToken');
		}
		return response.data;
	}

	public async getAccesskey(appointmentUuid: string) {
		const response: Response<AccessKeyResult> = await GuestApiService.get(
			`/guest/access-key/${appointmentUuid}`,
		);
		return response.data;
	}

	public async getAppointments(appointmentUuid: string) {
		const response: Response<any> = await GuestApiService.get(
			`/guest/appointment-data/${appointmentUuid}`,
		);
		if (response.data?.status === 'error') {
			throw new Error(response.data.statusCode?.toString());
		}
		return response.data;
	}
}

export default new GuestUserService();

import { useState, useEffect } from 'react';
import { useMutation, useQuery } from 'react-query';

import { CommonApiService } from 'common/services/config';
import AsyncMessagingService, {
	CategoriesRequestMessages,
	ConversationFilters,
	ConversationMessages,
	ReplyConversation,
	CreateNewExpertConversationPayload,
	CreateNewPatientConversationPayload,
	CreateEditCategorySubcategory,
	ExpertSearchParams,
	Category,
	Subcategory,
	CategoriesFilter,
} from 'common/services/asyncMessaging/asyncMessaging';

export const useReplyMessage = (
	apiService: CommonApiService,
	selectedConversation: string | null,
) => {
	const [sendingError, setSendingError] = useState('');
	const [sendingSuccess, setSendingSuccess] = useState<null | Array<{
		messageId: string;
	}>>(null);

	useEffect(() => {
		AsyncMessagingService.setApiService(apiService);
	}, []);

	const { isLoading: sending, mutate: replyMessage } = useMutation(
		async (values: Array<ReplyConversation>) => {
			setSendingError('');
			setSendingSuccess(null);

			return Promise.all(
				values.map((value) =>
					AsyncMessagingService.replyAsyncMessage(
						value,
						selectedConversation as unknown as string,
					),
				),
			);
		},
		{
			onError: () => {
				setSendingError('asyncMessaging.sendingError');
			},
			onSuccess: (responseData) => {
				if (responseData.find((resp: any) => resp.status === 'error')) {
					setSendingError('asyncMessaging.sendingError');
				} else {
					setSendingSuccess(responseData);
				}
			},
		},
	);

	return [sendingError, sendingSuccess, replyMessage, sending] as const;
};

export const useSendNewMessageExpert = (apiService: CommonApiService) => {
	const [sendingError, setSendingError] = useState<string | null>(null);
	const [sendingSuccess, setSendingSuccess] = useState<string | null>(null);

	useEffect(() => {
		AsyncMessagingService.setApiService(apiService);
	}, []);

	const { isLoading: sending, mutate: sendNewMessage } = useMutation(
		(values: CreateNewExpertConversationPayload) => {
			setSendingError(null);
			setSendingSuccess(null);

			return AsyncMessagingService.sendNewMessageExpert(values);
		},
		{
			onError: () => {
				setSendingError('asyncMessaging.sendingError');
			},
			onSuccess: (responseData) => {
				if (responseData.status === 'error') {
					setSendingError('asyncMessaging.sendingError');
				} else {
					setSendingSuccess('asyncMessaging.success');
				}
			},
		},
	);

	return [sendingError, sendingSuccess, sendNewMessage, sending] as const;
};

export const useConversations = (
	name: string,
	apiService: CommonApiService,
	showEnded: boolean = false,
	filters?: ConversationFilters,
) => {
	useEffect(() => {
		AsyncMessagingService.setApiService(apiService);
	}, []);

	const {
		data: conversations,
		isFetching,
		refetch: getConversations,
	} = useQuery(
		name,
		async () => AsyncMessagingService.getConversations(showEnded, filters),
		{
			initialData: { items: [] },
			select: (data) => data.items,
			enabled: false,
		},
	);

	return [conversations, isFetching, getConversations] as const;
};

export const useConversationMessages = (
	name: string,
	apiService: CommonApiService,
	conversationId: string | null,
	subcategoryId?: string,
) => {
	useEffect(() => {
		AsyncMessagingService.setApiService(apiService);
	}, []);

	const [sendingSuccess, setSendingSuccess] = useState<string | null>(null);

	const {
		data: conversationMessages,
		isFetching,
		dataUpdatedAt,
		refetch: getConversationMessages,
	} = useQuery(
		name,
		async () => {
			setSendingSuccess(null);

			if (conversationId) {
				return AsyncMessagingService.getConversationMessages(
					conversationId,
					subcategoryId,
				);
			}

			return { items: [] };
		},
		{
			initialData: { items: [] },
			select: (data) => data.items,
			enabled: false,
			onSuccess: (responseData: any) => {
				if (responseData?.status !== 'error') {
					setSendingSuccess('asyncMessaging.success');
				}
			},
		},
	);

	return [
		conversationMessages as ConversationMessages,
		getConversationMessages,
		isFetching,
		dataUpdatedAt,
		sendingSuccess,
	] as const;
};

export const useEndConversation = (
	apiService: CommonApiService,
	conversationId: string | null,
	successHandler: () => void,
) => {
	useEffect(() => {
		AsyncMessagingService.setApiService(apiService);
	}, []);

	const { mutate: endConversation } = useMutation(
		async () => {
			if (conversationId) {
				return AsyncMessagingService.endConversation(conversationId);
			}
			return {};
		},
		{
			onSuccess: (responseData: any) => {
				if (responseData.status !== 'error') {
					successHandler();
				}
			},
		},
	);

	return [endConversation] as const;
};

export const useMarkConversationAsRead = (
	apiService: CommonApiService,
	conversationId: string,
	successHandler: (conversationId: string) => void,
) => {
	useEffect(() => {
		AsyncMessagingService.setApiService(apiService);
	}, []);

	const { mutate: markAsRead } = useMutation(
		async () => {
			if (conversationId) {
				return AsyncMessagingService.markConversationAsRead(conversationId);
			}
			return {};
		},
		{
			onSuccess: (responseData: any) => {
				if (responseData.status !== 'error') {
					successHandler(conversationId);
				}
			},
		},
	);

	return [markAsRead] as const;
};

export const useCategoriesRequests = (apiService: CommonApiService) => {
	useEffect(() => {
		AsyncMessagingService.setApiService(apiService);
	}, []);

	const {
		data: categories,
		isFetching,
		refetch: getCategoriesRequests,
	} = useQuery(
		'asyncMessaging.categoriesRequests',
		async () => AsyncMessagingService.getCategoriesRequests(),
		{
			initialData: { items: [] },
			select: (data) => data.items,
			enabled: false,
		},
	);

	return [categories, isFetching, getCategoriesRequests] as const;
};

export const useCategoriesRequestsMessages = (
	apiService: CommonApiService,
	subcategoryId: string,
) => {
	useEffect(() => {
		AsyncMessagingService.setApiService(apiService);
	}, []);

	const {
		data: categoriesMessages,
		isFetching,
		refetch: getCategoriesRequestMessages,
	} = useQuery(
		'asyncMessaging.categoriesMessages',
		async () =>
			AsyncMessagingService.getCategoriesRequestMessages(subcategoryId),
		{
			initialData: { items: [] },
			select: (data) => data.items,
			enabled: false,
		},
	);

	return [
		categoriesMessages as CategoriesRequestMessages,
		isFetching,
		getCategoriesRequestMessages,
	] as const;
};

export const useReplyCategoryMessage = (
	apiService: CommonApiService,
	categoryId: string,
	patientId: string,
) => {
	const [sendingError, setSendingError] = useState('');
	const [sendingSuccess, setSendingSuccess] = useState('');

	useEffect(() => {
		AsyncMessagingService.setApiService(apiService);
	}, []);

	const { isLoading: sending, mutate: replyCategoriesMessage } = useMutation(
		async () =>
			AsyncMessagingService.replyCategoryMessage(categoryId, patientId),
		{
			onError: () => {
				setSendingError('asyncMessaging.sendingError');
			},
			onSuccess: (responseData) => {
				if (responseData.status === 'error') {
					setSendingError('asyncMessaging.sendingError');
				} else {
					setSendingSuccess('asyncMessaging.success');
				}
			},
		},
	);

	return [
		sendingError,
		sendingSuccess,
		replyCategoriesMessage,
		sending,
	] as const;
};

export const useCategoriesList = (
	apiService: CommonApiService,
	params?: CategoriesFilter,
) => {
	useEffect(() => {
		AsyncMessagingService.setApiService(apiService);
	}, []);

	const {
		data: categoriesList,
		isFetching,
		refetch: getCategoriesList,
	} = useQuery(
		'asyncMessaging.categoriesList',
		async () => AsyncMessagingService.getCategoriesList(params || {}),
		{
			initialData: { items: [] },
			select: (data) => data.items,
			enabled: false,
		},
	);

	return [categoriesList, isFetching, getCategoriesList] as const;
};

export const useSubcategoriesList = (
	apiService: CommonApiService,
	categoryId: string,
) => {
	useEffect(() => {
		AsyncMessagingService.setApiService(apiService);
	}, []);

	const {
		data: subcategoriesList,
		isFetching,
		refetch: getSubcategoriesList,
	} = useQuery(
		'asyncMessaging.subcategoriesList',
		async () => AsyncMessagingService.getSubcategoriesList(categoryId),
		{
			initialData: { items: [] },
			select: (data) => data.items,
			enabled: false,
		},
	);

	return [subcategoriesList, isFetching, getSubcategoriesList] as const;
};

export const useSendNewMessagePatient = (apiService: CommonApiService) => {
	const [sendingError, setSendingError] = useState<string | null>(null);
	const [sendingSuccess, setSendingSuccess] = useState<string | null>(null);

	useEffect(() => {
		AsyncMessagingService.setApiService(apiService);
	}, []);

	const reset = () => {
		setSendingError(null);
		setSendingSuccess(null);
	};

	const { isLoading: sending, mutate: sendNewMessage } = useMutation(
		(values: CreateNewPatientConversationPayload) => {
			setSendingError(null);
			setSendingSuccess(null);

			return AsyncMessagingService.sendNewMessagePatient(values);
		},
		{
			onError: () => {
				setSendingError('asyncMessaging.sendingError');
			},
			onSuccess: async (responseData, val) => {
				if (responseData.status === 'error') {
					setSendingError('asyncMessaging.sendingError');
				} else {
					if (val.messageCode) {
						await AsyncMessagingService.markMessageCodeAsUsed(val.messageCode);
					}
					setSendingSuccess('asyncMessaging.success');
				}
			},
		},
	);

	return [
		sendingError,
		sendingSuccess,
		sendNewMessage,
		sending,
		reset,
	] as const;
};

export const useSaveCategorySubcategory = (apiService: CommonApiService) => {
	const [sendingError, setSendingError] = useState('');
	const [sendingSuccess, setSendingSuccess] = useState<{
		categoryId?: string;
		subcategoryId?: string;
	} | null>(null);

	useEffect(() => {
		AsyncMessagingService.setApiService(apiService);
	}, []);

	const { isLoading: sending, mutate: saveCategorySubcategory } = useMutation(
		async (data: CreateEditCategorySubcategory) => {
			if (data.isSubcategory) {
				return AsyncMessagingService.saveSubcategory(data);
			}

			return AsyncMessagingService.saveCategory(data);
		},
		{
			onError: () => {
				setSendingError('asyncMessaging.sendingError');
			},
			onSuccess: (responseData) => {
				if (responseData.status === 'error') {
					setSendingError('asyncMessaging.sendingError');
				} else {
					setSendingSuccess(responseData);
				}
			},
		},
	);

	return [
		sendingError,
		sendingSuccess,
		saveCategorySubcategory,
		sending,
	] as const;
};

export const useSearchForExperts = (
	apiService: CommonApiService,
	{ searchQuery, specialization }: ExpertSearchParams,
) => {
	const [sendingSuccess, setSendingSuccess] = useState<string | null>(null);

	useEffect(() => {
		AsyncMessagingService.setApiService(apiService);
	}, []);

	const {
		data: searchResults,
		isFetching,
		refetch: getSearchResults,
	} = useQuery(
		'asyncMessaging.searchResults',
		async () => {
			setSendingSuccess(null);

			return AsyncMessagingService.searchForExperts({
				searchQuery,
				...(specialization ? { specialization } : {}),
			});
		},
		{
			initialData: { experts: [] },
			select: (data) => data,
			enabled: false,
			onSuccess: () => {
				setSendingSuccess('asyncMessaging.success');
			},
		},
	);

	return [searchResults, isFetching, getSearchResults, sendingSuccess] as const;
};

export const useValidateMessageCode = (
	apiService: CommonApiService,
	messageCode: string,
) => {
	const [sendingError, setSendingError] = useState<string | null>(null);
	const [sendingSuccess, setSendingSuccess] = useState<{
		category: Category;
		subcategory: Subcategory;
	} | null>(null);

	useEffect(() => {
		AsyncMessagingService.setApiService(apiService);
	}, []);

	const reset = () => {
		setSendingError(null);
		setSendingSuccess(null);
	};

	const { isLoading: sending, mutate: validateMessageCode } = useMutation(
		async () => {
			setSendingError(null);
			setSendingSuccess(null);
			return AsyncMessagingService.validateMessageCode(messageCode);
		},
		{
			onError: () => {
				setSendingError('asyncMessaging.messageCodes.error.500');
			},
			onSuccess: (responseData) => {
				if (responseData.status === 'error') {
					setSendingError(
						`asyncMessaging.messageCodes.error.${responseData.statusCode}`,
					);
				} else {
					setSendingSuccess(responseData);
				}
			},
		},
	);

	return [
		sendingError,
		sendingSuccess,
		validateMessageCode,
		sending,
		reset,
	] as const;
};

import { FormikErrors, FormikValues } from 'formik';

export const validateEmail = (email?: string) =>
	!!email
		?.toLowerCase()
		.match(
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
		);

export const hexValidation = /^#(([0-9a-fA-F]{2}){3})$/;

export const validatePinCode = (pincode: string) =>
	/^[0-9]{6,6}$/.test(pincode);

export const showErrors = (
	submitCount: number,
	errors: FormikErrors<FormikValues>,
	includeKey?: boolean,
) => {
	if (submitCount > 0) {
		return Object.entries(errors).map(([key, value]) =>
			includeKey ? [key, value] : value,
		);
	}
	return [];
};

export const stripHtmlTags = (value: string) => value.replace(/<[^>]*>?/gm, '');

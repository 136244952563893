import i18next from 'i18next';
import React, { useEffect } from 'react';

import WebAuthService from 'app/services/webAuth/webAuth';

import {
	QuestionnaireWrapper,
	QuestionnaireContainer,
	QuestionnaireFrame,
} from './questionnaire.style';

interface QuestionnaireProps {
	success: ({ metadata }: { metadata: string }) => void;
	error: (error: string) => void;
	queueId: string;
}

type ToHTAConnector = {
	type: 'Auth';
	payload: {
		accessToken: string;
		gender: string;
		age: number;
		useEncryptedResult: boolean;
	};
};

enum HTAConnectorEvent {
	init = 'Init',
	completed = 'Completed',
	completedEncrypted = 'CompletedEncrypted',
	error = 'Error',
}

export const Questionnaire: React.FC<QuestionnaireProps> = ({
	success,
	error,
	queueId,
}) => {
	const lang = i18next.language === 'se' ? 'sv' : i18next.language;

	const iframeUrl = `${process.env.REACT_APP_HTA_CONNECTOR_URL}?lang=${lang}&queueId=${queueId}`;

	useEffect(() => {
		addMessageListeners();
		return () => removeMessageListeners();
	}, []);

	const addMessageListeners = () => {
		window.addEventListener('message', handleMessage);
	};

	const removeMessageListeners = () => {
		window.removeEventListener('message', handleMessage);
	};

	const handleMessage = async (event: MessageEvent) => {
		if (event.origin !== process.env.REACT_APP_HTA_CONNECTOR_URL) {
			return;
		}

		switch (event.data.type) {
			case HTAConnectorEvent.init: {
				const { accessToken, age, gender } =
					await WebAuthService.getHTAConnectorAuthToken();
				if (accessToken && gender && age) {
					sendMessage(
						{
							type: 'Auth',
							payload: { accessToken, gender, age, useEncryptedResult: true },
						},
						event,
					);
				}
				break;
			}
			case HTAConnectorEvent.completed:
			case HTAConnectorEvent.completedEncrypted:
				success({ metadata: event.data.payload?.encryptedData });
				break;
			case HTAConnectorEvent.error:
				error(event.data.message);
				break;
			default:
		}
	};

	const sendMessage = (message: ToHTAConnector, { source }: MessageEvent) => {
		const targetOrigin = process.env.REACT_APP_HTA_CONNECTOR_URL;
		source?.postMessage(message, { targetOrigin });
	};

	const handleError = () => {
		error('general_error');
	};

	return (
		<QuestionnaireWrapper>
			<QuestionnaireContainer>
				<QuestionnaireFrame
					title="HTAConnector"
					width="400"
					height="400"
					src={iframeUrl}
					sandbox="allow-top-navigation allow-same-origin allow-scripts allow-forms allow-modals allow-downloads allow-popups"
					referrerPolicy="origin-when-cross-origin"
					onError={handleError}
				/>
			</QuestionnaireContainer>
		</QuestionnaireWrapper>
	);
};

import { useState, useRef, useEffect } from 'react';

const useSurveyHeight = (initialHeight: string | number) => {
	const [height, setHeight] = useState<string>();
	const timerRef = useRef<any>();

	useEffect(() => {
		setHeight(ensureValueUnit(initialHeight));
	}, [initialHeight]);

	const setHeightDebounced = (newHeight: string) => {
		clearTimeout(timerRef.current);
		timerRef.current = setTimeout(() => {
			if (newHeight !== height) {
				setHeight(ensureValueUnit(newHeight));
			}
		}, 300);
	};

	const ensureValueUnit = (value: string | number) => {
		if (typeof value === 'number') {
			return `${value}px`;
		}
		return value;
	};

	return { height, setHeightDebounced };
};

export default useSurveyHeight;

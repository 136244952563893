import React, { Suspense, useEffect, useState } from 'react';
import { Provider as StoreProvider } from 'react-redux';

import { initI18n } from 'expertApplication/lang/i18n';
import { ExpertProvider } from 'expertApplication/context/expertContext';
import { store } from 'expertApplication/store';

const Expert = React.lazy(
	() => import('./components/application/application') as any,
);

export const ExpertApplication = () => {
	const [langLoaded, setLangLoaded] = useState(false);

	useEffect(() => {
		initI18n().then(() => {
			setLangLoaded(true);
		});
	}, []);

	return (
		<Suspense fallback={<></>}>
			<StoreProvider store={store}>
				<ExpertProvider>{langLoaded && <Expert />}</ExpertProvider>
			</StoreProvider>
		</Suspense>
	);
};

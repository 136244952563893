import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import i18next from 'i18next';

import {
	Icon,
	Subtitle,
	Text,
} from '@terveystalo/design-system-react-components';

import { Conversations } from 'common/services/asyncMessaging/asyncMessaging';
import { convertDate } from 'common/helpers/dates';
import { Language } from 'common/lang/lang';

import {
	ConversationItem,
	ItemSubject,
	ItemName,
	UnreadWrapper,
	SpinnerWrapper,
	ConversationItemCard,
	ConversationItemCardHeader,
	ConversationItemCardContent,
	ConversationItemCardIcon,
	UnreadIndicator,
	ConversationItemCardContentTop,
	ConversationItemDate,
} from './conversationsList.styles';

interface ConversationsListProps {
	isLoading: boolean;
	items: Conversations;
	selectedConversation?: Conversations[0];
	handleItemClick?: (item: Conversations[0]) => void;
	cardStyle?: boolean;
}

export const ConversationsList = ({
	items,
	isLoading,
	selectedConversation,
	handleItemClick,
	cardStyle,
}: ConversationsListProps) => {
	const theme = useContext(ThemeContext);
	const lang = i18next.language;

	if (isLoading) {
		return (
			<SpinnerWrapper>
				<Icon name="Spinner" color={theme.colors.primary} />
			</SpinnerWrapper>
		);
	}

	const handleClick = (item: Conversations[0]) => () => {
		// eslint-disable-next-line @typescript-eslint/no-unused-expressions
		handleItemClick && handleItemClick(item);
	};

	return (
		<div>
			{items.map((item: Conversations[0]) => (
				<div>
					{!cardStyle && (
						<ConversationItem
							onClick={handleClick(item)}
							active={item.id === selectedConversation?.id}
						>
							<div>
								<ItemSubject>{item.subject}</ItemSubject>
								{item.name && <ItemName>{item.name}</ItemName>}
							</div>
							{(item.unreadMessages || 0) > 0 && (
								<UnreadWrapper>{item.unreadMessages}</UnreadWrapper>
							)}
						</ConversationItem>
					)}

					{cardStyle && (
						<ConversationItemCard onClick={handleClick(item)}>
							{item.expertName && (
								<ConversationItemCardHeader>
									<div>
										<Subtitle size="s" color={theme.colors.primary}>
											{item?.expertName}
										</Subtitle>
										<div>
											{item.expertSpecializations?.map(
												(
													specialization: NonNullable<
														Conversations[0]['expertSpecializations']
													>[0],
													specIndex: number,
												) => (
													<Text size="m" color={theme.colors.zodiacBlack65}>
														{`${specialization.name[lang]}${
															specIndex !==
															(item.expertSpecializations?.length || 0) - 1
																? ', '
																: ''
														}
														`}
													</Text>
												),
											)}
										</div>
									</div>
									<ConversationItemCardIcon>
										<Icon name="ArrowRight" color={theme.colors.primary} />
									</ConversationItemCardIcon>
								</ConversationItemCardHeader>
							)}
							<ConversationItemCardContent>
								<ConversationItemCardContentTop>
									{(item?.unreadMessages as number) > 0 && <UnreadIndicator />}
									<div>
										{item.subject && !item.categoryName && (
											<div>
												<Subtitle size="s" weight="bold">
													{item.subject}
												</Subtitle>
											</div>
										)}

										{item.categoryName && (
											<div>
												<Subtitle size="s">{item.categoryName[lang]}</Subtitle>
											</div>
										)}
										{item.subcategoryName && (
											<div>
												<Text size="m">{item.subcategoryName[lang]}</Text>
											</div>
										)}

										<br />
									</div>
								</ConversationItemCardContentTop>
								<ConversationItemDate
									unread={(item?.unreadMessages as number) > 0}
								>
									<div>
										{item.lastMessageDate &&
											convertDate(
												item.lastMessageDate,
												undefined,
												lang as Language,
												true,
											)}
										{item.hasAttachments && <Icon name="Attachment" />}
									</div>
									<Icon name="ArrowRight" color={theme.colors.primary} />
								</ConversationItemDate>
							</ConversationItemCardContent>
						</ConversationItemCard>
					)}
				</div>
			))}
		</div>
	);
};

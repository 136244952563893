import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { ThemeContext } from 'styled-components';
import { useSelector } from 'react-redux';
import i18next from 'i18next';

import {
	Icon,
	Subtitle,
	Text,
	Title,
} from '@terveystalo/design-system-react-components';

import {
	BackButton,
	BackButtonText,
	BackContainer,
} from 'common/styles/components/back/back';
import { useConversations } from 'common/hooks/asyncMessaging';
import { Conversations } from 'common/services/asyncMessaging/asyncMessaging';
import { ConversationsList } from 'common/components/asyncMessaging/components/conversationsList/conversationsList';

import { config } from 'app/config/config';
import PatientApiService from 'app/services/config';
import { selectConfig } from 'app/store/configuration/slices/configuration.slice';

import { NewMessageButton } from './conversationsList.styles';

export const ConversationsListPage = () => {
	const { t } = useTranslation();
	const history = useHistory();
	const theme = useContext(ThemeContext);
	const configData = useSelector(selectConfig);
	const lang = i18next.language;

	const [conversations, isLoadingConversations, getConversations] =
		useConversations('asyncMessaging.conversations', PatientApiService);

	useEffect(() => {
		getConversations();
	}, []);

	const handleGoToLandingPage = (ev: React.MouseEvent) => {
		ev.preventDefault();
		history.push(config.paths.landing);
	};

	const showConversationMessages = (conversation: Conversations[0]) => {
		history.push(config.paths.conversation, { conversation });
	};

	const handleNewMessage = () => {
		history.push(config.paths.conversationNewMessage);
	};

	return (
		<div>
			<BackContainer>
				<BackButton
					aria-label="Back button"
					href=""
					onClick={handleGoToLandingPage}
				>
					<Icon name="ArrowLeft" color={theme.colors.primary} />
					<BackButtonText size="m" color={theme.colors.primary}>
						{t('common.back')}
					</BackButtonText>
				</BackButton>
			</BackContainer>
			<Title as="h3" size="l">
				{t('asyncMessaging.messages.title')}
			</Title>
			<div>
				{!!configData.data?.asyncMessagingFrontPage[lang] && (
					<>
						<Text
							size="m"
							dangerouslySetInnerHTML={{
								__html: configData.data?.asyncMessagingFrontPage[lang],
							}}
						/>
						<br />
					</>
				)}
			</div>

			<br />
			<NewMessageButton variant="primary" onClick={handleNewMessage}>
				{t('asyncMessaging.newMessage')}
			</NewMessageButton>
			<br />
			<br />
			<br />
			<Subtitle size="m">{t('asyncMessaging.yourMessages')}</Subtitle>
			<br />
			<br />
			{!isLoadingConversations && conversations?.length === 0 && (
				<div>{t('asyncMessaging.noMessages')}</div>
			)}
			<ConversationsList
				items={conversations as Conversations}
				isLoading={isLoadingConversations}
				handleItemClick={showConversationMessages}
				cardStyle
			/>
		</div>
	);
};

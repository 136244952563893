export const se = {
	'pageTitles.allBookings': 'Bläddra bland alla bokningar',
	'pageTitles.appointment.open.edit': 'Redigera bokningsbar tid',
	'pageTitles.appointment.open.new': 'Öppna bokningsbara tider',
	'pageTitles.appointment.edit': 'Redigera en bokning',
	'pageTitles.appointment.new': 'Boka en tid',
	'pageTitles.appointmentHistory': 'Kontakthistorik',
	'pageTitles.login': 'Logga in',
	'pageTitles.schedule': 'Schema',
	'pageTitles.surveys': 'ndersökningar',
	'pageTitles.messagesInbox': 'Inkorg',
	'pageTitles.messagesSendNew': 'Skicka ett nytt meddelande',
	'pageTitles.openConversations': 'Öppna konversationer',
	'pageTitles.messagesEndedConversations.title': 'Arkiverade konversationer',

	'common.writeMessage': 'Skriv ett meddelande',
	'common.skip': 'Hoppa över',
	'common.send': 'Skicka',
	'common.reopen': 'Öppna igen',
	'common.delete': 'Radera',
	'common.minutes': 'minuter',
	'common.save': 'Spara',
	'common.edit': 'Redigera',
	'common.cancel': 'Avbryt',
	'common.login': 'Logga in',
	'common.menu': 'Meny',
	'common.username': 'Användarnamn',
	'common.password': 'Lösenord',
	'common.logOut': 'Logga ut',
	'common.hello': 'Hej',
	'common.language.finnish': 'Finska',
	'common.language.english': 'Engelska',
	'common.language.swedish': 'Svenska',
	'common.backToSchedule': 'Tillbaka till Schema',
	'common.downloadPDF': 'Ladda ner PDF',
	'common.backToAllBookings': 'Tillbaka till Alla bokningar',
	'common.email': 'E-post',
	'common.savingError': 'Fel vid sparande',
	'common.errors': 'Fel',
	'common.errors.105': 'personnumret är inte giltigt.',
	'common.errors.601': 'Administratörsanvändaren existerar inte',
	'common.errors.602':
		'Du har inte tillräckligt med rättigheter för att återställa användarens lösenord',
	'common.errors.801': 'Länken för att ange lösenord har slutat gälla',
	'common.errors.802':
		'Lösenordet är inte tillräckligt starkt, det måste innehålla små och stora bokstäver och siffror',
	'common.back': 'Tillbaka',
	'common.you': 'Du',
	'common.forward': 'Vidarebefordra meddelandet',
	'login.headline': 'Logga in för att fortsätta',
	'login.lead':
		'Välkommen till fjärrskrivbordsapplikationen för specialister. Genomför digitala möten och hjälp patienter på ett effektivt och behagligt sätt.',
	'login.info':
		'Fyll i dina inloggningsuppgifter till höger och logga in för att fortsätta.',
	'login.question.one': 'Vad är fjärrskrivbordsapplikationen för specialister?',
	'login.question.two': 'Hur skaffar jag inloggningsuppgifter?',
	'login.question.three':
		'Hur får jag min organisation att använda fjärrskrivbordsapplikationen?',
	'login.button': 'Logga in med användarnamn',
	'login.loginFailed': 'Inloggningen misslyckades.',
	'login.fromRegistration.title':
		'Du har skapat ett lösenord. Logga in för att fortsätta.',
	'login.changePassword': 'Glömt lösenordet?',
	'login.logout.expired':
		'Din session har löpt ut och du har loggats ut automatiskt. Logga in igen om du behöver använda adminpanelen.',
	'login.method.vrkCard': 'Logga in med VRK-korten',
	'login.method.adCredentials': 'Logga in med organisationens AD-referens',
	'mainMenu.options.desktop': 'Skrivbord',
	'mainMenu.options.schedule': 'Schema',
	'mainMenu.options.surveys': 'Undersökningar',
	'mainMenu.options.messages': 'Meddelanden',
	'mainMenu.options.termsOfService': 'Tjänstevillkor',
	'mainMenu.options.privacyPolicy': 'Integritetspolicy',
	'mainMenu.section1': 'Hur kan vi hjälpa dig?',
	'mainMenu.section2': 'Om tjänsten',
	'profileMenu.options.myInformation': 'Mina uppgifter',
	'profileMenu.options.workReports': 'Arbetsrapporter',
	'profileMenu.options.toAdminPanel': 'Till adminpanelen',
	'header.title': 'Fjärrskrivbordsapplikationen för specialister',
	'header.links.desktop': 'Skrivbord',
	'header.links.schedule': 'Schema',
	'header.links.surveys': 'Undersökningar',
	'header.links.messages': 'Meddelanden',
	'header.links.ninchat': 'Öppna Ninchat',
	'schedule.title': 'I dag',
	'schedule.section.today.title': 'Bokningar',
	'schedule.section.patient.title': 'Patient',
	'schedule.section.actions.title': 'Insatser',
	'schedule.cancel.title': 'Avboka tid?',
	'schedule.cancel.body':
		'Är du säker på att du vill avboka tiden? Detta kommer att skicka en avisering till alla deltagare.',
	'schedule.cancel.buttons.cancel': 'Avboka tid',
	'schedule.cancel.buttons.close': 'Stäng utan att avbryta',
	'schedule.delete.title': 'Ta bort bokning?',
	'schedule.delete.body': 'Är du säker på att du vill ta bort bokningen?',
	'schedule.delete.buttons.submit': 'Ta bort bokning',
	'schedule.delete.buttons.close': 'Stäng utan att radera',
	'schedule.buttons.now': 'Nu',
	'schedule.buttons.sendAMessage': 'Skriv ett meddelande',
	'schedule.buttons.patientsAppointmentHistory': 'Patientens bokningshistorik',
	'schedule.buttons.surveysResults': 'Undersökningens resultat',
	'schedule.patientForm.ssn': 'Personnummer',
	'schedule.patientForm.city': 'Ort',
	'schedule.patientForm.age': 'Ålder',
	'schedule.patientForm.mobile': 'Mobilnummer',
	'schedule.patientForm.company': 'Företag (betalningsnummer)',
	'schedule.patientForm.email': 'E-post',
	'schedule.patientForm.additionalInfo': 'Ytterligare info',
	'schedule.buttons.today': 'I dag',
	'schedule.buttons.openAvailableTimes': 'Öppna tillgängliga tider',
	'schedule.buttons.registerNewAppointment': 'Boka en tid',
	'schedule.buttons.browseAllBookings': 'Bläddra bland alla bokningar',
	'schedule.buttons.preQuestionnaire': 'Frågeformulär',
	'schedule.open.title': 'Öppen tid (bokningsbar)',
	'CreateAppointmentModal.title': 'Boka en tid',
	'CreateAppointmentModal.field.ssn': 'Personnummer',
	'CreateAppointmentModal.field.nationality': 'Patientens nationalitet',
	'CreateAppointmentModal.field.expert': 'Specialist',
	'CreateAppointmentModal.field.startingDate': 'Datum',
	'CreateAppointmentModal.field.startingTime': 'Starttid',
	'CreateAppointmentModal.field.preferredEndingTime': 'Föredragen sluttid',
	'CreateAppointmentModal.field.otherDuration': 'Övrigt',
	'CreateAppointmentModal.field.language': 'Patientens språk',
	'CreateAppointmentModal.field.email': 'E-post',
	'CreateAppointmentModal.field.firstName': 'Förnamn',
	'CreateAppointmentModal.field.lastName': 'Efternamn',
	'CreateAppointmentModal.field.name': 'Namn',
	'CreateAppointmentModal.duration.subtitle': 'Varaktighet (minuter)',
	'CreateAppointmentModal.duration.oneTimeslot.subtitle':
		'En tidsluckas varaktighet (minuter)',
	'CreateAppointmentModal.generateTimeslots.text':
		'{{number}} tidsluckor kommer att genereras. Du måste bekräfta tidsluckorna efteråt för att öppna dem.',
	'CreateAppointmentModal.generateTimeslots.button': 'Generera tidsluckor',
	'CreateAppointmentModal.timeslots.reservable': 'Bokningsbar',
	'CreateAppointmentModal.timeslots.break': 'Paus',
	'CreateAppointmentModal.timeslots.table.startingTime': 'Starttid',
	'CreateAppointmentModal.timeslots.table.endTime': 'Sluttid',
	'CreateAppointmentModal.timeslots.table.type': 'Typ',
	'CreateAppointmentModal.error.ssn': 'Lägg till personnummer',
	'CreateAppointmentModal.error.expert': 'Välj specialist i listan',
	'CreateAppointmentModal.error.startingDate': 'Lägg till korrekt startdatum',
	'CreateAppointmentModal.error.startingTime': 'Lägg till korrekt starttid',
	'CreateAppointmentModal.error.startingDate.past':
		'Det går inte att välja ett tidigare datum',
	'CreateAppointmentModal.error.nationality': 'Välj nationalitet',
	'CreateAppointmentModal.error.timeslotsListEmpty':
		'Tidsluckor måste genereras först',
	'CreateAppointmentModal.error.availableDate': 'Datum har inte valts',
	'CreateAppointmentModal.error.availableAppointment': 'Bokning har inte valts',
	'CreateAppointmentModal.error.email': 'Ange korrekt e-postadress',
	'CreateAppointmentModal.reminderCheckbox':
		'Skicka en bokningsbekräftelse via e-post till patienten',
	'createRemoteAppointment.emailReminder.lead':
		'När du har registrerat dig får du och alla inblandade parter ett bekräftelsemeddelande om det registrerade besöket via e-post.',
	'CreateAppointmentModal.button.create': 'Registrera tid',
	'CreateAppointmentModal.button.update': 'Uppdatera tid',
	'CreateAppointmentModal.button.createOpenAppointment': 'Öppna tidsluckor',
	'CreateAppointmentModal.openAppointment.lead':
		'När du har öppnat tiden kan patienterna se och reservera den genom systemet.',
	'CreateAppointmentModal.subtitle.open.new':
		'Välj ett datum och hur mycket du ska arbeta',
	'CreateAppointmentModal.subtitle.open.edit': 'Redigera tidslucka',
	'CreateAppointmentModal.subtitle.new.createNewTimeslotAndBookIt':
		'Skapa en ny tidslucka och boka den',
	'CreateAppointmentModal.subtitle.new.bookAvailableTimeslot':
		'Boka en ledig tidslucka',
	'CreateAppointmentModal.subtitle.edit': 'Redigera en bokning',
	'CreateAppointmentModal.field.date': 'Datum',
	'CreateAppointmentModal.field.appointment': 'Bokning',
	'CreateAppointmentModal.button.nextAvailable': 'Nästa tillgängliga',
	'CreateAppointmentModal.bookTimeslot.noMore': 'Inga fler lediga tidsluckor',
	'CreateAppointmentModal.bookTimeslot.timeslotSectionTile': 'Tidslucka',
	'CreateAppointmentModal.bookTimeslot.patientSectionTile': 'Patient',
	'CreateAppointmentModal.toast.1': 'Ny bokning har skapats',
	'CreateAppointmentModal.toast.2': 'Bokning genomförd',
	'CreateAppointmentModal.toast.3': 'Bokning redigerad',
	'CreateAppointmentModal.toast.4': 'Öppna tidsluckor skapade',
	'CreateAppointmentModal.toast.5': 'Öppen tidslucka redigerad',
	'services.preQuestionnaire.title': 'Frågeformulär före självbokning',
	'appointment.title.open.new': 'Öppna bokningsbara tider',
	'appointment.title.open.edit': 'Redigera bokningsbar tid',
	'appointment.title.new': 'Boka en tid',
	'appointment.title.edit': 'Redigera en bokning',
	'appointment.checkbox.bookAvailableTimeslot': 'Boka en ledig tidslucka',
	'appointment.checkbox.createNewTimeslotAndBookIt':
		'Skapa en ny tidslucka och boka den',
	'changePassword.headline': 'Specialist Ändra lösenord',
	'changePassword.successText':
		'Ett e-postmeddelande har skickats till den angivna adressen',
	'changePassword.subtext':
		'Ett e-postmeddelande skickas till den här adressen med en länk för att ändra ditt lösenord. Denna länk kommer att sluta gälla 30 minuter efter att den skickats.',
	'changePassword.submit': 'Ändra lösenord',
	'changePassword.backToLogin': 'Tillbaka till inloggning',
	'changePassword.error.incorrectFormat': 'Felaktigt e-postformat',
	'changePassword.error.general': 'Det uppstod ett fel med din begäran',
	'changePassword.error.badEmail': 'E-postadressen hittades inte',
	'registration.title': 'Skapa kontolösenord',
	'registration.errors.samePassword':
		'Lösenord och Bekräfta lösenord måste vara samma',
	'registration.errors.validation':
		'Lösenordet måste innehålla minst 12 tecken inklusive siffror, små och stora bokstäver',
	'registration.errors.general':
		'Det går inte att ange lösenord. Kontakta administratören.',
	'registration.fields.password': 'Lösenord',
	'registration.fields.passwordConfirm': 'Bekräfta lösenord',
	'appointmentHistory.title': 'Kontakthistorik',
	'appointmentHistory.lead':
		'Här kan du se de gånger du har interagerat med patienten under de senaste {{days}} dagarna med hjälp av fjärrskrivbordsapplikationen för specialister.',
	'allBookings.title': 'Bläddra bland alla bokningar',
	'allBookings.subtitle': 'Du kan filtrera dina resultat efter datum',
	'allBookings.filter.startDate': 'Startdatum',
	'allBookings.filter.endDate': 'Slutdatum',
	'allBookings.filter.matchStartDate': 'Matcha med startdatum',
	'surveyPage.error': 'Det gick inte att ladda upp enkäten',
	'allBookings.table.patient.title': 'Patient',
	'allBookings.table.status.title': 'Status',
	'allBookings.table.dateTime.title': 'Date, Tid',
	'allBookings.table.actions.title': 'Åtgärder',
	'allBookings.table.status.selfBooked': 'Reserverad',
	'allBookings.table.status.booked': 'Reserverad',
	'allBookings.table.status.cancelled': 'Avbokad',
	'allBookings.table.status.open': 'Öppen',
	'common.backToHomepage': 'Tillbaka',
	'surveyItem.nameMissing': 'Enkätnamn saknas',
	'surveyItem.selectSurvey': 'Välj en undersökning att lägga till',
	'surveyItem.addSurvey': 'Lägg till enkät',
	'survey.Description':
		'När du skapar en bokning kan du välja färdiga enkäter att skicka till patienten. Enkäten kan till exempel innehålla standardiserade medicinska frågor, du kan också skapa dina egna frågor.',
	'survey.Title': 'Enkäter',
	'surveyList.surveyResults': 'Enkätresultat',
	'surveyList.answeredSurveys': 'Besvarade enkäter',
	'surveyList.open': 'Öppna undersökningar',
	'surveyList.showAnswers': 'Visa svar',
	'organizationSelectionModal.button.login': 'Logga in',
	'organizationSelectionModal.headline': 'Välj en organisation',
	'questionnaireModal.title': 'Frågeformulär',
	'questionnaireModal.lead':
		'Nedan hittar du patientens svar på frågeformuläret.',
	'feedback.title':
		'Välkommen tillbaka! Berätta gärna vad du tycker om vår tjänst',
	'feedback.lead': 'Hur enkelt var det att använda denna tjänst?',
	'feedback.veryHard': 'Mycket svårt',
	'feedback.veryEasy': 'Mycket lätt',
	'feedback.form.lead':
		'Vi vill gärna höra vad du tycker så lämna gärna feedback om du vill.',
	'survey.navigation.answered': 'Besvarade enkäter',
	'survey.navigation.open': 'Öppna undersökningar',
	'survey.navigation.forwarded': 'Förmedlad till dig',
	'surveyManagement.forwarded': 'Förmedlad enkäters',
	'surveyManagement.templates': 'Enkätmallar',
	'surveyManagement.sortBy': 'Sortera:',
	'surveyManagement.appointmentDate': 'Mötets datum',
	'surveyManagement.patientName': 'Patientens namn',
	'surveyManagement.templates.description':
		'Tässä näet kaikki organisaatiollasi käytössä olevat kyselyt. Jos haluat käyttöön jonkin uuden kyselyn, ole yhteydessä organisaatiosi hallintakäyttäjään.',
	'surveyList.activatedOn': 'Aktiverad',
	'surveyList.answered': 'svaradu',
	'surveyList.forwardedBy': 'Förmedlad',
	'surveyList.forwardedAt': '',
	'surveyList.sendReminder': 'Skicka påminnelse',
	'surveyList.forward': 'Förmedla',
	'surveyManagement.forwardModal.title': 'Förmedla enkätsvaren',
	'surveyManagement.forwardModal.selectRecipients': 'Välj mottagare',
	'surveyManagement.forwardModal.button': 'Förmedla svaren',
	'surveyManagement.toast.surveyForwarded': 'Enkät har vidarebefordrats',
	'surveyManagement.remindersSent': 'påminnelser skickade',
	'surveyManagement.toast.reminderSent': 'Påminnelse skickad',
	'surveyManagement.modal.info':
		'Skicka enkätmeddelande via e-post till patienten igen',
	'surveyManagement.modal.title': 'Skicka påminnelse',
	'asyncMessaging.navigation.inbox': 'Anlände',
	'asyncMessaging.navigation.sendNewMessage': 'Skicka meddelande',
	'asyncMessaging.navigation.openConversations': 'Öppna konversationer',
	'asyncMessaging.navigation.endedConversations': 'Arkiverade konversationer',
	'asyncMessaging.sendNewMessage.title': 'Skicka ett nytt meddelande',
	'asyncMessaging.sendNewMessage.subtitle': 'Mottagare',
	'asyncMessaging.sendNewMessage.name': 'Mottagarens namn',
	'asyncMessaging.sendNewMessage.ssn': 'Personnummer',
	'asyncMessaging.sendNewMessage.email': 'Mottagarens email',
	'asyncMessaging.sendNewMessage.mobile': 'Mottagarens telefonnummer',
	'asyncMessaging.sendNewMessage.sms': 'Skicka en påminnelse via sms',
	'asyncMessaging.sendNewMessage.subjectTitle': 'Ämne',
	'asyncMessaging.sendNewMessage.messageTitle': 'Meddelande',
	'asyncMessaging.sendNewMessage.errorsTitle': 'Fel:',
	'asyncMessaging.sendNewMessage.ssnError': 'Ange rätt ssn-nummer',
	'asyncMessaging.sendNewMessage.nameError': 'Ange rätt namn',
	'asyncMessaging.sendNewMessage.emailError':
		'Vänligen ange en giltig e-postadress',
	'asyncMessaging.sendNewMessage.mobileError': 'Ange rätt telefonnummer',
	'asyncMessaging.sendNewMessage.subjectError': 'Sätt rätt meddelandeämne',
	'asyncMessaging.sendNewMessage.messageBoxTextError':
		'Sätt in rätt meddelande',
	'asyncMessaging.sendNewMessage.sendButton': 'Skicka',
	'asyncMessaging.sendNewMessage.attachButton': 'Bifoga en fil',
	'asyncMessaging.success': 'Meddelande skickades',
	'asyncMessaging.patients.title': 'Invånare',
	'asyncMessaging.patients.openConversations.title': 'Öppna konversationer',
	'asyncMessaging.patients.endedConversations.title':
		'Arkiverade konversationer',
	'asyncMessaging.patients.conversation.title': 'Konversation',
	'asyncMessaging.patients.actions.title': 'Actions',
	'asyncMessaging.patients.actions.endConversation': 'Avsluta konversationen',
	'asyncMessaging.patients.actions.bookAnAppointment': 'Boka ett möte',
	'asyncMessaging.patients.actions.sendSurvey': 'Skicka en enkät',
	'asyncMessaging.patients.reply': 'Svara',
	'asyncMessaging.patients.forward': 'Vidarebefordra',
	'asyncMessaging.patients.endConversationConfirmation':
		'Är du säker på att du vill stänga den här konversationen?',
	'asyncMessaging.patients.categories.title': 'Kategorier',
	'asyncMessaging.inbox.title': 'Inkorg',
	'asyncMessaging.inbox.groups.title': 'Kategorier',
	'asyncMessaging.filters.title': 'Filter',
	'asyncMessaging.filters.subject': 'Ämne',
	'asyncMessaging.filters.name': 'Mottagarens namn',
	'asyncMessaging.filters.ssn': 'Mottagarens ID',
	'asyncMessaging.sendingError': 'Spara fel. Försök igen senare.',
};

import styled from 'styled-components';

import { Text } from '@terveystalo/design-system-react-components';

import { addAlpha } from 'app/helpers/colors';

export const ButtonWrapper = styled.div`
	display: flex;
	justify-content: flex-end;
	flex-wrap: wrap;
	margin: ${(props) => props.theme.spacing['1']} 0;
`;

export const ButtonInnerWrapper = styled.div``;

export const ButtonInfoWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const ActionOnBehalfWrapper = styled.div`
	margin: ${(props) => props.theme.spacing['1']} 0;
	display: flex;
`;

export const OnBehalfButtonContent = styled.div`
	display: flex;

	background-color: ${(props) => addAlpha(props.theme.colors.primary, 0.1)};
	padding: ${(props) => props.theme.spacing['0_5']};
	// box-shadow: ${(props) => props.theme.boxShadows.boxShadow4};
	justify-content: center;
`;

export const OnBehalfButtonText = styled(Text)`
	margin: 0 ${(props) => props.theme.spacing['1']};
`;

export const OnBehalfContent = styled.div`
	padding: ${(props) => props.theme.spacing['1']};
	background-color: ${(props) => addAlpha(props.theme.colors.primary, 0.1)};
	position: relative;
	border-top: 1px solid ${(props) => addAlpha(props.theme.colors.primary, 0.1)};
`;

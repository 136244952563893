import React, { useContext, useEffect, useState } from 'react';
import { ThemeContext } from 'styled-components';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import i18next from 'i18next';

import {
	Icon,
	Subtitle,
	Text,
} from '@terveystalo/design-system-react-components';

import { stripHtmlTags } from 'common/helpers/validation';
import LocalStorageService from 'common/services/localStorage';
import { CustomCheckbox } from 'common/components/forms/checkbox/Checkbox.styles';
import {
	BackButton,
	BackButtonText,
	BackContainer,
} from 'common/styles/components/back/back';

import { AppContext } from 'app/context/appContext';
import { CustomButton } from 'app/styles/components/button/button';
import { config } from 'app/config/config';
import { selectConfig } from 'app/store/configuration/slices/configuration.slice';
import AnalyticsService from 'common/services/analytics/analytics';
import { Rte } from 'app/components/rte/rte';

import { ContentSection } from '../home.styles';

import {
	WelcomeWrapper,
	ButtonsWrapper,
	CheckboxWrapper,
	TextWrapper,
} from './welcome.styles';

const prepareServiceInfoText = (textAreaContent: string) =>
	textAreaContent ? textAreaContent.split('\n').join('<br />') : '';

export const WelcomeSubpage = () => {
	const theme = useContext(ThemeContext);
	const location = useLocation<any>();
	const history = useHistory();
	const [checked, setChecked] = useState(false);
	const { userDetails } = useContext(AppContext);
	const { t } = useTranslation();
	const configData = useSelector(selectConfig);

	const isProfileWelcomeRoute = !!useRouteMatch(config.paths.profileWelcome);
	const standalone = isProfileWelcomeRoute;
	const fromChatCodes = location?.state?.fromChatCodes;

	const lang: string = i18next.language;

	useEffect(() => {
		document.title = t('pageTitles.serviceInfo');
	}, [lang]);

	useEffect(() => {
		document.title = t('pageTitles.serviceInfo');
		AnalyticsService.sendPageView(t('pageTitles.serviceInfo'));
	}, []);

	const continueToServices = () => {
		if (checked) {
			LocalStorageService.set(
				`${userDetails?.email}-no-welcome-screen`,
				'true',
			);
		}
		history.push(config.paths.landing);
	};

	const handleDontShowAnymore = (
		event: React.ChangeEvent<HTMLInputElement>,
	) => {
		setChecked(event.target.checked);

		if (event.target.checked) {
			AnalyticsService.sendCustomEvent(
				'onBoardingHowServiceWorksDontShow',
				'on boarding',
				'click dont show anymore',
				'',
			);
		}
	};

	const handleGoBack = (ev: React.MouseEvent) => {
		ev.preventDefault();
		history.push(config.paths.serviceSelection);
	};

	return (
		<WelcomeWrapper>
			{fromChatCodes && (
				<BackContainer>
					<BackButton aria-label="Back button" href="#" onClick={handleGoBack}>
						<Icon name="ArrowLeft" color={theme.colors.primary} />
						<BackButtonText size="m" color={theme.colors.primary}>
							{t('common.backToServiceSelection')}
						</BackButtonText>
					</BackButton>
				</BackContainer>
			)}
			<Subtitle
				color={theme.colors.zodiacBlack}
				as="h3"
				size="m"
				data-testid="main-authentication-welome-headline"
			>
				{t('welcome.headline')}
			</Subtitle>

			{(!!configData.data.serviceInfoText[lang] ||
				(!standalone && !fromChatCodes)) && (
				<>
					<ContentSection>
						<Text size="m">
							<span
								// eslint-disable-next-line react/no-danger
								dangerouslySetInnerHTML={{
									__html: prepareServiceInfoText(
										configData.data.serviceInfoText[lang],
									),
								}}
							/>
						</Text>
						<br />
						<br />
						<br />
						{!standalone && !fromChatCodes && (
							<>
								<ButtonsWrapper>
									<CustomButton
										onClick={continueToServices}
										data-testid="continue-to-service"
									>
										{t('welcome.button')}
									</CustomButton>
								</ButtonsWrapper>
								<br />
								<CheckboxWrapper>
									<CustomCheckbox
										name="cbi-default"
										label={t('welcome.dontShowMessage')}
										checked={checked}
										onChange={handleDontShowAnymore}
									/>
								</CheckboxWrapper>
							</>
						)}
					</ContentSection>
					<br />
				</>
			)}
			{(standalone || fromChatCodes) &&
				configData.data.chatCodesInfo[lang] &&
				stripHtmlTags(configData.data.chatCodesInfo[lang]) && (
					<ContentSection>
						<TextWrapper>
							<Rte content={configData.data.chatCodesInfo[lang]} />
						</TextWrapper>
					</ContentSection>
				)}
		</WelcomeWrapper>
	);
};

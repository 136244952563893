import styled from 'styled-components';

import { Text } from '@terveystalo/design-system-react-components';

import { roundedStyles } from 'common/styles/components/wrappers';

export const SurveyButton = styled.button`
	background: none;
	border: none;
	${roundedStyles};
	text-align: left;
	display: flex;
	margin: ${(props) => props.theme.spacing['1']} 0;
	padding: ${(props) => props.theme.spacing['1_5']}
		${(props) => props.theme.spacing['1_5']};
	width: 100%;
	cursor: pointer;
	align-items: flex-start;
`;

export const SurveyIconWrapper = styled.div`
	margin-right: ${(props) => props.theme.spacing['1_5']};
	width: 30px;
`;

export const SurveyItemInfo = styled.div`
	width: 100%;
`;

export const TitleWrapper = styled.div`
	margin-bottom: ${(props) => props.theme.spacing['0_5']};
`;

export const SurveyActionWrapper = styled.div`
	display: flex;
	white-space: nowrap;
	align-items: center;
`;

export const SurveyActionText = styled(Text)`
	display: none;
	${(props) => props.theme.media.mediaQueries.desktop} {
		display: block;
	}
`;

import styled from 'styled-components';
import { Dialog } from '@terveystalo/design-system-react-components';

export const CustomDialog = styled(Dialog)<{
	noHeader?: boolean;
	className?: string;
}>`
	z-index: ${(props) => props.theme.zIndex.dialogDefault};

	> div {
		> div:first-child {
			color: ${(props) => props.theme.colors.primary};
		}
	}

	&.appointmentModal {
		> div:last-child {
			max-width: 24rem;
		}
	}

	&.actOnBehalfModal {
		> div:last-child {
			max-width: 30rem;
		}
	}

	&.selectOrganizationModal {
		> div:last-child {
			max-width: 22rem;
		}
	}

	${(props) =>
		props.noHeader &&
		`
			> div > h2 {
				display: none;
			}
	`};
`;

export const CustomDialogHeadline = styled.span`
	color: ${(props) => props.theme.colors.primary};
`;

import React from 'react';

import { RteWrapper, RteContent } from './rte.styles';

interface RteProps {
	content: string;
}

export const Rte = ({ content }: RteProps) => (
	<RteWrapper>
		<RteContent
			// eslint-disable-next-line react/no-danger
			dangerouslySetInnerHTML={{
				__html: content,
			}}
		/>
	</RteWrapper>
);

import React, { useState } from 'react';

import { ToastData } from 'common/components/toast/toast';

interface ExpertAppContextType {
	toastData: ToastData;
	setToastData: (value: ToastData) => void;
	mainMenuOpen: boolean;
	setMainMenuOpen: (value: boolean) => void;
	profileMenuOpen: boolean;
	setProfileMenuOpen: (value: boolean) => void;
}

export const ExpertAppContext = React.createContext<ExpertAppContextType>({
	toastData: {
		isOpen: false,
		text: '',
		color: '',
		icon: <></>,
		background: '',
	},
	setToastData: () => {},
	mainMenuOpen: false,
	setMainMenuOpen: () => {},
	profileMenuOpen: false,
	setProfileMenuOpen: () => {},
});

const { Provider } = ExpertAppContext;

export const ExpertProvider = ({ children }: any) => {
	const [mainMenuOpen, setMainMenuOpen] = useState<boolean>(false);
	const [profileMenuOpen, setProfileMenuOpen] = useState<boolean>(false);
	const [toastData, setToastData] = useState<ToastData>({
		isOpen: false,
		text: '',
		color: '',
		icon: <></>,
		background: '',
	});

	return (
		<Provider
			value={{
				toastData,
				setToastData,
				mainMenuOpen,
				setMainMenuOpen,
				profileMenuOpen,
				setProfileMenuOpen,
			}}
		>
			{children}
		</Provider>
	);
};

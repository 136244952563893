import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { Text } from '@terveystalo/design-system-react-components';

import {
	CustomDialog,
	CustomDialogHeadline,
} from 'app/styles/components/dialog/dialog';
import { CustomButton } from 'app/styles/components/button/button';
import { config } from 'app/config/config';

interface QuestionnaireErrorModalProps {
	isOpen: boolean;
	toggleModal(open: boolean): void;
	success: () => void;
}

export const QuestionnaireErrorModal = ({
	isOpen,
	toggleModal,
	success,
}: QuestionnaireErrorModalProps) => {
	const { t } = useTranslation();
	const history = useHistory();

	const toggleQuestionnaireErrorModal = () => {
		toggleModal(false);
	};

	const handleGoToServiceSelection = () => {
		toggleQuestionnaireErrorModal();
		history.push(config.paths.serviceSelection);
	};

	const handleGoToChat = () => {
		success();
	};

	return (
		<CustomDialog
			className="appointmentModal"
			open={isOpen}
			onClose={toggleQuestionnaireErrorModal}
			title={
				<CustomDialogHeadline>
					{t('questionnaireErrorModal.title')}
				</CustomDialogHeadline>
			}
			description={
				<div>
					<div>
						<Text size="m">{t('questionnaireErrorModal.text')}</Text>
					</div>
					<br />
					<div>
						<Text size="m">{t('questionnaireErrorModal.text2')}</Text>
					</div>
				</div>
			}
		>
			<CustomButton onClick={handleGoToChat}>
				{t('questionnaireErrorModal.button.chat')}
			</CustomButton>
			<CustomButton variant="secondary" onClick={handleGoToServiceSelection}>
				{t('questionnaireErrorModal.button.serviceSelection')}
			</CustomButton>
		</CustomDialog>
	);
};

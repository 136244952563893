import styled from 'styled-components';

import { addAlpha } from 'app/helpers/colors';

import { HEADER_HEIGHT, HEADER_HEIGHT_MOBILE } from '../header/header.styles';

export const ApplicationWrapper = styled.div`
	position: relative;
`;

export const Content = styled.main`
	padding-top: ${HEADER_HEIGHT_MOBILE}px;
	background-color: ${(props) => props.theme.colors.background};
	background-image: url(${(props) => props.theme.backgroundImage});
	background-size: cover;
	margin: 0 auto;

	${(props) => props.theme.media.mediaQueries.desktop} {
		padding-top: ${HEADER_HEIGHT}px;
	}
`;

export const ContentInner = styled.div`
	width: 100%;
	max-width: 944px;
	margin: 0 auto;
	min-height: calc(100vh - ${HEADER_HEIGHT_MOBILE}px);
	position: relative;

	${(props) => props.theme.media.mediaQueries.desktop} {
		min-height: calc(100vh - ${HEADER_HEIGHT}px);
		padding: ${(props) => props.theme.spacing['1_5']} 0;
	}
`;

export const LoaderWrapper = styled.div`
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const ProfileWrapper = styled.div`
	padding: ${(props) => props.theme.spacing['1']};

	${(props) => props.theme.media.mediaQueries.desktop} {
		padding: 0;
	}
`;

export const ActOnBehalfInfo = styled.div`
	position: fixed;
	left: 0;
	right: 0;
	top: ${HEADER_HEIGHT_MOBILE}px;
	z-index: ${(props) => props.theme.zIndex.actOnBehalf};
	background-color: ${(props) => props.theme.colors.white};
	color: ${(props) => props.theme.colors.primary};
	${(props) => props.theme.media.mediaQueries.desktop} {
		top: ${HEADER_HEIGHT}px;
	}
	> div {
		background-color: ${(props) => addAlpha(props.theme.colors.primary, 0.1)};
		text-align: center;
		width: 100%;
		padding: ${(props) => props.theme.spacing['0_5']};
	}
`;

export const ActOnBehalfEmpty = styled.div`
	padding: ${(props) => props.theme.spacing['1']};
`;

export const SkipToContentLink = styled.a`
	background-color: ${(props) => props.theme.colors.primary};
	top: -2px;
	left: 50%;
	padding: ${(props) => props.theme.spacing['1']};
	position: absolute;
	transform: translate(-50%, -100%);
	transition: transform 0.3s;
	z-index: ${(props) => props.theme.zIndex.skipToContent};
	color: ${(props) => props.theme.colors.white};
	text-decoration: none;

	&:focus {
		transform: translate(-50%, 0%);
	}
`;

import styled from 'styled-components';

import { Modal } from '@terveystalo/design-system-react-components';
import { CustomButton } from 'app/styles/components/button/button';

export const CookieWrapper = styled.div`
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: ${(props) => props.theme.colors.rgba.zodiacBlack0_9};
	z-index: ${(props) => props.theme.zIndex.cookie};
	color: ${(props) => props.theme.colors.white};
	padding: ${(props) => props.theme.spacing['2']};

	.cookie-policy-link {
		color: white;
		text-decoration: ;
	}
`;

export const CookieInner = styled.div`
	width: 100%;
	max-width: 1100px;
	margin: 0 auto;
	text-align: center;

	${(props) => props.theme.media.mediaQueries.desktop} {
		display: flex;
		text-align: left;
	}
`;

export const TextWrapper = styled.div`
	margin-right: ${(props) => props.theme.spacing['1']};
`;

export const ButtonWrapper = styled.div`
	white-space: nowrap;
	text-align: center;
`;

export const CookieSettingsButton = styled.button`
	background: none;
	border: none;
	border-bottom: 1px solid ${(props) => props.theme.colors.white};
	font-weight: bold;
	color: ${(props) => props.theme.colors.white};
	padding: 0;
	font-size: ${(props) => props.theme.typography.fontSize.body.m};
	cursor: pointer;
`;

export const CookieModal = styled(Modal)`
	> div:first-child {
		h2 {
			margin: 0;
		}
	}

	> button {
		border: none;
		background: none;
		cursor: pointer;
	}
`;

export const CookieSubmitButton = styled(CustomButton)`
	border-color: ${(props) => props.theme.colors.white};

	&:before {
		background-color: ${(props) => props.theme.colors.white};
		border-color: ${(props) => props.theme.colors.white};
	}

	&:not(:disabled):hover {
		border-color: ${(props) => props.theme.colors.white};
		opacity: 0.9;
	}
`;

export const CookieModalButtonsWrapper = styled.div`
	display: flex;
	justify-content: flex-end;
`;

import { createGlobalStyle, keyframes } from 'styled-components';
import '@terveystalo/design-system-cdn/dist/index/fonts.css';
import { ThemeType } from '../themes/themes';

export const GlobalStyles = createGlobalStyle<{
	theme: ThemeType;
}>`
  body {
    margin: 0;
    color: ${(props) => props.theme.colors.zodiacBlack};
    font-family: ${(props) => props.theme.typography.fontFamily.body};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    &.ninchat-open {
      overflow: auto !important;
      position: static !important;
    }
  }

  button {
    font-family: ${(props) => props.theme.typography.fontFamily.body};
    -webkit-font-smoothing: antialiased;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  * {
    box-sizing: border-box;
  }

  .sr-only{
    position:absolute;
    left:-10000px;
    top:auto;
    width:1px;
    height:1px;
    overflow:hidden;
 }
`;

export const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

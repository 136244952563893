import { Response } from 'common/typings/response';
import ExpertApiService from 'expertApplication/services/config';

export interface ExpertUserData {
	uuid: string;
	username: string;
	organizationId: string;
	organizationName: string;
	showFeedbackDialog: boolean;
}

class ExpertUserService {
	public async getExpertUserData(): Promise<any> {
		const response: Response<ExpertUserData> = await ExpertApiService.get(
			`/sso/user_data`,
		);
		return {
			...response.data,
		};
	}
}

export default new ExpertUserService();

import styled from 'styled-components';

import { roundedStyles } from 'common/styles/components/wrappers';

export const HomeWrapper = styled.div`
	padding: ${(props) => props.theme.spacing['1']};

	${(props) => props.theme.media.mediaQueries.desktop} {
		padding: 0;
	}
`;

export const HomeHeader = styled.div`
	margin: 0 0 ${(props) => props.theme.spacing['1_5']};
	background-color: ${(props) => props.theme.colors.white};
	border: 1px solid ${(props) => props.theme.colors.zodiacBlack22};
	padding: 0 ${(props) => props.theme.spacing['1']};
	height: 55px;
	align-items: center;
	display: flex;

	${(props) => props.theme.media.mediaQueries.desktop} {
		display: flex;
	}
`;

export const ContentSection = styled.div<{ smallBottom?: boolean }>`
	${roundedStyles}
	margin: ${(props) => props.theme.spacing['0_5']} 0;
	${(props) => props.theme.media.mediaQueries.desktop} {
		padding: ${(props) => props.theme.spacing['1_5']}
			${(props) => props.theme.spacing['4']}
			${(props) => props.theme.spacing['3']};
	}

	&& {
		${(props) =>
			props.smallBottom &&
			`
		padding-bottom: ${props.theme.spacing['1']};
	`};
	}
`;

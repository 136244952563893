import styled from 'styled-components';

import { roundedStyles } from 'common/styles/components/wrappers';

export const ServiceItemWrapper = styled.div<{
	disabled?: boolean;
}>`
	${({ disabled }) =>
		disabled &&
		` 
      opacity: 0.5;
  `}
`;

export const ServiceItemContent = styled.div``;

export const ServiceItemArea = styled.div`
	${roundedStyles};
	text-align: left;
	display: flex;
	margin: ${(props) => props.theme.spacing['1']} 0;
	width: 100%;
	align-items: flex-start;
`;

export const ServiceItemIconWrapper = styled.div`
	margin-right: ${(props) => props.theme.spacing['1']};
	width: 30px;
`;

export const ServiceItemArrowWrapper = styled.div``;

export const ServiceItemInfo = styled.div`
	width: 100%;
`;

export const CancellableWrapper = styled.span`
	display: inline-block;
`;

export const StartWrapper = styled.div`
	display: inline-block;
	margin-bottom: ${(props) => props.theme.spacing['1']};
	margin-right: ${(props) => props.theme.spacing['1']};
`;

import React from 'react';
import { useTranslation } from 'react-i18next';

import { Text } from '@terveystalo/design-system-react-components';

import {
	CustomDialog,
	CustomDialogHeadline,
} from 'app/styles/components/dialog/dialog';
import { CustomButton } from 'app/styles/components/button/button';

interface ChatCodeInfoModalProps {
	isOpen: boolean;
	toggleModal(open: boolean): void;
}

export const ChatCodeInfoModal = ({
	isOpen,
	toggleModal,
}: ChatCodeInfoModalProps) => {
	const { t } = useTranslation();

	const toggleChatCodeInfoModal = () => {
		toggleModal(false);
	};

	return (
		<CustomDialog
			className="chatCodeInfoModal"
			open={isOpen}
			onClose={toggleChatCodeInfoModal}
			title={
				<CustomDialogHeadline>
					{t('serviceSelection.chatCode.infoModal.headline')}
				</CustomDialogHeadline>
			}
			description={
				<div>
					<Text size="m">{t('serviceSelection.chatCode.infoModal.body')}</Text>
					<br />
					<br />
					<Text size="m">
						{t('serviceSelection.chatCode.infoModal.body.second')}
					</Text>
				</div>
			}
		>
			<CustomButton onClick={toggleChatCodeInfoModal}>
				{t('serviceSelection.chatCode.infoModal.submit')}
			</CustomButton>
		</CustomDialog>
	);
};

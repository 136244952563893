import {
	createSlice,
	createSelector,
	createAsyncThunk,
} from '@reduxjs/toolkit';

import { ResponseError } from 'common/typings/response';

import UserService, { UserData } from 'common/services/user';
import { RootState } from 'adminApplication/store';

export interface UserState {
	data: UserData | null;
	isLoading: boolean;
	isSaving: boolean;
	error: any;
	savingError: any;
}

const initialState: UserState = {
	data: null,
	isLoading: false,
	isSaving: false,
	error: null,
	savingError: null,
};

export const getUserAsync: any = createAsyncThunk(
	'user/getUser',
	async (_obj, thunkAPI) => {
		try {
			const userData: UserData & ResponseError =
				await UserService.getUserData();

			if (userData.status === 'error') {
				return thunkAPI.rejectWithValue(userData.statusCode);
			}
			return userData;
		} catch (err) {
			return thunkAPI.rejectWithValue(err);
		}
	},
);

export const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(getUserAsync.pending, (state) => {
			state.isLoading = true;
		});

		builder.addCase(getUserAsync.rejected, (state, action) => {
			state.error = action.payload;
			state.data = null;
			state.isLoading = false;
		});

		builder.addCase(getUserAsync.fulfilled, (state, action) => {
			state.data = action.payload;
			state.isLoading = false;
		});
	},
});

export default userSlice.reducer;

export const selectUser = createSelector(
	(state: RootState) => state.user,
	(user) => user,
);

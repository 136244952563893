import React, { useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Formik, Form, Field, FormikValues, FormikErrors } from 'formik';
import { Button, Alert } from '@terveystalo/design-system-react-components';

import { FormikTextField } from 'common/components/forms/textField/FormikTextField';
import {
	validateEmail,
	validatePinCode,
	showErrors,
} from 'common/helpers/validation';
import { ResponseError } from 'common/typings/response';

import { AppContext } from 'app/context/appContext';
import GuestUserService from 'app/services/guestUser/index';

import {
	GuestLoginWrapper,
	LoginFieldsWrapper,
	AlertListItem,
} from './guestLogin.styles';

export interface GuestCredentials {
	email?: string;
	pincode?: number;
}

export const GuestLogin = () => {
	const [loginError, setLoginError] = useState<ResponseError | null>(null);
	const [isLoading, setIsLoading] = useState(false);
	const { uuid } = useParams<{ uuid: string }>();
	const { setGuestDetails } = useContext(AppContext);
	const { t } = useTranslation();

	const handleSubmit = async (e: GuestCredentials) => {
		if (e.email && e.pincode) {
			setIsLoading(true);
			const loginRes = await GuestUserService.login(uuid, e.email, e.pincode);
			if ('token' in loginRes) {
				setGuestDetails({ name: loginRes?.name, email: loginRes.email });
			}
			if ('status' in loginRes && loginRes.status === 'error') {
				setLoginError(loginRes);
			}

			setIsLoading(false);
		}
	};

	const handleValidate = (values: FormikValues) => {
		const errors: FormikErrors<FormikValues> = {};

		if (!validatePinCode(values.pincode)) {
			errors.pincode = t('guestPage.malformattedPin');
		}

		if (!validateEmail(values.email)) {
			errors.email = t('guestPage.malformattedEmail');
		}

		return errors;
	};

	const displayLoginError = () => {
		if (loginError?.statusCode === 101) {
			return t('guestPage.wrongpassword');
		}
		return t('guestPage.unknownLoginError');
	};

	return (
		<GuestLoginWrapper>
			<h1>{t('guestPage.enterPinHeader')}</h1>
			<div>{t('guestPage.enterPin')}</div>
			{loginError && (
				<>
					<br />
					<Alert icon="AlertOctagon" variant="error">
						{displayLoginError()}
					</Alert>
				</>
			)}
			<Formik
				initialValues={{ email: '', pincode: '' } as any}
				validate={handleValidate}
				validateOnBlur
				onSubmit={handleSubmit}
			>
				{({ submitCount, errors }) => (
					<Form data-testid="guest-login-form">
						<LoginFieldsWrapper>
							{showErrors(submitCount, errors).length > 0 && (
								<Alert
									icon="AlertOctagon"
									variant="error"
									data-testid="guest-login-errors"
								>
									{t('guestPage.error')}:
									<ul>
										{showErrors(submitCount, errors).map((err: any) => (
											<AlertListItem>{t(err)}</AlertListItem>
										))}
									</ul>
								</Alert>
							)}
							<Field
								label={t('guestPage.email')}
								name="email"
								id="email"
								component={FormikTextField}
							/>
							<Field
								label={t('guestPage.pinCode')}
								name="pincode"
								id="pincode"
								component={FormikTextField}
							/>
						</LoginFieldsWrapper>
						<Button type="submit" disabled={isLoading}>
							{t('guestPage.continue')}
						</Button>
					</Form>
				)}
			</Formik>
		</GuestLoginWrapper>
	);
};

import React, { Suspense, useEffect, useState } from 'react';

import { Provider as StoreProvider } from 'react-redux';
import { store } from 'adminApplication/store';
import { AppProvider } from 'adminApplication/context/adminContext';

import { initI18n } from 'adminApplication/lang/i18n';

const Admin = React.lazy(
	() => import('./components/application/application') as any,
);

export const AdminApplication = () => {
	const [langLoaded, setLangLoaded] = useState(false);
	useEffect(() => {
		initI18n().then(() => {
			setLangLoaded(true);
		});
	}, []);

	return (
		<Suspense fallback={<></>}>
			<AppProvider>
				<StoreProvider store={store}>{langLoaded && <Admin />}</StoreProvider>
			</AppProvider>
		</Suspense>
	);
};

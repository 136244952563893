import React from 'react';
import { useTranslation } from 'react-i18next';

import { Alert } from '@terveystalo/design-system-react-components';

import { CustomTextArea } from 'common/components/forms/textArea/TextArea';

import { CustomLead } from 'common/styles/components/lead/lead';
import {
	FeedbackButton,
	FormWrapper,
	TextFieldWrapper,
	ButtonsWrapper,
} from './feedbackForm.styles';

interface FeedbackFormProps {
	textValue: string;
	setTextValue: (value: string) => void;
	savingError: string;
	savingInProgress: boolean;
	sendFeedback: (sendText: boolean) => () => void;
}

export const FeedbackForm = ({
	setTextValue,
	textValue,
	savingError,
	savingInProgress,
	sendFeedback,
}: FeedbackFormProps) => {
	const { t } = useTranslation();

	return (
		<div>
			<CustomLead size="s" weight="light">
				{t('feedback.form.lead')}
			</CustomLead>
			<FormWrapper>
				<TextFieldWrapper>
					<CustomTextArea
						aria-label={t('feedback.textarea.title')}
						id="tf-area-icon-valid"
						placeholder={t('common.writeMessage')}
						onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
							setTextValue(event.target.value)
						}
					/>
				</TextFieldWrapper>
			</FormWrapper>
			{savingError && !savingInProgress && (
				<Alert icon="AlertOctagon" variant="error">
					{t('common.savingError')}
				</Alert>
			)}
			<ButtonsWrapper>
				<FeedbackButton
					variant="secondary"
					onClick={sendFeedback(false)}
					data-testid="chat-feedback-skip-button"
				>
					{t('common.skip')}
				</FeedbackButton>
				<FeedbackButton
					{...(savingInProgress ? { icon: 'Spinner' } : {})}
					disabled={!textValue}
					last
					onClick={sendFeedback(true)}
				>
					{t('common.send')}
				</FeedbackButton>
			</ButtonsWrapper>
		</div>
	);
};

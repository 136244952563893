import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

import { ThemeContext } from 'styled-components';
import { Icon, Text } from '@terveystalo/design-system-react-components';
import { convertDate } from 'common/helpers/dates';
import { Language } from 'common/lang/lang';

import { AppointmentItemData } from 'app/services/serviceSelection';
import {
	ServiceItemWrapper,
	ServiceItemContent,
	ServiceButton,
	ServiceItemArrowWrapper,
	ServiceItemInfo,
	LoaderWrapper,
} from './serviceItem.styles';

interface OpenAppointmentItemProps {
	item: AppointmentItemData;
	isClickable?: boolean;
	isLoading?: boolean;
	handleClick?: (item: AppointmentItemData) => any;
	className?: string;
}

export const OpenAppointmentItem = ({
	item,
	handleClick,
	isClickable,
	isLoading,
	className,
}: OpenAppointmentItemProps) => {
	const { t } = useTranslation();
	const theme = useContext(ThemeContext);
	const currentLanguage = i18next.language as Language;

	const content = () => (
		<>
			<ServiceItemInfo>
				<Text size="m" color={theme.colors.primary}>
					{item.startTime
						? convertDate(item.startTime, undefined, currentLanguage)
						: ''}
				</Text>
				<div>
					<Text size="m" color={theme.colors.zodiacBlack65}>
						{item.name}
						<br />
						{item.duration} {t('common.minutes')}
					</Text>
					{isLoading && (
						<LoaderWrapper>
							<Icon name="Spinner" color={theme.colors.primary} />
						</LoaderWrapper>
					)}
				</div>
			</ServiceItemInfo>
			{isClickable && (
				<ServiceItemArrowWrapper>
					<Icon name="ChevronRight" color={theme.colors.primary} />
				</ServiceItemArrowWrapper>
			)}
		</>
	);

	const handleClickInternal = () => {
		if (isClickable && handleClick) {
			handleClick(item);
		}
	};

	return (
		<ServiceItemWrapper className={className}>
			<ServiceItemContent>
				<ServiceButton
					onClick={handleClickInternal}
					type="button"
					data-testid="bookAppointment-openAppointment-button"
				>
					{content()}
				</ServiceButton>
			</ServiceItemContent>
		</ServiceItemWrapper>
	);
};

import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { ThemeContext } from 'styled-components';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { useSelector } from 'react-redux';

import {
	Alert,
	Icon,
	Title,
	Button,
	Text,
} from '@terveystalo/design-system-react-components';

import {
	useConversationMessages,
	useMarkConversationAsRead,
	useReplyMessage,
} from 'common/hooks/asyncMessaging';
import {
	ConversationMessages,
	Conversations,
	ReplyConversation,
} from 'common/services/asyncMessaging/asyncMessaging';
import {
	BackButton,
	BackButtonText,
	BackContainer,
} from 'common/styles/components/back/back';

import { MessageBox } from 'common/components/asyncMessaging/components/messageBox/messageBox';
import { ConversationPatient } from 'common/components/asyncMessaging/components/conversationPatient/conversationPatient';

import { config } from 'app/config/config';
import PatientApiService from 'app/services/config';
import { AppContext } from 'app/context/appContext';
import { selectConfig } from 'app/store/configuration/slices/configuration.slice';

import {
	ContentSectionConversation,
	LoaderWrapper,
	ErrorsWrapper,
	ButtonsWrapper,
	SubcategoryText,
	NoAnswerAlert,
	Separator,
} from './conversation.styles';

export const ConversationPage = () => {
	const location = useLocation<any>();
	const history = useHistory();
	const theme = useContext(ThemeContext);
	const { t } = useTranslation();
	const lang = i18next.language;
	const { userDetails } = useContext(AppContext);
	const configData = useSelector(selectConfig);

	const [newConversationMessages, setNewConversationMessages] =
		useState<ConversationMessages>([]);

	const [replyVisible, setReplyVisible] = useState(false);
	const [messageBoxText, setMessageBoxText] = useState('');
	const [attachments, setAttachments] = useState<Array<File>>([]);

	const [selectedConversation, setSelectedConversation] = useState<
		Conversations[0]
	>({ id: '' });

	const [
		conversationMessages,
		getConversationMessages,
		isLoadingMessages,
		conversationMessagesUpdatedAt,
	] = useConversationMessages(
		'asyncMessaging.conversationMessages',
		PatientApiService,
		selectedConversation.id,
		selectedConversation.subcategoryId,
	);

	const [markConversationAsRead] = useMarkConversationAsRead(
		PatientApiService,
		selectedConversation.id,
		() => {},
	);

	const [replyError, replySuccess, replyMessage, replySending] =
		useReplyMessage(PatientApiService, selectedConversation.id);

	const scrollToBottom = () => {
		setTimeout(() => {
			window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
		}, 300);
	};

	useEffect(() => {
		if (location?.state?.conversation) {
			setSelectedConversation(location?.state?.conversation);
		}
	}, [location?.state?.conversation]);

	useEffect(() => {
		if (selectedConversation!.id) {
			getConversationMessages();
		}
	}, [selectedConversation!.id]);

	useEffect(() => {
		if ((selectedConversation.unreadMessages as number) > 0) {
			markConversationAsRead();
			scrollToBottom();
		}
	}, [conversationMessagesUpdatedAt]);

	useEffect(() => {
		if (replySuccess) {
			const newMessages = [...newConversationMessages];

			const newMessage = {
				id: '',
				name: userDetails?.email || '',
				date: new Date().getTime(),
				isRead: true,
				isOwn: true,
			};

			if (messageBoxText) {
				newMessages.push({
					...newMessage,
					id: replySuccess[0].messageId,
					content: messageBoxText,
					type: 'text',
				});
			}

			for (let i = 0; i < attachments.length; i += 1) {
				newMessages.push({
					...newMessage,
					id: replySuccess[messageBoxText ? i + 1 : i].messageId,
					content: attachments[i].name,
					type: 'file',
				});
			}

			setNewConversationMessages(newMessages);
			setMessageBoxText('');
			setAttachments([]);
			scrollToBottom();
		}
	}, [replySuccess]);

	const handleGoToMessages = (ev: React.MouseEvent) => {
		ev.preventDefault();
		history.push(config.paths.messages);
	};

	const handleSend = async ({
		text,
		attachments: addedAttachments,
	}: {
		text: string;
		attachments: Array<File>;
	}) => {
		if (selectedConversation) {
			const toSend: Array<ReplyConversation> = [];

			if (text) {
				toSend.push({ content: text, type: 'text' });
			}

			for (let i = 0; i < addedAttachments.length; i += 1) {
				toSend.push({ content: addedAttachments[i], type: 'file' });
			}

			replyMessage(toSend);
		}
	};

	const handleOpenReply = () => {
		setReplyVisible(true);
	};

	const hasAnswerFromExpert =
		conversationMessages.length > 0 &&
		!conversationMessages[conversationMessages.length - 1].isOwn;

	return (
		<div>
			<BackContainer>
				<BackButton
					aria-label="Back button"
					href=""
					onClick={handleGoToMessages}
				>
					<Icon name="ArrowLeft" color={theme.colors.primary} />
					<BackButtonText size="m" color={theme.colors.primary}>
						{t('common.back')}
					</BackButtonText>
				</BackButton>
			</BackContainer>
			<ContentSectionConversation>
				<div>
					{selectedConversation.subject && !selectedConversation.categoryName && (
						<div>
							<Title as="h1" size="l">
								{selectedConversation.subject}
							</Title>
						</div>
					)}

					{selectedConversation.categoryName && (
						<div>
							<Title as="h1" size="l">
								{selectedConversation.categoryName[lang]}
							</Title>
						</div>
					)}
					{selectedConversation.subcategoryName && (
						<div>
							<SubcategoryText size="l">
								{selectedConversation.subcategoryName[lang]}
							</SubcategoryText>
						</div>
					)}
				</div>
				<br />
				<br />
				{isLoadingMessages && (
					<LoaderWrapper>
						<br />
						<Icon name="Spinner" color={theme.colors.primary} />
					</LoaderWrapper>
				)}
				{!isLoadingMessages && (
					<div>
						<ConversationPatient
							labels={{
								you: t('common.you'),
								newLabel: t('common.new'),
							}}
							items={[
								...(conversationMessages as ConversationMessages),
								...newConversationMessages,
							]}
						/>

						{!hasAnswerFromExpert &&
							!!configData.data?.asyncMessagingNewMessageBanner[lang] && (
								<NoAnswerAlert variant="info" color={theme.colors.zodiacBlack}>
									<Icon name="Info" color={theme.colors.matisseBlue} />
									<Text
										size="m"
										color={theme.colors.matisseBlue}
										dangerouslySetInnerHTML={{
											__html:
												configData.data?.asyncMessagingNewMessageBanner[lang],
										}}
									/>
								</NoAnswerAlert>
							)}
					</div>
				)}
				{!isLoadingMessages && (
					<div>
						{!replyVisible && (
							<ButtonsWrapper>
								<Button
									disabled={!!selectedConversation.subcategoryId}
									onClick={handleOpenReply}
								>
									{t('asyncMessaging.reply')}
								</Button>
							</ButtonsWrapper>
						)}
						{replyVisible && (
							<div>
								<Separator />
								<MessageBox
									charactersLimit={2000}
									simple
									isSending={replySending}
									sendingSuccess={replySuccess}
									labels={{
										attachButton: t(
											'asyncMessaging.sendNewMessage.attachButton',
										),
										sendButton: t('asyncMessaging.sendNewMessage.sendButton'),
									}}
									disableSend={
										(!messageBoxText && attachments.length < 1) ||
										!selectedConversation
									}
									onChange={(text: string, attach: Array<File>) => {
										setMessageBoxText(text);
										setAttachments([...attach]);
									}}
									onSend={handleSend}
								/>
								<ButtonsWrapper>
									<Button
										variant="secondary"
										onClick={() => setReplyVisible(false)}
									>
										{t('asyncMessaging.discardDraft')}
									</Button>
								</ButtonsWrapper>
								<div>
									{replyError && (
										<ErrorsWrapper>
											<Alert icon="AlertOctagon" variant="error">
												{t(replyError)}
											</Alert>
										</ErrorsWrapper>
									)}
								</div>
							</div>
						)}
					</div>
				)}
			</ContentSectionConversation>
		</div>
	);
};

import { setLightness } from 'polished';
import styled from 'styled-components';

import { PillButton } from '@terveystalo/design-system-react-components';

export const PrimaryPillButton = styled(PillButton)`
	margin: ${(props) => props.theme.spacing['0_25']};
	background-color: ${(props) => props.theme.colors.primary};
	color: ${(props) => props.theme.colors.white};
	border: none;

	> span {
		display: flex;
		align-items: center;
	}

	&:disabled {
		color: ${(props) => props.theme.colors.white};
		background-color: ${(props) => props.theme.colors.zodiacBlack65};

		&:hover {
			&:before {
				content: none;
			}
		}
	}

	&:not(:disabled) {
		&:before {
			background-color: ${(props) =>
				setLightness(0.5, props.theme.colors.primary)};
		}

		&:hover {
			color: ${(props) => props.theme.colors.white};
		}
	}
`;

import styled from 'styled-components';

export const ChatCodeWrapper = styled.div``;

export const ChatCodeLabel = styled.span`
	display: flex;
	align-items: center;
	color: ${(props) => props.theme.colors.primary};

	> svg {
		margin-left: ${(props) => props.theme.spacing['0_5']};
	}
`;

export const ChatCodeError = styled.div<{ errorStyle: 'error' | 'warning' }>`
	padding: ${(props) => props.theme.spacing['0']}
		${(props) => props.theme.spacing['0']}
		${(props) => props.theme.spacing['1']};
	font-weight: bold;
	margin-top: -${(props) => props.theme.spacing['0_5']};

	${(props) =>
		props.errorStyle === 'error' &&
		`
	color: ${props.theme.colors.coralRed150};

	`}
`;

import { theme } from '@terveystalo/design-system-theme';

export const defaultTheme = {
	backgroundImage: '',
	colors: {
		...theme.color,
		primary: theme.color.matisseBlue,
		background: theme.color.zodiacBlack02,
		rgba: {
			zodiacBlack0_11: 'rgba(33, 35, 34, 0.11)',
			zodiacBlack0_9: 'rgba(33, 35, 34, 0.9);',
		},
		chatExpert: 'rgba(129, 140, 154, 0.2)',
	},
	spacing: {
		...theme.spacing,
	},
	media: {
		breakpoint: theme.media.breakpoint,
		mediaQueries: {
			mobileSmall: `@media screen and (max-width: 500px)`,
			mobile: `@media screen and (max-width: 1023px)`, // cannot use ems from design system
			desktop: `@media screen and (min-width: ${theme.media.breakpoint.m})`,
		},
	},
	typography: {
		...theme.typography,
		customFontSizes: {
			small: '14px',
		},
	},
	border: {
		...theme.border,
	},
	boxShadows: {
		boxShadow1: '0px 6px 12px rgba(6, 29, 69, 0.1)',
		boxShadow2: '0px 8px 48px rgba(44, 67, 106, 0.25)',
		boxShadow3: '0px 4px 8px rgba(33, 35, 34, 0.05)',
		boxShadow4: '0px 3px 10px rgba(44, 67, 106, 0.1)',
	},
	zIndex: {
		header: 7,
		actOnBehalf: 7,
		mainMenuOverlay: 8,
		mainMenu: 9,
		cookie: 10,
		dialogDefault: 11,
		skipToContent: 12,
		autocompleteDropdown: 6,
		toast: 200,
	},
};

export type ThemeType = typeof defaultTheme;

interface IUserTheme {
	colors: {
		primary: string | null;
		background: string | null;
	};
	backgroundUrl: string | null;
	logoUrl: string | null;
}

export const prepareUserTheme: any = (userTheme: IUserTheme | null) => ({
	...defaultTheme,
	backgroundImage: userTheme?.backgroundUrl || '',
	colors: {
		...defaultTheme.colors,
		primary: userTheme?.colors?.primary || defaultTheme.colors.primary,
		background: userTheme?.colors?.background || defaultTheme.colors.background,
	},
});

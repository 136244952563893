import React, { useEffect, useState } from 'react';
import { Provider as StoreProvider } from 'react-redux';
import { useLocation } from 'react-router-dom';

import LocalStorageService from 'common/services/localStorage';

import Application from 'app/components/application/application';
import { store } from 'app/store';
import { AppProvider } from 'app/context/appContext';
import { SelectedOrganizationProvider } from 'app/context/selectedOrganizationContext';
import UserService from 'app/services/user/user';

import { initI18n } from 'app/lang/i18n';
import { languages } from 'common/lang/lang';

export const CustomerApplication = () => {
	const [langLoaded, setLangLoaded] = useState(false);
	const [selectedLanguage, setSelectedLanguage] = useState<string | null>(null);
	const location = useLocation();

	const sendLanguageInfo = (lang: string) => {
		UserService.setClientParams({ lang });
	};

	useEffect(() => {
		const params = new URLSearchParams(location.search);
		let lang = params.get('lang');
		if (lang === 'sv') {
			lang = 'se';
		}

		if (
			lang === languages.fi ||
			lang === languages.se ||
			lang === languages.en
		) {
			LocalStorageService.set('language', lang);
			sendLanguageInfo(lang);
			setSelectedLanguage(lang);
		} else if (LocalStorageService.get('language')) {
			sendLanguageInfo(LocalStorageService.get('language') as string);
			setSelectedLanguage(LocalStorageService.get('language'));
		} else {
			LocalStorageService.set('language', languages.fi);
			sendLanguageInfo(languages.fi);
			setSelectedLanguage(languages.fi);
		}
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (selectedLanguage) {
			initI18n(selectedLanguage).then(() => {
				setLangLoaded(true);
			});
		}
	}, [selectedLanguage]);

	return (
		<StoreProvider store={store}>
			<AppProvider>
				<SelectedOrganizationProvider>
					{langLoaded && <Application />}
				</SelectedOrganizationProvider>
			</AppProvider>
		</StoreProvider>
	);
};

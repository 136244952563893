import React, { useContext } from 'react';

import {
	Redirect,
	Route,
	Switch,
	useHistory,
	useRouteMatch,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from 'styled-components';
import { useSelector } from 'react-redux';

import { Icon } from '@terveystalo/design-system-react-components';

import {
	BackButton,
	BackButtonText,
	BackContainer,
} from 'common/styles/components/back/back';

import LocalStorageService from 'common/services/localStorage';
import { PrivateRoute } from 'common/helpers/privateRoute';

import { AppContext } from 'app/context/appContext';
import { config } from 'app/config/config';
import WebAuthService from 'app/services/webAuth/webAuth';
import { containerId, NinchatConfig } from 'app/components/ninChat/ninChat';
import AnalyticsService from 'common/services/analytics/analytics';
import { SelectedOrganizationContext } from 'app/context/selectedOrganizationContext';
import { selectConfig } from 'app/store/configuration/slices/configuration.slice';

import { AuthenticationSubPage } from './authentication/authentication';
import { ContactSubPage } from './contact/contact';
import { ServiceSelectionPage } from './serviceSelection/serviceSelection';
import { BookAppointmentPage } from './bookAppointment/bookAppointment';
import { BookedAppointmentsPage } from './bookedAppointments/bookedAppointemnts';
import { TermsSubPage } from './terms/terms';
import { ChatSubpage } from './chat/chat';
import { WelcomeSubpage } from './welcome/welcome';
import { FeedbackSubPage } from './feedback/feedback';
import { LandingPage } from './landing/landing';
import { AppointmentHistoryPage } from './appointmentHistory/appointmentHistory';
import AppoinmentHistoryDetails from './appointmentHistoryDetails/appoinmentHistoryDetails';
import { ConversationsListPage } from './conversationsList/conversationsList';
import { ConversationPage } from './conversation/conversation';
import { ConversationNewMessagePage } from './conversationNewMessage/conversationNewMessage';

import { HomeWrapper } from './home.styles';

type History = {
	ninChatConfig: NinchatConfig | any;
};

function HomeScreen() {
	const configData = useSelector(selectConfig);

	const isTermsRoute = !!useRouteMatch(config.paths.terms);
	const isServiceSelectionRoute = !!useRouteMatch(
		config.paths.serviceSelection,
	);
	const isChatRoute = !!useRouteMatch(config.paths.chat);
	const isFeedbackRoute = !!useRouteMatch(config.paths.feedback);
	const isAppoinmentDetailsRoute = !!useRouteMatch(
		config.paths.appointmentDetails,
	);

	const { t } = useTranslation();
	const history = useHistory<History>();
	const theme = useContext(ThemeContext);
	const { userDetails, isGuestAuthenticated } = useContext(AppContext);
	const { selectedOrganization } = useContext(SelectedOrganizationContext);

	const termsAccepted = userDetails?.termsAccepted;

	const isAuthenticated = WebAuthService.isAuthenticated();

	const welcomeDontShowAgain =
		LocalStorageService.get(`${userDetails?.email}-no-welcome-screen`) ===
		'true';

	const handleGoBack = (event: React.MouseEvent) => {
		event.preventDefault();
		if (isServiceSelectionRoute) {
			history.push(config.paths.landing);
		} else if (isTermsRoute) {
			history.push(config.paths.contact);
		} else if (isFeedbackRoute) {
			history.push(config.paths.serviceSelection);
		} else if (isChatRoute && isGuestAuthenticated()) {
			AnalyticsService.sendCustomEvent(
				'chatScreenNavigation',
				'remote appointment',
				'navigate back',
				'',
			);
			document.getElementById(containerId)?.remove();
			const appointmentUuid: string =
				history.location.state?.ninChatConfig?.uuid;
			history.push(config.paths.guestUser + appointmentUuid);
		} else if (isChatRoute) {
			AnalyticsService.sendCustomEvent(
				'chatScreenNavigation',
				'remote appointment',
				'navigate back',
				'',
			);
			document.getElementById(containerId)?.remove();
			history.push(config.paths.serviceSelection);
		} else if (isAppoinmentDetailsRoute) {
			history.push(config.paths.appointmentHistory);
		}
	};

	const handleBackLabel = () => {
		if (isServiceSelectionRoute) {
			return t('common.backToHomepage');
		}

		if (isTermsRoute) {
			return t('common.backToContact');
		}

		if (isFeedbackRoute || isChatRoute) {
			return t('common.backToServiceSelection');
		}

		if (isAppoinmentDetailsRoute) {
			return t('common.backToAppointmentHistory');
		}

		return '';
	};

	const redirectUrl = () => {
		if (isAuthenticated) {
			if (termsAccepted) {
				if (welcomeDontShowAgain) {
					return config.paths.landing;
				}
				return config.paths.welcome;
			}
			return config.paths.contact;
		}

		return config.paths.authentication;
	};

	const backLabel = handleBackLabel();

	return (
		<HomeWrapper>
			{backLabel && !isGuestAuthenticated && (
				<BackContainer>
					<BackButton aria-label="Back button" href="#" onClick={handleGoBack}>
						<Icon name="ArrowLeft" color={theme.colors.primary} />
						<BackButtonText size="m" color={theme.colors.primary}>
							{handleBackLabel()}
						</BackButtonText>
					</BackButton>
				</BackContainer>
			)}
			<Switch>
				<PrivateRoute
					exact
					checkRule={
						isAuthenticated &&
						(!termsAccepted || !selectedOrganization) &&
						configData?.data?.contactEnabled
					}
					path={config.paths.contact}
					component={ContactSubPage}
					redirectPath={config.paths.base}
				/>
				<PrivateRoute
					exact
					path={config.paths.terms}
					checkRule={isAuthenticated && !termsAccepted && selectedOrganization}
					component={TermsSubPage}
					redirectPath={config.paths.base}
				/>
				<PrivateRoute
					exact
					path={config.paths.appointmentHistory}
					checkRule={
						isAuthenticated &&
						termsAccepted &&
						selectedOrganization &&
						configData?.data?.appointmentHistoryEnabled
					}
					component={AppointmentHistoryPage}
					redirectPath={config.paths.base}
				/>
				{configData?.data?.asyncMessagingEnabled && (
					<PrivateRoute
						exact
						path={config.paths.messages}
						checkRule={isAuthenticated && termsAccepted && selectedOrganization}
						component={ConversationsListPage}
						redirectPath={config.paths.base}
					/>
				)}
				{configData?.data?.asyncMessagingEnabled && (
					<PrivateRoute
						exact
						path={config.paths.conversation}
						checkRule={isAuthenticated && termsAccepted && selectedOrganization}
						component={ConversationPage}
						redirectPath={config.paths.base}
					/>
				)}
				{configData?.data?.asyncMessagingEnabled && (
					<PrivateRoute
						exact
						path={[
							config.paths.conversationNewMessage,
							config.paths.conversationNewMessageCreate,
							config.paths.conversationNewMessageCode,
						]}
						checkRule={isAuthenticated && termsAccepted && selectedOrganization}
						component={ConversationNewMessagePage}
						redirectPath={config.paths.base}
					/>
				)}
				<PrivateRoute
					exact
					path={config.paths.appointmentDetails}
					checkRule={
						isAuthenticated &&
						termsAccepted &&
						selectedOrganization &&
						configData?.data?.appointmentHistoryEnabled
					}
					component={AppoinmentHistoryDetails}
					redirectPath={config.paths.base}
				/>
				<PrivateRoute
					exact
					path={config.paths.welcome}
					checkRule={isAuthenticated && termsAccepted && selectedOrganization}
					component={WelcomeSubpage}
					redirectPath={config.paths.base}
				/>
				<PrivateRoute
					exact
					path={config.paths.landing}
					checkRule={isAuthenticated && termsAccepted && selectedOrganization}
					component={LandingPage}
					redirectPath={config.paths.base}
				/>
				<PrivateRoute
					exact
					path={config.paths.serviceSelection}
					checkRule={
						isAuthenticated &&
						termsAccepted &&
						selectedOrganization &&
						configData?.data?.remoteReceptionEnabled
					}
					component={ServiceSelectionPage}
					redirectPath={config.paths.base}
				/>
				<PrivateRoute
					exact
					path={config.paths.bookAppointment}
					checkRule={
						isAuthenticated &&
						termsAccepted &&
						selectedOrganization &&
						configData?.data?.bookingsEnabled
					}
					component={BookAppointmentPage}
					redirectPath={config.paths.base}
				/>
				<PrivateRoute
					exact
					path={config.paths.bookedAppointments}
					checkRule={
						isAuthenticated &&
						termsAccepted &&
						selectedOrganization &&
						configData?.data?.yourBookingsEnabled
					}
					component={BookedAppointmentsPage}
					redirectPath={config.paths.base}
				/>
				<PrivateRoute
					exact
					path={config.paths.chat}
					checkRule={
						(isAuthenticated && termsAccepted && selectedOrganization) ||
						isGuestAuthenticated()
					}
					component={ChatSubpage}
					redirectPath={config.paths.base}
				/>
				<PrivateRoute
					exact
					path={config.paths.feedback}
					checkRule={
						(isAuthenticated && termsAccepted && selectedOrganization) ||
						isGuestAuthenticated()
					}
					component={FeedbackSubPage}
					redirectPath={config.paths.base}
				/>
				{!isAuthenticated && (
					<Route
						exact
						path={[
							config.paths.authentication,
							config.paths.authenticationLogout,
						]}
						render={() => <AuthenticationSubPage />}
					/>
				)}
				<Redirect to={redirectUrl()} />
			</Switch>
		</HomeWrapper>
	);
}

export default HomeScreen;

import styled from 'styled-components';
import { roundedStyles } from 'common/styles/components/wrappers';

export const NinChatWrapper = styled.div`
	${roundedStyles}
	padding: 0;

	.ninchat-iframe-container,
	iframe {
		border-radius: ${(props) => props.theme.border.radius.m};
	}
`;

import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from 'styled-components';
import i18next from 'i18next';

import { Icon, Subtitle } from '@terveystalo/design-system-react-components';

import {
	BackButton,
	BackButtonText,
	BackContainer,
} from 'common/styles/components/back/back';

import { AppContext } from 'app/context/appContext';
import { NinChat } from 'app/components/ninChat/ninChat';
import { config } from 'app/config/config';
import AnalyticsService from 'common/services/analytics/analytics';
import { ChatNotAvailableModal } from 'app/components/chatNotAvailableModal/chatNotAvailableModal';
import { ChatInfoModal } from 'app/components/chatInfoModal/chatInfoModal';
import { QuestionnaireErrorModal } from 'app/components/questionnaireErrorModal/questionnaireErrorModal';
import { Questionnaire } from 'app/components/questionnaire/questionnaire';

import { ChatWrapper, HelpButton, HeaderWrapper } from './chat.styles';

enum Steps {
	initial = 'initial',
	questionnaire = 'questionnaire',
	ninchat = 'ninchat',
}

export const ChatSubpage = () => {
	const location: any = useLocation();
	const { t } = useTranslation();
	const [chatNotAvailableModalOpen, setChatNotAvailableModalOpen] =
		useState(false);
	const [questionnaireErrorModalOpen, setQuestionnaireErrorModalOpen] =
		useState(false);

	const [chatInfoModalOpen, setChatInfoModalOpen] = useState(false);

	const [ninchatConfig, setNinchatConfig] = useState<any>(null);
	const [currentStep, setCurrentStep] = useState(Steps.initial);

	const theme = useContext(ThemeContext);
	const history = useHistory();
	const lang = i18next.language;
	const { isGuestAuthenticated } = useContext(AppContext);

	const handleNinchatError = () => {
		setChatNotAvailableModalOpen(true);
	};

	const handleQuestionnaireDone = ({ metadata }: { metadata: string }) => {
		setNinchatConfig({
			...ninchatConfig,
			questionnaireMetadata: metadata,
		});
		setCurrentStep(Steps.ninchat);
	};

	const handleQuestionnaireError = () => {
		setQuestionnaireErrorModalOpen(true);
	};

	const handleOpenChat = () => {
		setQuestionnaireErrorModalOpen(false);
		setCurrentStep(Steps.ninchat);
	};

	useEffect(() => {
		document.title = t('pageTitles.chat');
	}, [lang]);

	useEffect(() => {
		document.title = t('pageTitles.chat');
		AnalyticsService.sendPageView(t('pageTitles.chat'));
	}, []);

	useEffect(() => {
		if (location.state?.ninChatConfig) {
			setNinchatConfig(location.state?.ninChatConfig);
			if (location.state?.ninChatConfig.showQuestionnaire) {
				setCurrentStep(Steps.questionnaire);
			} else {
				setCurrentStep(Steps.ninchat);
			}
		} else if (!location.state?.ninchatConfig && isGuestAuthenticated()) {
			history.push(config.paths.guestUser);
		} else {
			history.push(config.paths.serviceSelection);
		}
		// eslint-disable-next-line
	}, [location.state]);

	const openHelpInfo = () => {
		setChatInfoModalOpen(true);
	};

	const handleGoToRemoteReception = (ev: React.MouseEvent) => {
		ev.preventDefault();
		history.push(config.paths.serviceSelection);
	};

	return (
		<ChatWrapper>
			<BackContainer>
				<BackButton
					aria-label="Back button"
					href=""
					onClick={handleGoToRemoteReception}
				>
					<Icon name="ArrowLeft" color={theme.colors.primary} />
					<BackButtonText size="m" color={theme.colors.primary}>
						{t('common.backToServiceSelection')}
					</BackButtonText>
				</BackButton>
			</BackContainer>
			<HeaderWrapper>
				<Subtitle size="m" color={theme.colors.zodiacBlack} as="h3">
					{t('chat.headline')}
				</Subtitle>
				<HelpButton iconLeft="Question" onClick={openHelpInfo}>
					{t('chat.button.help')}
				</HelpButton>
			</HeaderWrapper>
			{/* <CustomLead size="s" weight="light">
					{t('chat.body')}
				</CustomLead> */}
			{currentStep === Steps.ninchat && ninchatConfig && (
				<NinChat config={ninchatConfig} onChatError={handleNinchatError} />
			)}
			{currentStep === Steps.questionnaire && (
				<Questionnaire
					success={handleQuestionnaireDone}
					error={handleQuestionnaireError}
					queueId={ninchatConfig.queueId}
				/>
			)}
			<div>
				<ChatNotAvailableModal
					isOpen={chatNotAvailableModalOpen}
					toggleModal={setChatNotAvailableModalOpen}
					appointmentUuid={ninchatConfig?.uuid}
				/>
				<ChatInfoModal
					isOpen={chatInfoModalOpen}
					toggleModal={setChatInfoModalOpen}
				/>
			</div>
			<div>
				<QuestionnaireErrorModal
					isOpen={questionnaireErrorModalOpen}
					toggleModal={setQuestionnaireErrorModalOpen}
					success={handleOpenChat}
				/>
			</div>
		</ChatWrapper>
	);
};

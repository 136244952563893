import React from 'react';
import { DatePickerField } from './DatePicker';

export const FormikDatePicker = (props: any) => {
	const {
		field: { name, value },
		form: { touched, errors, submitCount },
		noState,
	} = props;

	const invalid = (touched[name] || submitCount > 0) && errors[name];
	const valid = !!(!errors[name] && value);

	return (
		<DatePickerField
			{...props}
			{...(!noState && { invalid })}
			{...(!noState && { valid })}
		/>
	);
};

export const config = {
	ninchatUrl: 'https://ninchat.com/app/#/login',
	paths: {
		home: '/expert',
		desktop: '/expert/desktop',
		surveys: '/expert/surveys', // /:appointmentUuid
		appointmentSurveys: '/expert/surveys/appointment', // /:appointmentUuid
		answeredSurveys: '/expert/surveys/answered',
		openSurveys: '/expert/surveys/open',
		forwardedSurveys: '/expert/surveys/forwarded',
		surveyTemplates: '/expert/surveys/templates',
		messagesNew: '/expert/messages/new',
		messagesPatients: '/expert/messages/patients',
		messagesPatientsEnded: '/expert/messages/patientsEnded',
		messagesInbox: '/expert/messages/inbox',
		schedule: '/expert/schedule',
		appointment: '/expert/appointment',
		appointmentHistory: '/expert/appointmentHistory',
		allBookings: '/expert/allBookings',
		login: '/expert/login',
		changePassword: '/expert/changePassword',
		register: '/expert/register',
		surveyAnswers: '/expert/surveyAnswers', // /:responseId
	},
};

import styled from 'styled-components';

import { LanguageSwitch } from '../languageSwitch/languageSwitch';
import { MainMenuWrapper } from '../mainMenu/mainMenu.styles';

export const ProfileMenuWrapper = styled(MainMenuWrapper)<{ visible: boolean }>`
	right: 0;
	left: initial;

	${(props) =>
		!props.visible &&
		`	
			right: -375px;
			left: initial;

		`}
`;

export const NavigationLanguageSwitcher = styled(LanguageSwitch)``;

export const BottomSectionWrapper = styled.div``;

export const MessagesIndicator = styled.div`
	width: 8px;
	height: 8px;
	background: ${(props) => props.theme.colors.primary};
	border-radius: 50%;
`;

export const AsyncMessagesItem = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
`;

import styled from 'styled-components';

export const ServiceItemWrapper = styled.div<{
	disabled?: boolean;
	variant?: 'default' | 'small' | 'appointment';
}>`
	&:last-child {
		border: none;
	}

	${({ theme }) =>
		`
      padding: ${theme.spacing['0_5']} 0;
      border-bottom: 1px solid ${theme.colors.rgba.zodiacBlack0_11};
  `}

	${({ disabled }) =>
		disabled &&
		` 
      opacity: 0.5;
  `}
`;

export const ServiceItemContent = styled.div``;

export const ServiceButton = styled.button<{ noCursor?: boolean }>`
	background: none;
	border: none;
	text-align: left;
	display: flex;
	margin: 0;
	width: 100%;
	cursor: pointer;

	${(props) =>
		props.noCursor &&
		`
		cursor: default;
	`};
`;

export const ServiceNonButton = styled.div`
	display: flex;
	align-items: center;
`;

export const ServiceItemIconWrapper = styled.div`
	margin-right: ${(props) => props.theme.spacing['1']};
	margin-top: ${(props) => props.theme.spacing['0_25']};
	width: 30px;
`;

export const ServiceItemArrowWrapper = styled.div``;

export const ServiceItemInfo = styled.div`
	width: 100%;
`;

export const LoaderWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
`;

import { useState, useEffect } from 'react';

import { checkAppoitmentAvailabilityTime } from 'app/helpers/appointmentsTime';
import { AppointmentItemData } from 'app/services/serviceSelection';

export const useValidAppointments = (
	appointments: Array<AppointmentItemData>,
) => {
	const [filteredAppointments, setFilteredAppointments] = useState<
		Array<AppointmentItemData>
	>([]);

	const setValidAppointments = () => {
		if (appointments?.length > 0) {
			setFilteredAppointments(
				appointments
					.filter((appointment: AppointmentItemData) =>
						checkAppoitmentAvailabilityTime(
							appointment.startTime,
							appointment.duration,
						),
					)
					.sort(
						(a: AppointmentItemData, b: AppointmentItemData) =>
							Number(a.startTime) - Number(b.startTime),
					),
			);
		} else {
			setFilteredAppointments([]);
		}
	};

	useEffect(() => {
		let interval: any = null;
		if (appointments?.length >= 0) {
			setValidAppointments();

			interval = setInterval(() => {
				setValidAppointments();
			}, 5000);
		}
		return () => clearInterval(interval);
		// eslint-disable-next-line
	}, [appointments]);

	return [filteredAppointments];
};

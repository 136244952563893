import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import i18next from 'i18next';

import { Icon, Subtitle } from '@terveystalo/design-system-react-components';

import { Toggle } from 'common/components/toggle/Toggle';
import {
	BackButton,
	BackButtonText,
	BackContainer,
} from 'common/styles/components/back/back';

import { ChatCode } from 'app/components/chatCode/chatCode';
import { ServiceItem } from 'app/components/serviceItem/serviceItem';
import { ChatCodeInfoModal } from 'app/components/chatCodeInfoModal/chatCodeInfoModal';
import { AppointmentModal } from 'app/components/appointmentModal/appointmentModal';

import { config } from 'app/config/config';

import {
	AppointmentItemData,
	ServiceItemData,
} from 'app/services/serviceSelection';
import AnalyticsService from 'common/services/analytics/analytics';

import {
	ChatCodeService,
	getChatCodeServiceAsync,
	getServicesAsync,
	selectServices,
} from 'app/store/serviceSelection/slices/serviceSelection.slice';
import { ServiceItemAppointment } from 'app/components/serviceItem/serviceItemAppointment';
import { selectConfig } from 'app/store/configuration/slices/configuration.slice';
import {
	openNinChatCommon,
	closeAppointmentCommon,
} from 'app/components/appointmentModal/appointmentModalHelpers';
import { useValidAppointments } from 'app/hooks/validAppointments';

import { ContentSection } from '../home.styles';

import {
	AvailableServicesWrapper,
	ServicesSelectionWrapper,
	ChatCodeServicesWrapper,
	ChatCodeServicesInnerWrapper,
	AvailableServicesList,
	ChatCodeToggleButton,
	ChatCodeInfoButton,
	ChatCodeToggleContent,
	DropdownIconWrapper,
	ChatCodeServicesTitleWrapper,
} from './serviceSelection.styles';

export type ChatDetails = Pick<ServiceItemData, 'chatQueueId'>;

export const ServiceSelectionPage = () => {
	const { t } = useTranslation();
	const theme = useContext(ThemeContext);
	const [appointmentDialogOpen, setAppointmentDialogOpen] = useState(false);
	const configData = useSelector(selectConfig);

	const [scheduledAppointmentsActive, setScheduledAppointmentsActive] =
		useState(false);

	const [chatCodeInfoModalOpen, setChatCodeInfoModalOpen] = useState(false);

	const history = useHistory();
	const [selectedChatDetails, setSelectedChatDetails] = useState<any>(null);

	const dispatch = useDispatch();

	const { isLoading, data: servicesData } = useSelector(selectServices);

	const lang = i18next.language;

	useEffect(() => {
		document.title = t('pageTitles.serviceSelection');
	}, [lang]);

	useEffect(() => {
		document.title = t('pageTitles.serviceSelection');
		AnalyticsService.sendPageView(t('pageTitles.serviceSelection'));
		dispatch(getServicesAsync());
	}, []);

	const [filteredAppointments] = useValidAppointments(
		servicesData?.appointments,
	);

	const openNinChat = async ({
		showQuestionnaire,
	}: {
		showQuestionnaire: boolean;
	}) => {
		setAppointmentDialogOpen(false);
		await openNinChatCommon(selectedChatDetails);

		history.push(config.paths.chat, {
			ninChatConfig: {
				...selectedChatDetails,
				showQuestionnaire,
			},
		});
	};

	const openDialog = (item: ServiceItemData) => () => {
		setSelectedChatDetails({
			serviceName: item.serviceName.en,
			queueId: item.chatQueueId,
			chatCodeService: false,
			uuid: item.uuid,
			mandatorySelfAssessment: item.mandatorySelfAssessment,
			optionalSelfAssessment: item.optionalSelfAssessment,
		});
		setScheduledAppointmentsActive(false);
		setAppointmentDialogOpen(true);

		AnalyticsService.sendCustomEvent(
			'remoteAppointmentSelectAppointment',
			'remote appointment',
			'select appointment',
			'',
			{
				serviceType: 'servicesForYou',
				service: item.serviceName.en,
			},
		);
	};

	const openScheduledAppointmentDialog =
		(item: AppointmentItemData, isCancellable: boolean) => async () => {
			setSelectedChatDetails({
				serviceName: item.name,
				queueId: item.queueId,
				chatCodeService: false,
				uuid: item.uuid,
				mandatorySelfAssessment: false,
				optionalSelfAssessment: false,
				isCancellable,
				type: item.type,
				audienceId: item.audienceId,
			});
			setScheduledAppointmentsActive(true);
			setAppointmentDialogOpen(true);
		};

	const handleFormSubmit = (chatCode: string) => {
		dispatch(getChatCodeServiceAsync(chatCode)).then(
			({ payload }: { payload: ChatCodeService }) => {
				if (payload.status !== 'error') {
					setSelectedChatDetails({
						queueId: payload.chatQueueId,
						serviceName: payload.serviceName?.en,
						chatCodeService: true,
						uuid: payload.uuid,
						mandatorySelfAssessment: payload.mandatorySelfAssessment,
						optionalSelfAssessment: payload.optionalSelfAssessment,
						chatCode,
					});
					setAppointmentDialogOpen(true);

					AnalyticsService.sendCustomEvent(
						'remoteAppointmentSelectAppointment',
						'remote appointment',
						'select appointment',
						'',
						{
							serviceType: 'servicesWithChatCode',
							service: payload.serviceName?.en,
						},
					);
				}
			},
		);
	};

	const openChatCodeInfo = (event: React.MouseEvent) => {
		event.preventDefault();
		// setChatCodeInfoModalOpen(true);

		AnalyticsService.sendCustomEvent(
			'clickHowCanIGetChatCode',
			'remote appointment',
			'how to get chat code',
			'',
		);

		history.push(config.paths.profileWelcome, {
			fromChatCodes: true,
		});
	};

	const closeAppointment = () => {
		closeAppointmentCommon(selectedChatDetails);
	};

	const cancelAppointment = () => {
		dispatch(getServicesAsync());
	};

	// const handleBookAppointment = () => {
	// 	AnalyticsService.sendCustomEvent(
	// 		'OpenBookingView',
	// 		'remote appointment',
	// 		'click booking view',
	// 		'',
	// 	);
	// 	history.push(config.paths.bookAppointment);
	// };

	const handleGoToHomepage = (ev: React.MouseEvent) => {
		ev.preventDefault();
		history.push(config.paths.landing);
	};

	return (
		<ServicesSelectionWrapper>
			<BackContainer>
				<BackButton
					aria-label="Back button"
					href=""
					onClick={handleGoToHomepage}
				>
					<Icon name="ArrowLeft" color={theme.colors.primary} />
					<BackButtonText size="m" color={theme.colors.primary}>
						{t('common.backToHomepage')}
					</BackButtonText>
				</BackButton>
			</BackContainer>
			<Subtitle size="m" color={theme.colors.zodiacBlack}>
				{t('serviceSelection.headline')}
			</Subtitle>
			<AvailableServicesWrapper>
				{isLoading && (
					<div>
						<br />
						<Icon name="Spinner" color={theme.colors.primary} />
					</div>
				)}
				{!isLoading && (
					<div>
						{filteredAppointments.length > 0 &&
							configData?.data?.bookingsEnabled && (
								<>
									<Subtitle color={theme.colors.primary} size="s">
										{t('serviceSelection.yourAppointments')}
									</Subtitle>
									{filteredAppointments.map(
										(appointment: AppointmentItemData) => (
											<>
												<ServiceItemAppointment
													onClick={openScheduledAppointmentDialog}
													item={appointment}
												/>
											</>
										),
									)}
									{/* {filteredAppointments.length === 0 && (
									<div>
										<br />
										<Text size="m">
											{t('serviceSelection.noUpcomingAppointments')}
										</Text>
									</div>
								)} */}
								</>
							)}
						{/* {configData?.data?.bookingsEnabled && (
							<BookAppointmentButtonWrapper>
								<CustomButton
									onClick={handleBookAppointment}
									data-testid="serviceSelection-bookAppointment-button"
								>
									{t('serviceSelection.bookings.newAppointment.button')}
								</CustomButton>
							</BookAppointmentButtonWrapper>
						)} */}
					</div>
				)}
				{!isLoading && servicesData?.openServices?.length > 0 && (
					<div>
						<Subtitle color={theme.colors.primary} size="s">
							{t('serviceSelection.availableServices')}
						</Subtitle>
						{servicesData?.openServices?.map(
							(availableService: ServiceItemData) => (
								<AvailableServicesList data-testid="service-open-list">
									<ServiceItem onClick={openDialog} item={availableService} />
								</AvailableServicesList>
							),
						)}
					</div>
				)}
			</AvailableServicesWrapper>
			{servicesData?.chatCodeServices.length > 0 && (
				<ChatCodeServicesWrapper>
					<ChatCodeServicesTitleWrapper>
						<Subtitle color={theme.colors.primary} size="s">
							{t('serviceSelection.chatCode.headline')}
						</Subtitle>
						<ChatCodeInfoButton iconLeft="Question" onClick={openChatCodeInfo}>
							{t('serviceSelection.chatCode.infoButton')}
						</ChatCodeInfoButton>
					</ChatCodeServicesTitleWrapper>
					<ChatCodeServicesInnerWrapper>
						<ContentSection>
							{/* <Text size="m">{t('serviceSelection.chatCode.lead')}</Text>
							<br />
							<br /> */}
							<ChatCode onFormSubmit={handleFormSubmit} />
							<br />

							<Toggle
								buttonStyles={{
									display: 'inline-block',
									width: 'auto',
								}}
								trigger={(collapsed: boolean) => (
									<ChatCodeToggleButton>
										<Subtitle color={theme.colors.primary} size="s">
											{t('serviceSelection.chatCode.services')}
										</Subtitle>
										<DropdownIconWrapper open={collapsed}>
											<Icon name="ChevronDown" color={theme.colors.primary} />
										</DropdownIconWrapper>
									</ChatCodeToggleButton>
								)}
								content={
									<ChatCodeToggleContent>
										{servicesData?.chatCodeServices?.map(
											(availableService: ServiceItemData) => (
												<ServiceItem item={availableService} variant="small" />
											),
										)}
									</ChatCodeToggleContent>
								}
							/>
						</ContentSection>
					</ChatCodeServicesInnerWrapper>
				</ChatCodeServicesWrapper>
			)}
			{appointmentDialogOpen && (
				<AppointmentModal
					isOpen={appointmentDialogOpen}
					toggleModal={setAppointmentDialogOpen}
					successAction={openNinChat}
					closeAction={closeAppointment}
					cancelAction={cancelAppointment}
					scheduledAppointmentsActive={scheduledAppointmentsActive}
					selectedChatDetails={selectedChatDetails}
				/>
			)}
			<ChatCodeInfoModal
				isOpen={chatCodeInfoModalOpen}
				toggleModal={setChatCodeInfoModalOpen}
			/>
		</ServicesSelectionWrapper>
	);
};

import { ServiceItemData } from 'app/services/serviceSelection';
import i18next from 'i18next';
import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';

import { Text, Icon } from '@terveystalo/design-system-react-components';

import {
	ServiceItemWrapper,
	ServiceItemContent,
	ServiceButton,
	ServiceItemIconWrapper,
	ServiceItemArrowWrapper,
	ServiceItemInfo,
	ServiceNonButton,
} from './serviceItem.styles';

interface ServiceItemProps {
	variant?: 'default' | 'small';
	disabled?: boolean;
	item: ServiceItemData;
	onClick?: (item: ServiceItemData) => any;
}

export const ServiceItem = ({
	variant,
	disabled,
	item,
	onClick,
}: ServiceItemProps) => {
	const theme = useContext(ThemeContext);

	const currentLanguage = i18next.language;

	const isSmall = variant === 'small';

	const content = () => (
		<>
			<ServiceItemIconWrapper>
				{item.serviceImage && (
					<img
						src={`${item.serviceImage}?color=${theme.colors.primary.replace(
							'#',
							'',
						)}`}
						alt=""
					/>
				)}
			</ServiceItemIconWrapper>
			<ServiceItemInfo>
				<Text
					size="m"
					color={isSmall ? theme.colors.zodiacBlack65 : theme.colors.primary}
				>
					{item.serviceName[currentLanguage]}
				</Text>
				<div>
					{!isSmall && (
						<Text color="zodiacBlack65" size="m">
							{item.serviceDescription[currentLanguage]}
						</Text>
					)}
				</div>
			</ServiceItemInfo>
			{!isSmall && (
				<ServiceItemArrowWrapper>
					<Icon name="ChevronRight" color={theme.colors.primary} />
				</ServiceItemArrowWrapper>
			)}
		</>
	);

	return (
		<ServiceItemWrapper variant={variant} disabled={disabled}>
			<ServiceItemContent>
				{!isSmall && (
					<ServiceButton onClick={onClick && onClick(item)} type="button">
						{content()}
					</ServiceButton>
				)}
				{isSmall && <ServiceNonButton>{content()}</ServiceNonButton>}
			</ServiceItemContent>
		</ServiceItemWrapper>
	);
};

ServiceItem.defaultProps = {
	variant: 'default',
	disabled: false,
};

import styled from 'styled-components';

export const DatePickerWrapper = styled.div`
	.react-datepicker {
		margin-top: -${(props) => props.theme.spacing['1']};

		&-popper {
			z-index: ${(props) => props.theme.zIndex.datepicker};
		}

		&__triangle {
			left: 80px !important;
			transform: translate(0, 0) !important;

			&:after {
				border-bottom-color: ${(props) => props.theme.colors.white} !important;
			}
		}

		&--time-only {
			.react-datepicker {
				&__time-container {
					width: 150px;
				}
				&__time-box {
					width: 100%;
				}
			}
		}

		&__month-container {
			padding: 0 ${(props) => props.theme.spacing['1']};
		}

		&__tab-loop {
			width: auto;
		}

		&__header {
			background-color: transparent;
			padding: ${(props) => props.theme.spacing['1']} 0
				${(props) => props.theme.spacing['0_5']};
		}

		&__current-month {
			margin-bottom: ${(props) => props.theme.spacing['1']};
		}

		&__day {
			line-height: 40px;
			width: 40px;
			height: 40px;
			vertical-align: middle;
			color: ${(props) => props.theme.colors.zodiacBlack};
			font-size: ${(props) => props.theme.typography.fontSize.body.m};
			font-weight: 500;
			opacity: 0.9;

			${(props) => props.theme.media.mediaQueries.mobileSmall} {
				width: 30px;
				height: 30px;
				line-height: 30px;
				font-size: ${(props) => props.theme.typography.customFontSizes.small};
			}

			&--keyboard-selected,
			&--selected,
			&--in-selecting-range,
			&--in-range {
				background-color: transparent;
				color: ${(props) => props.theme.colors.primary};
				font-weight: 900;

				&:not(.react-datepicker--day-in-selecting-range) {
					background-color: transparent;
					color: ${(props) => props.theme.colors.primary};
					font-weight: 900;
				}
			}

			&--disabled,
			&--outside-month {
				opacity: 0.4;
			}
		}

		&__day-name {
			line-height: 40px;
			width: 40px;
			height: 40px;
			color: ${(props) => props.theme.colors.zodiacBlack};
			font-weight: 700;

			${(props) => props.theme.media.mediaQueries.mobileSmall} {
				width: 30px;
				height: 30px;
				line-height: 20px;
			}
		}

		&__navigation {
			&-icon {
				top: 8px;
				&:before {
					border-color: ${(props) => props.theme.colors.primary};
					border-width: 2px 2px 0 0;
				}
			}
		}
	}
`;

export const DatePickerInputWrapper = styled.div``;

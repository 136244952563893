import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import i18next from 'i18next';

import { Icon } from '@terveystalo/design-system-react-components';

import { ConversationMessages } from 'common/services/asyncMessaging/asyncMessaging';
import { convertDate } from 'common/helpers/dates';
import { Language } from 'common/lang/lang';

import {
	ConversationPatientWrapper,
	ConversationItem,
	ConversationMessage,
	ConversationMessageTitle,
	NewBadge,
	FileButton,
} from './conversationPatient.styles';

interface ConversationPatientProps {
	items: ConversationMessages;
	labels: {
		you: string;
		newLabel: string;
	};
}

const defaultLabels = {
	you: 'You',
	newLabel: 'New',
};

export const ConversationPatient = ({
	items,
	labels,
}: ConversationPatientProps) => {
	const lang = i18next.language;
	const theme = useContext(ThemeContext);

	const { you, newLabel } = { ...labels, ...defaultLabels };

	const handleDownloadFile = (messageId: string) => () => {
		window.open(
			`${process.env.REACT_APP_API_URL}/patient/async-messaging/${messageId}/attachment`,
			'_blank',
		);
	};

	return (
		<ConversationPatientWrapper>
			{items.map((item: ConversationMessages[0]) => (
				<ConversationItem isOwn={item.isOwn}>
					<div>
						<ConversationMessageTitle isOwn={item.isOwn}>
							<div>
								{item.isOwn ? you : item.name},&nbsp;&nbsp;
								{convertDate(item.date, undefined, lang as Language, true)}
							</div>
							{!item.isRead && (
								<div>
									<NewBadge>{newLabel}</NewBadge>
								</div>
							)}
						</ConversationMessageTitle>
						<ConversationMessage isOwn={item.isOwn}>
							{item.type === 'text' ? item.content : ''}
							{item.type === 'file' ? (
								<FileButton onClick={handleDownloadFile(item.id)}>
									<Icon name="Attachment" color={theme.colors.primary} />
									{item.content}
								</FileButton>
							) : (
								''
							)}
						</ConversationMessage>
					</div>
				</ConversationItem>
			))}
		</ConversationPatientWrapper>
	);
};

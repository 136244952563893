import React, { useEffect, useState } from 'react';
import i18next from 'i18next';
import { useSelector } from 'react-redux';
import LocalStorageService from 'common/services/localStorage';
import { languages } from 'common/lang/lang';
import { selectConfig } from 'app/store/configuration/slices/configuration.slice';
import UserService from 'app/services/user/user';

import {
	LanguageWrapper,
	LanguageButton,
	LanguageSimpleButton,
} from './languageSwitch.styles';

interface ILanguageSwitchProps {
	className?: string;
	simpleLinks?: boolean;
}

export const LanguageSwitch = ({
	className,
	simpleLinks,
}: ILanguageSwitchProps) => {
	const [, setLanguage] = useState(i18next.language);
	const config = useSelector(selectConfig);
	const currentLanguage = () => {
		const lang = i18next.language;
		if (lang === 'se') {
			return 'sv';
		}
		return lang;
	};

	useEffect(() => {
		document.documentElement.lang = currentLanguage();
	}, []);

	const changeLanguageHandler = (lang: string) => () => {
		i18next.changeLanguage(lang).then(() => {
			document.documentElement.lang = currentLanguage();
			LocalStorageService.set('language', lang);
			UserService.setClientParams({ lang });
			setLanguage(lang);
		});
	};

	const isFinish = i18next.language === languages.fi;
	const isSwedish = i18next.language === languages.se;
	const isEnglish = i18next.language === languages.en;

	return (
		<LanguageWrapper className={className}>
			{!simpleLinks && (
				<>
					{(config.data.finnishEnabled ||
						config.data.finnishEnabled === undefined) && (
						<LanguageButton
							data-testid="language-switcher-button-fi"
							role="button"
							myvariant={isFinish ? 'primary' : 'secondary'}
							onClick={changeLanguageHandler(languages.fi)}
						>
							<span lang="fi">Suomeksi</span>
						</LanguageButton>
					)}

					{(config.data.swedishEnabled ||
						config.data.finnishEnabled === undefined) && (
						<LanguageButton
							data-testid="language-switcher-button-se"
							role="button"
							myvariant={isSwedish ? 'primary' : 'secondary'}
							onClick={changeLanguageHandler(languages.se)}
						>
							<span lang="sv">På svenska</span>
						</LanguageButton>
					)}

					{(config.data.englishEnabled ||
						config.data.finnishEnabled === undefined) && (
						<LanguageButton
							data-testid="language-switcher-button-en"
							role="button"
							myvariant={isEnglish ? 'primary' : 'secondary'}
							onClick={changeLanguageHandler(languages.en)}
						>
							<span lang="en">In English</span>
						</LanguageButton>
					)}
				</>
			)}
			{simpleLinks && (
				<>
					{(config.data.finnishEnabled ||
						config.data.finnishEnabled === undefined) && (
						<LanguageSimpleButton
							data-testid="language-switcher-button-fi"
							active={isFinish}
							onClick={changeLanguageHandler(languages.fi)}
						>
							<span lang="fi">Suomeksi</span>
						</LanguageSimpleButton>
					)}

					{(config.data.swedishEnabled ||
						config.data.finnishEnabled === undefined) && (
						<LanguageSimpleButton
							data-testid="language-switcher-button-se"
							active={isSwedish}
							onClick={changeLanguageHandler(languages.se)}
						>
							<span lang="sv">På svenska</span>
						</LanguageSimpleButton>
					)}

					{(config.data.englishEnabled ||
						config.data.finnishEnabled === undefined) && (
						<LanguageSimpleButton
							data-testid="language-switcher-button-en"
							active={isEnglish}
							onClick={changeLanguageHandler(languages.en)}
						>
							<span lang="en">In English</span>
						</LanguageSimpleButton>
					)}
				</>
			)}
		</LanguageWrapper>
	);
};

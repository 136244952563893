import styled from 'styled-components';

import { roundedStyles } from 'common/styles/components/wrappers';
import { PillButton } from '@terveystalo/design-system-react-components';

export const ConversationItem = styled.button<{ active?: boolean }>`
	border: none;
	cursor: pointer;
	${roundedStyles};
	padding: ${(props) => props.theme.spacing['1']}
		${(props) => props.theme.spacing['1']};
	margin: ${(props) => props.theme.spacing['0_5']}
		${(props) => props.theme.spacing['0_25']}
		${(props) => props.theme.spacing['0_5']};
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	min-height: 60px;
	font-size: ${(props) => props.theme.typography.fontSize.body.m};
	text-align: left;

	${(props) =>
		props.active &&
		`
		outline: 2px solid ${props.theme.colors.primary};
	`}
`;

export const ItemSubject = styled.div`
	color: ${(props) => props.theme.colors.primary};
`;

export const ItemName = styled.div`
	font-size: ${(props) => props.theme.typography.customFontSizes.extraSmall};
`;

export const UnreadWrapper = styled.div`
	border-radius: 50%;
	background-color: ${(props) => props.theme.colors.primary};
	color: ${(props) => props.theme.colors.white};
	width: 24px;
	height: 24px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: bold;
	min-width: 24px;
`;

export const SpinnerWrapper = styled.div`
	margin: ${(props) => props.theme.spacing['1']};
	display: flex;
	justify-content: center;
`;

export const ConversationItemCard = styled.button`
	display: block;
	border: none;
	width: 100%;
	text-align: left;
	font-size: ${(props) => props.theme.typography.fontSize.body.m};
	${roundedStyles};
	padding: 0;
	margin-bottom: ${(props) => props.theme.spacing['1']};
	cursor: pointer;

	${(props) => props.theme.media.mediaQueries.desktop} {
		display: flex;
	}
`;

export const ConversationItemCardIcon = styled.div`
	max-width: 30px;
`;

export const ConversationItemCardHeader = styled.div`
	display: flex;
	justify-content: space-between;
	border-bottom: 1px solid ${(props) => props.theme.colors.zodiacBlack22};
	width: 100%;
	padding: ${(props) => props.theme.spacing['1']};

	${(props) => props.theme.media.mediaQueries.desktop} {
		max-width: 300px;
		border: none;
		border-right: 1px solid ${(props) => props.theme.colors.zodiacBlack22};
		align-self: stretch;

		${ConversationItemCardIcon} {
			display: none;
		}
	}
`;

export const ConversationItemCardContent = styled.div`
	min-height: 60px;
	padding: ${(props) => props.theme.spacing['1']};

	${(props) => props.theme.media.mediaQueries.desktop} {
		display: flex;
		justify-content: space-between;
		width: 100%;
	}
`;

export const UnreadIndicator = styled.div`
	display: inline-block;
	border-radius: 50%;
	background-color: ${(props) => props.theme.colors.primary};
	width: 8px;
	height: 8px;
	margin-right: ${(props) => props.theme.spacing['1']};
	min-width: 8px;
	margin-top: ${(props) => props.theme.spacing['0_5']};
`;

export const ConversationItemCardContentTop = styled.div`
	display: flex;
`;

export const FileButton = styled(PillButton)`
	margin-right: ${(props) => props.theme.spacing['0_5']};
	font-size: ${(props) => props.theme.typography.customFontSizes.small};

	> span {
		display: flex;
		align-items: center;
		gap: 10px;
	}
`;

export const ConversationItemDate = styled.div<{ unread: boolean }>`
	display: flex;
	align-items: flex-start;
	gap: ${(props) => props.theme.spacing['1']};

	${(props) =>
		props.unread &&
		`
			margin-left: ${props.theme.spacing['1_5']};
	`}

	img {
		display: none;
	}

	${(props) => props.theme.media.mediaQueries.desktop} {
		display: flex;
		justify-content: space-between;

		img {
			display: block;
		}
	}
`;

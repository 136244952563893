import React, {
	useEffect,
	useRef,
	useState,
	CSSProperties,
	useContext,
} from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { ThemeContext } from 'styled-components';
import { Icon, Title, Text } from '@terveystalo/design-system-react-components';
import { useTranslation } from 'react-i18next';
import SurveyService, {
	SurveyAccess,
} from 'common/services/survey/surveyService';
import { CommonApiService } from 'common/services/config';
import AnalyticsService from 'common/services/analytics/analytics';

import {
	BackButton,
	BackButtonText,
	BackContainer,
} from 'common/styles/components/back/back';

import {
	DownloadButton,
	DownloadButtonText,
} from 'common/styles/components/download/download';

import useSurveyHeight from './useSurveyHeight';

import {
	SurveyContainer,
	SurveyFrame,
	ButtonItems,
	ActionButton,
	SurveyButtonIcon,
	SurveyThankYou,
} from './survey.styles';

enum SurveyEvent {
	Init = 'Init',
	Close = 'Close',
	Layout = 'Layout',
}

enum SurveyStatus {
	Loading = 'loading',
	Error = 'error',
	Closed = 'closed',
	Ready = 'ready',
}

interface AuthMessage {
	accessToken: string;
}

interface ThemeMessage {
	// TODO: typing
	[key: string]: any;
}

interface SurveyProps {
	expert?: boolean;
	returnUrl: string;
	apiService: CommonApiService;
}

type SurveyPostMessage =
	| {
			type: 'Auth';
			payload: AuthMessage;
	  }
	| {
			type: 'Theme';
			payload: ThemeMessage;
	  };

export const Survey = ({ expert, returnUrl, apiService }: SurveyProps) => {
	const theme = useContext(ThemeContext);
	const [surveyStatus, setSurveyStatus] = useState<SurveyStatus>(
		SurveyStatus.Loading,
	);
	const { height, setHeightDebounced } = useSurveyHeight('100%');
	const { responseId } = useParams<{ responseId: string }>();
	const history = useHistory();
	const surveyAccess = useRef<SurveyAccess | undefined>();

	const { t } = useTranslation();

	useEffect(() => {
		SurveyService.setApiService(apiService);

		getAccess();
		addMessageListeners();
		return () => {
			removeMessageListeners();
		};
	}, []);

	const isExpertUser = () => !!expert;

	const pdfURL = `${process.env.REACT_APP_API_URL}/survey-expert/answers_pdf/${responseId}`;

	const getAccess = async () => {
		const accesRes = await SurveyService.getSurveyUrlAndAuthToken(
			responseId,
			expert,
		);

		if (!accesRes.token && !accesRes.url) {
			setSurveyStatus(SurveyStatus.Error);
			return;
		}
		surveyAccess.current = accesRes;
		setSurveyStatus(SurveyStatus.Ready);
	};

	const addMessageListeners = () => {
		window.addEventListener('message', handleMessage);
	};

	const removeMessageListeners = () => {
		window.removeEventListener('message', handleMessage);
	};

	const handleMessage = async (event: MessageEvent) => {
		switch (event.data.type) {
			case SurveyEvent.Init: {
				if (surveyAccess.current?.token) {
					sendMessage(
						{
							type: 'Theme',
							payload: surveyAccess.current?.theme,
						},
						event,
					);
					sendMessage(
						{
							type: 'Auth',
							payload: { accessToken: surveyAccess.current?.token },
						},
						event,
					);
				}
				break;
			}
			case SurveyEvent.Close:
				if (isExpertUser()) {
					break;
				}
				setHeightDebounced('500px');
				await SurveyService.closeSurvey(responseId);
				setSurveyStatus(SurveyStatus.Closed);
				break;
			case SurveyEvent.Layout:
				setHeightDebounced(event.data.payload.height);
				break;
			default:
		}
	};

	const handleError = () => {
		setSurveyStatus(SurveyStatus.Error);
	};

	const sendMessage = (
		message: SurveyPostMessage,
		{ source }: MessageEvent,
	) => {
		const targetOrigin = surveyAccess.current?.url;
		source?.postMessage(message, { targetOrigin });
	};

	const handleButton = () => {
		history.push(returnUrl);
	};

	const handleBack = () => {
		// because of iframe, need to go back 2 steps
		history.go(-2);
	};

	const handleLoad = () => {
		if (isExpertUser()) {
			AnalyticsService.sendCustomEvent(
				'surveyOpened',
				'surveys',
				'survey sent',
				'',
			);
		}
	};

	return (
		<>
			{isExpertUser() && (
				<BackContainer>
					<BackButton aria-label="Back button" href="#" onClick={handleBack}>
						<Icon name="ArrowLeft" color={theme.colors.primary} />
						<BackButtonText size="m" color={theme.colors.primary}>
							{t('common.back')}
						</BackButtonText>
					</BackButton>

					<DownloadButton
						aria-label="Download Button"
						href={pdfURL}
						target="_blank"
					>
						<Icon name="Download" color={theme.colors.primary} />
						<DownloadButtonText size="m" color={theme.colors.primary}>
							{t('common.downloadPDF')}
						</DownloadButtonText>
					</DownloadButton>
				</BackContainer>
			)}

			<SurveyContainer
				style={
					{
						'--height': height,
					} as CSSProperties
				}
			>
				{surveyStatus === SurveyStatus.Error && t('surveyPage.error')}
				{surveyStatus === SurveyStatus.Loading && <Icon name="Spinner" />}

				{surveyStatus === SurveyStatus.Ready && (
					<SurveyFrame
						title="Survey"
						src={surveyAccess.current?.url}
						sandbox="allow-top-navigation allow-same-origin allow-scripts allow-forms allow-modals allow-popups"
						referrerPolicy="origin-when-cross-origin"
						onLoad={handleLoad}
						onError={handleError}
						style={
							{
								'--height': height,
							} as CSSProperties
						}
					/>
				)}
				{surveyStatus === SurveyStatus.Closed && (
					<SurveyThankYou>
						<Title as="h1" size="l">
							{t('surveyPage.thankYou.title')}
						</Title>
						<Text size="m">{t('surveyPage.thankYou.content')}</Text>
					</SurveyThankYou>
				)}
				{(surveyStatus === SurveyStatus.Closed ||
					surveyStatus === SurveyStatus.Error ||
					isExpertUser()) && (
					<ActionButton onClick={handleButton}>
						<ButtonItems>
							{t('common.backToHomepage')}
							<SurveyButtonIcon />
						</ButtonItems>
					</ActionButton>
				)}
			</SurveyContainer>
		</>
	);
};

declare global {
	interface Window {
		_paq: {
			push: (eventType: [string, any?, any?]) => void;
		};
		_mtm: {
			push: (obj: any) => void;
		};
	}
}

class Analytics {
	sendPageView(pageTitle: string) {
		// eslint-disable-next-line no-underscore-dangle
		window._paq?.push(['setCustomUrl', document.location.href]);
		if (pageTitle) {
			// eslint-disable-next-line no-underscore-dangle
			window._paq?.push(['setDocumentTitle', pageTitle]);
		}

		// eslint-disable-next-line no-underscore-dangle
		window._paq?.push(['trackPageView']);
	}

	sendCustomDimension(city: string) {
		// eslint-disable-next-line no-underscore-dangle
		window._paq?.push(['setCustomDimensionValue', 1, city]);
	}

	sendCustomEvent(
		event: string,
		category: string,
		action: string,
		name: string,
		customParams?: any,
	) {
		// eslint-disable-next-line no-underscore-dangle
		window._mtm?.push({
			event,
			category,
			action,
			name,
			...customParams,
		});
	}
}

export default new Analytics();

import React from 'react';
import { useTranslation } from 'react-i18next';

import { Text } from '@terveystalo/design-system-react-components';

import {
	CustomDialog,
	CustomDialogHeadline,
} from 'app/styles/components/dialog/dialog';
import { CustomButton } from 'app/styles/components/button/button';

interface BookingConfirmationModalProps {
	isOpen: boolean;
	toggleModal(): void;
	success(): void;
}

export const BookingConfirmationModal = ({
	isOpen,
	toggleModal,
	success,
}: BookingConfirmationModalProps) => {
	const { t } = useTranslation();

	const toggleBookingConfirmationModal = () => {
		toggleModal();
	};

	const handleContinue = () => {
		success();
		toggleModal();
	};

	const handleGoBack = () => {
		toggleModal();
	};

	return (
		<CustomDialog
			className="bookingConfirmationModal"
			open={isOpen}
			onClose={toggleBookingConfirmationModal}
			title={
				<CustomDialogHeadline>
					{t('bookAppointment.confirmationModal.title')}
				</CustomDialogHeadline>
			}
			description={
				<Text size="m">{t('bookAppointment.confirmationModal.lead')}</Text>
			}
		>
			<CustomButton onClick={handleContinue}>
				{t('common.continue')}
			</CustomButton>
			<CustomButton variant="secondary" onClick={handleGoBack}>
				{t('common.back')}
			</CustomButton>
		</CustomDialog>
	);
};

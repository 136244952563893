import React from 'react';
import { useTranslation } from 'react-i18next';

import { Smiley, Text } from '@terveystalo/design-system-react-components';

import { ScoreTypes } from 'common/services/feedback/feedback';
import { CustomLead } from 'common/styles/components/lead/lead';

import {
	FeedbackCircle,
	VotingBottomInfoWrapper,
	CircleWrapper,
	FacesOuterWrapper,
	FacesInnerWrapper,
	LeadTextWrapper,
} from './voteFaces.styles';

interface VoteFacesProsps {
	leadText: string;
	type: ScoreTypes;
	handleFeedbackClick: (number: number, type: ScoreTypes) => () => void;
}

export const VoteFaces = ({
	leadText,
	type,
	handleFeedbackClick,
}: VoteFacesProsps) => {
	const { t } = useTranslation();
	return (
		<div>
			<LeadTextWrapper>
				<CustomLead size="s" weight="light">
					{leadText}
				</CustomLead>
			</LeadTextWrapper>
			<FacesOuterWrapper>
				<FacesInnerWrapper>
					<CircleWrapper>
						<FeedbackCircle index={0} onClick={handleFeedbackClick(1, type)}>
							<Smiley name="VeryNegative" alt="Very negative vote" />
						</FeedbackCircle>
						<FeedbackCircle index={2} onClick={handleFeedbackClick(2, type)}>
							<Smiley name="Negative" alt="Negative vote" />
						</FeedbackCircle>
						<FeedbackCircle index={5} onClick={handleFeedbackClick(3, type)}>
							<Smiley name="Neutral" alt="Neutral vote" />
						</FeedbackCircle>
						<FeedbackCircle index={8} onClick={handleFeedbackClick(4, type)}>
							<Smiley name="Positive" alt="Positive vote" />
						</FeedbackCircle>
						<FeedbackCircle
							index={10}
							onClick={handleFeedbackClick(5, type)}
							data-testid="feedback-score-very-possitive"
						>
							<Smiley name="VeryPositive" alt="Very positive vote" />
						</FeedbackCircle>
					</CircleWrapper>
					<VotingBottomInfoWrapper>
						<Text size="m">
							{type === ScoreTypes.ces || type === ScoreTypes.expertCes
								? t('feedback.veryHard')
								: t('feedback.careSatisfaction.bad')}
						</Text>
						<Text size="m">
							{type === ScoreTypes.ces || type === ScoreTypes.expertCes
								? t('feedback.veryEasy')
								: t('feedback.careSatisfaction.good')}
						</Text>
					</VotingBottomInfoWrapper>
				</FacesInnerWrapper>
			</FacesOuterWrapper>
		</div>
	);
};

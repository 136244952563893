import styled from 'styled-components';
import { Button, Icon } from '@terveystalo/design-system-react-components';

interface SurveyContainerProps {
	height?: string;
}

export const SurveyContainer = styled.div<SurveyContainerProps>`
	display: flex;
	flex-direction: column;

	border: 0;

	align-items: center;
`;

export const SurveyFrame = styled.iframe`
	min-height: 500px;
	width: 100%;
	height: var(--height);
	display: flex;
	overflow: hidden;
	border: 0;
`;

export const ButtonItems = styled.div`
	display: flex;
	align-items: center;
	gap: 10px;
	width: 100%;
`;

export const ActionButton = styled(Button)`
	margin-top: 25px;
	display: flex;
	justify-content: center;
`;

export const SurveyButtonIcon = styled(Icon).attrs((props) => ({
	name: 'ArrowRight',
	color: props.theme.colors.background,
}))`
	display: flex;
	align-self: center;
	justify-self: center;
`;

export const SurveyThankYou = styled.div`
	display: flex;
	flex-direction: column;
	min-height: 300px;
	align-items: center;
	justify-content: center;
	text-align: center;
`;

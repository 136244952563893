import styled from 'styled-components';

import { roundedStyles } from 'common/styles/components/wrappers';
import { CustomTextButton } from 'app/styles/components/button/button';

export const ServicesSelectionWrapper = styled.div``;

export const AvailableServicesWrapper = styled.div`
	padding-top: ${(props) => props.theme.spacing['2']};
`;

export const AvailableServicesList = styled.div`
	${roundedStyles}
	margin: ${(props) => props.theme.spacing['1']} 0;
`;

export const ChatCodeServicesWrapper = styled.div`
	padding-top: ${(props) => props.theme.spacing['2']};
`;

export const ChatCodeServicesInnerWrapper = styled.div`
	margin-top: ${(props) => props.theme.spacing['1']};
`;

export const ChatCodeInfoLink = styled.button`
	cursor: pointer;
	padding: 0;
	border: none;
	background: none;
	display: inline-flex;
	align-items: center;
	text-decoration: none;
	color: ${(props) => props.theme.colors.primary};
	font-size: ${(props) => props.theme.typography.fontSize.body.m};
	font-weight: 700;
	width: auto;
`;

export const DropdownIconWrapper = styled.div<{ open: boolean }>`
	transition: 0.2s all;
	${(props) =>
		!props.open &&
		`
			transform: rotate(180deg);
		`}
`;

export const ChatCodeToggleButton = styled.div`
	display: flex;
	align-items: center;
	${DropdownIconWrapper} {
		margin-left: ${(props) => props.theme.spacing['0_5']};
	}
`;

export const ChatCodeInfoButton = styled(CustomTextButton)`
	border: none;
	background: none;
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const ChatCodeToggleContent = styled.div``;

export const BookAppointmentButtonWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const ChatCodeServicesTitleWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

import { combineReducers, Reducer } from 'redux';
import userReducer from './user/user.slice';
import organizationReducer from './organization/organization.slice';
import iconsReducer from './icons/icons.slice';

const rootReducer: Reducer = combineReducers({
	user: userReducer,
	organization: organizationReducer,
	icons: iconsReducer,
});

export default rootReducer;

import React, { useState } from 'react';

interface ISelectedOrganizationContextType {
	selectedOrganization: string | null;
	setSelectedOrganization: (value: string | null) => void;
}

export const SelectedOrganizationContext =
	React.createContext<ISelectedOrganizationContextType>({
		selectedOrganization: null,
		setSelectedOrganization: () => {},
	});

const { Provider } = SelectedOrganizationContext;

export const SelectedOrganizationProvider = ({ children }: any) => {
	const [selectedOrganization, setSelectedOrganization] = useState<
		string | null
	>(null);

	return (
		<Provider
			value={{
				selectedOrganization,
				setSelectedOrganization,
			}}
		>
			{children}
		</Provider>
	);
};

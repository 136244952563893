import React, { useContext, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { ThemeContext } from 'styled-components';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Text, Icon } from '@terveystalo/design-system-react-components';

import { useConversations } from 'common/hooks/asyncMessaging';

import { selectConfig } from 'app/store/configuration/slices/configuration.slice';
import WebAuthService from 'app/services/webAuth/webAuth';
import { AppContext } from 'app/context/appContext';
import { config } from 'app/config/config';
import { SelectedOrganizationContext } from 'app/context/selectedOrganizationContext';
import PatientApiService from 'app/services/config';

import {
	MenuButton,
	MenuItemWrapper,
	MainMenuIconWrapper,
	TopSectionWrapper,
	MenuText,
	MenuButtonClose,
	MainMenuOverlay,
} from '../mainMenu/mainMenu.styles';

import {
	ProfileMenuWrapper,
	NavigationLanguageSwitcher,
	BottomSectionWrapper,
	MessagesIndicator,
	AsyncMessagesItem,
} from './profileMenu.styles';

export const ProfileMenu = () => {
	const {
		setProfileMenuOpen,
		profileMenuOpen,
		userDetails,
		setLogoutDialogOpen,
		setActOnBehalfDialogOpen,
	} = useContext(AppContext);
	const { t } = useTranslation();
	const menuButtonRef = useRef<any>(null);
	const menuWrapperRef = useRef<any>(null);
	const [menuTransitionEnd, setMenuTransitionEnd] = useState(true);
	const theme = useContext(ThemeContext);
	const history = useHistory();
	const { selectedOrganization } = useContext(SelectedOrganizationContext);
	const termsAccepted = userDetails?.termsAccepted;
	const configData = useSelector(selectConfig);
	const { isGuestAuthenticated } = useContext(AppContext);
	const isAuthenticated = WebAuthService.isAuthenticated();

	const isProfileContactRoute = !!useRouteMatch(config.paths.profileContact);
	const isBookedAppointmentRoute = !!useRouteMatch(
		config.paths.bookedAppointments,
	);
	const isChatHistoryRoute = !!useRouteMatch(config.paths.appointmentHistory);
	const isChangeMyLocationRoute = !!useRouteMatch(
		config.paths.changeMyLocation,
	);

	const isAsyncMessagesRoute = !!useRouteMatch(config.paths.messages);

	const [conversations, , getConversations] = useConversations(
		'asyncMessaging.conversations',
		PatientApiService,
	);
	const [unreadMessages, setUnreadMessages] = useState(0);

	useEffect(() => {
		if (isAuthenticated) {
			getConversations();
		}
	}, []);

	useEffect(() => {
		if (conversations && conversations.length > 0) {
			const unreadMess = conversations.reduce(
				(acc, item) => acc + (item.unreadMessages || 0),
				0,
			);

			setUnreadMessages(unreadMess);
		}
	}, [conversations]);

	const handleCloseClick = (ev: React.MouseEvent) => {
		ev.preventDefault();
		setProfileMenuOpen(false);
	};

	const logOut = () => {
		setLogoutDialogOpen(true);
	};

	const redirectTo = (path: string) => (ev: React.MouseEvent) => {
		ev.preventDefault();

		if (termsAccepted && selectedOrganization) {
			history.push(path, {
				standalone: true,
			});
		}

		setProfileMenuOpen(false);
	};

	const handleFocusTrap = () => {
		const handler = (ev: KeyboardEvent) => {
			const elements = menuWrapperRef.current.querySelectorAll(
				'a:not([disabled]), button:not([disabled])',
			);
			const isTabPressed = ev.key === 'Tab';
			const isEscPressed = ev.key === 'Escape';

			if (isEscPressed) {
				setProfileMenuOpen(false);
				return;
			}

			if (!isTabPressed) {
				return;
			}

			if (ev.shiftKey) {
				/* shift + tab */ if (document.activeElement === elements[0]) {
					elements[elements.length - 1].focus();
					ev.preventDefault();
				}
			} else if (document.activeElement === elements[elements.length - 1]) {
				elements[0].focus();
				ev.preventDefault();
			}
		};

		menuWrapperRef.current.addEventListener('keydown', handler);
	};

	const handleActOnBehalf = (ev: React.MouseEvent) => {
		ev.preventDefault();
		setActOnBehalfDialogOpen(true);
	};

	useEffect(() => {
		const listener = (ev: Event) => {
			if (profileMenuOpen && !menuWrapperRef.current.contains(ev.target)) {
				ev.stopPropagation();
				ev.preventDefault();
				setProfileMenuOpen(false);
			}
		};

		if (profileMenuOpen) {
			document.addEventListener('click', listener);
		}
		return () => {
			document.removeEventListener('click', listener);
		};
		// eslint-disable-next-line
	}, [profileMenuOpen]);

	useEffect(() => {
		if (profileMenuOpen) {
			setTimeout(() => menuButtonRef.current.focus(), 100);
		} else {
			setMenuTransitionEnd(false);
		}
	}, [profileMenuOpen]);

	useEffect(() => {
		menuWrapperRef.current.addEventListener('transitionend', () => {
			setMenuTransitionEnd(true);
		});

		handleFocusTrap();
		// eslint-disable-next-line
	}, []);

	return (
		<>
			<ProfileMenuWrapper
				data-testid="profile-menu"
				aria-label="Main menu"
				aria-hidden={profileMenuOpen ? 'false' : 'true'}
				visible={profileMenuOpen}
				transitionEnd={menuTransitionEnd && !profileMenuOpen}
				ref={menuWrapperRef}
			>
				<TopSectionWrapper>
					<MenuButtonClose
						data-testid="profile-menu-close-button"
						ref={menuButtonRef}
						onClick={handleCloseClick}
						aria-expanded={profileMenuOpen}
					>
						<Icon name="Close" color={theme.colors.primary} />
						<span className="sr-only">{t('common.close')}</span>
					</MenuButtonClose>
				</TopSectionWrapper>
				{userDetails && (
					<>
						<MenuItemWrapper>
							<Text size="m" weight="bold" color={theme.colors.primary}>
								{t('common.hello')},&nbsp;
							</Text>
							<Text size="m" weight="bold" color={theme.colors.primary}>
								{userDetails.firstName}!
							</Text>
						</MenuItemWrapper>

						{configData?.data?.contactEnabled && (
							<MenuItemWrapper
								active={isProfileContactRoute}
								aria-current={isProfileContactRoute ? 'page' : false}
							>
								<MenuButton
									data-testid="profile-menu-profile-contact"
									href="#"
									onClick={redirectTo(config.paths.profileContact)}
									disabled={!termsAccepted || !selectedOrganization}
								>
									<MainMenuIconWrapper>
										<Icon name="Profile" color={theme.colors.primary} />
									</MainMenuIconWrapper>

									<MenuText
										size="m"
										weight="bold"
										active={isProfileContactRoute}
									>
										{t('common.mainMenu.contact')}
									</MenuText>
								</MenuButton>
							</MenuItemWrapper>
						)}

						{configData?.data?.yourBookingsEnabled && (
							<MenuItemWrapper
								active={isBookedAppointmentRoute}
								aria-current={isBookedAppointmentRoute ? 'page' : false}
							>
								<MenuButton
									href="#"
									onClick={redirectTo(config.paths.bookedAppointments)}
									disabled={!termsAccepted || !selectedOrganization}
									data-testid="profile-menu-bookedAppointments-button"
								>
									<MainMenuIconWrapper>
										<Icon name="Time" color={theme.colors.primary} />
									</MainMenuIconWrapper>
									<MenuText
										size="m"
										weight="bold"
										active={isBookedAppointmentRoute}
									>
										{t('common.mainMenu.yourAppointments')}
									</MenuText>
								</MenuButton>
							</MenuItemWrapper>
						)}

						{configData?.data?.appointmentHistoryEnabled && (
							<MenuItemWrapper
								active={isChatHistoryRoute}
								aria-current={isChatHistoryRoute ? 'page' : false}
							>
								<MenuButton
									href="#"
									data-testid="profile-menu-appointment-history"
									onClick={redirectTo(config.paths.appointmentHistory)}
									disabled={!termsAccepted || !selectedOrganization}
								>
									<MainMenuIconWrapper>
										<Icon name="History" color={theme.colors.primary} />
									</MainMenuIconWrapper>
									<MenuText size="m" weight="bold" active={isChatHistoryRoute}>
										{t('common.mainMenu.history')}
									</MenuText>
								</MenuButton>
							</MenuItemWrapper>
						)}

						{configData?.data?.asyncMessagingEnabled && (
							<MenuItemWrapper
								active={isAsyncMessagesRoute}
								aria-current={isAsyncMessagesRoute ? 'page' : false}
							>
								<MenuButton
									href="#"
									data-testid="profile-menu-messages"
									onClick={redirectTo(config.paths.messages)}
									disabled={!termsAccepted || !selectedOrganization}
								>
									<MainMenuIconWrapper>
										<Icon name="Chat" color={theme.colors.primary} />
									</MainMenuIconWrapper>
									<AsyncMessagesItem>
										<MenuText
											size="m"
											weight="bold"
											active={isAsyncMessagesRoute}
										>
											{t('common.mainMenu.asyncMessages')}
										</MenuText>
										{unreadMessages > 0 && <MessagesIndicator />}
									</AsyncMessagesItem>
								</MenuButton>
							</MenuItemWrapper>
						)}

						{configData?.data?.changeLocationEnabled && (
							<MenuItemWrapper
								active={isChangeMyLocationRoute}
								aria-current={isChangeMyLocationRoute ? 'page' : false}
							>
								<MenuButton
									href="#"
									onClick={redirectTo(config.paths.changeMyLocation)}
									disabled={!termsAccepted || !selectedOrganization}
									data-testid="profile-menu-change-my-location"
								>
									<MainMenuIconWrapper>
										<Icon name="MapPin" color={theme.colors.primary} />
									</MainMenuIconWrapper>
									<MenuText
										size="m"
										weight="bold"
										active={isChangeMyLocationRoute}
									>
										{t('changeMyLocation.title')}
									</MenuText>
								</MenuButton>
							</MenuItemWrapper>
						)}

						{configData?.data?.actingEnabled && (
							<MenuItemWrapper>
								<MenuButton
									href="#"
									onClick={handleActOnBehalf}
									disabled={!termsAccepted}
									data-testid="profile-menu-on-behalf"
								>
									<MainMenuIconWrapper>
										<Icon name="AgeGroup" color={theme.colors.primary} />
									</MainMenuIconWrapper>
									<MenuText size="m" weight="bold">
										{t('common.mainMenu.actOnBehalf')}
									</MenuText>
								</MenuButton>
							</MenuItemWrapper>
						)}
					</>
				)}
				{((configData?.data?.logoutEnabled && isAuthenticated) ||
					isGuestAuthenticated()) && (
					<MenuItemWrapper>
						<MenuButton href="#" onClick={logOut} data-testid="logout-button">
							<MainMenuIconWrapper>
								<Icon name="LogOut" color={theme.colors.primary} />
							</MainMenuIconWrapper>
							<Text size="m" weight="bold">
								{t('common.logOut')}
							</Text>
						</MenuButton>
					</MenuItemWrapper>
				)}
				<BottomSectionWrapper>
					<NavigationLanguageSwitcher simpleLinks />
				</BottomSectionWrapper>
			</ProfileMenuWrapper>
			{profileMenuOpen && <MainMenuOverlay />}
		</>
	);
};

import styled from 'styled-components';

export const ToggleWrapper = styled.div``;

export const ToggleButton = styled.button`
	background: none;
	padding: 0;
	border: none;
	width: 100%;
	cursor: pointer;
`;

export const ToggleContent = styled.div`
	overflow: hidden;
	transition: height 0.3s ease-out;
	height: 0;
`;

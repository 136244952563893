import styled from 'styled-components';
import { Text } from '@terveystalo/design-system-react-components';

export const ExpertMessageBubble = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: left;
	align-items: flex-start;
	max-width: 80%;
	margin:${(props) => props.theme.spacing['0_5']}; auto
		${(props) => props.theme.spacing['0_5']} 0;
`;

export const ExpertMessageBubbleText = styled(Text)`
	width: fit-content;
	background-color: ${(props) => props.theme.colors.chatExpert};
	padding: ${(props) => props.theme.spacing['0_5']};
	border-radius: 0px ${(props) => props.theme.border.radius.m}
		${(props) => props.theme.border.radius.m}
		${(props) => props.theme.border.radius.m};
	margin: 0;
`;

export const CustomerMessageBubble = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: right;
	align-items: flex-end;
	max-width: 80%;
	border-radius: ;
	margin: ${(props) => props.theme.spacing['0_5']} 0
		${(props) => props.theme.spacing['0_5']} auto;
`;

export const CustomerMessageBubbleText = styled(Text)`
	width: fit-content;
	color: white;
	background-color: ${(props) => props.theme.colors.primary};
	padding: ${(props) => props.theme.spacing['0_5']};
	border-radius: ${(props) => props.theme.border.radius.m} 0px
		${(props) => props.theme.border.radius.m}
		${(props) => props.theme.border.radius.m};
	margin: 0;
`;

export const CustomTimeText = styled(Text)`
	width: fit-content;
	padding: 0.1em;
	margin: 0;
`;

export const SpinnerWrapper = styled.div`
	text-align: center;
`;

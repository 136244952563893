import { LanguagesType } from 'common/lang/lang';
import { Response } from 'common/typings/response';
import { CommonApiService } from '../config';

export type Specializations = Array<{
	id: string;
	name: LanguagesType;
	availableAt?: number;
}>;

class SpecializationsService {
	apiService: CommonApiService = new CommonApiService();

	public setApiService(apiService: CommonApiService) {
		this.apiService = apiService;
	}

	public async getSpecializations(showExisting: boolean = false): Promise<any> {
		const response: Response<Specializations> = await this.apiService.get(
			showExisting ? '/list/specializations/existing' : '/list/specializations',
		);

		return response.data.map((item: any) => ({
			...item,
		}));
	}
}

export default new SpecializationsService();

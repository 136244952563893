import styled from 'styled-components';
import { Select } from '@terveystalo/design-system-react-components';

export const CustomSelectComponent = styled(Select).attrs({
	grayBackground: true,
})<{ invalid?: boolean }>``;

export const CustomSelectWrapper = styled.div`
	position: relative;
	select {
		background-image: none;
	}
`;

export const IconWrapper = styled.div`
	position: absolute;
	bottom: 18px;
	right: 12px;
	width: 24px;
	height: 24px;
	pointer-events: none;
`;

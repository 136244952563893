export const getCompanyId = () => {
	const [, companyId] = window.location.pathname.split('/');
	if (
		companyId !== 'authentication' &&
		companyId !== 'logout' &&
		companyId !== 'guest-access'
	) {
		return companyId;
	}
	return null;
};

import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';

import {
	Text,
	Icon,
	IconName,
} from '@terveystalo/design-system-react-components';

import {
	ActionText,
	ActivityButton,
	ActivityIconWrapper,
	ActivityItemAction,
	ActivityItemInfo,
	TitleWrapper,
} from './activityItem.styles';

interface ActivityItemProps {
	title: string;
	iconName: IconName;
	text?: string;
	linkText?: string;
	onClick?: () => void;
}

export const ActivityItem = ({
	title,
	iconName,
	text,
	linkText,
	onClick,
	...rest
}: ActivityItemProps) => {
	const theme = useContext(ThemeContext);

	const handleOnClick = () => {
		if (onClick) {
			onClick();
		}
	};

	return (
		<ActivityButton onClick={handleOnClick} {...rest}>
			<ActivityIconWrapper>
				<Icon name={iconName} color={theme.colors.primary} />
			</ActivityIconWrapper>
			<ActivityItemInfo>
				<TitleWrapper nomargin={!text}>
					<Text color={theme.colors.primary} size="m">
						{title}
					</Text>
				</TitleWrapper>
				{!!text && (
					<Text size="m" color={theme.colors.zodiacBlack65}>
						{text}
					</Text>
				)}
			</ActivityItemInfo>
			<ActivityItemAction>
				<ActionText size="m" color={theme.colors.primary}>
					{linkText}
				</ActionText>
				<Icon name="ChevronRight" color={theme.colors.primary} />
			</ActivityItemAction>
		</ActivityButton>
	);
};

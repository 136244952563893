import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import { Text } from '@terveystalo/design-system-react-components';
// import { ThemeContext } from 'styled-components';
import AnalyticsService from 'common/services/analytics/analytics';

import {
	CustomDialog,
	CustomDialogHeadline,
} from 'app/styles/components/dialog/dialog';
import { CustomButton } from 'app/styles/components/button/button';
import {
	getConfigAsync,
	selectConfig,
} from 'app/store/configuration/slices/configuration.slice';
import { getContactAsync } from 'app/store/contact/slices/contact.slice';
import { config } from 'app/config/config';
import ActOnBehalfService from 'app/services/actOnBehalf/index';

import { ActingOnBehalfWrapper, CancelButton } from './actOnBehalfModal.styles';

interface ActOnBehalfModalProps {
	isOpen: boolean;
	toggleModal(open: boolean): void;
}

export const ActOnBehalfModal = ({
	isOpen,
	toggleModal,
}: ActOnBehalfModalProps) => {
	const { t } = useTranslation();
	const configData = useSelector(selectConfig);
	const dispatch = useDispatch();
	const history = useHistory();

	const { isLoading: clearing, mutate: clearOnBehalf } = useMutation(
		() => ActOnBehalfService.clearOnBehalf(),
		{
			onError: () => {},
			onSuccess: async () => {
				toggleModal(false);
				await dispatch(getConfigAsync({}));
				await dispatch(getContactAsync());
				history.push(config.paths.landing);
			},
		},
	);

	const toggleActOnBehalfModal = () => {
		toggleModal(false);
	};

	const handleActAsMyself = () => {
		clearOnBehalf();
	};

	const handleOpenSuomi = () => {
		AnalyticsService.sendCustomEvent(
			'remoteAppointmentOnBehalf',
			'remote appointment',
			'on behalf feature',
			'Open Suomi.fi',
		);
		ActOnBehalfService.connectToSumomiFi();
	};

	return (
		<CustomDialog
			className="actOnBehalfModal"
			open={isOpen}
			onClose={toggleActOnBehalfModal}
			title={
				<CustomDialogHeadline>
					{t('actOnBehalfModal.title')}
				</CustomDialogHeadline>
			}
			description={
				<div>
					<Text size="m">{t('actOnBehalfModal.lead')}</Text>
				</div>
			}
		>
			<ActingOnBehalfWrapper>
				{configData.data?.actOnBehalf?.ssn && (
					<div>
						<div>
							<Text size="m">
								{t('actOnBehalfModal.actingAsSomeone', {
									name: configData.data?.actOnBehalf?.name,
								})}
							</Text>
						</div>
						<br />
						<CustomButton
							{...(clearing ? { icon: 'Spinner' } : {})}
							disabled={clearing}
							onClick={handleActAsMyself}
						>
							{t('actOnBehalfModal.button.actingAsMyself')}
						</CustomButton>
						<br />
						<Text size="m">{`${t('actOnBehalfModal.or')[0].toUpperCase()}${t(
							'actOnBehalfModal.or',
						).slice(1)}`}</Text>
					</div>
				)}
				<div>
					<CustomButton
						disabled={clearing}
						variant="secondary"
						onClick={handleOpenSuomi}
						data-testid="act-on-behalf-button"
					>
						{configData.data?.actOnBehalf?.ssn
							? t('actOnBehalfModal.button.actingAsSomeoneElse')
							: t('actOnBehalfModal.button.actingAsSomeone')}
					</CustomButton>
					<br />
					<Text size="m">{t('actOnBehalfModal.willtakeYouMessage')}</Text>
				</div>
				<br />
				<div>
					<CancelButton onClick={toggleActOnBehalfModal}>
						{t('common.cancel')}
					</CancelButton>
				</div>
			</ActingOnBehalfWrapper>
		</CustomDialog>
	);
};

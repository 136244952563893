import React, { useState } from 'react';
// import { Auth0UserProfile } from 'auth0-js';

import WebAuthService, { UserInitialData } from 'app/services/webAuth/webAuth';
import { Contact } from 'app/services/contact';
import { ToastOpen } from 'app/components/toast/toast';

export type UserDetails = {
	telia: UserInitialData;
	/* auth0: Auth0UserProfile & { 'https://www.terveystalo.com/userguid': string }; */
} & Contact;

export interface GuestDetails {
	name?: string;
	email: string;
}
interface IAppContextType {
	mainMenuOpen: boolean;
	setMainMenuOpen: (value: boolean) => void;
	profileMenuOpen: boolean;
	setProfileMenuOpen: (value: boolean) => void;
	userDetails: UserDetails | null;
	setUserDetails: (value: any) => void;
	logoutDialogOpen: boolean;
	setLogoutDialogOpen: (value: boolean) => void;
	toastOpen: ToastOpen;
	toggleToast: (value: any, options?: any) => void;
	cookiesOpen: any;
	toggleCookies: (value: any) => void;
	actOnBehalfDialogOpen: boolean;
	setActOnBehalfDialogOpen: (value: boolean) => void;
	guestDetails: GuestDetails | null;
	setGuestDetails: (value: GuestDetails | null) => void;
	isGuestAuthenticated: () => boolean;
}

export const AppContext = React.createContext<IAppContextType>({
	mainMenuOpen: false,
	setMainMenuOpen: () => {},
	profileMenuOpen: false,
	setProfileMenuOpen: () => {},
	userDetails: null,
	setUserDetails: () => {},
	logoutDialogOpen: false,
	setLogoutDialogOpen: () => {},
	toastOpen: { text: false },
	toggleToast: () => {},
	cookiesOpen: false,
	toggleCookies: () => {},
	actOnBehalfDialogOpen: false,
	setActOnBehalfDialogOpen: () => {},
	guestDetails: null,
	setGuestDetails: () => {},
	isGuestAuthenticated: () => false,
});

const { Provider } = AppContext;

export const AppProvider = ({ children }: any) => {
	const [mainMenuOpen, setMainMenuOpen] =
		useState<IAppContextType['mainMenuOpen']>(false);
	const [profileMenuOpen, setProfileMenuOpen] =
		useState<IAppContextType['profileMenuOpen']>(false);

	const [userDetails, setUserDetails] =
		useState<IAppContextType['userDetails']>(null);
	const [logoutDialogOpen, setLogoutDialogOpen] =
		useState<IAppContextType['logoutDialogOpen']>(false);
	const [guestDetails, setGuestDetails] =
		useState<IAppContextType['guestDetails']>(null);

	const [toastOpen, toggleToast] = useState<IAppContextType['toastOpen']>({
		text: false,
	});

	const [cookiesOpen, toggleCookies] =
		useState<IAppContextType['cookiesOpen']>(false);

	const [actOnBehalfDialogOpen, setActOnBehalfDialogOpen] =
		useState<IAppContextType['logoutDialogOpen']>(false);

	const isAuthenticated = WebAuthService.isAuthenticated();
	const useDetailsData = isAuthenticated ? userDetails : null;
	const isGuestAuthenticated = () => !!guestDetails;

	return (
		<Provider
			value={{
				mainMenuOpen,
				setMainMenuOpen,
				profileMenuOpen,
				setProfileMenuOpen,
				userDetails: useDetailsData,
				setUserDetails,
				logoutDialogOpen,
				setLogoutDialogOpen,
				toastOpen,
				toggleToast,
				cookiesOpen,
				toggleCookies,
				actOnBehalfDialogOpen,
				setActOnBehalfDialogOpen,
				guestDetails,
				setGuestDetails,
				isGuestAuthenticated,
			}}
		>
			{children}
		</Provider>
	);
};

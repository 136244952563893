import React, { useContext, useState, useEffect, useRef } from 'react';
import { ThemeContext } from 'styled-components';

import {
	Icon,
	Subtitle,
	Text,
} from '@terveystalo/design-system-react-components';

import {
	AutocompleteWrapper,
	AutocompleteInnerWrapper,
	AutocompleInput,
	ItemWrapper,
	Autocomplete,
	AutocompleteInputWrapper,
	AutocompleteIconWrapper,
	ChildContainer,
	MenuWrapper,
	Menu,
	BackMenu,
} from './Autocomplete.styles';

interface Item {
	label: string;
	value: string;
	childs?: Array<{ label: string; value: string }>;
}

export const FormikAutocompleteComponent = (formikProps: any) => {
	const theme = useContext(ThemeContext);

	const {
		field: { name, value },
		form: { setFieldValue, touched, errors, submitCount },
		items,
		loading,
		label,
		required,
		disabled,
		onChangeValue,
		renderMode,
	} = formikProps;

	const invalid = (touched[name] || submitCount > 0) && errors[name];
	const valid = !errors[name] && value;

	const [textValue, setTextValue] = useState(value);

	const [dropdownVisible, setDropdownVisible] = useState(false);
	const autocompleteWrapperRef = useRef<any>(null);

	const [activeParent, setActiveParent] = useState<any>(null);

	useEffect(() => {
		checkActiveParent();

		if (!value) {
			setTextValue('');
		} else {
			let selectedText = '';

			items.some((item: Item) => {
				if (item.childs) {
					const selectedChild = item.childs.find(
						(child: { label: string; value: string }) => child.value === value,
					);

					if (selectedChild) {
						selectedText = selectedChild.label;
						return true;
					}
				} else if (item.value === value) {
					selectedText = item.label;
					return true;
				}
				return false;
			});

			if (selectedText) {
				setTextValue(selectedText);
			}
		}
	}, [value, items]);

	useEffect(() => {
		const listener = (ev: Event) => {
			if (
				dropdownVisible &&
				autocompleteWrapperRef.current &&
				!autocompleteWrapperRef.current.contains(ev.target)
			) {
				ev.stopPropagation();
				ev.preventDefault();
				setDropdownVisible(false);
				if (onChangeValue) {
					onChangeValue('');
				}

				setActiveParent(null);
			}
		};

		if (dropdownVisible) {
			document.addEventListener('click', listener);
		}
		return () => {
			document.removeEventListener('click', listener);
		};
		// eslint-disable-next-line
	}, [dropdownVisible]);

	const checkActiveParent = () => {
		if (items.length > 0) {
			const selectedParent = items.find(
				(item: Item) =>
					item.childs &&
					item.childs.find(
						(child: { label: string; value: string }) => child.value === value,
					),
			);

			if (selectedParent) {
				setActiveParent(selectedParent);
			}
		}
	};

	const handleInputClick = () => {
		checkActiveParent();
		setDropdownVisible(true);
	};

	let menuStyle: any = {
		zIndex: theme.zIndex.autocompleteDropdown,
		borderRadius: theme.border.radius.m,
		overflow: 'auto',
		maxHeight: '400px',
	};

	if (renderMode === 'fixed') {
		menuStyle = {
			...menuStyle,
			position: 'fixed',
			marginTop: '2px',
			width: 'auto',
		};
	} else {
		menuStyle = {
			...menuStyle,
			position: 'absolute',
			top: '65px',
			left: '0',
			width: '100%',
			marginTop: '0px',
		};
	}

	const handleSelect = (text: string, itemValue: string) => {
		setTextValue(text);
		setDropdownVisible(false);
		setFieldValue(name, itemValue);
		setActiveParent(null);
		if (onChangeValue) {
			onChangeValue(itemValue);
		}
	};

	return (
		<AutocompleteWrapper ref={autocompleteWrapperRef}>
			{label && (
				<Subtitle size="s">
					{label}
					{required ? (
						<Text size="l" color="attentionRed">
							*
						</Text>
					) : (
						''
					)}
				</Subtitle>
			)}
			<AutocompleteInnerWrapper>
				<Autocomplete
					getItemValue={(item: Item) => item.label}
					items={items}
					shouldItemRender={(item: Item, val: string) =>
						item.label.toLowerCase().indexOf(val.toLowerCase()) > -1 ||
						(item.childs &&
							item.childs.find(
								(child) =>
									child.label.toLowerCase().indexOf(val.toLowerCase()) > -1,
							))
					}
					renderMenu={(menuItems: any, _menuValue: any, style: any) => (
						<MenuWrapper>
							<Menu
								hasItems={menuItems.length > 0}
								style={{
									...style,
									...menuStyle,
									visibility: activeParent ? 'hidden' : 'visible',
								}}
							>
								{menuItems}
							</Menu>
							{activeParent && (
								<ChildContainer>
									<ItemWrapper
										onClick={(ev) => {
											setActiveParent(null);
											ev.preventDefault();
											ev.stopPropagation();
										}}
										isHighlighted={false}
										type="button"
										noBorder
									>
										<BackMenu>
											<Icon name="ChevronLeft" />
											<Subtitle size="xs">{activeParent.label}</Subtitle>
										</BackMenu>
									</ItemWrapper>
									<div>
										{activeParent?.childs &&
											activeParent.childs.map((child: any) => (
												<ItemWrapper
													isHighlighted={false}
													isSelected={child.value === value}
													onClick={() => handleSelect(child.label, child.value)}
												>
													{child.label}
												</ItemWrapper>
											))}
									</div>
								</ChildContainer>
							)}
						</MenuWrapper>
					)}
					renderInput={(props: any) => (
						<AutocompleteInputWrapper>
							<AutocompleInput
								{...props}
								valid={valid}
								invalid={invalid}
								name={name}
								disabled={disabled}
								onClick={handleInputClick}
							/>
							<AutocompleteIconWrapper>
								{loading ? (
									<Icon name="Spinner" color={theme.colors.primary} />
								) : (
									<Icon name="ChevronDown" color={theme.colors.primary} />
								)}
							</AutocompleteIconWrapper>
						</AutocompleteInputWrapper>
					)}
					renderItem={(item: Item, isHighlighted: boolean) => (
						<ItemWrapper
							className="autocomplete-item"
							isHighlighted={isHighlighted}
							isSelected={item.value === value}
							type="button"
						>
							{item.label}
							{item.childs && item.childs.length > 0 && (
								<Icon name="ChevronRight" />
							)}
						</ItemWrapper>
					)}
					open={dropdownVisible}
					value={textValue}
					wrapperStyle={{
						display: 'block',
					}}
					onChange={(ev: React.ChangeEvent<HTMLSelectElement>) => {
						setTextValue(ev.target.value);
						setDropdownVisible(true);
						setActiveParent(null);

						if (!ev.target.value) {
							setFieldValue(name, '');
						}
					}}
					onSelect={(
						text: string,
						{
							value: itemValue,
							childs,
						}: {
							value: string;
							childs: Array<{ label: string; value: string }>;
						},
					) => {
						if (childs && childs.length > 0) {
							setActiveParent({ value: itemValue, childs, label: text });
						} else {
							handleSelect(text, itemValue);
						}
					}}
				/>
			</AutocompleteInnerWrapper>
		</AutocompleteWrapper>
	);
};

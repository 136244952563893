import styled from 'styled-components';
import { Lead } from '@terveystalo/design-system-react-components';

export const CustomLead = styled(Lead)<{ weight?: string }>`
	${(props) =>
		props.weight === 'light' &&
		`
    font-weight: 300;
  `}
`;
